import {
  ApiCreateOrder,
  ApiDeleteOrderLocations,
  ApiDeleteOrders,
  ApiDeleteSuccess,
  ApiOrderDetails,
  ApiSubmitOrder,
  ApiSubmitOrderOut,
  ApiUpdateOrder,
  orderApiEndpoints,
  OrderSummaryMap
} from '@yourxx/types';
import type { OrderDetails } from '@yourxx/types/src/api/order/order';

import { toRequest } from '../../utils';
import { apiClient } from '../client';

type DeleteSuccess = { success: boolean };
type ErrorResponse = { error: string };

export const getOrderSummaries = async (params: ApiOrderDetails): Promise<OrderSummaryMap> => {
  if (params.orderIds && Array.isArray(params.orderIds)) {
    params.orderIds = params.orderIds.join(' ');
  }
  const request = toRequest({ path: orderApiEndpoints.order, method: 'GET', params });
  const response = await apiClient.request<OrderSummaryMap>(request);
  if ('error' in response) throw new Error(String(response.error));
  return response;
};

export const createOrderApi = async (body: ApiCreateOrder): Promise<OrderDetails> => {
  const request = toRequest({ path: orderApiEndpoints.order, method: 'PUT', body });
  const response = await apiClient.request<OrderDetails | ErrorResponse>(request);
  if ('error' in response) throw new Error(String(response.error));
  return response;
};

export const updateOrderApi = async (body: ApiUpdateOrder): Promise<OrderDetails> => {
  const request = toRequest({ path: orderApiEndpoints.order, method: 'PATCH', body });
  const response = await apiClient.request<OrderDetails>(request);
  if ('error' in response) throw new Error(String(response.error));
  return response;
};

export const deletesOrderApi = async (body: ApiDeleteOrders): Promise<ApiDeleteSuccess> => {
  const request = toRequest({ path: orderApiEndpoints.order, method: 'DELETE', body });
  const response = await apiClient.request<ApiDeleteSuccess>(request);
  if ('error' in response) throw new Error(String(response.error));
  return response;
};

export const deleteOrderLocationApi = async (body: ApiDeleteOrderLocations): Promise<DeleteSuccess> => {
  const request = toRequest({ path: orderApiEndpoints.orderLocation, method: 'DELETE', body });
  const response = await apiClient.request<ApiDeleteSuccess>(request);
  if ('error' in response) throw new Error(String(response.error));
  return response;
};

export const placeOrderApi = async (body: ApiSubmitOrder): Promise<ApiSubmitOrderOut> => {
  const request = toRequest({ path: orderApiEndpoints.orderSubmit, method: 'PUT', body });
  const response = await apiClient.request<ApiSubmitOrderOut>(request);
  if ('error' in response) throw new Error(String(response.error));
  return response;
};
