import type { Event } from '../../event-tracker';
import { AssortmentTypes } from '../assortments';

export type ApiGetCustomerIn = CustomerList & CustomerAssortment;
export type ApiGetCustomerOut = GetCustomerListResponse[] | GetCustomerAssortmentResponse | { error: string };

export const CustomerView = {
  List: 'list',
  Assortment: 'assortment'
} as const;

export type CustomerList = {
  type: typeof CustomerView.List;
  brand: string;
};

export type CustomerAssortment = {
  type: typeof CustomerView.Assortment;
  customerId: string;
  brand: string;
  seasons?: string;
};

export type GetCustomerListResponse = {
  customerId: string;
  customerName: string;
  planningCd?: string;
  planningName?: string;
  assortments?: { season: string; active?: number; archived?: number; final?: number }[];
};

export type CustomerAssortmentProps = {
  assortmentId: string;
  assortmentName: string;
  assortmentType?: AssortmentTypes;
  archivedAt?: string;
  updatedAt?: string;
  publishedAt?: string;
  add?: number;
  pending?: number;
  drop?: number;
  remove?: number;
  parentName?: string;
  parentId?: string;
  region?: string;
  brand?: string;
};

export type CustomerAssortmentsSeasonMap = {
  [seasonKey: string]: CustomerAssortmentProps[];
};

export type SeasonList = {
  seasonId: string;
  season: string;
  parentName: string;
  parentId: string;
  cutOff?: string;
};

export type CmApiLocations = {
  shipTo: Record<string, CustomerShipTo>;
  soldTo: Record<string, CustomerSoldTo>;
  billTo: Record<string, CustomerBillTo>;
};

export type CmApiData = {
  soldTo?: string;
  planningCd?: string;
  planningName?: string;
  sapName?: string;
  salesOrg?: string;
  division?: string;
  channel?: string;
  language?: string;
  paymentTerms?: string;
  locations?: CmApiLocations;
  altCustomer?: CmApiData[];
  region?: string;
};

export type CustomerSoldTo = {
  id: string;
  isPrimary: boolean;
  name: string;
  houseNoAndStreet: string;
  city: string;
  postalCode: string;
  country?: string;
  emailAddress?: string;
  salesOrg: string;
  division: string;
  channel: string;
  region: string;
  paymentTerms: string;
};

export type CustomerShipTo = {
  id: string;
  isPrimary: boolean;
  name: string;
  houseNoAndStreet: string;
  city: string;
  postalCode: string;
  country: string;
};

export type CustomerBillTo = {
  id: string;
  isPrimary: boolean;
  name: string;
  houseNoAndStreet: string;
  city: string;
  postalCode: string;
  country?: string;
  emailAddress?: string;
  salesOrg: string;
  division: string;
  channel: string;
  region: string;
  paymentTerms: string;
};

export type CustomerOrders = {
  orderId: string;
  assortmentId: string;
  season: string;
};
export type CustomerOrderSeasonMap = {
  [seasonKey: string]: Array<CustomerOrders>;
};
export type GetCustomerAssortmentResponse = {
  customerId: string;
  customerName: string;
  urlSlug?: string;
  soldTo?: string;
  region?: string;
  brand?: string;
  seasons?: SeasonList[];
  assortments?: CustomerAssortmentsSeasonMap;
  orders?: CustomerOrderSeasonMap;
} & CmApiData;

export type GetAuditTrailResponse = {
  event_id: string;
  item_id: string;
  item_type: string;
  created_at: string;
  created_by: string;
  first_name: string | null;
  last_name: string | null;
  name: Event;
  payload: { pc9s?: string[] };
}[];
