import type { Month } from 'services/OrdersService/OrderSizingManager/types';
import { parseMonthId } from 'services/OrdersService/utils';
import { memoise, tail, unique } from 'utils';

// Opt + '-' on Mac keyboard. Longer dash to indicate ranges.
const ndash = '–';

/**
 * Formats contiguous ranges and individual (non-range) months.
 */
export const formatSizedMonthRanges = (raw: Month[]) => {
  const months = unique(raw).sort();
  const formatted: string[] = [];

  const range: number[] = [];

  for (let i = 0; i < months.length; i++) {
    const m = Number(months[i]);
    range.push(m);

    const next_m = Number(months[i + 1]);

    if (isNaN(next_m) || !areContiguous(m, next_m)) {
      formatted.push(format(range));
      range.length = 0;
    }
  }

  return formatted.join(', ');
};

/**
 * We're memoising the results here so that after a few renders we won't be doing these calculations.
 */
const areContiguous = memoise((left: number, right: number) => {
  const [y, m] = [Math.floor(left / 100), left % 100];
  const [next_y, next_m] = [Math.floor(right / 100), right % 100];
  return (y === next_y && m + 1 === next_m) || (y + 1 === next_y && m === 12 && next_m === 1);
});

const format = memoise((range: number[]) => {
  if (range.length === 1) {
    const [startAndEnd] = range;
    return parseMonthId(startAndEnd).monthName;
  } else {
    const [start, end] = [range[0], tail(range)] as const;
    return [parseMonthId(start).monthName, parseMonthId(end).monthName].join(ndash);
  }
});
