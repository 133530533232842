import { Check } from 'assets/icons';
import { Checkbox } from 'components/Checkbox';
import { Image } from 'components/Image';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import type { OrderProductModel } from 'services';
import { styled } from 'styled-components';
import { multiSort, rem, themed } from 'utils';

import { Nullable } from '@yourxx/support';

import { AgListView } from '../../../../../components';
import { useOrderProductsColDefs } from '../../../hooks/useOrderProductsColDefs';
import type { ProductDisplayInfo } from '../../types';

const ProductCard = styled.div`
  display: flex;
  gap: ${rem(12)};
  padding: ${rem(12)};
  background-color: ${themed('color.white')};
  border-radius: ${themed('borderRadius')};
  width: fit-content;

  h4,
  h5 {
    margin: 0;
  }

  h4 {
    ${themed('typography.h4')};
    font-weight: ${themed('font.weight.semiBold')};
  }

  h5 {
    color: ${themed('color.grey')};
    ${themed('typography.h5')};
    font-weight: ${themed('font.weight.regular')};
  }
`;
const Thumbnail = styled.div`
  overflow: hidden;
  width: ${rem(90)};
  height: ${rem(90)};
  border-radius: ${themed('borderRadius')};
`;
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${themed('spacing.s')};
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(12)};
  width: ${rem(1024)};

  section {
    display: flex;
    flex-direction: column;
    gap: ${rem(12)};
    flex-grow: 1;
    width: 100%;
  }

  section > h3 {
    margin: 0;
    color: ${themed('color.grey')};
    ${themed('typography.h3')};
    font-weight: ${themed('font.weight.bold')};
    text-transform: uppercase;
  }
`;

const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  justify-self: center;
  gap: ${themed('spacing.xl')};
  padding: ${themed('spacing.xxxl')} 0;
`;
const ConfirmTitle = styled.h2`
  margin: 0;
  ${themed('typography.h1')};
  font-weight: ${themed('font.weight.black')};
  text-transform: uppercase;
`;
const ConfirmDescription = styled.p`
  margin: 0;
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.regular')};
  text-align: center;
`;

export const CopyTo = <T extends ProductDisplayInfo<OrderProductModel>>({
  currency,
  source,
  products,
  targets,
  canCopyTo,
  onSelect
}: {
  currency?: string;
  source: T;
  products: readonly T[];
  targets: readonly T[];
  canCopyTo: (target: T) => boolean;
  onSelect: (targets: readonly T[]) => void;
}) => {
  const [str] = useLocalisation();
  return (
    <Container>
      <section>
        <h3>{str('general.from')}</h3>
        <Product {...source} />
      </section>
      <section>
        <h3>{str('general.to')}</h3>
        <PlaceholderGrid<T>
          currency={currency}
          products={products}
          selected={targets}
          canSelect={canCopyTo}
          onSelect={p => onSelect([p])}
        />
      </section>
    </Container>
  );
};

export const PasteFrom = <T extends ProductDisplayInfo<OrderProductModel>>({
  currency,
  products,
  target,
  source,
  canPasteFrom,
  selectSource
}: {
  currency?: string;
  products: readonly T[];
  target: T;
  source: Nullable<T>;
  canPasteFrom: (source: T) => boolean;
  selectSource: (source: T) => void;
}) => {
  const [str] = useLocalisation();
  return (
    <Container>
      <section>
        <h3>{str('general.from')}</h3>
        <PlaceholderGrid<T>
          currency={currency}
          products={products}
          selected={source ? [source] : []}
          canSelect={canPasteFrom}
          onSelect={selectSource}
        />
      </section>
      <section>
        <h3>{str('general.to')}</h3>
        <Product {...target} />
      </section>
    </Container>
  );
};

export const ConfirmOverwrite = <T extends ProductDisplayInfo<OrderProductModel>>({
  toBeOverwritten
}: {
  toBeOverwritten: readonly T[];
}) => {
  const [str] = useLocalisation();
  return (
    <Container>
      <ConfirmContent>
        <ConfirmTitle>{str('Order.sizing.copyToOverwriteTitle')}</ConfirmTitle>
        {toBeOverwritten.map(product => (
          <Product key={product.pc9} {...product} />
        ))}
        <ConfirmDescription>{str('Order.sizing.copyToOverwriteDescription')}</ConfirmDescription>
      </ConfirmContent>
    </Container>
  );
};

const Product = ({ imageUrl, name, pc9 }: ProductDisplayInfo<OrderProductModel>) => (
  <ProductCard>
    <Thumbnail>
      <Image width={90} src={imageUrl} />
    </Thumbnail>
    <ProductInfo>
      <h4>{name}</h4>
      <h5>{pc9}</h5>
    </ProductInfo>
  </ProductCard>
);

const Checkmark = styled(Check)`
  path {
    stroke: ${themed('color.black')};
  }
`;
const GridWrapper = styled.div`
  height: ${rem(460)};
`;

const PlaceholderGrid = <T extends ProductDisplayInfo<OrderProductModel>>({
  currency,
  products,
  selected,
  onSelect,
  canSelect
}: {
  currency?: string;
  products: readonly T[];
  selected: readonly T[];
  canSelect: (product: T) => boolean;
  onSelect: (product: T) => void;
}) => {
  const [str] = useLocalisation();
  const sortedProducts = useMemo(
    () => multiSort(products, { by: ['matchesGrid', 'units', 'pc9'], dir: -1 }),
    [products]
  );

  const columns = useOrderProductsColDefs({ currency, allowPC9Navigation: false });
  const customColumns = useMemo(
    () => [
      {
        colId: 'selectProduct',
        width: 48,
        sortable: false,
        cellRenderer: ({ data }: { data: T }) => {
          if (!canSelect(data)) return null;
          return <Checkbox rounded checked={selected.includes(data)} onChange={() => onSelect(data)} />;
        }
      },
      ...columns.filter(({ colId }) => colId && ['image', 'pc9', 'name', 'firstOnFloor'].includes(colId)),
      {
        field: 'units',
        headerName: str('Landing.order.table.units')
      },
      {
        headerName: 'Grid Match',
        cellRenderer: ({ data }: { data: T }) => (canSelect(data) ? <Checkmark /> : null),
        width: 120
      }
    ],
    [canSelect, columns, onSelect, selected, str]
  );

  return (
    <GridWrapper>
      <AgListView data={sortedProducts} columns={customColumns} />
    </GridWrapper>
  );
};
