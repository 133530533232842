import { ReactNode } from 'react';

import {
  AuditOrderPlacementCreatedId,
  AuditOrderPlacementLocationError,
  AuditOrderPlacementNoProducts,
  AuditOrderPlacementOutsideWindow,
  AuditOrderPlacementRemovedProducts,
  AuditOrderPlacementResult,
  AuditOrderPlacementUserError
} from '@yourxx/types';

import { auditCardTs, AuditPanelEvents } from '../EventCards';

const toLocaleString = (date: string | Date) => {
  return new Date(date).toLocaleString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
};

export const orderPlacementEventCards: Record<string, (event: AuditPanelEvents) => ReactNode> = {
  order_placement_removed_products: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderPlacementRemovedProducts>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} - unavailable products</h4>
        <span>${event.products.map(p => `${p.pc9} ${p.size1}:${p.size2 ?? '-'}`).join(', ')}</span>
      </div>
    );
  },
  order_placement_user_auth_error: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderPlacementUserError>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} order placement failed</h4>
        <span>User is not authorized</span>
      </div>
    );
  },
  order_placement_outside_window: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderPlacementOutsideWindow>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} order placement failed</h4>
        <span>Outside prebook window</span>
        {event.prevClose ? (
          <div>
            <span>Window Closed: {toLocaleString(event.prevClose)}</span>
          </div>
        ) : null}
        {event.nextOpen ? (
          <div>
            <span>Next Window: {toLocaleString(event.nextOpen)}</span>
          </div>
        ) : null}
      </div>
    );
  },
  order_placement_no_products: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderPlacementNoProducts>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} order placement failed</h4>
        <span>No products in order, please size the order</span>
      </div>
    );
  },
  order_placement_location_error: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderPlacementLocationError>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} order placement failed</h4>
        <span>Invalid shipping or billing address</span>
      </div>
    );
  },
  order_placement_created_id: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderPlacementCreatedId>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} passed validation</h4>
        <span>Order References - {event.orderPlacementIds.join(', ')}</span>
      </div>
    );
  },
  order_placement_result: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderPlacementResult>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} order status</h4>
        <span>{event.reason}</span>
      </div>
    );
  }
};
