import { createContext, useContext, useEffect, useRef } from 'react';

import { Nullable } from '@yourxx/support';

import { useCreateAssortmentsService } from './AssortmentsService';
import { useCreateCustomersService } from './CustomersService';
import { EventBus } from './EventBus';
import { useCreateExportsService } from './ExportsService';
import { useCreateLocalisationService } from './LocalisationService';
import { useCreateOrdersService } from './OrdersService';
import { useCreateProductFiltersService } from './ProductFiltersService/useCreateProductFiltersService';
import { useCreateProductsService } from './ProductsService';
import { useCreateProductVideoService } from './ProductVideoService';
import type { Services } from './Services';
import { useCreateStoriesService } from './StoriesService/createStoriesService';
import { useCreateToastService } from './ToastService';

export const ServicesContext = createContext<Nullable<Services>>(null);

export const ServicesProvider = ({ children }: { children: React.ReactNode }) => {
  const eventBus = useRef(EventBus());

  useEffect(() => {
    if (!Object.hasOwn(window, 'eventBus'))
      Object.defineProperty(window, 'eventBus', {
        writable: false,
        configurable: false,
        value: eventBus.current
      });
  }, []);

  return (
    <ServicesContext.Provider
      value={{
        eventBus: eventBus.current,
        customersService: useCreateCustomersService(),
        productsService: useCreateProductsService({ eventBus: eventBus.current }),
        productVideoService: useCreateProductVideoService({ eventBus: eventBus.current }),
        toastService: useCreateToastService({ eventBus: eventBus.current }),
        filtersService: useCreateProductFiltersService(),
        exportsService: useCreateExportsService({ eventBus: eventBus.current }),
        localisationService: useCreateLocalisationService(),
        storiesService: useCreateStoriesService({ eventBus: eventBus.current }),
        assortmentsService: useCreateAssortmentsService({ eventBus: eventBus.current }),
        ordersService: useCreateOrdersService({ eventBus: eventBus.current })
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export const useServices = () => {
  const services = useContext(ServicesContext);

  if (!services)
    throw new ReferenceError(
      'Hook "useServices" used outside its provider. Make sure <ServicesProvider /> is a parent to your components.'
    );

  return services;
};
