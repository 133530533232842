import { Skeleton as Sk } from 'components';
import { SlotId } from 'pages';
import { useSlot } from 'providers';
import { useMemo } from 'react';

export const OrderSizingSkeleton = () => {
  useSlot(
    SlotId.FooterLeft,
    useMemo(() => <Sk.Text $width="15%" />, [])
  );

  return (
    <div>
      <Sk.Row>
        <Sk.Col $size={4}>
          <Sk.Row>
            <Sk.Col $size={1}>
              <Sk.Vertical>
                <Sk.Repeat times={6} children={<Sk.Box $height={48} />} />
              </Sk.Vertical>
            </Sk.Col>
            <Sk.Col>
              <Sk.Box $height={480} />
            </Sk.Col>
          </Sk.Row>
        </Sk.Col>
        <Sk.Col>
          <Sk.Row>
            <Sk.Repeat
              times={6}
              children={
                <Sk.Col>
                  <Sk.Box $height={72} />
                </Sk.Col>
              }
            />
          </Sk.Row>
          <Sk.Spacer $size="xl" />
          <Sk.Table rows={8} />
        </Sk.Col>
      </Sk.Row>
    </div>
  );
};
