import { type Dimension, NULL_DIMENSION } from './OrdersService';
import { doSizeGridsOverlap } from './utils/doSizeGridsOverlap';

export interface SizeGrid {
  index: Readonly<Record<Dimension, Record<Dimension, boolean>>>;
  overlaps(rhs: this): boolean;
  supports(d1: Dimension, d2?: Dimension): boolean;
  d1s(): readonly Dimension[];
  d2s(): readonly Dimension[];
}

export const SizeGrid = (dimensions: ReadonlyArray<readonly [Dimension, Dimension?]>): SizeGrid => {
  const d1s: Dimension[] = [];
  const d2s: Dimension[] = [];

  const index: Record<Dimension, Record<Dimension, boolean>> = {};
  const sizeGrid: SizeGrid = {
    index,
    supports(d1, d2 = NULL_DIMENSION) {
      return !!sizeGrid.index[d1]?.[d2];
    },
    d1s() {
      return d1s;
    },
    d2s() {
      return d2s;
    },
    overlaps(rhs) {
      return doSizeGridsOverlap(sizeGrid, rhs);
    }
  };

  const [uniqueD1s, uniqueD2s] = [new Set<Dimension>(), new Set<Dimension>()];
  for (const [d1, d2 = NULL_DIMENSION] of dimensions) {
    (index[d1] ??= {})[d2] = true;
    uniqueD1s.add(d1);
    uniqueD2s.add(d2);
  }

  d1s.push(...Array.from(uniqueD1s));
  d2s.push(...Array.from(uniqueD2s));

  return sizeGrid;
};
