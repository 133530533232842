import { useContextfulSearch } from 'components/Header/ContextfulSearchProvider';
import { ProductSearchSuggestion } from 'components/ProductSearchSuggestion';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { searchProducts } from '@yourxx/support';

export interface UseOrderProductsSearchProps<P extends { pc9: string; name: string }> {
  contextId: string;
  products: readonly P[];
  getUrl: (pc9: string) => string;
}

export const useOrderProductsSearch = <P extends { pc9: string; name: string }>({
  contextId,
  products,
  getUrl
}: UseOrderProductsSearchProps<P>) => {
  const { searchTerm, addSuggestions } = useContextfulSearch({ contextId });

  const searchedProducts = useMemo(
    () => (searchTerm ? searchProducts(searchTerm, products) : null),
    [products, searchTerm]
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (!searchedProducts?.length) return;

    addSuggestions(
      searchedProducts.slice(0, 20).map(result => {
        const firstMatch = result.matches[0];
        const firstMatchValue = firstMatch.value;
        return {
          label: firstMatchValue,
          renderedLabel: (
            <ProductSearchSuggestion
              pc9={result.item.pc9}
              desc={firstMatch.meta === 'PC9' ? result.item.name : firstMatchValue}
            />
          ),
          onSelect: () => navigate(getUrl(result.item.pc9))
        };
      })
    );
  }, [addSuggestions, getUrl, navigate, searchedProducts]);
};
