import { useEffect, useState } from 'react';

export const useBeforeUnload = (requireConfirmation: boolean) => {
  const [isAboutToUnload, setIsAboutToUnload] = useState(false);

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      if (!requireConfirmation) return false;

      event.preventDefault();
      event.returnValue = true;
      setIsAboutToUnload(true);
      return true;
    };

    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [requireConfirmation]);

  useEffect(() => {
    if (!requireConfirmation) setIsAboutToUnload(false);
  }, [requireConfirmation]);

  return isAboutToUnload;
};
