import { SlotId } from 'pages/CommonLayout';
import { type ReactNode, useCallback, useMemo } from 'react';
import { Defect, type Nullable, OrderModel, RetryTimeout, retryUntil, TooManyRetries, useServices } from 'services';
import { records } from 'services/OrdersService/inMemorySizingRecords';
import { useOrderSizing } from 'services/OrdersService/OrderSizingManager/useOrderSizing';
import { copySizingDataToOrder } from 'services/OrdersService/utils';
import { useBeforeUnload } from 'utils';

import type { GetCustomerAssortmentResponse } from '@yourxx/types/src/api/base/customer';

import { useCustomersData } from '../CustomersDataProvider';
import { useLocalisation } from '../LocalisationProvider';
import { useSlot } from '../SlotsProvider';
import { PendingChangesDialog } from './components/PendingChangesDialog';
import { Saving } from './components/Saving';
import { OrdersContext, OrdersContextValue } from './OrdersContext';
import type { OrderDetails, OrderSizing } from './types';
import { useOrdersManager } from './useOrdersManager';

export const OrdersProvider = ({
  brand,
  customerId,
  season,
  children
}: {
  brand: string;
  customerId: string;
  season: string;
  children: ReactNode;
}) => {
  const [str] = useLocalisation();
  const { eventBus, ordersService: service } = useServices();
  const { assortmentsFor } = useCustomersData();
  const customerData = useMemo(() => assortmentsFor(customerId), [assortmentsFor, customerId]);

  const customerName = useMemo(
    () => customerData?.customerName ?? customerId.replace(/-+/g, ' ').toUpperCase(),
    [customerData?.customerName, customerId]
  );

  const finalAssortments = useMemo(() => {
    if (!(season in (customerData?.assortments ?? {}))) return [];
    return customerData?.assortments?.[season].filter(a => a.assortmentType === 'FINAL' && !a.archivedAt) ?? [];
  }, [customerData?.assortments, season]);

  const soldToLocations = useMemo(
    () => Object.values(customerData?.locations?.soldTo ?? {}),
    [customerData?.locations?.soldTo]
  );

  const billToLocations = useMemo(
    () => Object.values(customerData?.locations?.billTo ?? {}),
    [customerData?.locations?.billTo]
  );

  const shipToLocations = useMemo(
    () => Object.values(customerData?.locations?.shipTo ?? {}),
    [customerData?.locations?.shipTo]
  );

  /**
   * This will wait until the assortments data is loaded so we can get all customer's order ids to load their details.
   * It would've been nice to have a simple API call to load orders for a customerId, but we don't, yet anyway.
   */
  const getCustomerOrderIds = useCallback(async () => {
    const response = await retryUntil<Nullable<GetCustomerAssortmentResponse>, GetCustomerAssortmentResponse, void[]>(
      async () => assortmentsFor(customerId),
      (response): response is GetCustomerAssortmentResponse => response !== null,
      { initialDelayMs: 200, maxRetries: 30 }
    )().catch(error => {
      if (error instanceof TooManyRetries || error instanceof RetryTimeout) return null;
      throw error;
    });

    return Object.fromEntries(
      Object.entries(response?.orders ?? {}).map(([season, orders]) => [season, orders.map(o => o.orderId)])
    );
  }, [assortmentsFor, customerId]);

  const ordersManager = useOrdersManager({ str, brand, customerId, season, eventBus, service, getCustomerOrderIds });
  const { isLoading, manager, clipboard } = useOrderSizing({
    str,
    eventBus,
    records,
    findOrder: ordersManager.findOrder
  });

  const loadCustomerOrders = useMemo(() => {
    // These promises require caching to have a good experience when using <Suspense /> and <Await />
    const cache: Record<string, Promise<OrderModel[]>> = {};
    const keyOf = (season: string) => `${customerId}-${season}`;

    return async (season: string) =>
      (cache[keyOf(season)] ??= ordersManager.loadOrders().then(() => ordersManager.orders)).catch(error => {
        delete cache[keyOf(season)];
        throw error;
      });
  }, [customerId, ordersManager]);

  const loadOrderWithProducts = useCallback(
    (slugOrOrderId: string) =>
      service.loadOrder({ brand, customerId, getCustomerOrderIds, slugOrOrderId }).then(async order => {
        await order.loadProducts();
        return order;
      }),
    [brand, customerId, getCustomerOrderIds, service]
  );

  const loadOrderDetails = useMemo(() => {
    // These promises require caching to have a good experience when using <Suspense /> and <Await />
    const cache: Record<string, Promise<OrderDetails>> = {};
    const keyOf = (slugOrOrderId: string) => `${customerId}-${slugOrOrderId}`;

    return async (slugOrOrderId: string): Promise<OrderDetails> =>
      (cache[keyOf(slugOrOrderId)] ??= loadOrderWithProducts(slugOrOrderId)
        .then(async order => ({
          customerName,
          brand: order.details.brand,
          customerId: order.details.customerId,
          assortmentId: order.details.assortmentId,
          orderId: order.id,
          currency: order.details.currency,
          products: order.products ?? [],
          get isReadOnly() {
            return order.isReadOnly;
          },
          get locationsCount() {
            return order.locations.length;
          },
          get summary() {
            const { pc9s: pc9sCount, rrp: totalPrice, units: totalUnits } = order.details.breakdown;
            return { pc9sCount, totalPrice, totalUnits };
          }
        }))
        .catch(error => {
          delete cache[keyOf(slugOrOrderId)];
          throw error;
        }));
  }, [customerId, customerName, loadOrderWithProducts]);

  const loadOrderSizing = useMemo(() => {
    // These promises require caching to have a good experience when using <Suspense /> and <Await />
    const cache: Record<string, Promise<OrderSizing>> = {};
    const keyOf = (slugOrOrderId: string) => `${customerId}-${slugOrOrderId}`;

    return async (slugOrOrderId: string): Promise<OrderSizing> =>
      (cache[keyOf(slugOrOrderId)] ??= loadOrderWithProducts(slugOrOrderId)
        .then(order => ({
          customerName,
          brand: order.details.brand,
          assortmentId: order.details.assortmentId,
          customerId: order.details.customerId,
          orderId: order.id,
          currency: order.details.currency,
          // The object in this promise will be cached, but in case the order changes over the duration of the session
          // these getters will ensure they are getting the data straight from the order.
          get isReadOnly() {
            return order.isReadOnly;
          },
          get locationsCount() {
            return order.locations.length;
          },
          get locationLabels() {
            return order.locations.map(l => l.displayName);
          },
          get orderTotalUnits() {
            return order.details.breakdown.units;
          },
          getProductContext(pc9: string) {
            const products = order.products ?? [];

            const productIndex = products.findIndex(p => p.pc9 === pc9) ?? -1;
            if (productIndex < 0) throw new Defect(`Product "${pc9}" not found in order ${order.details.displayName}.`);

            const [next, prev] = [
              productIndex <= 0 ? products[products.length - 1] : products[productIndex - 1],
              productIndex === products.length - 1 ? products[0] : products[productIndex + 1]
            ];

            const product = products[productIndex];
            const siblings = products.filter(p => p !== product);
            return { product, siblings, next, prev };
          }
        }))
        .catch(error => {
          delete cache[keyOf(slugOrOrderId)];
          throw error;
        }));
  }, [customerId, customerName, loadOrderWithProducts]);

  const copyOrderSizing = useCallback<OrdersContextValue['copyOrderSizing']>(
    async (source, target) => copySizingDataToOrder(source, target, manager),
    [manager]
  );

  useSlot(SlotId.Modals, useBeforeUnload(isLoading) && <PendingChangesDialog isLoading={isLoading} />);
  useSlot(SlotId.FooterLeft, isLoading && <Saving />, -Infinity);
  // TODO: Remove once we migrate the Order Capture footer to use the new layout footer.
  useSlot(SlotId.LegacyFooter, isLoading && <Saving />, -Infinity);

  return (
    <OrdersContext.Provider
      value={{
        brand,
        customerId,
        customerName,
        finalAssortments,
        billToLocations,
        shipToLocations,
        soldToLocations,
        loadCustomerOrders,
        loadOrderDetails,
        loadOrderSizing,
        copyOrderSizing,
        ...ordersManager,
        isLoading,
        manager,
        clipboard
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
