import type { Nullable } from '@yourxx/support';
import { OrderAssortProducts } from '@yourxx/types';

import type { Month, PC9Availability } from './OrderSizingManager/types';
import { SizeGrid } from './SizeGrid';
import { spreadPC9Availability } from './utils';

interface OrderProductData extends OrderAssortProducts {
  imageUrl: string;
  imageUrlParams: string;
}

export interface OrderProductModel extends OrderProductData {
  sizeGrid: SizeGrid;
  availability: Nullable<PC9Availability>;
  allMonths: Month[];
  thumbnail(size: number, format?: string): string;
  gallery(size: number, format?: string): string[];
}

const getAvailability = (product: OrderAssortProducts): Nullable<PC9Availability> => {
  if (!product.firstOnFloor || !product.lastOnFloor) return null;
  const start = new Date(product.firstOnFloor);
  const end = new Date(product.lastOnFloor);
  return [
    `${start.getFullYear()}${(start.getMonth() + 1).toString().padStart(2, '0')}`,
    `${end.getFullYear()}${(end.getMonth() + 1).toString().padStart(2, '0')}`
  ];
};

export const OrderProductModel = (details: OrderProductData): Readonly<OrderProductModel> => {
  const availability = getAvailability(details);

  const createImageUrl = (imageId: string, size: number, format = 'jpg'): string => {
    return `${details.imageUrl}/${details.pc9}?${details.imageUrlParams}&image_format=${format}&image_type=${imageId}&width=${size}&height=${size}`;
  };

  const product: OrderProductModel = {
    ...details,
    sizeGrid: SizeGrid(details.sizes ?? []),
    availability,
    allMonths: spreadPC9Availability(availability),
    thumbnail(size, format) {
      const highestPriorityImage = details.images?.[0];
      if (!highestPriorityImage) return '';
      return createImageUrl(highestPriorityImage, size, format);
    },
    gallery(size, format) {
      return product.images?.map(imageId => createImageUrl(imageId, size, format)) ?? [];
    }
  };

  return product;
};
