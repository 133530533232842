import { tagsCallback } from '.';

export enum PDPTags {
  PDPIMAGEVIEW = 'pdp_image_view',
  FAIMAGECAROUSEL = 'FA_image_carousal',
  PDPVIDEOPLAY = 'pdp_video_play',
  PDPVIDEOURL = 'pdp_video_url',
  PDPSAVEVIDEO = 'pdp_save_video',
  PDPIMAGEFULLSCREEN = 'pdp_image_fullscreen',
  PDPIMAGEZOOM = 'pdp_image_zoom',
  PDPPHASINGSTART = 'pdp_phasing_start',
  PDPPHASINGEND = 'pdp_phasing_end',
  PDPARROWINTERACTION = 'pdp_arrow_interaction',
  PDPNOTES = 'pdp_notes',
  PDPSTORY = 'pdp_story',
  PDPNAMECAROUSEL = 'pdp_name_carousel',
  FANAMECAROUSEL = 'FA_name_carousel',
  PDPDESCRIPTION = 'pdp_pc9_description',
  FADESCRIPTION = 'FA_pc9_description',
  PDPMODALCLOSE = 'pdp_modal_close',
  FAMODALCLOSE = 'FA_modal_close',
  PDPEDIT = 'pdp_edit',
  FAEDIT = 'FA_edit',
  PDPSETTINGS = 'pdp_settings',
  FASETTINGS = 'FA_settings'
}

export const pdpVideoPlayTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPVIDEOPLAY, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpVideoUrlTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPVIDEOURL, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpSaveVideoTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPSAVEVIDEO, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpImageFullscreenTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPIMAGEFULLSCREEN, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpImageZoomTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPIMAGEZOOM, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpPhasingStartTag = ({
  start,
  productId,
  assortmentId,
  customerId
}: {
  start: string;
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPPHASINGSTART, {
    start_value: start,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpPhasingEndTag = ({
  end,
  productId,
  assortmentId,
  customerId
}: {
  end: string;
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPPHASINGEND, {
    end_value: end,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpArrowInteractionTag = ({
  action,
  section,
  productId,
  assortmentId,
  customerId
}: {
  action: 'collapse' | 'expand';
  section: string;
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPARROWINTERACTION, {
    action,
    section,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpNotesTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPNOTES, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpStoryTag = ({
  storyName,
  productId,
  assortmentId,
  customerId
}: {
  storyName: string;
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPSTORY, {
    story_name: storyName,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};
