import type { EntityContext } from 'domain-events';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useMemo } from 'react';
import { filterOptionToRenderedLabel } from 'utils';

import { type AnyFilter, filtersToFilterFunction } from '@yourxx/support';
import { CustomerAssortmentProps, ProductListData, UIProduct } from '@yourxx/types';

import { useServices } from '../ServicesProvider';
import { addUnisexGenderOnOptionSelect } from './addUnisexGenderOnOptionSelect';
import { buildFilterOptions } from './buildFilterOptions';
import { createStatusFilterOptions } from './createStatusFilterOptions';
import { optionsSorter } from './optionsSorter';
import { priceFilterTypeOrDefault } from './priceFilterTypeOrDefault';
import { useFilterExpanded } from './useFilterExpanded';
import { useFiltersSchema } from './useFiltersSchema';
import { useFiltersState } from './useFiltersState';
import { useFiltersSubscriptions } from './useFiltersSubscriptions';

export const useProductsFilters = ({
  products,
  finalAssortments,
  storiesOrder,
  context,
  statuses
}: {
  products: ReadonlyArray<UIProduct<ProductListData>>;
  finalAssortments: ReadonlyArray<CustomerAssortmentProps> | undefined;
  storiesOrder?: string[];
  statuses: Record<string, number> | undefined;
  context: EntityContext | undefined;
}) => {
  const [str] = useLocalisation();
  const { eventBus, filtersService: service } = useServices();

  useEffect(() => {
    service.initialise();
  }, [service]);

  const { filtersSchema } = useFiltersSchema();
  const filters = useFiltersState({ service, eventBus, context, onFilterSet: addUnisexGenderOnOptionSelect });
  const priceFilterType = useMemo(
    () => priceFilterTypeOrDefault(filters.activeCombination.filters, 'wsp'),
    [filters.activeCombination.filters]
  );

  const filterOptions = useMemo(() => {
    return buildFilterOptions({
      schema: filtersSchema,
      ordering: filters.filtersOrder,
      items: products,
      valueGetter: (item, filterId) => {
        switch (filterId) {
          case 'tops':
          case 'bottoms':
          case 'footwear':
          case 'accessories':
            return item.category0 === filterId.toUpperCase() ? item.category1 : undefined;
          case 'fit':
            return item.category0 === 'BOTTOMS' ? item.category2 : undefined;
          case 'price':
            return item[priceFilterType as keyof typeof item];
          case 'notInFinalAssortment':
            return item.inFinalAssortments;
          case 'mandatory':
            // We want to treat all falsy values as false
            return item.mandatory ?? false;
          case 'replen':
            // We want to treat all falsy values as false
            return item.replen === 'TRUE';
          case 'storeGrades':
            return item.storeGrades;
          case 'ranking':
            return item.ranking ?? 0;
          default:
            return item[filterId as keyof typeof item];
        }
      },
      labeler: (filterId, optionValue) => {
        return filterOptionToRenderedLabel({
          str,
          filterId,
          optionValue,
          finalAssortments
        });
      },
      override: filter => {
        if (filter.id === 'status') return createStatusFilterOptions([filter], statuses ?? {}) ?? null;
        return null;
      },
      isEligible: ({ filter, options }) => {
        switch (filter.id) {
          case 'mandatory':
            return !!options.find(o => o.value === true);
          default:
            return true;
        }
      },
      optionsSorter: (filterId, options) => {
        switch (filterId) {
          case 'status':
            return optionsSorter(['ADD', 'PENDING', 'DROP', 'REMOVE'], options);
          case 'story':
            return optionsSorter(storiesOrder ?? [], options);
          default:
            return options;
        }
      }
    });
  }, [filters.filtersOrder, filtersSchema, finalAssortments, priceFilterType, products, statuses, storiesOrder, str]);

  const setFilter = useCallback(
    (filter: AnyFilter) => {
      if (filters.state === 'Idle') filters.set(filter);
    },
    [filters]
  );

  const filterUtils = useFilterExpanded({ eventBus });
  useFiltersSubscriptions({ eventBus, setFilter, filtersSchema });

  const filterProductsFn = useMemo(
    () => filtersToFilterFunction(filters.activeCombination.filters),
    [filters.activeCombination.filters]
  );

  return {
    filters,
    filterUtils,
    filterOptions,
    filterProductsFn
  };
};
