import { DomainEvent } from '../EventBus';
import { OrderModel } from './OrderModel';
import type { OrderSizingCommand } from './OrdersService';

type Failed<T> = T & { reason: string };

type LoadOrdersPayload = { brand: string; customerId: string; orderIds: string[] };
export class OrdersRequested extends DomainEvent<LoadOrdersPayload> {}
export class OrdersLoaded extends DomainEvent<LoadOrdersPayload> {}
export class OrdersLoadFailed extends DomainEvent<Failed<LoadOrdersPayload>> {}

type OrderProductsPayload = { brand: string; customerId: string; orderId: string; slug: string };
export class OrderProductsRequested extends DomainEvent<OrderProductsPayload> {}
export class OrderProductsLoaded extends DomainEvent<OrderProductsPayload> {}
export class OrderProductsLoadFailed extends DomainEvent<Failed<OrderProductsPayload>> {}

type BasePayload = {
  brand: string;
  customerId: string;
  finalAssortmentId: string;
  finalAssortmentName: string;
  orderId: string;
  displayName: string;
};

type OrderCreatedPayload = BasePayload & { slug: string };
export class OrderCreateRequested extends DomainEvent<Omit<BasePayload, 'orderId'>> {}
export class OrderCreated extends DomainEvent<OrderCreatedPayload> {}
export class CreateOrderFailed extends DomainEvent<Failed<Omit<OrderCreatedPayload, 'orderId' | 'slug'>>> {}

type OrderUpdatePayload = BasePayload & { update: any };
export class OrderUpdateRequested extends DomainEvent<OrderUpdatePayload> {}
export class OrderUpdated extends DomainEvent<OrderUpdatePayload> {}
export class OrderUpdateFailed extends DomainEvent<Failed<OrderUpdatePayload>> {}

type OrderDeletePayload = BasePayload;
export class OrderDeleteRequested extends DomainEvent<OrderDeletePayload> {}
export class OrderDeleted extends DomainEvent<OrderDeletePayload> {}
export class OrderDeleteFailed extends DomainEvent<Failed<OrderDeletePayload>> {}

type OrderSizingPayload = BasePayload & { cmds: readonly OrderSizingCommand[] };
export class OrderSizingRequested extends DomainEvent<OrderSizingPayload> {}
export class OrderSized extends DomainEvent<OrderSizingPayload> {}
export class OrderSizingFailed extends DomainEvent<Failed<OrderSizingPayload>> {}

type OrderPlacePayload = BasePayload;
export class OrderPlaceRequested extends DomainEvent<OrderPlacePayload> {}
export class OrderPlaced extends DomainEvent<OrderPlacePayload> {}
export class OrderPlaceFailed extends DomainEvent<Failed<OrderPlacePayload>> {}

export const basePayloadFor = (order: OrderModel): BasePayload => {
  return {
    brand: order.details.brand,
    customerId: order.details.customerId,
    finalAssortmentId: order.details.assortmentId,
    finalAssortmentName: order.details.assortmentName,
    orderId: order.id,
    displayName: order.details.displayName
  };
};
