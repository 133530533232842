import { PLPTags, tagsCallback } from '.';
import { LandingTags } from './landingTags'; // Ensure correct import path

const getGlobalPayload = (customerId?: string, assortmentId?: string) => ({
  customer_id: customerId,
  assortment_id: assortmentId
});

export const triggerTag = (tag: LandingTags | PLPTags, payload: Record<string, any>) => {
  tagsCallback(tag, payload);
};

export const handleShowFilterTag = (
  sidePanel: any,
  customerId?: string,
  assortmentId?: string,
  isFinalAssortment?: boolean,
  userId?: string,
  userRole?: string
) => {
  const isFiltersOpen = sidePanel.is('filters');

  triggerTag(isFinalAssortment ? LandingTags.FALANDINGSHOWFILTER : LandingTags.LANDINGSHOWFILTER, {
    ...getGlobalPayload(customerId, assortmentId),
    state: isFiltersOpen ? 'collapse' : 'expand',
    user_id: userId,
    user_role: userRole
  });

  sidePanel.set('filters');
};

export const handleFilterClearAllTag = (
  customerId?: string,
  assortmentId?: string,
  isFinalAssortment?: boolean,
  version?: string
) => {
  triggerTag(isFinalAssortment ? PLPTags.FALANDINGFILTERCLEAR : PLPTags.LANDINGFILTERCLEAR, {
    ...getGlobalPayload(customerId, assortmentId),
    version
  });
};

export const handleFilterSaveTag = (
  assortmentId: string,
  customerId: string,
  filterSelected: { id: string; type: string; value: string[] }[],
  filterName: string,
  isFinalAssortment?: boolean
) => {
  const filterSelectedString = filterSelected.map(filter => `${filter.id}:${filter.value.join(',')}`).join(';');

  triggerTag(isFinalAssortment ? PLPTags.FALANDINGFILTERSAVE : PLPTags.LANDINGFILTERSAVE, {
    ...getGlobalPayload(customerId, assortmentId),
    filter_selected: filterSelectedString,
    filter_name: filterName
  });
};

export const handleSaveUsedTag = (
  filterName: string,
  assortmentId?: string,
  customerId?: string,
  isFinalAssortment?: boolean
) => {
  triggerTag(isFinalAssortment ? PLPTags.FALANDINGFILTERSAVEUSE : PLPTags.LANDINGFILTERSAVEUSE, {
    ...getGlobalPayload(customerId, assortmentId),
    filter_name: filterName
  });
};

export const handleAuditTrialTag = (assortmentId?: string, customerId?: string, isFinalAssortment?: boolean) => {
  triggerTag(
    isFinalAssortment ? PLPTags.FAPLPAUDITTRIAL : PLPTags.PLPAUDITTRIAL,
    getGlobalPayload(customerId, assortmentId)
  );
};

export const handleVisibilityTag = (assortmentId?: string, customerId?: string, isFinalAssortment?: boolean) => {
  triggerTag(
    isFinalAssortment ? PLPTags.FAPLPVISIBILITYSETTINGS : PLPTags.PLPVISIBILITYSETTINGS,
    getGlobalPayload(customerId, assortmentId)
  );
};

export const handleVisibilitySaveTag = (assortmentId?: string, customerId?: string, isFinalAssortment?: boolean) => {
  triggerTag(
    isFinalAssortment ? PLPTags.FAPLPVISIBILITYSETTINGSAVE : PLPTags.PLPVISIBILITYSETTINGSAVE,
    getGlobalPayload(customerId, assortmentId)
  );
};

export const handleCombinationTag = (assortmentId?: string, customerId?: string, isFinalAssortment?: boolean) => {
  triggerTag(
    isFinalAssortment ? PLPTags.FAFILTERCOMBINATIONADD : PLPTags.FILTERCOMBINATIONADD,
    getGlobalPayload(customerId, assortmentId)
  );
};

export const handleAddProductTag = (
  assortmentId?: string,
  customerId?: string,
  isFinalAssortment?: boolean,
  status?: boolean
) => {
  triggerTag(isFinalAssortment ? PLPTags.FAASSORTMENTADDPRODUCT : PLPTags.PLPASSORTMENTADDPRODUCT, {
    ...getGlobalPayload(customerId, assortmentId),
    state: status ? 'opened' : 'closed'
  });
};

export const handleSelectAllProducts = (assortmentId?: string, customerId?: string, isFinalAssortment?: boolean) => {
  triggerTag(
    isFinalAssortment ? PLPTags.FASELECTALLPC9 : PLPTags.SELECTALLPC9,
    getGlobalPayload(customerId, assortmentId)
  );
};

export const handleClearAllProducts = (assortmentId?: string, customerId?: string, isFinalAssortment?: boolean) => {
  triggerTag(
    isFinalAssortment ? PLPTags.FACLEARALLPC9 : PLPTags.CLEARALLPC9,
    getGlobalPayload(customerId, assortmentId)
  );
};

export const handleProductSearchTag = (
  assortmentId?: string,
  customerId?: string,
  searchTerm?: string,
  isFinalAssortment?: boolean
) => {
  triggerTag(isFinalAssortment ? PLPTags.FAADDPRODUCTSEARCH : PLPTags.PLPADDPRODUCTSEARCH, {
    ...getGlobalPayload(customerId, assortmentId),
    search_term: searchTerm
  });
};

export const handleFilterChangeTag = (
  assortmentId?: string,
  customerId?: string,
  filterType?: string,
  value?: string | string[],
  filterName?: string,
  isFinalAssortment?: boolean
) => {
  triggerTag(isFinalAssortment ? LandingTags.FALANDINGFILTERCHANGE : LandingTags.LANDINGFILTERCHANGE, {
    ...getGlobalPayload(customerId, assortmentId),
    filter_type: filterType,
    value: Array.isArray(value) ? value.join(', ') : value,
    filter_name: filterName
  });
};

export const handleAddProductConfirmTag = (
  assortmentId?: string,
  customerId?: string,
  assortmentName?: string,
  pc9s?: string[],
  version?: string,
  isFinalAssortment?: boolean
) => {
  triggerTag(isFinalAssortment ? PLPTags.FAADDPRODUCTCONFIRM : PLPTags.PLPADDPRODUCTCONFIRM, {
    ...getGlobalPayload(customerId, assortmentId),
    assortment_name: assortmentName,
    version,
    pc9_code: Array.isArray(pc9s) ? pc9s.join(', ') : pc9s
  });
};

export const handlePlpTabsTag = (
  tabName: string,
  assortmentId?: string,
  customerId?: string,
  isFinalAssortment?: boolean
) => {
  triggerTag(isFinalAssortment ? PLPTags.FAPLPTABS : PLPTags.PLPTABS, {
    ...getGlobalPayload(customerId, assortmentId),
    tab_name: tabName
  });
};

export const handleFilterReorder = (
  assortmentId?: string,
  customerId?: string,
  isFinalAssortment?: boolean,
  version?: string
) => {
  triggerTag(isFinalAssortment ? PLPTags.FAFILTERREORDER : PLPTags.LANDINGFILTERREORDER, {
    ...getGlobalPayload(customerId, assortmentId),
    version
  });
};

export const handleFilterExpandAll = (
  assortmentId?: string,
  customerId?: string,
  isFinalAssortment?: boolean,
  version?: string
) => {
  triggerTag(isFinalAssortment ? PLPTags.FAFILTEREXPANDALL : PLPTags.PLPEXPANDALL, {
    ...getGlobalPayload(customerId, assortmentId),
    version
  });
};

export const handleDownloadTypeTag = (
  assortmentId?: string,
  customerId?: string,
  type?: string,
  isFinalAssortment?: boolean
) => {
  triggerTag(isFinalAssortment ? PLPTags.FAASSORTMENTDOWNLOADTYPE : PLPTags.PLPASSORTMENTDOWNLOADTYPE, {
    ...getGlobalPayload(customerId, assortmentId),
    download_type: type
  });
};

export const handleSelectPc9Assortment = (
  assortmentId?: string,
  customerId?: string,
  assortment_name?: string,
  isFinalAssortment?: boolean
) => {
  triggerTag(isFinalAssortment ? PLPTags.FASELECTPC9ASSORTMENT : PLPTags.PLPSELECTPC9ASSORTMENT, {
    ...getGlobalPayload(customerId, assortmentId),
    assortment_name: assortment_name
  });
};
