import { findById } from 'utils';

import { type AnyFilter, FilterType } from '@yourxx/support';

import type { FilterCombination } from './FilterCombination';

export const addUnisexGenderOnOptionSelect = (filter: AnyFilter, activeCombination: FilterCombination) => {
  if (filter.id !== 'gender' || filter.type !== FilterType.Multi) return filter;

  const currentValues = Array.isArray(filter.value) ? filter.value : [];
  const prevGenderFilter = findById(filter.id)(activeCombination.filters);
  const prevValues: string[] = Array.isArray(prevGenderFilter?.value) ? prevGenderFilter.value : [];

  if (currentValues.length === 0) return filter.clear();

  const newValues = [...currentValues];

  const isMSelected = currentValues.includes('M');
  const isWSelected = currentValues.includes('F');
  const isUSelected = currentValues.includes('U');

  const wasMSelected = prevValues.includes('M');
  const wasWSelected = prevValues.includes('F');
  const wasUSelected = prevValues.includes('U');

  // it will add U only when intially all are not selected
  if (!wasMSelected && !wasWSelected && !wasUSelected && !isUSelected) {
    newValues.push('U');
  }

  // It will check only when only only W was slected
  if (!wasUSelected && !isUSelected && !wasMSelected && isMSelected && wasWSelected && isWSelected) {
    newValues.push('U');
  }

  // It will check only when only only M was slected
  if (!wasUSelected && !isUSelected && !wasWSelected && isWSelected && wasMSelected && isMSelected) {
    newValues.push('U');
  }

  return filter.clear().set(newValues);
};
