import { tagsCallback } from '.';

export enum LandingTags {
  LANDINGSHOWSORT = 'landing_show_sort',
  LANDINGSHOWFILTER = 'landing_show_filter',
  FALANDINGSHOWFILTER = 'FA_filter',
  LANDINGSORTCHANGE = 'landing_sort_change',
  LANDINGFILTERCHANGE = 'landing_filter_change',
  FALANDINGFILTERCHANGE = 'fa_landing_filter_change',
  LANDINGSEARCH = 'landing_search',
  LANDINGSEASONCHANGE = 'landing_season_change',
  LANDINGNEWASSORTMENT = 'landing_new_assortment',
  ASSORTMENTBUILDINGCREATE = 'assortment_building_create'
}

export const landingShowSortTag = ({ customerId }: { customerId?: string }) => {
  tagsCallback(LandingTags.LANDINGSHOWSORT, { customer_id: customerId });
};

export const landingShowFilterTag = ({
  customerId,
  assortmentId,
  state,
  tag = LandingTags.LANDINGSHOWFILTER
}: {
  customerId?: string;
  assortmentId?: string;
  state: 'expand' | 'collapse';
  tag?: LandingTags;
}) => {
  const payload = { customer_id: customerId, assortment_id: assortmentId, state };
  tagsCallback(tag, payload);
};

export const landingSortChangeTag = ({
  type,
  direction,
  customerId
}: {
  type: string;
  direction: 'desc' | 'asc';
  customerId?: string;
}) => {
  tagsCallback(LandingTags.LANDINGSORTCHANGE, {
    customer_id: customerId,
    sort_type: type,
    sort_direction: direction
  });
};

export const landingSearchTag = ({ location, term }: { location: string; term: string }) => {
  tagsCallback(LandingTags.LANDINGSEARCH, {
    search_location: location,
    search_term: term
  });
};

export const landingSeasonChangeTag = ({ season, customerId }: { season: string; customerId?: string }) => {
  tagsCallback(LandingTags.LANDINGSEASONCHANGE, {
    customer_id: customerId,
    season
  });
};

export const landingNewAssortmentTag = ({ customerId }: { customerId?: string }) => {
  tagsCallback(LandingTags.LANDINGNEWASSORTMENT, { customer_id: customerId });
};

export const assortmentBuildingCreateTag = ({
  id,
  name,
  type,
  season
}: {
  id: string;
  name: string;
  type?: string;
  season?: string;
}) => {
  tagsCallback(LandingTags.ASSORTMENTBUILDINGCREATE, {
    assortment_id: id,
    assortment_name: name,
    assortment_status: type,
    season: season
  });
};
