import { Plus } from 'assets/icons';
import { IAM } from 'components';
import { GhostButton, SlotId } from 'pages';
import { useOrdersSearch } from 'pages/Orders/hooks/useOrdersSearch';
import { useLocalisation, useSlot } from 'providers';
import { useMemo, useState } from 'react';
import { routes } from 'routes';
import { type OrderModel, type OrdersManager } from 'services';
import styled from 'styled-components';
import { findById, themed } from 'utils';

import { CustomerAssortmentProps, type CustomerBillTo, CustomerShipTo, CustomerSoldTo } from '@yourxx/types';

import { OrderCreateEditModal, OrderDeleteModal, OrderFooter, OrderList, type OrderModalType } from '.';
import { NoOrdersAvailable } from './NoOrdersAvailable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${themed('color.offWhite')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
  }

  > div:first-of-type {
    flex: 1;
  }
`;

export interface OrderCaptureProps extends OrdersManager {
  shipToLocations?: CustomerShipTo[];
  billToLocations?: CustomerBillTo[];
  soldToLocations?: CustomerSoldTo[];
  finalAssortments: CustomerAssortmentProps[];
  copyOrderSizing(source: OrderModel, target: OrderModel): Promise<boolean>;
}

export const OrderCapture = ({
  orders,
  finalAssortments,
  shipToLocations,
  billToLocations,
  soldToLocations,
  createOrder,
  editOrder,
  duplicateOrder,
  deleteOrders,
  placeOrders,
  copyOrderSizing
}: OrderCaptureProps) => {
  const [str] = useLocalisation();
  const [orderModalType, setOrderModalType] = useState<OrderModalType>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
  const [currentOrderId, setCurrentOrderId] = useState<string>();

  useOrdersSearch({ contextId: routes.orders.toString(), orders });

  const currentOrder = useMemo(() => {
    if (!currentOrderId) return;
    return findById(currentOrderId)(orders);
  }, [currentOrderId, orders]);

  const selectedOrders = useMemo(
    () => orders.filter(({ id }) => selectedOrderIds.includes(id)),
    [orders, selectedOrderIds]
  );

  useSlot(
    SlotId.PageToolbarRight,
    useMemo(
      () => (
        <GhostButton onClick={() => setOrderModalType('create')}>
          <Plus />
          {str('Order.createOrder')}
        </GhostButton>
      ),
      [str]
    )
  );

  const closeModal = () => {
    setOrderModalType(undefined);
  };

  const showDeleteConfirmation = () => {
    setIsDeleting(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleting(false);
  };

  useSlot(
    SlotId.Modals,
    <IAM action="orders.edit" prefix={'customers'}>
      {orderModalType && (
        <OrderCreateEditModal
          isReadOnly={currentOrder?.isReadOnly}
          type={orderModalType}
          order={currentOrder}
          assortments={finalAssortments}
          shipToLocations={shipToLocations}
          billToLocations={billToLocations}
          soldToLocations={soldToLocations}
          createOrder={cmd => createOrder(cmd).then(closeModal)}
          editOrder={cmd => editOrder(cmd).then(closeModal)}
          duplicateOrder={order => duplicateOrder(order).then(closeModal)}
          deleteOrders={async () => {
            if (currentOrder) showDeleteConfirmation();
          }}
          onClose={() => {
            setCurrentOrderId(undefined);
            closeModal();
          }}
        />
      )}
    </IAM>
  );

  useSlot(
    SlotId.Modals,
    <IAM action="orders.delete" prefix={'customers'}>
      {isDeleting && (
        <OrderDeleteModal
          orders={currentOrder ? [currentOrder] : selectedOrders}
          onConfirm={() =>
            deleteOrders(currentOrder ? [currentOrder] : selectedOrders)
              .then(() => {
                if (currentOrder) {
                  setCurrentOrderId(undefined);
                  setSelectedOrderIds(prev =>
                    prev.includes(currentOrder.id) ? prev.filter(id => id !== currentOrder.id) : prev
                  );
                } else {
                  setSelectedOrderIds([]);
                }
              })
              .finally(() => {
                closeModal();
                closeDeleteConfirmation();
              })
          }
          onClose={closeDeleteConfirmation}
        />
      )}
    </IAM>
  );

  return (
    <Wrapper>
      {!orders?.length ? (
        <NoOrdersAvailable />
      ) : (
        <>
          <OrderList
            orders={orders}
            selectedOrders={selectedOrders}
            setSelectedOrders={orders => setSelectedOrderIds(orders.map(o => o.id))}
            setCurrentOrder={order => setCurrentOrderId(order.id)}
            setOrderModalType={setOrderModalType}
            onPlace={placeOrders}
            onDelete={showDeleteConfirmation}
            onCopy={copyOrderSizing}
          />
          <OrderFooter
            orders={orders}
            selectedOrders={selectedOrders.length}
            onDeselectAll={() => setSelectedOrderIds([])}
            placeOrders={() => placeOrders(selectedOrders)}
            deleteOrders={showDeleteConfirmation}
          />
        </>
      )}
    </Wrapper>
  );
};
