import { asErrorMessage } from 'utils';

import { Defect, type Nullable } from '@yourxx/support';
import { OrderDetails } from '@yourxx/types/src/api/order/order';
import {
  deleteOrderLocationApi,
  deletesOrderApi,
  getOrderProduct,
  updateOrderApi,
  updateOrderProduct
} from '@yourxx/ui-utils';
import { placeOrderApi } from '@yourxx/ui-utils/src/api/order/order';

import type { EventBus } from '../EventBus';
import {
  basePayloadFor,
  OrderDeleted,
  OrderDeleteFailed,
  OrderDeleteRequested,
  OrderPlaced,
  OrderPlaceFailed,
  OrderPlaceRequested,
  OrderProductsLoaded,
  OrderProductsLoadFailed,
  OrderProductsRequested,
  OrderSized,
  OrderSizingFailed,
  OrderSizingRequested,
  OrderUpdated,
  OrderUpdateFailed,
  OrderUpdateRequested
} from './events';
import { records } from './inMemorySizingRecords';
import { OrderProductModel } from './OrderProductModel';
import {
  type CreateOrderCommand,
  NULL_DIMENSION,
  type OrderId,
  OrderStatus,
  type SizingAdjustment
} from './OrdersService';
import { updateOrderSizingRecords } from './utils';
import { monthToDeliveryDate } from './utils/monthToDeliveryDate';

type OrderLocation = { orderLocationId?: string; displayName: string; shipTo: string };
type LocationDetails = Required<OrderDetails>['locations'][string];
type OrderUpdateCommand = { displayName?: string; billTo?: string; poNumber?: string; locations?: OrderLocation[] };

export interface OrderModel {
  id: string;
  slug: string;
  status: OrderStatus;
  isReadOnly: boolean;
  details: OrderDetails & { brand: string; customerId: string };
  _products: Nullable<ReturnType<typeof getOrderProduct>>;
  products: Nullable<readonly OrderProductModel[]>;
  locations: LocationDetails[];
  locationById(id: string): Nullable<LocationDetails>;
  locationByShipTo(id: string): Nullable<LocationDetails>;
  loadProducts(traceId?: string): ReturnType<typeof getOrderProduct>;
  reloadProducts(traceId?: string): ReturnType<typeof getOrderProduct>;
  delete(): Promise<void>;
  update(cmd: OrderUpdateCommand): Promise<void>;
  size<P extends Pick<OrderProductModel, 'pc9' | 'sizeGrid' | 'availability'>>(
    cmds: readonly { orderId: OrderId; locationId: string; product: P; adjustments: readonly SizingAdjustment[] }[]
  ): Promise<void>;
  place(): Promise<void>;
  toDuplicateCommand(): CreateOrderCommand;
  ensureEditable(): Promise<void>;
}

export const OrderModel = ({
  details,
  brand,
  customerId,
  onLoadProducts = getOrderProduct,
  onUpdate = updateOrderApi,
  onUpdateProduct = updateOrderProduct,
  onDelete = deletesOrderApi,
  onDeleteLocations = deleteOrderLocationApi,
  onPlace = placeOrderApi,
  eventBus
}: {
  details: OrderDetails;
  brand: string;
  customerId: string;
  onLoadProducts?: typeof getOrderProduct;
  onUpdate?: typeof updateOrderApi;
  onUpdateProduct?: typeof updateOrderProduct;
  onDelete?: typeof deletesOrderApi;
  onDeleteLocations?: typeof deleteOrderLocationApi;
  onPlace?: typeof placeOrderApi;
  eventBus: EventBus;
}) => {
  const order: OrderModel = {
    // Don't reference this directly, use `order.details` as it can get updated
    // and the original `details` object gets garbage-collected.
    details: {
      brand,
      customerId,
      ...details
    },

    get id() {
      return order.details.orderId;
    },

    get slug() {
      return order.details.urlSlug;
    },

    get status() {
      if (!order.details.status) return OrderStatus.New;

      return (
        {
          CREATED: OrderStatus.Created,
          FAILED: OrderStatus.Failed,
          SUBMITTED: OrderStatus.Submitted
        }[order.details.status] ?? OrderStatus.New
      );
    },

    get isReadOnly() {
      return order.status !== OrderStatus.New;
    },

    get locations() {
      return Object.values(order.details.locations ?? {});
    },

    locationById(id) {
      return order.details.locations?.[id] ?? null;
    },

    locationByShipTo(id) {
      return order.locations.find(({ shipTo }) => shipTo === id) ?? null;
    },

    async ensureEditable() {
      if (order.isReadOnly) throw new Defect('Order cannot be updated after being placed.');
    },

    _products: null,
    products: null,
    loadProducts(traceId?: string) {
      const requested = new OrderProductsRequested({
        brand,
        customerId,
        orderId: order.details.orderId,
        slug: order.details.urlSlug
      });

      if (traceId) requested.trace(traceId);

      if (!order._products) {
        order._products = onLoadProducts({ orderId: order.details.orderId });
        eventBus.emit(requested);

        order._products
          .then(data => {
            order.products =
              data.assortmentProducts?.map(data =>
                OrderProductModel({
                  ...data,
                  get imageUrl() {
                    return order.details.imageUrl;
                  },
                  get imageUrlParams() {
                    return order.details.imageUrlParams;
                  }
                })
              ) ?? [];

            // After sizing and reloading products we can update the breakdown based on the latest edits.
            order.details.breakdown = data.summary?.breakdown ?? order.details.breakdown;

            // We're getting the sizing data from the response and adding it to the local state in the records object.
            updateOrderSizingRecords(order.id, records.computed, data.orderProducts);

            const productsLoaded = new OrderProductsLoaded(requested.payload).trace(requested);
            eventBus.emit(productsLoaded);
          })
          .catch(error => {
            order._products = null;
            const failed = new OrderProductsLoadFailed({ ...requested.payload, reason: asErrorMessage(error) });
            eventBus.emit(failed.trace(requested));
          });
      }

      return order._products;
    },

    async reloadProducts(traceId) {
      order._products = null;
      return order.loadProducts(traceId);
    },

    async update(cmd) {
      const updateRequested = new OrderUpdateRequested({ ...basePayloadFor(order), update: cmd });
      eventBus.emit(updateRequested);

      return order
        .ensureEditable()
        .then(async () => {
          const locations = getUpdatedLocationsFor(order, cmd);

          if (locations.removed.length)
            await onDeleteLocations({ orderId: order.id, orderLocationIds: locations.removed });

          return locations;
        })
        .then(async locations => {
          return onUpdate({ ...cmd, orderId: order.id, locations: [...locations.kept, ...locations.added] });
        })
        .then(details => {
          order.details = { ...order.details, ...details };
          eventBus.emit(new OrderUpdated(updateRequested.payload).trace(updateRequested));
        })
        .catch(error => {
          eventBus.emit(
            new OrderUpdateFailed({ ...updateRequested.payload, reason: asErrorMessage(error) }).trace(updateRequested)
          );

          throw error;
        });
    },

    toDuplicateCommand() {
      return {
        brand,
        customerId,
        finalAssortmentId: order.details.assortmentId,
        finalAssortmentName: order.details.assortmentName,
        orderName: `${order.details.displayName} (Copy)`,
        soldTo: order.details.soldTo,
        billTo: order.details.billTo,
        poNumber: order.details.poNumber,
        locations: order.locations.map(({ displayName, shipTo, billTo }) => ({
          displayName,
          shipTo,
          billTo
        }))
      } as CreateOrderCommand;
    },

    async delete() {
      const requested = new OrderDeleteRequested(basePayloadFor(order));
      eventBus.emit(requested);

      return order
        .ensureEditable()
        .then(() => {
          return onDelete({ orderIds: [order.id] });
        })
        .then(() => {
          const deleted = new OrderDeleted(requested.payload);
          eventBus.emit(deleted.trace(requested));
        })
        .catch(error => {
          const failed = new OrderDeleteFailed({ ...requested.payload, reason: asErrorMessage(error) });
          eventBus.emit(failed.trace(requested));
          throw error;
        });
    },

    async size(cmds) {
      // TODO: Check for specific location id.
      //   If it's wildcard (*) then update all locations in the order (default behaviour for now).
      const products = order.locations.flatMap(location =>
        cmds.flatMap(cmd =>
          cmd.adjustments.flatMap(([months, [d1, d2], units]) =>
            months.map(month => ({
              deliveryDate: monthToDeliveryDate(month),
              orderLocationId: location.orderLocationId,
              pc9: cmd.product.pc9,
              size1: d1,
              size2: d2 === NULL_DIMENSION ? undefined : d2,
              quantity: units ?? 0
            }))
          )
        )
      );

      if (!products.length) return;

      const requested = new OrderSizingRequested({ ...basePayloadFor(order), cmds });
      eventBus.emit(requested);

      return order
        .ensureEditable()
        .then(() => {
          // FIXME: Check for success in response (do this in the utils package...)
          return onUpdateProduct({ params: { orderId: order.id }, products });
        })
        .then(async () => {
          const sized = new OrderSized(requested.payload);
          eventBus.emit(sized.trace(requested));
        })
        .catch(error => {
          const failed = new OrderSizingFailed({ ...requested.payload, cmds, reason: asErrorMessage(error) });
          eventBus.emit(failed.trace(requested));
          throw error;
        })
        .then(() => order.reloadProducts(requested.traceId()))
        .then(() => undefined);
    },

    async place() {
      const requested = new OrderPlaceRequested(basePayloadFor(order));
      eventBus.emit(requested);

      return order
        .ensureEditable()
        .then(() => {
          return onPlace({ orderId: order.id });
        })
        .then(data => {
          order.details.status = data.status ?? order.details.status;
          const placed = new OrderPlaced(requested.payload);
          eventBus.emit(placed.trace(requested));
        })
        .catch(error => {
          const failed = new OrderPlaceFailed({ ...requested.payload, reason: asErrorMessage(error) });
          eventBus.emit(failed.trace(requested));
          throw error;
        });
    }
  };

  return order;
};

const getUpdatedLocationsFor = (order: OrderModel, cmd: OrderUpdateCommand) => {
  const removed = order.locations
    .filter(({ orderLocationId }) => !cmd.locations?.find(l => l.orderLocationId === orderLocationId))
    .map(({ orderLocationId }) => orderLocationId);

  const [added, kept]: [OrderLocation[], OrderLocation[]] = [[], []];

  for (const location of cmd.locations ?? []) {
    if (typeof location.orderLocationId === 'undefined') added.push(location);
    else if (!removed.find(deletedId => deletedId === location.orderLocationId)) kept.push(location);
  }

  return { removed, kept, added };
};
