import { AddProductSlideOut } from 'components';
import { Entity, type EntityContext, type SidePanelId, SidePanelOpened } from 'domain-events';
import { useCustomersData, useLocalisation } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import { useDebouncedFn, useEventSubscriptions } from 'utils';
import {
  handleAddProductConfirmTag,
  handleClearAllProducts,
  handleProductSearchTag,
  handleSelectAllProducts
} from 'utils/tags/filterTagUtils';

import type { ProductListData, UIProduct } from '@yourxx/types';

import { filterEligibleAssortmentsAsAddSource } from '../filterEligibleAssortmentsAsAddSource';
import { useAddProduct } from './useAddProduct';
import type { UseSidePanelReturn } from './useSidePanel';

export const useAddProductUI = ({
  context,
  products,
  targetAssortmentSeason,
  canAddFromParentLine,
  parentLineId,
  sidePanel,
  isFinalAssortment
}: {
  context: EntityContext | undefined;
  products: UIProduct<ProductListData>[];
  canAddFromParentLine: boolean;
  parentLineId?: string;
  targetAssortmentSeason?: string;
  sidePanel: UseSidePanelReturn<SidePanelId>;
  isFinalAssortment: boolean;
}) => {
  const [str] = useLocalisation();
  const { eventBus, toastService, productsService } = useServices();
  const { isLoading, loadProductsFor, loadAssortmentsFor, productsFor, assortmentsFor } = useCustomersData();
  const { customerId, assortmentId, lineId } = useParams();
  const productsKeyedByPC9 = useMemo(
    () =>
      products.reduce<Record<string, UIProduct<ProductListData>>>((acc, product) => {
        acc[product.pc9] = product;
        return acc;
      }, {}),
    [products]
  );

  const mAssortmentsFor = useCallback(
    (customerId: string) => {
      const maybeAssortments = assortmentsFor(customerId);
      if (!maybeAssortments) return null;

      const season = targetAssortmentSeason?.toLowerCase();
      if (!season) return maybeAssortments;

      return {
        ...maybeAssortments,
        assortments: {
          [season]: filterEligibleAssortmentsAsAddSource(maybeAssortments.assortments?.[season] ?? [], parentLineId)
        }
      };
    },
    [assortmentsFor, parentLineId, targetAssortmentSeason]
  );

  const mProductsFor = useCallback(
    (assortmentOrLineId: string, maybeTag?: 'line' | 'parentLine') => {
      const { details, products } = productsFor(assortmentOrLineId, maybeTag ?? 'assortment') ?? {};
      if (!details?.parentId) return null;

      return {
        parentLineId: details.parentId,
        products: products?.filter(product => !(product.pc9 in productsKeyedByPC9)) ?? []
      };
    },
    [productsFor, productsKeyedByPC9]
  );

  const [shouldLoadData, setShouldLoadData] = useState(false);

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(SidePanelOpened, event => {
          if (event.payload.sidePanelId === 'addProduct') setShouldLoadData(true);
        })
      ],
      [eventBus]
    )
  );

  const mLoadAssortmentsFor = useCallback(() => {
    if (!shouldLoadData) return;
    if (context && 'customerId' in context) loadAssortmentsFor(context.customerId);
  }, [context, loadAssortmentsFor, shouldLoadData]);

  const mLoadProductsFor = useCallback(
    (assortmentId: string, maybeTag?: 'line' | 'parentLine') => {
      if (!shouldLoadData) return;
      loadProductsFor(assortmentId, maybeTag ?? 'assortment');
    },
    [loadProductsFor, shouldLoadData]
  );

  const onSelectAll = useCallback(() => {
    handleSelectAllProducts(assortmentId, customerId, isFinalAssortment);
  }, [assortmentId, customerId, isFinalAssortment]);

  const onSearchFor = useCallback(
    useDebouncedFn((term: string) => {
      handleProductSearchTag(assortmentId, customerId, term, isFinalAssortment);
    }, 500),
    [assortmentId, customerId, lineId, isFinalAssortment]
  );

  const onClearAll = useCallback(() => {
    handleClearAllProducts(assortmentId, customerId, isFinalAssortment);
  }, [assortmentId, customerId, isFinalAssortment]);

  const addProductProps = useAddProduct({
    context,
    isLoading,
    parentLineId: canAddFromParentLine ? parentLineId : undefined,
    assortmentsFor: mAssortmentsFor,
    productsFor: mProductsFor,
    loadAssortmentsFor: mLoadAssortmentsFor,
    loadProductsFor: mLoadProductsFor,
    onSearchFor,
    onSelectAll,
    onClearAll,
    onAdd: async ({ context, products }) => {
      return productsService
        .add({ context, items: products })
        .then(() => {
          handleAddProductConfirmTag(
            assortmentId,
            customerId,
            context.name,
            products.map(p => p.pc9),
            '2',
            isFinalAssortment
          );
          toastService.send(
            str(
              context.entity === Entity.Line
                ? 'Assortment.addProduct.toast.successForLine'
                : 'Assortment.addProduct.toast.success',
              { count: products.length }
            )
          );
        })
        .catch(e => {
          toastService.send(e instanceof Error ? e.message : `${e}`, 'error');
        });
    }
  });

  return (
    <AddProductSlideOut
      {...addProductProps}
      isFinalAssortment={isFinalAssortment}
      customerId={customerId}
      isOpen={sidePanel.id === 'addProduct'}
      onClose={() => sidePanel.set('addProduct')}
    />
  );
};
