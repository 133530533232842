import { AuditOrderProductDelete, AuditOrderProductUpdate, OrderProduct } from '@yourxx/types';

import { auditCardTs, AuditPanelEvents } from '../EventCards';

const toLocaleString = (date: string | Date) => {
  return new Date(date).toLocaleString(undefined, {
    year: 'numeric',
    month: 'short'
  });
};

const orderProductUpdateText = (products: OrderProduct[], deliveryDate: string) => {
  return products.map(({ size2, size1, pc9, quantity }) => (
    <span>{`${toLocaleString(deliveryDate)} - ${pc9} ${[size1, size2].join(':')} x ${quantity}\n`}</span>
  ));
};

type SupportedEvents = AuditOrderProductDelete | AuditOrderProductUpdate;
type Event = AuditPanelEvents<SupportedEvents>;
export const AuditOrderProductUpdateCard = (event: Event) => {
  return (
    <div>
      {auditCardTs(event)}
      <h4>{event.by?.initials} edited product</h4>
      {orderProductUpdateText(event.products, event.deliveryDate)}
    </div>
  );
};
