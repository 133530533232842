export enum SlotId {
  HeaderTopToolbar = 'header.top-toolbar',
  HeaderToolbarRight = 'header.toolbar-right',
  HeaderBrandLogo = 'header.brand-logo',
  HeaderMainMenu = 'header.main-menu',
  PageBreadcrumbs = 'page.breadcrumbs',
  PageTitle = 'page.title',
  PageToolbar = 'page.toolbar',
  PageToolbarRight = 'page.toolbar-right',
  PageDetails = 'page.details',
  Status = 'page.status',
  CustomerMenu = 'customer.menu',
  CustomerToolbar = 'customer.toolbar',
  ProductsMenu = 'products.menu',
  ProductsToolbar = 'products.toolbar',
  OrderTotals = 'order.totals',
  OrderToolbar = 'order.toolbar',
  Modals = 'modals',
  FooterLeft = 'footer.left',
  FooterRight = 'footer.right',
  LegacyFooter = 'legacy-footer-left',
  SidePanels = 'side-panels'
}
