import { Copy } from 'assets/icons';
import { AutoFocusInput, Dialog, Label } from 'components';
import { useLocalisation } from 'providers';
import { routes } from 'routes';
import { ToastRequested, useServices } from 'services';
import styled from 'styled-components';
import { rem, themed, useRoute } from 'utils';

import { State, useProductVideo, type UseProductVideoProps } from './useProductVideo';

const Content = styled.div`
  min-width: ${rem(480)};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themed('spacing.s')};

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(34)};
    height: ${rem(34)};
    margin: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    will-change: opacity;
    transition-property: opacity;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
`;

export const useProductVideoUI = ({
  pc9,
  videoUrl,
  onSave
}: Pick<UseProductVideoProps, 'pc9' | 'videoUrl' | 'onSave'>) => {
  const [str] = useLocalisation();
  const { route } = useRoute();
  const { eventBus } = useServices();
  const state = useProductVideo({
    service: useServices().productVideoService,
    pc9,
    videoUrl
  });

  let Renderable: React.ReactNode = null;

  if (route?.matches(routes.assortmentProduct) || route?.matches(routes.lineProduct)) {
    if (state.state === State.Editing)
      Renderable = (
        <Dialog
          title={str('PDP.labels.addVideo')}
          onClose={state.cancel}
          content={
            <Content>
              <Label text={str('PDP.labels.videoLinkInputLabel')}>
                <InputWrapper>
                  <AutoFocusInput value={state.videoUrl} onChange={state.inputUrl} />
                  {videoUrl && (
                    <button
                      disabled={!state.videoUrl.trim()}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(state.videoUrl)
                          .then(() => eventBus.emit(ToastRequested.success(str('PDP.labels.videoLinkCopied'))))
                          .catch(() => ToastRequested.error(str('PDP.labels.couldNotCopyVideoLink')));
                      }}
                    >
                      <Copy />
                    </button>
                  )}
                </InputWrapper>
              </Label>
            </Content>
          }
          confirm={{
            label: str('PDP.labels.saveVideo'),
            handler: () => {
              onSave?.();
              state.confirm();
            },
            disabled: !state.canConfirm
          }}
          cancel={state.canRemove ? { label: str('PDP.labels.removeVideo'), handler: state.remove } : undefined}
        />
      );
    else if (state.state === State.PendingConfirmation)
      Renderable = (
        <Dialog
          title={str('PDP.labels.removeVideo')}
          onClose={state.cancel}
          content={<p>{str('PDP.labels.confirmMessageRemoveVideo', { pc9 })}</p>}
          confirm={{ label: str('PDP.labels.confirmRemoveVideo'), handler: state.confirm }}
          cancel={{ label: str('general.cancel'), handler: state.cancel }}
        />
      );

    return {
      Renderable,
      state
    };
  }

  return {
    Renderable: null,
    state
  };
};
