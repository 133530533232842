import type { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { useServices } from 'services';
import { useEnsureRouteParams } from 'utils';

import { CustomersDataProvider } from './CustomersDataProvider';
import { LegacyStoriesProvider } from './LegacyStoriesProvider';

const ToastNotificationsSetup = () => {
  const { toastService } = useServices();
  return toastService.RenderableToasts;
};

export const RouterDependentAppProviders = () => (
  <CustomersDataProviderWiring>
    <Outlet />
    <ToastNotificationsSetup />
  </CustomersDataProviderWiring>
);

const CustomersDataProviderWiring = ({ children }: { children: ReactNode }) => {
  const { eventBus, customersService } = useServices();
  const [brand] = useEnsureRouteParams('brandName');

  return (
    <CustomersDataProvider service={customersService} eventBus={eventBus} brand={brand}>
      <LegacyStoriesProvider>{children}</LegacyStoriesProvider>
    </CustomersDataProvider>
  );
};
