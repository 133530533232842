import {
  type Dimension,
  NULL_DIMENSION,
  type OrderSizingContext,
  type SizingAdjustment,
  SizingMonth,
  type Units
} from './OrdersService';
import { adjustmentsFromSizing } from './utils';

export interface PC9Sizing extends OrderSizingContext {
  readonly version: number;
  readonly values: Readonly<Record<SizingMonth, Record<Dimension, Record<Dimension, Units>>>>;
  isEmpty(): boolean;
  value(month: number, d1: Dimension, d2?: Dimension): Units;
  toAdjustments(specificMonth?: SizingMonth): readonly SizingAdjustment[];
  [Symbol.iterator](): Iterator<[SizingMonth, Dimension, Dimension, Units]>;
}

export const PC9Sizing = (
  sizing: OrderSizingContext & {
    version: number;
    values: Record<string, Record<Dimension, Record<Dimension, Units>>>;
  }
) => {
  return {
    ...sizing,

    toAdjustments(specificMonth?: SizingMonth) {
      return adjustmentsFromSizing(this, specificMonth);
    },

    isEmpty() {
      return !hasValues(this);
    },

    value(month, d1, d2 = NULL_DIMENSION) {
      return sizing.values[month]?.[d1]?.[d2] ?? null;
    },

    *[Symbol.iterator]() {
      for (const month in sizing.values) {
        for (const d1 in sizing.values[month]) {
          for (const d2 in sizing.values[month][d1]) {
            yield [Number(month), d1, d2, sizing.values[month][d1][d2]];
          }
        }
      }
    }
  } as PC9Sizing;
};

const hasValues = (sizing: PC9Sizing) => {
  for (const [_month, , , value] of sizing) {
    if (value != null) return true;
  }
  return false;
};
