import type { FeatureFlags } from '@yourxx/types';

import { persistedStore } from './persistedStore';

export const CACHE_TTL = 3000; // ms
const CACHED_ACTIONS: [number | undefined, string[] | undefined] = [undefined, undefined];

export const getActions = (forceRefresh = false) => {
  if (!forceRefresh) {
    const [ts, cachedActions] = CACHED_ACTIONS;
    if (cachedActions && ts && Date.now() - ts < CACHE_TTL) {
      return cachedActions;
    }
  }

  const actions = persistedStore.getUserProfile()?.legacyAction ?? [];
  CACHED_ACTIONS.splice(0, 2, Date.now(), actions);
  return actions;
};

export const canUse: (flag: FeatureFlags) => boolean = (flag: FeatureFlags) => getActions().includes(flag);
export const canNotUse = (flag: FeatureFlags) => !canUse(flag);
export const canUseSome = (features: ReadonlyArray<FeatureFlags>) => features.some(canUse);
export const canUseAll = (features: ReadonlyArray<FeatureFlags>) => features.every(canUse);
