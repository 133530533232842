import { Image } from 'components/Image';
import { ImageSlider, SliderImage } from 'components/ImageSlider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { pdpImageFullscreenTag, pdpImageZoomTag, pdpVideoPlayTag, pdpVideoUrlTag, rem, themed } from 'utils';
import { pdpImageViewTag } from 'utils/tags/pdpTagsUtils';

import { PageDetails, ProductDetailsData, UIProduct } from '@yourxx/types';

const Container = styled.div`
  position: relative;
  width: calc(100% - ${rem(50)} - ${themed('spacing.l')});
  overflow: auto;
`;
const SliderWrapper = styled.div`
  border-radius: 0 0 ${themed('spacing.m')} ${themed('spacing.m')};
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1;

  > div {
    height: 100%;
  }
`;
const ImageType = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  padding: ${themed('spacing.l')};
  background-color: ${themed('color.white')};
  text-transform: uppercase;
  font-weight: ${themed('font.weight.semiBold')};
  border-radius: ${themed('spacing.m')} ${themed('spacing.m')} 0 0;
`;
const ProductStatus = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  text-transform: uppercase;
  font-size: ${themed('font.size.s')};
  font-weight: 500;
  color: ${themed('color.white')};
  background-color: ${themed('color.transparentLightBlack')};
  padding: ${themed('spacing.xl')};
  transform: translate(-50%, -50%);
`;

export const ProductImages = ({
  images,
  product,
  details,
  activeSlide,
  onSlide,
  isFinalAssortment
}: {
  images: SliderImage[];
  product: UIProduct<ProductDetailsData>;
  details?: PageDetails;
  activeSlide: number;
  onSlide: (index: number) => void;
  isFinalAssortment?: boolean;
}) => {
  const { customerId, assortmentId: customerAssortmentId, lineId } = useParams();
  const customerOrLineId = customerId ?? lineId;
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  useEffect(() => {
    if (images[activeSlide] && !isFullscreen) {
      pdpImageViewTag(
        customerAssortmentId,
        customerOrLineId,
        product.pId,
        'pdp',
        images[activeSlide].imageType,
        isFinalAssortment,
        '2',
        product.pc9
      );
    }
  }, [activeSlide, images, isFullscreen, product, customerAssortmentId, customerOrLineId]);

  if (!images.length) return <Image />;
  return (
    <Container>
      <ImageType>{images?.[activeSlide]?.imageType}</ImageType>
      <SliderWrapper>
        <ImageSlider
          images={images}
          product={product}
          details={details}
          zoomedIn={isZoomed}
          fullscreened={isFullscreen}
          onZoomChange={val => {
            setIsZoomed(val);
            if (val) {
              pdpImageZoomTag({
                productId: product.pId,
                customerId: customerOrLineId,
                assortmentId: customerAssortmentId
              });
            }
          }}
          onFullscreenChange={val => {
            setIsFullscreen(val);
            if (val) {
              pdpImageFullscreenTag({
                productId: product.pId,
                customerId: customerOrLineId,
                assortmentId: customerAssortmentId
              });
            }
          }}
          defaultSlide={activeSlide}
          onSlideChange={index => onSlide(index)}
          disableDots
          onVideoPlay={() =>
            pdpVideoPlayTag({
              productId: product.pId,
              customerId: customerOrLineId,
              assortmentId: customerAssortmentId
            })
          }
          onShowVideoEdit={() =>
            pdpVideoUrlTag({ productId: product.pId, customerId: customerOrLineId, assortmentId: customerAssortmentId })
          }
        />
      </SliderWrapper>
      {product.status.toLowerCase() !== 'add' && <ProductStatus>{product.status}</ProductStatus>}
    </Container>
  );
};
