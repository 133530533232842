import { useCallback } from 'react';

import { AuditTrail } from '../../../components/AuditTrail/AuditTrail';
import { type UseSidePanelReturn } from '../../Products';

export const useOrderAuditTrailUI = ({
  sidePanel,
  orderId,
  assortmentId
}: {
  orderId: string;
  assortmentId: string;
  sidePanel: UseSidePanelReturn<'auditTrail'>;
}) => {
  const onClose = useCallback(() => sidePanel.set('auditTrail'), [sidePanel]);

  return (
    <AuditTrail
      isOpen={sidePanel.id === 'auditTrail'}
      onClose={onClose}
      orderId={orderId}
      assortmentId={assortmentId}
    />
  );
};
