import { Plus } from 'assets/icons';
import {
  AssortmentModal,
  LoadingSpinner,
  ResourceLoadedEmpty,
  ResourceLoadingFailed,
  Tooltip,
  useContextfulSearch
} from 'components';
import { GhostButton, SlotId } from 'pages';
import { customerRoute, useSetupCustomerView } from 'pages/Customer';
import { InsightsSetup } from 'pages/Products';
import { FailedLoadingAssortments, useCustomersData, useLocalisation, useSlot } from 'providers';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { useServices } from 'services';
import styled from 'styled-components';
import { assortmentBuildingCreateTag, landingNewAssortmentTag, parseSeasonParam, relativePath, themed } from 'utils';

import { searchAssortments, type SearchResult } from '@yourxx/support';
import {
  type CustomerAssortmentProps,
  type CustomerAssortmentsSeasonMap,
  FeatureFlags,
  type GetCustomerAssortmentResponse
} from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

import { AssortmentsGrid } from './AssortmentsGrid';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
    background-color: ${themed('color.offWhite')};
  }

  .virtualGrid {
    height: auto !important;
    flex: 1;
  }
`;

export type CustomerViews = keyof typeof customerRoute;

export const Assortments = ({
  customerId,
  season: maybeSeason,
  currentView,
  // TODO: Move data loading outside
  customerData: _loaderCustomerData
}: {
  customerId: string;
  season: string | undefined;
  currentView: CustomerViews;
  customerData: GetCustomerAssortmentResponse;
}) => {
  const [str] = useLocalisation();
  const navigate = useNavigate();
  const { toastService } = useServices();
  const { isLoading, error, assortmentsFor, loadAssortmentsFor, retry, clearCache } = useCustomersData();

  const customerData = useMemo(() => (customerId ? assortmentsFor(customerId) : null), [customerId, assortmentsFor]);
  const season = parseSeasonParam(maybeSeason);

  const errorMessage = useMemo(() => {
    if (error instanceof FailedLoadingAssortments) return str('Landing.customers.retrievingAssortmentsError');
  }, [error, str]);

  const {
    searchTerm: assortmentsSearchTerm,
    changeTerm: changeAssortmentsTerm,
    addSuggestions: addAssortmentsSearchSuggestions
  } = useContextfulSearch({
    contextId: routes.assortments.toString()
  });

  const assortmentsSearchResults = useMemo(() => {
    if (!assortmentsSearchTerm || !customerData?.assortments) return {};

    return Object.entries(customerData.assortments).reduce<Record<string, SearchResult<CustomerAssortmentProps>[]>>(
      (acc, [season, assortments]) => ({ ...acc, [season]: searchAssortments(assortmentsSearchTerm, assortments) }),
      {}
    );
  }, [assortmentsSearchTerm, customerData?.assortments]);

  useEffect(() => {
    if (!season) return;

    const currentSeasonResults = assortmentsSearchResults[season];
    if (!currentSeasonResults?.length) return;

    const suggestions = currentSeasonResults.slice(0, 10).map(result => {
      const firstMatchValue = result.matches[0].value;
      return {
        label: firstMatchValue,
        onSelect: () => {
          changeAssortmentsTerm(firstMatchValue);
          navigate(result.item.assortmentId);
        }
      };
    });

    addAssortmentsSearchSuggestions(suggestions);
  }, [addAssortmentsSearchSuggestions, assortmentsSearchResults, changeAssortmentsTerm, navigate, season]);

  const searchedAssortments = useMemo(() => {
    if (!assortmentsSearchTerm || !customerData?.assortments) return customerData?.assortments;

    return Object.entries(assortmentsSearchResults).reduce<CustomerAssortmentsSeasonMap>((accum, [season, results]) => {
      accum[season] = results.map(({ item }) => item);
      return accum;
    }, {});
  }, [assortmentsSearchResults, assortmentsSearchTerm, customerData?.assortments]);

  const [showAssortmentModal, setAssortmentModalVisibility] = useState(false);
  useSlot(
    SlotId.PageToolbarRight,
    <>
      {canUse(FeatureFlags.Assortment_Create) && customerData && true ? (
        <Tooltip
          text={str('Landing.customers.createAssortmentNotAllowed')}
          position="bottom"
          disableTooltipOnly={!!customerData.seasons?.length}
          noPadding
        >
          <GhostButton
            onClick={() => {
              landingNewAssortmentTag({ customerId });
              setAssortmentModalVisibility(true);
            }}
            disabled={!customerData.seasons?.length}
          >
            <Plus />
            {str('Landing.customers.createAssortment')}
          </GhostButton>
        </Tooltip>
      ) : null}
    </>
  );

  useSlot(
    SlotId.Modals,
    <>
      {customerData && showAssortmentModal && (
        <AssortmentModal
          customerData={customerData}
          selectedSeason={season}
          onClose={() => setAssortmentModalVisibility(false)}
          onCreate={newAssortment => {
            assortmentBuildingCreateTag({
              id: newAssortment.assortment_id,
              name: newAssortment.name,
              type: newAssortment.type ?? undefined,
              season: season
            });
            setAssortmentModalVisibility(false);
            if (customerId) {
              clearCache({ id: customerId, tag: 'customerAssortments' });
              loadAssortmentsFor(customerId);
            }
            // TODO: find a way to await loadAssortmentsFor
            if (newAssortment.type === 'FINAL') {
              // TODO: Test this works.
              navigate(relativePath(`../${customerRoute.final}`));
            }
            if (newAssortment.type === 'ARCHIVED') {
              navigate(relativePath(`../${customerRoute.archived}`));
            }
          }}
          onError={() => toastService.send(<span>{str('Assortment.create.error')}</span>, 'error')}
        />
      )}
    </>
  );

  useSetupCustomerView({
    customerId,
    currentView,
    season
  });

  if (isLoading && !customerData) {
    return (
      <Container>
        <LoadingSpinner
          label={customerId ? str('Landing.customers.loadingAssortments') : str('Landing.customers.loading')}
        />
      </Container>
    );
  }
  if (errorMessage) {
    return (
      <Container>
        <ResourceLoadingFailed error={errorMessage} onRetry={retry} retryLabel={str('general.retry')} />
      </Container>
    );
  }
  if (!customerData?.assortments) {
    return (
      <Container>
        <ResourceLoadedEmpty
          message={str(customerId ? 'Landing.customers.noAssortmentsFound' : 'Landing.customers.noneFound')}
        />
      </Container>
    );
  }

  return (
    <Container>
      {searchedAssortments ? (
        <AssortmentsGrid assortments={searchedAssortments} activeTab={currentView} season={season} />
      ) : (
        <ResourceLoadedEmpty message={str('Landing.customers.noAssortments')} />
      )}
      <InsightsSetup />
    </Container>
  );
};
