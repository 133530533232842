import { NULL_DIMENSION } from '../OrdersService';
import { History } from './History';
import { SizingComputedState } from './types';

export const computeState = (history: History, computed: SizingComputedState = { version: -Infinity, state: {} }) => {
  if (computed.version === history.version) return computed;

  const initialised: Record<string, boolean> = {};

  for (const { isReverted, adjustments } of history.entries) {
    for (const adjustment of adjustments) {
      const [months, [d1, d2 = NULL_DIMENSION], units] = adjustment;

      for (const month of months) {
        const key = `${month}-${d1}-${d2}`;
        if (!initialised[key]) {
          ((computed.state[month] ??= {})[d1] ??= {})[d2] = history.base.state[month]?.[d1]?.[d2];
          initialised[key] = true;
        }

        if (isReverted) continue;

        ((computed.state[month] ??= {})[d1] ??= {})[d2] = units;
      }
    }
  }

  return { version: history.version, state: computed.state };
};
