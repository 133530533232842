import { ChevronDown } from 'assets/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Container = styled.div<{ $showBottomBorder: boolean }>`
  width: 100%;
  border-bottom-style: ${({ $showBottomBorder }) => ($showBottomBorder ? 'solid' : 'none')};
  border-bottom-color: ${themed('color.offWhite')};
  border-bottom-width: 1px;
`;
const Trigger = styled.div<{ $borderRadius: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${themed('color.white')};
  padding: ${themed('spacing.l')} ${themed('spacing.l')} ${themed('spacing.m')};
  border-radius: ${({ $borderRadius }) => rem($borderRadius, $borderRadius, 0, 0)};
  cursor: pointer;
`;
const ContentWrapper = styled.div<{ $maxHeight: number; $borderRadius: number; $isExpanded?: boolean }>`
  max-height: ${({ $maxHeight, $isExpanded, $borderRadius }) =>
    $isExpanded ? rem($maxHeight) : $borderRadius ? rem($borderRadius) : 0};
  margin-top: ${({ $isExpanded, $borderRadius }) => ($isExpanded && $borderRadius ? rem(-$borderRadius) : 0)};
  will-change: max-height, margin-top;
  transition:
    max-height 0.2s ease-out,
    margin-top 0.2s ease-out;
  overflow: hidden;
  border-radius: ${({ $borderRadius }) => rem(0, 0, $borderRadius, $borderRadius)};

  > div {
    overflow: hidden;
  }
`;
const Chevron = styled(ChevronDown)<{ $isExpanded?: boolean }>`
  will-change: transform;
  transition: transform 0.2s ease-out;
  transform: ${({ $isExpanded }) => `rotate(${$isExpanded ? 180 : 0}deg)`};
`;

export type AccordionProps = {
  className?: string;
  header: React.ReactNode;
  children: React.ReactNode;
  isExpanded?: boolean;
  onToggle?: (isExpanded: boolean, isResizing?: boolean) => void;
  showChevron?: boolean;
  showBottomBorder?: boolean;
  defaultExpanded?: boolean;
  calcHeightOnResize?: boolean;
  borderRadius?: number;
};

export const Accordion = ({
  className,
  header,
  children,
  onToggle,
  isExpanded: isExpandedProp,
  showChevron = true,
  showBottomBorder = false,
  defaultExpanded = false,
  calcHeightOnResize = true,
  borderRadius = 0
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedProp ?? defaultExpanded);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    if (typeof isExpandedProp !== 'undefined') setIsExpanded(isExpandedProp);
  }, [isExpandedProp]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateContentHeight = useCallback(() => {
    setContentHeight(isExpanded ? contentRef?.current?.scrollHeight || 0 : 0);
  }, [isExpanded, setContentHeight, contentRef?.current]);

  useEffect(() => {
    if (contentRef.current) {
      if (!observerRef.current) {
        observerRef.current = new MutationObserver(updateContentHeight);
      }
      observerRef.current.observe(contentRef.current, { childList: true, subtree: true });
      updateContentHeight();
      return () => observerRef.current?.disconnect();
    }
  }, [children, updateContentHeight, contentRef?.current, observerRef?.current]);

  const handleResize = useCallback(() => {
    if (calcHeightOnResize) {
      setIsExpanded(defaultExpanded ?? false);
      onToggle?.(false, true);
    }
  }, [calcHeightOnResize, setIsExpanded, defaultExpanded, onToggle]);

  const handleExpandToggle = useCallback(() => {
    setIsExpanded(prev => !prev);
    onToggle?.(!isExpanded || false);
  }, [onToggle, setIsExpanded, isExpanded]);

  return (
    <Container className={className} $showBottomBorder={showBottomBorder}>
      <Trigger onClick={handleExpandToggle} $borderRadius={borderRadius}>
        {header}
        {showChevron && <Chevron $isExpanded={isExpanded} />}
      </Trigger>
      <ContentWrapper
        $maxHeight={contentRef?.current?.scrollHeight || contentHeight}
        $borderRadius={borderRadius}
        $isExpanded={isExpanded}
      >
        <div ref={contentRef}>{children}</div>
      </ContentWrapper>
    </Container>
  );
};
