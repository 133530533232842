import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { routes } from 'routes';

import { useContextfulSearch } from './ContextfulSearchProvider';

const searchableRoutes = [
  routes.orders,
  routes.order,
  routes.orderSizing,
  routes.customers,
  routes.assortments,
  routes.assortmentProducts,
  routes.lines,
  routes.lineProducts
];

export const useSetupContextfulSearch = () => {
  const { pathname } = useLocation();

  const contextId = useMemo(() => {
    for (const route of searchableRoutes) if (matchPath({ path: route.toString() }, pathname)) return route.toString();
  }, [pathname]);

  const searchProps = useContextfulSearch({ contextId });

  return useMemo(() => ({ ...searchProps, currentContext: contextId }), [contextId, searchProps]);
};
