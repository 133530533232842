import { SlotId, useAddBreadcrumb } from 'pages';
import { useOrders, useSlot } from 'providers';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { OrderModel } from 'services';

import { OrderMetadata, StatusCapsule } from '../components';
import { OrderDetails } from '../OrderDetails';
import { OrderLayout } from '../OrderLayout';
import { OrderSizingRoute } from '../OrderSizing';

export const CustomerOrder = ({ orderId: slugOrOrderId, season }: { orderId: string; season: string }) => {
  const { brand, customerId, customerName, findOrder } = useOrders();

  // TODO: Hard-coded for now.
  // TODO: Try https://reactrouter.com/en/main/utils/generate-path
  useAddBreadcrumb(customerName, 0, `/${brand}/customers/${customerId}/${season}`);
  useAddBreadcrumb('Orders', 1, `/${brand}/customers/${customerId}/${season}/orders`);

  const currentOrder = useMemo(() => findOrder(slugOrOrderId), [findOrder, slugOrOrderId]);

  return (
    <>
      {/*<EditOrderDemo />*/}
      {/*<CopyOrderModalDemo />*/}
      {currentOrder && <OrderMetadataSetup order={currentOrder} />}
      <Routes>
        <Route Component={OrderLayout}>
          <Route index element={<OrderDetails orderId={slugOrOrderId} />} />
          <Route path=":pc9/*" element={<OrderSizingRoute orderId={slugOrOrderId} />} />
        </Route>
      </Routes>
    </>
  );
};

const OrderMetadataSetup = ({ order }: { order: OrderModel }) => {
  useSlot(SlotId.PageTitle, `Order: ${order.details.displayName}`);

  useSlot(SlotId.Status, <StatusCapsule status={order.status} />);

  useSlot(
    SlotId.PageDetails,
    <OrderMetadata
      metadata={{
        Order: undefined,
        Location: order.locations.map(({ displayName }) => displayName).join(', ')
      }}
    />
  );

  return null;
};
