import {
  CreateOrderLocation,
  OrderAssortProducts,
  OrderProductsMap,
  UpdateOrderLocationQueryIn
} from '../../dao/order';
import { OrderSummary } from '../../dao/orderDetails';

export type ApiOrderDetails = { orderIds: string | string[] };

export type ApiCreateOrder = {
  assortmentId: string;
  displayName: string;
  soldTo?: string;
  billTo?: string;
  poNumber?: string;
  locations?: CreateOrderLocation[];
};

export type ApiGetOrderProductOut = {
  orderProducts: OrderProductsMap;
  assortmentProducts?: OrderAssortProducts[];
  summary?: OrderSummary;
};

export type ApiUpdateOrder = {
  orderId: string;
  displayName?: string;
  assortmentId?: string | null;
  soldTo?: string | null;
  billTo?: string | null;
  poNumber?: string | null;
  locations?: UpdateOrderLocationQueryIn[];
};

export type ApiDeleteSuccess = { success: boolean };
export type ApiSubmitOrder = {
  orderId: string;
};
export type ApiSubmitOrderOut = {
  orderId: string;
  status: string;
};
export type ApiDeleteOrders = {
  orderIds: string[];
};

export type ApiDeleteOrderLocations = {
  orderId: string;
  orderLocationIds: string[];
};

export type ApiCreateOrderLocation = {
  orderId: string;
  shipTo?: string;
  displayName?: string;
  billTo?: string | null;
  poNumber?: string | null;
};

type ProductBreakdown = {
  units: number;
  pc9s: number;
  rrp: number;
  wsp: number;
};

export type OrderProductCounts = {
  deliveryMonth: string;
  breakdown: ProductBreakdown;
  products: { [key: string]: boolean };
};

type OrderDetailsLocations = {
  orderLocationId: string;
  billTo?: string;
  poNumber?: string;
  shipTo?: string;
  displayName: string;
  urlSlug: string;
  createdAt: string;
  updatedAt?: string;
  breakdown: ProductBreakdown;
  deliveries?: { [key: string]: OrderProductCounts };
};

export type OrderDetails = {
  orderId: string;
  soldTo: string;
  billTo?: string;
  poNumber?: string;
  displayName: string;
  urlSlug: string;
  status?: string;
  season: string;
  imageUrl: string;
  imageUrlParams: string;
  createdAt: string;
  updatedAt?: string;
  breakdown: ProductBreakdown;
  assortmentId: string;
  assortmentName: string;
  currency: string;
  locations?: { [key: string]: OrderDetailsLocations };
};

export type ApiGetOrdersSummary = {
  orderIds: string[];
};
export type OrderSummaryMap = Record<string, OrderDetails>;

export type ApiUpdateOrderProductParams = { orderId: string };
export type ApiDeleteOrderProduct = { orderId: string; dpId: string[] | string };

export type UpdateOrderProduct = {
  orderLocationId: string;
  deliveryDate: string;
  pc9: string;
  size1: string;
  size2?: string;
  quantity: number;
};

export type ApiGetOrderProduct = { orderId: string };

export type ApiUpdateOrderProduct = {
  params: ApiUpdateOrderProductParams;
  products: UpdateOrderProduct[];
};

export const orderApiEndpoints = {
  orderProduct: `/v2/order/product`,
  order: `/v2/order`,
  orderLocation: `/v2/order/location`,
  orderSubmit: `/v2/order/submit`
} as const;
