import { type LocalisedStringFor } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { themed, useEventSubscriptions } from 'utils';

import type { EventBus } from '../../EventBus';
import { ToastRequested } from '../../ToastService';
import { createOrderSizingClipboard } from '../createOrderSizingClipboard';
import { OrderSized } from '../events';
import type { OrderModel } from '../OrderModel';
import type { OrderSizingRecords } from '../OrderSizingManager';
import { createOrderSizingManager } from './createOrderSizingManager';

const Toast = styled.p`
  margin: 0;
`;
const UndoButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  background: 0;
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.bold')};
  color: ${themed('color.white')};

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  ${Toast} + & {
    margin-top: ${themed('spacing.m')};
  }
`;

export const useOrderSizing = ({
  str,
  eventBus,
  findOrder,
  records
}: {
  str: LocalisedStringFor;
  eventBus: EventBus;
  records: OrderSizingRecords;
  findOrder: (slugOrOrderId: string) => OrderModel | undefined;
}) => {
  const { add, hasPending } = usePendingActions();

  const manager = useMemo(
    () =>
      createOrderSizingManager({
        records,
        onUpdate: async cmds => {
          // TODO: We don't support copying sizing from one order to another, so all sizing changes happen within the same order at the moment.
          //   Getting the first orderId is fine for now.
          const order = findOrder(cmds[0].orderId);
          if (!order) throw new ReferenceError('Order not found when attempting to persist sizing data.');

          add(order.size(cmds));
        }
      }),
    [add, findOrder, records]
  );

  const onCopied = useCallback(() => {
    eventBus.emit(ToastRequested.success(<Toast>{str('Order.sizing.copiedToClipboard')}</Toast>));
  }, [eventBus, str]);

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(OrderSized, event => {
          const isCopied = event.payload.cmds.some(cmd => cmd.isCopied);
          if (!isCopied) return;

          const undo = async () => {
            return Promise.allSettled(
              event.payload.cmds.map(copiedCmd => {
                const cmd = { ...copiedCmd };
                // This is an Undo, so we're not technically copy/pasting.
                cmd.isCopied = false;
                return manager.undo(cmd);
              })
            ).finally(() => toast.dismiss());
          };

          const toast = ToastRequested.success(
            <>
              <Toast>{str('Order.sizing.pastedFromClipboard')}</Toast>
              <UndoButton onClick={undo}>{str('general.undo')}</UndoButton>
            </>
          );

          eventBus.emit(toast.trace(event));
        })
      ],
      [eventBus, manager, str]
    )
  );

  const clipboard = useMemo(() => createOrderSizingClipboard({ manager, onCopied }), [manager, onCopied]);

  return { isLoading: hasPending, manager, clipboard };
};

const usePendingActions = () => {
  const [pendingActions, setPendingActions] = useState<Set<Promise<any>>>(new Set());

  const add = useCallback((action: Promise<any>) => {
    setPendingActions(prev => {
      const updated = new Set(prev);
      const promise = action.finally(() => {
        setPendingActions(prev => {
          const updated = new Set(prev);
          updated.delete(promise);
          return updated;
        });
      });
      updated.add(promise);
      return updated;
    });
  }, []);

  return { add, hasPending: pendingActions.size > 0 };
};
