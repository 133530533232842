import { ReactNode } from 'react';

import { ApiAuditEvent, AuditEvent, GetUserAuditDetails } from '@yourxx/types';

import { assortmentEventCard } from './AuditEventCards/AssortmentEventCards';
import { lineEventCards } from './AuditEventCards/LineEventCards';
import { orderEventCards } from './AuditEventCards/OrderEventCards';
import { orderPlacementEventCards } from './AuditEventCards/OrderPlacementEventCards';

export type AuditPanelEvents<T = AuditEvent> = ApiAuditEvent<T> & {
  by?: GetUserAuditDetails;
  buttonLabel?: string;
  buttonCallback?: VoidFunction;
};

export const auditCardTs = (event: AuditPanelEvents) => (
  <span>
    {new Date(event.ts).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })} - {event.event.split('_')[0]}
  </span>
);

export const EventCards: Record<string, (event: AuditPanelEvents) => ReactNode> = {
  ...lineEventCards,
  ...orderEventCards,
  ...assortmentEventCard,
  ...orderPlacementEventCards
};
