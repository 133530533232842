import { useCallback, useEffect, useState } from 'react';

import { Defect, type Nullable, toggleArrayValue } from '@yourxx/support';

type UseCopySizingProps<T> = {
  product: T;
  otherProducts: readonly T[];
  onCopy: (source: T, ...targets: readonly T[]) => Promise<void>;
  onCheckForOverwrites: (targets: readonly T[]) => T[];
};

export const useCopySizing = <T extends { pc9: string }>({
  product,
  otherProducts,
  onCopy,
  onCheckForOverwrites
}: UseCopySizingProps<T>): UseCopySizingReturn<T> => {
  const [state, setState] = useState(State.Idle);
  const [targets, setTargets] = useState<T[]>([]);
  const [source, setSource] = useState<Nullable<T>>(null);
  const [overwrite, setOverwrite] = useState<{ operation: 'copy' | 'paste'; overwrites: T[] }>();

  useEffect(() => {
    if (state !== State.Idle) return;

    setTargets([]);
    setSource(null);
    setOverwrite(undefined);
  }, [state]);

  const copyTo = useCallback(() => setState(State.CopyingTo), []);
  const pasteFrom = useCallback(() => setState(State.PastingFrom), []);
  const cancel = useCallback(() => setState(State.Idle), []);

  const selectTargets = useCallback((targets: readonly T[]) => setTargets(prev => toggleArrayValue(targets, prev)), []);

  const cancelOverwrite = useCallback(() => {
    if (!overwrite) throw new Defect('Expected to have overwrite state set.');

    setState({ copy: State.CopyingTo, paste: State.PastingFrom }[overwrite.operation]);
  }, [overwrite]);

  const copy = useCallback(() => {
    if (!targets.length) throw new Defect('Cannot copy sizing data without selected target products.');
    setState(State.CopyingInProgress);
    return onCopy(product, ...targets).then(() => setState(State.Idle));
  }, [onCopy, product, targets]);

  const paste = useCallback(() => {
    if (!source) throw new Defect('Cannot paste sizing data without a source product selected.');
    setState(State.PastingInProgress);
    return onCopy(source, product).then(() => setState(State.Idle));
  }, [onCopy, product, source]);

  const confirmCopy = useCallback(() => {
    const toBeOverwritten = onCheckForOverwrites(targets);

    if (toBeOverwritten.length) {
      setState(State.ConfirmingOverwrite);
      setOverwrite({ operation: 'copy', overwrites: toBeOverwritten });
      return;
    }

    copy();
  }, [copy, onCheckForOverwrites, targets]);

  const confirmPaste = useCallback(() => {
    const toBeOverwritten = onCheckForOverwrites([product]);

    if (toBeOverwritten.length) {
      setState(State.ConfirmingOverwrite);
      setOverwrite({ operation: 'paste', overwrites: toBeOverwritten });
      return;
    }

    paste();
  }, [onCheckForOverwrites, paste, product]);

  switch (state) {
    case State.Idle:
      return { state, copyTo, pasteFrom };

    case State.CopyingInProgress:
      return { state, source: product, products: otherProducts, targets };

    case State.PastingFrom:
      return {
        state,
        products: otherProducts,
        source,
        selectSource: setSource,
        target: product,
        cancel,
        confirm: confirmPaste,
        canConfirm: !!source
      };

    case State.PastingInProgress: {
      if (!source) throw new Defect('Expected source product to be set before transitioning to PastingInProgress');
      return { state, products: otherProducts, source, target: product };
    }

    case State.ConfirmingOverwrite: {
      if (!overwrite) throw new Defect('Expected overwrite state to be set.');
      return {
        state,
        productsToBeOverwritten: overwrite.overwrites,
        cancel: cancelOverwrite,
        confirm: { copy, paste }[overwrite.operation]
      };
    }

    case State.CopyingTo:
      return {
        state,
        targets,
        source: product,
        products: otherProducts,
        canConfirm: !!targets.length,
        selectTargets,
        cancel,
        confirm: confirmCopy
      };

    default:
      throw new Defect(`Unhandled state: ${state}`);
  }
};

export enum State {
  Idle = 'Idle',
  CopyingTo = 'CopyingTo',
  CopyingInProgress = 'CopyingInProgress',
  PastingFrom = 'PastingFrom',
  PastingInProgress = 'PastingInProgress',
  ConfirmingOverwrite = 'ConfirmingOverwrite'
}

export type UseCopySizingReturn<T> =
  | {
      state: State.Idle;
      copyTo: VoidFunction;
      pasteFrom: VoidFunction;
    }
  | {
      state: State.CopyingTo;
      products: readonly T[];
      source: T;
      targets: readonly T[];
      selectTargets(targets: readonly T[]): void;
      cancel: VoidFunction;
      confirm: VoidFunction;
      canConfirm: boolean;
    }
  | {
      state: State.CopyingInProgress;
      source: T;
      products: readonly T[];
      targets: readonly T[];
    }
  | {
      state: State.PastingFrom;
      products: readonly T[];
      source: Nullable<T>;
      selectSource: (source: T) => void;
      target: T;
      cancel: VoidFunction;
      confirm: VoidFunction;
      canConfirm: boolean;
    }
  | {
      state: State.PastingInProgress;
      source: T;
      target: T;
      products: readonly T[];
    }
  | {
      state: State.ConfirmingOverwrite;
      productsToBeOverwritten: readonly T[];
      cancel: VoidFunction;
      confirm: VoidFunction;
    };
