import type { OrderModel } from '../OrderModel';
import type { OrderSizingManager } from '../OrderSizingManager';
import type { OrderSizingCommand } from '../OrdersService';

export const copySizingDataToOrder = async (
  sourceOrder: OrderModel,
  targetOrder: OrderModel,
  manager: OrderSizingManager
) => {
  // Ensure both orders have their products loaded
  await sourceOrder.loadProducts();
  await targetOrder.loadProducts();

  // Generate the commands to copy sizing data from source to target
  const commands = sourceOrder.products
    // We need to filter the products so we only apply adjustments for products in the target order.
    ?.filter(product => targetOrder.products?.find(p => product.pc9 === p.pc9))
    // Create a command for each product
    .map<OrderSizingCommand>(product => ({
      orderId: targetOrder.id,
      locationId: '*',
      product,
      isCopied: true,
      adjustments: manager.sizingOf({ orderId: sourceOrder.id, locationId: '*', product }).toAdjustments()
    }))
    // We're only interested in PC9s that have sizing data
    .filter(cmd => cmd.adjustments.length > 0);

  // Apply sizing commands
  if (commands?.length) {
    return manager.size(...commands);
  }

  return false;
};
