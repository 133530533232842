import {
  AuditAssortmentProductAdd,
  AuditAssortmentProductUpdate,
  AuditLineProductAdd,
  AuditLineProductRemove,
  AuditLineProductUpdate,
  OrderProduct,
  UpdateAssortmentProduct
} from '@yourxx/types';

import { Hint } from '../../../pages';
import { auditCardTs, AuditPanelEvents } from '../EventCards';

type ProductEditCardEvent =
  | AuditPanelEvents<AuditLineProductUpdate>
  | AuditPanelEvents<AuditAssortmentProductUpdate>
  | AuditPanelEvents<AuditLineProductAdd>
  | AuditPanelEvents<AuditAssortmentProductAdd>
  | AuditPanelEvents<AuditLineProductRemove>;

export const AuditProductEditCard = (type: string, event: ProductEditCardEvent) => {
  const pc9s = event.products.map(p => p.pc9);
  return (
    <div>
      {auditCardTs(event)}
      <h4>
        {event.by?.initials} {type} {productHeading(event.products)} in Assortment
      </h4>
      <div>{pc9s.join(', ')}</div>
      <span>{productUpdateTooltip(event.products[0], pc9s)}</span>
    </div>
  );
};

const productUpdateTooltip = (product: UpdateAssortmentProduct, pc9s: string[]) => {
  const updates = Object.keys(product).reduce((a: string[], k) => {
    const text = productUpdateText[k] ? productUpdateText[k](product) : undefined;
    if (text) {
      a.push(text);
    }
    return a;
  }, []);
  if (updates.length > 0) {
    return (
      <Hint
        children={
          <div>
            <div>{pc9s.join(', ')}</div>
            <ul>
              {updates.map(up => (
                <li>{up}</li>
              ))}
            </ul>
          </div>
        }
        of={<div>More Details</div>}
        bottom={true}
      ></Hint>
    );
  }
  return null;
};

export const productHeading = (products: { pc9: string }[]) =>
  products.length === 1 ? `1 product` : `${products.length} products`;

type UpdateText = UpdateAssortmentProduct | OrderProduct;
const productUpdateText: { [key: string]: (product: UpdateText) => string | undefined } = {
  firstOnFloor: (product: UpdateAssortmentProduct) => `first on floor: ${product.firstOnFloor?.split('T')[0]}`,
  lastOnFloor: (product: UpdateAssortmentProduct) => `last on floor: ${product.lastOnFloor?.split('T')[0]}`,
  newness: (product: UpdateAssortmentProduct) => `newness: ${product.newness}`,
  ranking: (product: UpdateAssortmentProduct) => `ranking: ${product.ranking}`,
  vlp: (product: UpdateAssortmentProduct) => `vlp: ${product.vlp}`,
  note: (product: UpdateAssortmentProduct) => `note: ${product.note}`,
  storeGrades: (product: UpdateAssortmentProduct) => {
    if (product.storeGrades && Boolean(product.storeGrades?.length)) {
      return `store grades: ${product.storeGrades.join(',')}`;
    }
    return undefined;
  },
  mandatory: (product: UpdateAssortmentProduct) => `mandatory: ${product.mandatory ? 'Yes' : 'No'}`,
  rateOfSale: (product: UpdateAssortmentProduct) => `rate of sale: ${product.rateOfSale}`,
  weeksOnSale: (product: UpdateAssortmentProduct) => `weeks on sale: ${product.weeksOnSale}`,
  deliveries: (product: UpdateAssortmentProduct) => `deliveries: ${product.deliveries}`,
  video: (product: UpdateAssortmentProduct) => `video url: ${product.video}`
};
