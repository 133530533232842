import { Plus } from 'assets/icons';
import { IAM } from 'components/IAM';
import { type ContextOf, Entity } from 'domain-events';
import { useLocalisation, useOrders, useSlot } from 'providers';
import { useState } from 'react';

import { PrimaryButton } from '../../CommonLayout';
import { OrderCreateEditModal } from '../../Landing/Assortments';
import { SlotId } from '../../SlotId';

const noop = async () => undefined;

export const ConvertToPrebookAndSizeSetup = ({ context }: { context: ContextOf<Entity.FinalAssortment> }) => {
  const [str] = useLocalisation();
  const { createOrder, finalAssortments, shipToLocations, billToLocations, soldToLocations } = useOrders();
  const [showCreateOrder, setShowCreateOrder] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);

  useSlot(
    SlotId.PageToolbarRight,
    <IAM action="orders.create" prefix={'customers'}>
      <PrimaryButton onClick={() => setShowCreateOrder(true)}>
        <Plus />
        {str('AssortmentFinalization.addToOrder')}
      </PrimaryButton>
    </IAM>,
    Infinity
  );

  useSlot(
    SlotId.Modals,
    <IAM action="orders.create" prefix={'customers'}>
      {showCreateOrder && context.id && (
        <OrderCreateEditModal
          assortments={finalAssortments ?? []}
          preselectedAssortmentId={context.id}
          order={undefined}
          shipToLocations={shipToLocations}
          billToLocations={billToLocations}
          soldToLocations={soldToLocations}
          editOrder={noop}
          duplicateOrder={noop}
          deleteOrders={noop}
          createOrder={async cmd => {
            setCreatingOrder(true);
            createOrder?.(cmd).then(() => {
              setShowCreateOrder(false);
              setCreatingOrder(false);
            });
          }}
          onClose={() => setShowCreateOrder(false)}
          isLoading={creatingOrder}
        />
      )}
    </IAM>
  );

  return null;
};
