import { useLocalisation, useSlot } from 'providers';
import { useMemo } from 'react';

import { useSidePanel } from '../../Products';
import { SlotId } from '../../SlotId';
import { useOrderAuditTrailUI } from '../hooks/useOrderAuditTrailUI';
import { useAddOrderToolbarControl } from '../OrderLayout';

export const SetupOrderToolbar = ({ orderId, assortmentId }: { orderId: string; assortmentId: string }) => {
  const [str] = useLocalisation();
  const sidePanel = useSidePanel<'auditTrail'>();

  const showUnseenCount = true;
  const ui = useOrderAuditTrailUI({ orderId, sidePanel, assortmentId });

  useSlot(SlotId.SidePanels, ui);
  useAddOrderToolbarControl(
    useMemo(
      () => ({
        badgeContent: undefined,
        icon: undefined,
        isActive: sidePanel.id === 'auditTrail',
        isLoading: false,
        hint: undefined,
        label: str('Order.auditTrail.buttonLabel').toUpperCase(),
        onClick: () => sidePanel.set('auditTrail'),
        position: -Infinity
      }),
      [showUnseenCount, sidePanel, str]
    ),
    Infinity
  );

  return null;
};
