import { tagsCallback } from '.';
import { LandingTags } from './landingTags';
import { PDPTags } from './pdpTags';
import { PLPTags } from './plpTags';

// Global payload function
const getGlobalPayload = (customerId?: string, assortmentId?: string) => ({
  customer_id: customerId,
  assortment_id: assortmentId
});

// Generic tag triggering function
const triggerTag = (tag: LandingTags | PLPTags | PDPTags, payload: Record<string, any>) => {
  tagsCallback(tag, payload);
};

// Centralized tag mapping (matches your requested format)
const PDP_TAGS = {
  PDP_IMAGE_VIEW: { final: PDPTags.FAIMAGECAROUSEL, default: PDPTags.PDPIMAGEVIEW },
  PDP_NAME_CAROUSEL: { final: PDPTags.FANAMECAROUSEL, default: PDPTags.PDPNAMECAROUSEL },
  PDP_DESCRIPTION: { final: PDPTags.FADESCRIPTION, default: PDPTags.PDPDESCRIPTION },
  PDP_MODAL_CLOSE: { final: PDPTags.FAMODALCLOSE, default: PDPTags.PDPMODALCLOSE },
  PDP_EDIT: { final: PDPTags.FAEDIT, default: PDPTags.PDPEDIT },
  PDP_SETTINGS: { final: PDPTags.PDPSETTINGS, default: PDPTags.FASETTINGS },
  PDP_VIEW: { final: PLPTags.FAVIEWTYPECHANGE, default: PLPTags.PDPVIEWTYPECHANGE }
} as const;

// Generalized function to trigger PDP tags
const triggerPdpTag = (
  tagKey: keyof typeof PDP_TAGS,
  isFinalAssortment: boolean = false,
  extraPayload: Record<string, any> = {},
  customerId?: string,
  assortmentId?: string
) => {
  const tag = isFinalAssortment ? PDP_TAGS[tagKey].final : PDP_TAGS[tagKey].default;

  triggerTag(tag, {
    ...getGlobalPayload(customerId, assortmentId),
    ...extraPayload
  });
};

// Specific tag functions using the reusable helper
export const pdpImageViewTag = (
  assortmentId?: string,
  customerId?: string,
  productId?: string,
  viewType: 'pdp' | 'fullscreen' = 'pdp',
  imageType?: string,
  isFinalAssortment?: boolean,
  version?: string,
  pc9_code?: string
) =>
  triggerPdpTag(
    'PDP_IMAGE_VIEW',
    isFinalAssortment,
    { image_type: imageType, view_type: viewType, product_id: productId, version, pc9_code },
    customerId,
    assortmentId
  );

export const pdpNameCarouselTag = (
  assortmentId?: string,
  customerId?: string,
  pc9?: string,
  isFinalAssortment?: boolean
) => triggerPdpTag('PDP_NAME_CAROUSEL', isFinalAssortment, { pc9_id: pc9 }, customerId, assortmentId);

export const pdpDescriptionTag = (
  assortmentId?: string,
  customerId?: string,
  pc9?: string,
  isFinalAssortment?: boolean
) => triggerPdpTag('PDP_DESCRIPTION', isFinalAssortment, { pc9_id: pc9 }, customerId, assortmentId);

export const pdpModalClose = (assortmentId?: string, customerId?: string, pc9?: string, isFinalAssortment?: boolean) =>
  triggerPdpTag('PDP_MODAL_CLOSE', isFinalAssortment, { pc9_id: pc9 }, customerId, assortmentId);

export const pdpEdit = (assortmentId?: string, customerId?: string, isFinalAssortment?: boolean) =>
  triggerPdpTag('PDP_EDIT', isFinalAssortment, {}, customerId, assortmentId);

export const pdpSettings = (assortmentId?: string, customerId?: string, isFinalAssortment?: boolean) =>
  triggerPdpTag('PDP_SETTINGS', isFinalAssortment, {}, customerId, assortmentId);

export const pdpViewTypeChange = (
  assortmentId?: string,
  customerId?: string,
  type?: string,
  isFinalAssortment?: boolean
) => {
  triggerPdpTag('PDP_VIEW', isFinalAssortment, { type: type }, customerId, assortmentId);
};
