import { VerticalImageSlider } from 'components/ImageSlider';
import type { EntityContext } from 'domain-events';
import { useLocalisation } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { routes } from 'routes';
import styled from 'styled-components';
import { rem, themed, useRoute } from 'utils';

import { PageDetails, ProductDetailsData, UIProduct } from '@yourxx/types';

import { ProductImages, Stories } from './ProductImages';
import { ProductStats } from './ProductStats';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  gap: ${themed('spacing.l')};
`;
const ImagesCol = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const ImagesWrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;
const Thumbnails = styled.div`
  width: ${rem(50)};
  position: absolute;
  top: 0;
  left: 0;

  svg {
    height: ${rem(20)};
    width: ${rem(20)};
  }
`;
const SliderDetails = styled.div`
  width: calc(100% - ${rem(50)} - ${themed('spacing.l')});
`;

export const ProductView = ({
  context,
  product,
  details,
  isFinalAssortment
}: {
  context: EntityContext | undefined;
  product: UIProduct<ProductDetailsData>;
  details?: PageDetails;
  isFinalAssortment?: boolean;
}) => {
  const [str] = useLocalisation();
  const { productPageType } = useParams();
  const { params, route } = useRoute();
  const [activeSlide, setActiveSlide] = useState<number>(1);

  const images = useMemo(() => {
    if (!product.imageUrls?.length) return [];

    const productVideo = {
      url: product.video || '',
      imageType: str('PDP.labels.mediaContent'),
      isVideo: true
    };

    const imageList = product.imageUrls.map(image => ({
      ...image,
      imageType:
        image.imageType === 'front'
          ? str('PDP.labels.galleryReferenceOnly')
          : ['gallery16', 'gallery17'].includes(image.imageType)
            ? str('PDP.labels.galleryForFitReferenceOnly')
            : image.imageType
    }));

    return [productVideo, ...imageList];
  }, [product, str]);

  const getStoryIdFrom = useMemo(() => {
    const storiesKeyedByName =
      details?.stories?.reduce<Record<string, string>>((accum, story) => {
        accum[story.title] = story.id;
        return accum;
      }, {}) ?? {};

    return (title: string) => storiesKeyedByName[title];
  }, [details?.stories]);

  const storyRouteFor = useCallback(
    (storyId: string) => {
      let storiesRoute = '';
      const productPageType = 'stories';
      const { brandName } = params;

      if (route?.matches(routes.lineProduct)) {
        if ('lineId' in params) {
          const { lineId } = params;
          storiesRoute = routes.lineStories.with({ brandName, lineId, productPageType }).toString();
        }
      } else if (route?.matches(routes.assortmentProduct)) {
        if ('customerId' in params && 'assortmentId' in params) {
          const { customerId, assortmentId } = params;
          storiesRoute = routes.assortmentStories
            .with({ brandName, customerId, assortmentId, productPageType })
            .toString();
        }
      }

      if (!storiesRoute) return;

      return `${storiesRoute}?storyId=${storyId}`;
    },
    [params, route]
  );

  const stories = useMemo(() => {
    return (product.storyImages ?? product.story)?.map(s => {
      const story = typeof s === 'string' ? { title: s } : s;
      const storyId = getStoryIdFrom(story.title);
      const storyUrl = storyId ? storyRouteFor(storyId) : undefined;
      return { ...story, storyUrl };
    });
  }, [getStoryIdFrom, storyRouteFor, product.story, product.storyImages]);

  return (
    <Container>
      <ImagesCol>
        <ImagesWrapper>
          <Thumbnails>
            {images.length > 0 && (
              <VerticalImageSlider
                activeSlide={activeSlide}
                onSlide={index => setActiveSlide(index)}
                images={images}
                imageSize={100}
              />
            )}
          </Thumbnails>
          <ProductImages
            images={images}
            product={product}
            details={details}
            activeSlide={activeSlide}
            isFinalAssortment={isFinalAssortment}
            onSlide={index => setActiveSlide(index)}
          />
        </ImagesWrapper>
        <SliderDetails>
          {productPageType !== 'final' && stories && (
            <Stories stories={stories} navigationState={{ cameFrom: route?.toString() }} product={product} />
          )}
        </SliderDetails>
      </ImagesCol>
      <ProductStats context={context} product={product} details={details} isFinalAssortment={isFinalAssortment} />
    </Container>
  );
};
