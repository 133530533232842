import { SlotId, Toolbar } from 'pages';
import { Slot } from 'providers';
import type { ReactNode } from 'react';
import { styled } from 'styled-components';
import { themed } from 'utils';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.xxl')};
  padding: ${themed('spacing.xs')} ${themed('spacing.xl')};
  background-color: ${themed('color.white')};

  > * {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
  }
`;

const BrandLogoContainer = styled.div`
  flex-grow: 0;
  flex-basis: auto;

  > * {
    max-height: ${themed('spacing.xl')};

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const ToolbarContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const ToolbarRightContainer = ({
  children,
  'data-slot-id': dataSlotId
}: {
  children: ReactNode;
  'data-slot-id': string;
}) => {
  return (
    <ToolbarContainer data-slot-id={dataSlotId}>
      <Toolbar.Container data-slot-id={dataSlotId}>{children}</Toolbar.Container>
    </ToolbarContainer>
  );
};

export const MainNav = () => {
  return (
    <Container>
      <Slot id={SlotId.HeaderMainMenu} />
      <Slot id={SlotId.HeaderBrandLogo} Container={BrandLogoContainer} />
      <Slot id={SlotId.HeaderToolbarRight} Container={ToolbarRightContainer} Wrapper={Toolbar.ItemWrapper} />
    </Container>
  );
};
