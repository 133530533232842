import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CreateOrderFailed,
  type EventBus,
  OrderCreated,
  OrderDeleted,
  OrderDeleteFailed,
  type OrderModel,
  OrderPlaced,
  OrderPlaceFailed,
  OrdersLoaded,
  type OrdersManager,
  type OrdersService,
  OrderUpdated,
  OrderUpdateFailed,
  ToastRequested
} from 'services';
import { findOrder } from 'services/OrdersService/utils/findOrder';
import styled from 'styled-components';
import { themed, useEventSubscriptions } from 'utils';

import { type LocalisedStringFor } from '../LocalisationProvider';

export interface UseOrdersManagerProps {
  str: LocalisedStringFor;
  brand: string;
  customerId: string;
  season: string;
  getCustomerOrderIds: () => Promise<Record<string, string[]>>;
  eventBus: EventBus;
  service: OrdersService;
}

export const useOrdersManager = ({
  str,
  brand,
  season,
  customerId,
  getCustomerOrderIds,
  eventBus,
  service
}: UseOrdersManagerProps): OrdersManager => {
  const [timestamp, forceRender] = useState(new Date());

  const orders = useMemo(
    () => service.orders({ brand, customerId }).filter(order => order.details.season.toLowerCase() === season),
    [brand, customerId, season, timestamp, service]
  );

  useEventSubscriptions(
    useCallback(() => {
      const rerender = () => forceRender(new Date());
      return [
        eventBus.on(OrdersLoaded, rerender),
        eventBus.on(OrderCreated, rerender),
        eventBus.on(CreateOrderFailed, rerender),
        eventBus.on(OrderUpdated, rerender),
        eventBus.on(OrderUpdateFailed, rerender),
        eventBus.on(OrderDeleted, rerender),
        eventBus.on(OrderDeleteFailed, rerender)
      ];
    }, [eventBus])
  );

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(OrderCreated, event =>
          eventBus.emit(
            ToastRequested.success(
              <Toast>
                <span>
                  {str('Order.toast.created', {
                    order: event.payload.displayName,
                    assortment: event.payload.finalAssortmentName
                  })}
                </span>
                <Link to={`/${brand}/customers/${customerId}/${season}/orders/${event.payload.slug}`}>
                  {str('AssortmentFinalization.orderToastLink')}
                </Link>
              </Toast>
            ).trace(event)
          )
        ),

        eventBus.on(CreateOrderFailed, event =>
          eventBus.emit(
            ToastRequested.error(
              <Toast title={event.payload.reason}>
                {str('Order.toast.createFailed', {
                  order: event.payload.displayName,
                  assortment: event.payload.finalAssortmentName
                })}
              </Toast>
            ).trace(event)
          )
        ),

        eventBus.on(OrderUpdated, event =>
          eventBus.emit(
            ToastRequested.success(
              <Toast>{str('Order.toast.updated', { order: event.payload.displayName })}</Toast>
            ).trace(event)
          )
        ),

        eventBus.on(OrderUpdateFailed, event =>
          eventBus.emit(
            ToastRequested.error(
              <Toast title={event.payload.reason}>
                {str('Order.toast.updateFailed', { order: event.payload.displayName })}
              </Toast>
            ).trace(event)
          )
        ),

        eventBus.on(OrderDeleted, event =>
          eventBus.emit(
            ToastRequested.success(
              <Toast>{str('Order.toast.deleted', { order: event.payload.displayName })}</Toast>
            ).trace(event)
          )
        ),

        eventBus.on(OrderDeleteFailed, event =>
          eventBus.emit(
            ToastRequested.error(
              <Toast title={event.payload.reason}>
                {str('Order.toast.deleteFailed', { order: event.payload.displayName })}
              </Toast>
            ).trace(event)
          )
        ),

        eventBus.on(OrderPlaced, event =>
          eventBus.emit(
            ToastRequested.success(
              <Toast>{str('Order.toast.placed', { order: event.payload.displayName })}</Toast>
            ).trace(event)
          )
        ),

        eventBus.on(OrderPlaceFailed, event =>
          eventBus.emit(
            ToastRequested.error(
              <Toast title={event.payload.reason}>
                {str('Order.toast.placeFailed', { order: event.payload.displayName })}
              </Toast>
            ).trace(event)
          )
        )

        // TODO: OrderSizingFailed
      ],
      [brand, customerId, eventBus, season, str]
    )
  );

  const loadOrders = useCallback<OrdersManager['loadOrders']>(
    async () => service.loadOrders({ brand, customerId, getCustomerOrderIds }),
    [brand, customerId, getCustomerOrderIds, service]
  );

  const findOrderBy = useCallback(
    (slugOrOrderId: string): OrderModel | undefined => findOrder(slugOrOrderId, orders),
    [orders]
  );

  const createOrder = useCallback<OrdersManager['createOrder']>(
    async cmd => {
      // TODO: Merge types so we don't need a re-mapping here.
      await service.createOrder({
        brand,
        customerId,
        finalAssortmentId: cmd.finalAssortment.id,
        finalAssortmentName: cmd.finalAssortment.name,
        orderName: cmd.displayName,
        locations: cmd.locations,
        billTo: cmd.billTo,
        soldTo: cmd.soldTo,
        poNumber: cmd.poNumber
      });
    },
    [brand, customerId, service]
  );

  const editOrder = useCallback<OrdersManager['editOrder']>(async ({ order, update }) => {
    await order.update(update);
  }, []);

  const duplicateOrder = useCallback<OrdersManager['duplicateOrder']>(
    async order => {
      await service.createOrder(order.toDuplicateCommand());
    },
    [service]
  );

  const deleteOrders = useCallback<OrdersManager['deleteOrders']>(async orders => {
    // TODO: Implement bulk delete via service
    for (const order of orders) await order.delete();
  }, []);

  const placeOrders = useCallback<OrdersManager['placeOrders']>(async orders => {
    // TODO: Implement bulk place via service
    for (const order of orders) await order.place();
  }, []);

  return {
    loadOrders,
    orders,
    findOrder: findOrderBy,
    createOrder,
    editOrder,
    duplicateOrder,
    deleteOrders,
    placeOrders
  };
};

const Toast = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: ${themed('color.white')};
    margin-top: ${themed('spacing.m')};
  }
`;
