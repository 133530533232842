import { ColDef } from 'ag-grid-enterprise';
import { Newness } from 'assets/icons';
import { Image } from 'components/Image';
import { format } from 'date-fns';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatPrice, rem, themed } from 'utils';

import { formatSizedMonthRanges } from './formatSizedMonthRanges';

const NewnessIconBackground = styled.div`
  background-color: ${themed('color.red')};
  padding: ${themed('spacing.s')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${themed('spacing.s')};
`;
const Thumbnail = styled(Image)`
  &:not(#specificity-bump) {
    width: ${rem(32)};
  }
`;
const ProductNameLink = styled(Link)`
  color: ${themed('color.black')};
`;
const Numeric = styled.span`
  font-variant: tabular-nums;
`;
const PC9 = styled(Numeric)`
  font-weight: ${themed('font.weight.regular')};
`;
const Price = styled(Numeric)``;

export const useOrderProductsColDefs = ({
  currency,
  allowPC9Navigation = true
}: {
  currency: string | undefined;
  allowPC9Navigation?: boolean;
}) => {
  const [str] = useLocalisation();

  return useMemo<ColDef[]>(
    () => [
      {
        colId: 'image',
        field: 'url',
        headerName: str('Order.table.image'),
        sortable: false,
        cellRenderer: ({ data }: { data: any }) => <Thumbnail src={data.thumbnail(256)} alt={data.pc9} width={32} />,
        flex: 0,
        width: 72
      },
      {
        colId: 'pc9',
        field: 'pc9',
        headerName: str('Assortment.table.pc9'),
        filter: 'agMultiColumnFilter',
        cellRenderer: ({ value }: { value: any }) => <PC9>{value}</PC9>,
        floatingFilter: true,
        flex: 1,
        minWidth: 100
      },
      {
        colId: 'name',
        field: 'name',
        headerName: str('Assortment.table.productName'),
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        cellRenderer: ({ value, data }: { value: any; data: any }) =>
          allowPC9Navigation ? (
            <ProductNameLink title={value} to={data.pc9}>
              {value}
            </ProductNameLink>
          ) : (
            <Numeric>{value}</Numeric>
          ),
        flex: 3,
        minWidth: 300,
        cellStyle: {
          textAlign: 'left',
          justifyContent: 'flex-start'
        }
      },
      {
        colId: 'newness',
        field: 'newness',
        headerName: str('Assortment.table.newness'),
        cellRenderer: ({ value }: { value: any }) => {
          if (['co', 'c/o'].includes(value.toLowerCase())) {
            return (
              <NewnessIconBackground>
                <Newness />
              </NewnessIconBackground>
            );
          } else {
            return value;
          }
        },
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        flex: 1,
        minWidth: 100
      },
      {
        colId: 'color',
        field: 'color',
        headerName: str('Assortment.table.colour'),
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        flex: 1,
        minWidth: 100
      },
      {
        colId: 'months',
        field: 'sizedMonths',
        headerName: str('Order.table.months'),
        cellRenderer: ({ value }: { value: string[] }) => {
          if (!value) return null;
          return formatSizedMonthRanges(value);
        },
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        flex: 1,
        minWidth: 150
      },
      {
        colId: 'firstOnFloor',
        headerName: str('Order.table.fof'),
        valueGetter: ({ data }) => {
          const value = data.firstOnFloor;
          if (!value) return '';
          return format(value, 'MMM yyyy');
        },
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        flex: 1,
        minWidth: 120
      },
      {
        colId: 'units',
        field: 'totalUnits',
        headerName: str('Landing.order.table.units'),
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        flex: 1,
        minWidth: 100,
        sort: 'desc'
      },
      {
        colId: 'wsp',
        field: 'wsp',
        headerName: str('Assortment.table.wsp'),
        valueFormatter: ({ value }) => {
          if (!currency) return '';
          return formatPrice(currency, value);
        },
        cellRenderer: ({ valueFormatted }: { valueFormatted: string }) => <Price>{valueFormatted}</Price>,
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        flex: 1,
        minWidth: 120
      },
      {
        colId: 'rrp',
        field: 'rrp',
        headerName: str('Assortment.table.rrp'),
        valueFormatter: ({ value }) => {
          if (!currency) return '';
          return formatPrice(currency, value);
        },
        cellRenderer: ({ valueFormatted }: { valueFormatted: string }) => <Price>{valueFormatted}</Price>,
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        flex: 1,
        minWidth: 120
      },
      {
        colId: 'total',
        field: 'totalPrice',
        headerName: str('Order.table.total'),
        valueFormatter: ({ value }) => {
          if (!currency) return '';
          return formatPrice(currency, value);
        },
        cellRenderer: ({ valueFormatted }: { valueFormatted: string }) => <Price>{valueFormatted}</Price>,
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        flex: 1,
        minWidth: 120
      }
    ],
    [allowPC9Navigation, currency, str]
  );
};
