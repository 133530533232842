import { Copy, Paste, SpinnerAlt } from 'assets/icons';
import { Dialog } from 'components/Dialog';
import { SlotId } from 'pages/CommonLayout';
import { useLocalisation, useSlot } from 'providers';
import { useCallback, useMemo } from 'react';
import type { OrderProductModel } from 'services';
import { styled } from 'styled-components';
import { themed } from 'utils';

import { useAddOrderToolbarControl } from '../../../OrderLayout';
import type { ProductDisplayInfo } from '../../types';
import { ConfirmOverwrite, CopyTo, PasteFrom } from './modals';
import { useCopySizing } from './useCopySizing';

const PasteIconFix = styled.span`
  svg:not(#specificity-bump) * {
    stroke: none;
  }

  [data-is-active='true'] & svg:not(#specificity-bump) path {
    fill: ${themed('color.white')};
  }
`;

const noop = () => undefined;

export const CopySizingModalSetup = <P extends ProductDisplayInfo<OrderProductModel>>({
  isDisabled,
  currency,
  product,
  otherProducts,
  onCopy,
  onCheckForOverwrites
}: {
  isDisabled?: boolean;
  currency?: string;
  product: P;
  otherProducts: readonly P[];
  onCopy: (source: P, ...targets: P[]) => Promise<void>;
  onCheckForOverwrites: (products: readonly P[]) => P[];
}) => {
  const [str] = useLocalisation();
  const state = useCopySizing({ product, otherProducts, onCopy, onCheckForOverwrites });

  useAddOrderToolbarControl(
    useMemo(
      () => ({
        icon: <Copy />,
        label: str('Order.sizing.copyTo').toUpperCase(),
        hint: str('Order.sizing.copyToHint'),
        isDisabled,
        isActive: state.state === 'CopyingTo',
        onClick: state.state === 'Idle' ? state.copyTo : undefined
      }),
      [isDisabled, state, str]
    ),
    0
  );

  const canCopyTo = useCallback((product: P) => product.matchesGrid, []);
  useSlot(
    SlotId.Modals,
    state.state === 'CopyingTo' && (
      <Dialog
        title={str('Order.sizing.copyToTitle')}
        onClose={state.cancel}
        content={
          <CopyTo
            currency={currency}
            source={state.source}
            products={state.products}
            targets={state.targets}
            canCopyTo={canCopyTo}
            onSelect={state.selectTargets}
          />
        }
        cancel={{ handler: state.cancel, label: str('general.cancel') }}
        confirm={{ handler: state.confirm, label: str('general.copy'), disabled: !state.canConfirm }}
      />
    )
  );

  useSlot(
    SlotId.Modals,
    state.state === 'CopyingInProgress' && (
      <Dialog
        title={str('Order.sizing.copyToTitle')}
        onClose={noop}
        content={
          <CopyTo
            currency={currency}
            source={state.source}
            products={state.products}
            targets={state.targets}
            canCopyTo={canCopyTo}
            onSelect={noop}
          />
        }
        cancel={{ handler: noop, label: str('general.cancel'), disabled: true }}
        confirm={{ handler: noop, label: <SpinnerAlt />, disabled: true }}
      />
    )
  );

  useSlot(
    SlotId.Modals,
    state.state === 'ConfirmingOverwrite' && (
      <Dialog
        title={str('Order.sizing.copyToTitle')}
        onClose={state.cancel}
        content={<ConfirmOverwrite toBeOverwritten={state.productsToBeOverwritten} />}
        cancel={{ handler: state.cancel, label: str('general.cancel') }}
        confirm={{ handler: state.confirm, label: str('general.overwrite'), important: true }}
      />
    )
  );

  useAddOrderToolbarControl(
    useMemo(
      () => ({
        icon: (
          <PasteIconFix>
            <Paste />
          </PasteIconFix>
        ),
        label: str('Order.sizing.pasteFrom').toUpperCase(),
        hint: str('Order.sizing.pasteFromHint'),
        isDisabled,
        isActive: state.state === 'PastingFrom',
        onClick: state.state === 'Idle' ? state.pasteFrom : undefined
      }),
      [isDisabled, state, str]
    ),
    1
  );

  const canPasteFrom = useCallback((product: P) => product.matchesGrid && !!product.units, []);
  useSlot(
    SlotId.Modals,
    state.state === 'PastingFrom' && (
      <Dialog
        title={str('Order.sizing.pasteFromTitle')}
        onClose={state.cancel}
        content={
          <PasteFrom
            currency={currency}
            products={otherProducts}
            target={product}
            source={state.source}
            canPasteFrom={canPasteFrom}
            selectSource={state.selectSource}
          />
        }
        cancel={{ handler: state.cancel, label: str('general.cancel') }}
        confirm={{ handler: state.confirm, label: str('general.paste'), disabled: !state.canConfirm }}
      />
    )
  );

  useSlot(
    SlotId.Modals,
    state.state === 'PastingInProgress' && (
      <Dialog
        title={str('Order.sizing.pasteFromTitle')}
        onClose={noop}
        content={
          <PasteFrom
            currency={currency}
            products={otherProducts}
            target={product}
            source={state.source}
            canPasteFrom={canPasteFrom}
            selectSource={noop}
          />
        }
        cancel={{ handler: noop, label: str('general.cancel'), disabled: true }}
        confirm={{ handler: noop, label: <SpinnerAlt />, disabled: true }}
      />
    )
  );

  return null;
};
