import { useMemo } from 'react';
import { styled } from 'styled-components';
import { themed } from 'utils';

const MetadataList = styled.ul`
  list-style: none;
  display: flex;
  gap: ${themed('spacing.xl')};
  margin: 0;
  padding: 0;
  color: ${themed('color.grey')};
  ${themed('typography.h3')};

  li {
    display: flex;
    gap: ${themed('spacing.s')};
  }
`;

const Label = styled.span`
  font-weight: ${themed('font.weight.light')};
`;

const Value = styled.span`
  font-weight: ${themed('font.weight.semiBold')};
`;

export const OrderMetadata = ({ metadata }: { metadata: Record<string, any> }) => {
  return (
    <MetadataList>
      {useMemo(
        () =>
          Object.entries(metadata).map(([label, value]) => {
            if (typeof value === 'undefined') return null;
            return (
              <li key={label}>
                <Label>{label}</Label>
                <Value>{value}</Value>
              </li>
            );
          }),
        [metadata]
      )}
    </MetadataList>
  );
};
