import { GetContextMenuItemsParams } from 'ag-grid-enterprise';
import { AgListView } from 'components/ListView';
import { ShowAsyncErrorAndGoBack, SlotId, ViewTransition } from 'pages';
import { OrderDetails as IOrderDetails, useLocalisation, useOrders, useSlot } from 'providers';
import { Suspense, useCallback, useDeferredValue, useMemo } from 'react';
import { Await } from 'react-router-dom';
import { routes } from 'routes';
import { type OrderProductModel, PC9Sizing } from 'services';
import type { Month } from 'services/OrdersService/OrderSizingManager/types';
import { relativePath } from 'utils';

import { OrderTotals, SetupOrderToolbar } from '../components';
import { useOrderProductsColDefs } from '../hooks/useOrderProductsColDefs';
import { useOrderProductsSearch } from '../hooks/useOrderProductsSearch';
import { OrderDetailsSkeleton } from './OrderDetailsSkeleton';

export const OrderDetails = ({ orderId }: { orderId: string }) => {
  const { loadOrderDetails } = useOrders();

  // Only create a new promise when orderId changes. Prevents <Suspense /> re-render triggers.
  const promise = useMemo(() => loadOrderDetails(orderId), [loadOrderDetails, orderId]);
  const deferredPromise = useDeferredValue(promise);

  return (
    <Suspense fallback={<ViewTransition children={<OrderDetailsSkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={deferredPromise}
        children={data => <ViewTransition children={<View data={data} />} />}
      />
    </Suspense>
  );
};

const View = ({
  data: { orderId, assortmentId, currency, products, summary, locationsCount, isReadOnly }
}: {
  data: IOrderDetails;
}) => {
  const [str] = useLocalisation();
  const { manager } = useOrders();
  const columnDefs = useOrderProductsColDefs({ currency });

  const contextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): any => {
      const product = params.node?.data as OrderProductModel;
      if (!product) return [];

      return [
        {
          name: str('general.clear'),
          disabled: isReadOnly,
          action: async () => {
            await manager.clear({ orderId, locationId: '*', product });
          }
        }
      ];
    },
    [isReadOnly, manager, orderId, str]
  );

  useSlot(SlotId.OrderTotals, <OrderTotals currency={currency} summary={summary} />);
  useSlot(SlotId.FooterLeft, <>{products.length} PC9s</>);

  /**
   * We're enhancing the the product object with extra information until the API can return these rolled-up values.
   * TODO: Get totalUnits and totalPrice for each PC9 from the API response.
   */
  const gridData = useMemo(
    () =>
      products.map(product => ({
        ...product,
        get sizedMonths() {
          const sizing = manager.sizingOf({ orderId, locationId: '*', product });
          return getMonthsWithUnits(sizing);
        },
        get totalUnits() {
          return manager.unitsOf({ orderId, locationId: '*', product }) * locationsCount;
        },
        get totalPrice() {
          if (typeof product.rrp === 'undefined') return undefined;
          return manager.unitsOf({ orderId, locationId: '*', product }) * product.rrp * locationsCount;
        }
      })),
    [locationsCount, manager, orderId, products]
  );

  const exportColumns = useMemo(
    () => columnDefs.map(c => c.field as string).filter(c => c !== 'noExport'),
    [columnDefs]
  );

  const getProductURL = useCallback((pc9: string) => relativePath(`./${pc9}`), []);
  useOrderProductsSearch({ contextId: routes.order.toString(), products: gridData, getUrl: getProductURL });

  return (
    <>
      <SetupOrderToolbar orderId={orderId} assortmentId={assortmentId} />
      <AgListView
        data={gridData}
        columns={columnDefs}
        exportColumns={exportColumns}
        contextMenuItems={contextMenuItems}
      />
    </>
  );
};

const getMonthsWithUnits = (sizing: PC9Sizing) => {
  return Array.from(
    Array.from(sizing).reduce<Set<Month>>((set, [month, , , units]) => {
      // We don't want to count 0s and null
      if (!units) return set;
      return set.add(String(month));
    }, new Set())
  );
};
