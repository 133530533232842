import { Skeleton as Sk } from 'components';
import { SlotId } from 'pages';
import { useSlot } from 'providers';
import { useMemo } from 'react';

export const OrderDetailsSkeleton = () => {
  useSlot(
    SlotId.OrderTotals,
    useMemo(
      () => (
        <Sk.Vertical>
          <Sk.Spacer $size="xs" />
          <Sk.Heading $width="22%" />
          <Sk.Spacer $size="xs" />
        </Sk.Vertical>
      ),
      []
    )
  );

  useSlot(
    SlotId.CustomerToolbar,
    useMemo(() => <Sk.Heading $width="30%" />, [])
  );

  useSlot(
    SlotId.FooterLeft,
    useMemo(() => <Sk.Text $width="15%" />, [])
  );

  return (
    <Sk.Row>
      <Sk.Table rows={11} />
    </Sk.Row>
  );
};
