import { Plus } from 'assets/icons';
import { Button } from 'components/Button';
import { IAM } from 'components/IAM';
import { ToggleGroup } from 'components/ToggleGroup';
import { Tooltip } from 'components/Tooltip';
import { useLocalisation } from 'providers';
import { ViewMode } from 'providers';
import styled from 'styled-components';
import { canvasAddProductTag, canvasImageTag, canvasInfoTag, themed } from 'utils';

import { useCurrentCanvasContext } from './CurrentCanvasContext';
import { useCanvas } from './hooks';
import { MetricSelector } from './MetricSelector';
import { SharePopup } from './SharePopup';

const Container = styled.div`
  display: flex;
  position: relative;
  top: -${themed('spacing.m')};
`;
const Menu = styled.div`
  display: flex;
  gap: ${themed('spacing.m')};
  align-items: center;
  color: ${themed('color.black')};
  font-size: ${themed('font.size.s')};

  li {
    padding: 0;

    &:last-child > button {
      padding-right: 0;
    }
  }
`;
const SelectableButtons = styled(Button)<{ $isSelected?: boolean }>`
  background-color: ${({ $isSelected }) => ($isSelected ? themed('color.black') : 'transparent')};
  color: ${({ $isSelected }) => themed($isSelected ? 'color.white' : 'color.black')};
  padding: ${themed('spacing.m')};
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.regular')};

  svg {
    width: ${themed('font.size.xl')};
    height: ${themed('font.size.xl')};

    * {
      stroke: ${({ $isSelected }) => themed($isSelected ? 'color.white' : 'color.black')};
    }
  }
`;
const MetricContainer = styled.div`
  z-index: 9;

  button {
    font-weight: ${themed('font.weight.regular')};
  }
`;

export const BoardsActionButtons = ({
  showShareButton = true,
  showAddProduct = true,
  showViewModeToggle = true
}: {
  showShareButton?: boolean;
  showAddProduct?: boolean;
  showViewModeToggle?: boolean;
}) => {
  const [str] = useLocalisation();
  const { assortmentId, customerId, canvasBoardId } = useCurrentCanvasContext();
  const iamPrefix = 'products.canvas';
  const { boardItems, viewMode, setViewMode, setShowProductSelector, showProductSelector, setIsImageZoomed } =
    useCanvas();
  const toggleProductSelector = () => setShowProductSelector(prev => !prev);

  return (
    <Container>
      <Menu>
        {boardItems.length > 0 && showShareButton && (
          <IAM action={`shareBoard`} prefix={iamPrefix}>
            <Tooltip text={str('Canvas.actions.share')} position="bottom">
              <SharePopup />
            </Tooltip>
          </IAM>
        )}
        {viewMode === 'INFO' && (
          <IAM action={`viewMetrics`} prefix={iamPrefix}>
            <MetricContainer>
              <MetricSelector />
            </MetricContainer>
          </IAM>
        )}
        {showAddProduct && (
          <IAM action={`addProduct`} prefix={iamPrefix}>
            <Tooltip
              text={str('Canvas.actions.addProducts')}
              position="bottom"
              disableTooltipOnly={showProductSelector}
            >
              <SelectableButtons
                onClick={() => {
                  canvasAddProductTag({
                    assortmentId,
                    customerId,
                    comparisonId: canvasBoardId
                  });
                  toggleProductSelector?.();
                }}
                $isSelected={showProductSelector}
              >
                <Plus />
                {str('Canvas.actions.addProducts')}
              </SelectableButtons>
            </Tooltip>
          </IAM>
        )}
        {boardItems.length > 0 && showViewModeToggle && (
          <ToggleGroup
            items={[
              { label: str('Canvas.viewMode.image'), value: 'IMAGE' },
              { label: str('Canvas.viewMode.info'), value: 'INFO' }
            ]}
            value={viewMode}
            onChange={val => {
              setViewMode(prev => {
                // setViewMode is only called when prev !== val, preventing redundant calls
                if (prev !== val) {
                  setIsImageZoomed(false);
                  if (val === 'IMAGE') {
                    canvasImageTag({ assortmentId, customerId, comparisonId: canvasBoardId });
                  } else if (val === 'INFO') {
                    canvasInfoTag({ assortmentId, customerId, comparisonId: canvasBoardId });
                  }
                  return val as ViewMode;
                }
                return prev;
              });
            }}
          />
        )}
      </Menu>
    </Container>
  );
};
