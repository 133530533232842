import { ReactNode } from 'react';

import {
  AuditAssortmentProductAdd,
  AuditAssortmentProductDrop,
  AuditAssortmentProductRemove,
  AuditAssortmentProductUpdate,
  CreateAssortmentEvent,
  UpdateAssortmentEvent
} from '@yourxx/types';

import { AuditGenericCard } from '../AuditCard/AuditGenericCard';
import { AuditProductEditCard } from '../AuditCard/AuditProductEditCard';
import { AuditRemovedProductCard } from '../AuditCard/AuditRemovedProductCard';
import { AuditPanelEvents } from '../EventCards';

export const assortmentEventCard: Record<string, (event: AuditPanelEvents) => ReactNode> = {
  assortment_create: (event: AuditPanelEvents): ReactNode => {
    return AuditGenericCard(event as AuditPanelEvents<CreateAssortmentEvent>);
  },
  assortment_update: (event: AuditPanelEvents): ReactNode => {
    return AuditGenericCard(event as AuditPanelEvents<UpdateAssortmentEvent>);
  },
  assortment_product_update: (event: AuditPanelEvents): ReactNode => {
    return AuditProductEditCard('edited', event as AuditPanelEvents<AuditAssortmentProductUpdate>);
  },
  assortment_product_add: (event: AuditPanelEvents): ReactNode => {
    return AuditProductEditCard('added', event as AuditPanelEvents<AuditAssortmentProductAdd>);
  },
  assortment_product_remove: (event: AuditPanelEvents): ReactNode => {
    return AuditRemovedProductCard(event as AuditPanelEvents<AuditAssortmentProductRemove>);
  },
  assortment_product_drop: (event: AuditPanelEvents): ReactNode => {
    return AuditGenericCard(event as AuditPanelEvents<AuditAssortmentProductDrop>);
  }
};
