import { SpinnerAlt } from 'assets/icons';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { useLocalisation } from '../../LocalisationProvider';

const SavingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(10)};
  color: ${themed('color.black')};
  ${themed('typography.h3')};
`;

export const Saving = () => {
  const [str] = useLocalisation();
  return (
    <SavingContainer>
      <SpinnerAlt />
      {str('general.save.await')}
    </SavingContainer>
  );
};
