import { Copy, CopyAlt, Erase, Paste, Redo, Undo } from 'assets/icons';
import { OrderSizing as IOrderSizing, useLocalisation, useOrders, useSlot } from 'providers';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { routes } from 'routes';
import { type OrderProductModel, type OrderSizingContext, SizingMonth, useServices } from 'services';
import { fallsWithinAvailabilityWindow, parseMonthId } from 'services/OrdersService/utils';
import { rollupMonthsSizing } from 'services/OrdersService/utils/rollupMonthsSizing';
import { styled } from 'styled-components';
import { formatPrice, relativePath, rem, themed } from 'utils';

import { Hint, Toolbar } from '../../CommonLayout';
import { SlotId } from '../../SlotId';
import { useOrderProductsSearch } from '../hooks/useOrderProductsSearch';
import {
  BackToProductsListLink,
  CopySizingModalSetup,
  ProductInformation,
  ProductNavigation,
  ProductSlideshow,
  SizingMonths,
  SizingTotals
} from './components';
import type { SizingTotalsProps } from './components/SizingTotals';
import { useMultiMonthEditToggle } from './hooks/useMultiMonthEditToggle';
import { useSelectMonths } from './hooks/useSelectMonths';
import { ProductSizingGrid } from './ProductSizingGrid';
import type { MonthRollup, ProductDisplayInfo, SizingProductInformation } from './types';

const Container = styled.main`
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
  display: flex;
  gap: ${rem(40)};
  padding: 0 0 ${themed('spacing.xl')};
  clip-path: inset(0 0 0 0);
  min-height: 0;
  height: 100%;

  > aside,
  > div {
    flex-grow: 1;
  }
`;
const productSizingWidth = '65%';
const borderRadius = 'spacing.m';
const Details = styled.aside`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${themed('spacing.m')};
  width: calc(100% - ${productSizingWidth});
`;
const Sizing = styled.div`
  display: flex;
  flex-direction: column;
  width: ${productSizingWidth};
`;
const SizingGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.s')};
  flex: 1;
`;
const SelectedMonths = styled.div`
  border-top-left-radius: ${themed(borderRadius)};
  padding: ${themed('spacing.s')} ${themed('spacing.m')};
  background-color: ${themed('color.greyLight')};
  color: ${themed('color.black')};
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.black')};
  text-align: center;
  text-transform: uppercase;
`;
const SizingGridToolbar = styled.div``;
const ProductName = styled.h2`
  margin: 0;
  ${themed('typography.h1')};
  font-size: ${rem(16)}; // Outside of theme
  line-height: 1;
`;
const PC9 = styled.span`
  display: flex;
  color: ${themed('color.grey')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.regular')};
  font-variant: tabular-nums;
`;
const CopyPC9 = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${rem(2)};
  width: fit-content;
  margin-top: ${themed('spacing.s')};

  svg {
    width: ${rem(16)};
    height: ${rem(16)};
  }
`;
const PasteIconFix = styled.span`
  svg:not(#specificity-bump) > * {
    stroke: none;
  }

  [data-is-active='true'] & svg:not(#specificity-bump) > path {
    fill: ${themed('color.white')};
  }
`;

export const SizingView = ({
  pc9,
  data: { orderId, getProductContext, currency, orderTotalUnits, locationsCount, locationLabels, isReadOnly }
}: {
  pc9: string;
  data: IOrderSizing;
}) => {
  const [str] = useLocalisation();
  const { toastService } = useServices();
  const { isLoading, manager, clipboard } = useOrders();
  const { product, siblings, prev, next } = useMemo(() => getProductContext(pc9), [getProductContext, pc9]);

  const context = useMemo<OrderSizingContext>(() => ({ locationId: '*', orderId, product }), [orderId, product]);

  useSlot(
    SlotId.FooterLeft,
    !isLoading && (
      <>
        {str('Order.metrics.units')} {manager.unitsOf(context) * locationsCount} {str('general.of')} {orderTotalUnits}
      </>
    ),
    Infinity
  );

  const getProductURL = useCallback((pc9: string) => relativePath(`../${pc9}`), []);
  useOrderProductsSearch({ contextId: routes.orderSizing.toString(), products: siblings, getUrl: getProductURL });

  // FIXME: Use version to trigger cache invalidation
  const monthRollUps = rollupMonthsSizing(manager.sizingOf({ locationId: '*', orderId, product }), product.rrp ?? 0);

  const formattedMonthRollUps = useMemo(() => {
    let year = -Infinity;
    return product.allMonths.map<MonthRollup>((month, i) => {
      const parsed = parseMonthId(Number(month));

      let monthName = parsed.monthName;

      const yearHasChanged = year !== parsed.year;
      const isLast = i === product.allMonths.length - 1;
      if (yearHasChanged || isLast) {
        year = parsed.year;
        monthName += ` ${year}`;
      }

      return {
        id: month.toString(),
        monthName,
        hasUnits: !!monthRollUps[month]?.units,
        unitsCount: (monthRollUps[month]?.units ?? 0).toLocaleString(),
        isNotAvailable:
          !product.availability || !fallsWithinAvailabilityWindow(SizingMonth(Number(month)), product.availability),
        totalPrice: formatPrice(currency, monthRollUps[month]?.price ?? 0)
      };
    });
  }, [currency, monthRollUps, product.allMonths, product.availability]);

  const firstAvailableMonth = formattedMonthRollUps.find(m => !m.isNotAvailable)?.id;
  const {
    selectedMonths,
    selectMonth,
    replace: replaceSelectedMonths
  } = useSelectMonths({
    allowMultiple: useMultiMonthEditToggle({ isDisabled: isReadOnly }),
    months: !firstAvailableMonth ? [] : [firstAvailableMonth]
  });
  const selectedMonthsLabel = useMemo(
    () =>
      formattedMonthRollUps
        .filter(r => selectedMonths.includes(r.id))
        .map(r => r.monthName)
        .join(', ') || '—',
    [formattedMonthRollUps, selectedMonths]
  );

  /**
   * When navigation from one product to another, ensure the selection of months is valid.
   */
  useEffect(() => {
    const validMonths = selectedMonths.filter(
      month => !!product.availability && fallsWithinAvailabilityWindow(SizingMonth(Number(month)), product.availability)
    );
    replaceSelectedMonths(...validMonths);
  }, [product.availability, replaceSelectedMonths, selectedMonths]);

  const totals = useMemo<SizingTotalsProps>(() => {
    const rollUps = Object.values(monthRollUps);
    const unitsPerLocation = rollUps.reduce((sum, { units }) => sum + units, 0);
    const pricePerLocation = rollUps.reduce((sum, { price }) => sum + price, 0);

    return {
      perLocation: locationLabels.map(address => ({
        address,
        units: unitsPerLocation.toLocaleString(),
        price: formatPrice(currency, pricePerLocation)
      })),
      units: (unitsPerLocation * locationsCount).toLocaleString(),
      price: formatPrice(currency, pricePerLocation * locationsCount)
    };
  }, [currency, locationLabels, locationsCount, monthRollUps]);

  const productSlideshow = useMemo(() => {
    const thumbnails = product.gallery(96);
    const slideImages = product.gallery(1024);

    const imageSlides = thumbnails.map((thumbnailUrl, i) => ({
      thumbnailUrl,
      url: slideImages[i],
      isVideo: false
    }));

    const videoSlide = { thumbnailUrl: '', url: product.video ?? '', isVideo: true };

    return {
      slides: [videoSlide, ...imageSlides]
    };
  }, [product]);

  const productInformation = useMemo<SizingProductInformation>(
    () => ({
      pricing: {
        // TODO: Render only if we have permissions to see it.
        // 'customers.assortment.pdp.final.pdp.view.pricePos'
        // tier: 'TIER 2', // TODO:
        wsp: formatPrice(currency, product.wsp),
        rrp: formatPrice(currency, product.rrp)
      }
    }),
    [currency, product.rrp, product.wsp]
  );

  const productInfo = useMemo<ProductDisplayInfo<OrderProductModel>>(
    () => ({
      ...product,
      imageUrl: product.thumbnail(180),
      get units() {
        return manager.unitsOf({ ...context, product });
      },
      // Matches itself
      matchesGrid: true
    }),
    [context, manager, product]
  );

  const otherProducts = useMemo<ProductDisplayInfo<OrderProductModel>[]>(() => {
    return siblings.map(sibling => ({
      ...sibling,
      imageUrl: sibling.thumbnail(180),
      get units() {
        return manager.unitsOf({ ...context, product: sibling });
      },
      matchesGrid: sibling.sizeGrid.overlaps(product.sizeGrid)
    }));
  }, [context, manager, product.sizeGrid, siblings]);

  const onPC9Copy = useCallback(
    async <T extends ProductDisplayInfo<OrderProductModel>>(source: T, ...targets: T[]) => {
      await manager.copy({ ...context, product: source }, ...targets.map(target => ({ ...context, product: target })));
    },
    [context, manager]
  );

  const onCheckForOverwrites = useCallback(
    (products: readonly ProductDisplayInfo<OrderProductModel>[]) => {
      // When a product has at least 1 size unit set, we're considering this to be an overwrite.
      // If we need a more sophisticated approach, we can do that later (i.e. check for specific values).
      return products.filter(product => manager.unitsOf({ ...context, product }) !== 0);
    },
    [context, manager]
  );

  const [params] = useSearchParams();
  const selectedMonthIds = useMemo(() => selectedMonths.map(m => SizingMonth(Number(m))), [selectedMonths]);

  return (
    <Container>
      <BackToProductsListLink />
      <CopySizingModalSetup
        isDisabled={isReadOnly}
        currency={currency}
        product={productInfo}
        otherProducts={otherProducts}
        onCopy={onPC9Copy}
        onCheckForOverwrites={onCheckForOverwrites}
      />
      <Details>
        <ProductNavigation
          previous={{ ...prev, url: `${relativePath(`../${prev.pc9}`)}?${params}` }}
          next={{ ...next, url: `${relativePath(`../${next.pc9}`)}?${params}` }}
        >
          <ProductName>{product.name}</ProductName>
          <Hint
            of={
              <CopyPC9
                onClick={_ => {
                  navigator.clipboard
                    .writeText(product.pc9)
                    .then(() => toastService.send(str('PDP.product.pc9Copied')));
                }}
              >
                <PC9>{product.pc9}</PC9>
                <CopyAlt />
              </CopyPC9>
            }
          >
            {str('general.copy')}
          </Hint>
        </ProductNavigation>
        <ProductSlideshow {...productSlideshow} />
        <ProductInformation {...productInformation} />
      </Details>
      <Sizing>
        <SizingTotals {...totals} />
        <SizingMonths
          monthRollUps={formattedMonthRollUps}
          isSelected={id => selectedMonths.includes(id)}
          onSelect={selectMonth}
        />
        <SizingGridWrapper>
          <SelectedMonths>{selectedMonthsLabel}</SelectedMonths>
          <SizingGridToolbar>
            <Toolbar.Container>
              <Toolbar.Item
                icon={<Copy />}
                label={str('general.copy')}
                isDisabled={selectedMonthIds.length !== 1 || !clipboard.canCopy(manager.sizingOf(context))}
                onClick={() => clipboard.copy(manager.sizingOf(context), selectedMonthIds[0])}
              />
              <Toolbar.Item
                icon={
                  <PasteIconFix>
                    <Paste />
                  </PasteIconFix>
                }
                label={str('general.paste')}
                isDisabled={isReadOnly || !selectedMonths.length || !clipboard.canPaste(context)}
                onClick={() => clipboard.paste(context, ...selectedMonthIds)}
              />
              <Toolbar.Item
                icon={<Undo />}
                label={str('general.undo')}
                isDisabled={isReadOnly || !manager.canUndo(context)}
                onClick={() => manager.undo(context)}
              />
              <Toolbar.Item
                icon={<Redo />}
                label={str('general.redo')}
                isDisabled={isReadOnly || !manager.canRedo(context)}
                onClick={() => manager.redo(context)}
              />
              <Toolbar.Item
                icon={<Erase />}
                label={str('general.clear')}
                isDisabled={isReadOnly || !manager.unitsOf(context)}
                onClick={() => manager.clear(context, selectedMonthIds)}
              />
            </Toolbar.Container>
          </SizingGridToolbar>
          <ProductSizingGrid
            isReadOnly={isReadOnly}
            context={context}
            manager={manager}
            sizing={manager.sizingOf(context)}
            months={selectedMonths.map(Number)}
          />
        </SizingGridWrapper>
      </Sizing>
    </Container>
  );
};
