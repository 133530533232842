import { formatDate } from 'date-fns';
import { memoise } from 'utils';

/**
 * @param monthId e.g. 202503 for Match 2025
 */
export const parseMonthId = memoise((monthId: number) => {
  const [, y, m] = monthId.toString().match(/^(\d{4})(\d{2})$/) ?? [];
  const [year, month] = [Number(y), Number(m)];

  if (!Number.isFinite(year) || !Number.isFinite(month)) throw new RangeError(`Unable to parse month id: ${monthId}`);

  const monthName = formatDate(new Date(year, month - 1, 1), 'MMM');
  return { month, year, monthName };
});
