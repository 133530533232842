import { AddProductSlideOut } from 'components/AddProductSlideOut';
import { type EntityContext } from 'domain-events';
import { useAddProduct } from 'pages/Products';
import { useCustomersData } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  canvasAddTag,
  canvasCloseAddProductTag,
  canvasProductCheckboxSelectTag,
  canvasProductCheckboxTag,
  canvasProductCheckboxUnselectTag,
  canvasSearchTag,
  getPC9s,
  useDebouncedFn,
  useIAM
} from 'utils';

import type { BoardItem, PageDetails } from '@yourxx/types';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useAddBoardItems } from './useAddBoardItems';
import { useCanvas } from './useCanvas';

export const useAddProductCanvasUI = ({
  context,
  pageDetails,
  isOpen,
  onClose
}: {
  context: EntityContext | undefined;
  pageDetails?: PageDetails;
  isOpen: boolean;
  onClose: VoidFunction;
}) => {
  const { customerId, assortmentId, lineId, canvasBoardId } = useCurrentCanvasContext();
  const { isLoading, loadProductsFor, productsFor, loadAssortmentsFor, assortmentsFor } = useCustomersData();
  const { boardItems } = useCanvas();
  const { addBoardItems } = useAddBoardItems();
  const { canUse } = useIAM();
  const iamPrefix = 'products.canvas';

  const productsOnBoardKeyedByPC9 = useMemo(
    () =>
      boardItems.reduce<Record<string, BoardItem>>((acc, product) => {
        acc[product.pc9Code!] = product;
        return acc;
      }, {}),
    [boardItems]
  );

  const mAssortmentsFor = useCallback(
    (customerId: string) => {
      const maybeAssortments = assortmentsFor(customerId);
      if (!maybeAssortments) return null;

      const season = pageDetails?.seasonH?.toLowerCase();
      if (!season) return maybeAssortments;

      return {
        ...maybeAssortments,
        assortments: {
          [season]: maybeAssortments.assortments?.[season] ?? []
        }
      };
    },
    [assortmentsFor, pageDetails?.seasonH]
  );

  const mProductsFor = useCallback(
    (assortmentOrLineId: string, maybeTag?: 'line' | 'parentLine') => {
      const { details, products } = productsFor(assortmentOrLineId, maybeTag ?? 'assortment') ?? {};
      if (!details?.parentId) return null;

      return {
        parentLineId: details.parentId,
        products: products?.filter(product => !(product.pc9 in productsOnBoardKeyedByPC9)) ?? []
      };
    },
    [productsFor, productsOnBoardKeyedByPC9]
  );

  const [shouldLoadData, setShouldLoadData] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) setShouldLoadData(true);
  }, [isOpen]);

  const mLoadAssortmentsFor = useCallback(() => {
    if (!shouldLoadData) return;
    if (context && 'customerId' in context) loadAssortmentsFor(context.customerId);
  }, [context, loadAssortmentsFor, shouldLoadData]);

  const mLoadProductsFor = useCallback(
    (assortmentId: string, maybeTag?: 'line' | 'parentLine') => {
      if (!shouldLoadData) return;
      loadProductsFor(assortmentId, maybeTag ?? 'assortment');
    },
    [loadProductsFor, shouldLoadData]
  );

  const debouncedSearch = useDebouncedFn((term: string) => {
    canvasSearchTag({ assortmentId: lineId ?? assortmentId, customerId, comparisonId: canvasBoardId, term: term });
  }, 500);

  const addProductProps = useAddProduct({
    context,
    isLoading,
    parentLineId: pageDetails?.parentId,
    assortmentsFor: mAssortmentsFor,
    canAddFromCurrentAssortment: true,
    canAddFromLine: canUse('addFromLine', iamPrefix),
    productsFor: mProductsFor,
    loadAssortmentsFor: mLoadAssortmentsFor,
    loadProductsFor: mLoadProductsFor,
    onAdd: async ({ products }) => {
      const pc9Codes = getPC9s(products);
      canvasAddTag({
        assortmentId: lineId ?? assortmentId,
        customerId,
        comparisonId: canvasBoardId,
        pc9s: pc9Codes.join(', ')
      });
      addBoardItems(pc9Codes);
    },
    onSearchFor: debouncedSearch,
    onSelectAll: source => {
      canvasProductCheckboxSelectTag({
        assortmentId: lineId ?? assortmentId,
        customerId,
        comparisonId: canvasBoardId,
        source
      });
    },
    onClearAll: source => {
      canvasProductCheckboxUnselectTag({
        assortmentId: lineId ?? assortmentId,
        customerId,
        comparisonId: canvasBoardId,
        source
      });
    },
    onSelectProducts: (pc9s, source) => {
      canvasProductCheckboxTag({
        assortmentId: lineId ?? assortmentId,
        customerId,
        comparisonId: canvasBoardId,
        source,
        pc9s: pc9s.join(', ')
      });
    }
  });

  return (
    <AddProductSlideOut
      {...addProductProps}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        canvasCloseAddProductTag({
          assortmentId: lineId ?? assortmentId,
          customerId,
          comparisonId: canvasBoardId
        });
      }}
      topOffset={8}
    />
  );
};
