import { Package, Spend, Yarn } from 'assets/icons';
import { type OrderDetails, useLocalisation } from 'providers';
import styled from 'styled-components';
import { formatPrice, rem, themed } from 'utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.l')};

  h3 {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.semiBold')};
    color: ${themed('color.grey')};
    text-transform: uppercase;
    margin: 0;
  }
`;
const Total = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  position: relative;

  svg {
    width: ${rem(12)};
  }
  span {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.light')};
    text-transform: uppercase;
  }
  p {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.bold')};
    margin: 0;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -${themed('spacing.m')};
    width: ${rem(1)};
    height: ${rem(15)};
    background-color: ${themed('color.greyLight')};
  }
`;

export const OrderTotals = ({ currency, summary }: Pick<OrderDetails, 'currency' | 'summary'>) => {
  const [str] = useLocalisation();

  return (
    <Wrapper>
      <h3>{str('Landing.order.summary.title')}</h3>
      <Total>
        <Spend />
        <span>{str('Landing.order.summary.card.price')}</span>
        <p>{formatPrice(currency ?? 'EUR', summary.totalPrice, 2)}</p>
      </Total>
      <Total>
        <Yarn />
        <span>{str('Landing.order.summary.card.products')}</span>
        <p>{new Intl.NumberFormat(undefined).format(summary.pc9sCount)}</p>
      </Total>
      <Total>
        <Package />
        <span>{str('Landing.order.summary.card.units')}</span>
        <p>{new Intl.NumberFormat(undefined).format(summary.totalUnits)}</p>
      </Total>
    </Wrapper>
  );
};
