import { useLocalisation, useOrders } from 'providers';
import { Suspense, useDeferredValue, useMemo } from 'react';
import { Await } from 'react-router-dom';
import { relativePath } from 'utils';

import { ShowAsyncErrorAndGoBack, useAddBreadcrumb, ViewTransition } from '../../CommonLayout';
import { OrderSizingSkeleton } from './OrderSizingSkeleton';
import { SizingView } from './SizingView';

export const OrderSizing = ({ orderId, pc9 }: { orderId: string; pc9: string }) => {
  const [str] = useLocalisation();
  useAddBreadcrumb(str('Order.sizing.breadcrumbLabel'), 3, relativePath('..'));
  const { loadOrderSizing } = useOrders();

  // Only create a new promise when orderId changes. Prevents <Suspense /> re-render triggers.
  const value = useMemo(() => loadOrderSizing(orderId), [loadOrderSizing, orderId]);
  const deferredPromise = useDeferredValue(value);

  return (
    <Suspense fallback={<ViewTransition children={<OrderSizingSkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={deferredPromise}
        children={data => <ViewTransition children={<SizingView pc9={pc9} data={data} />} />}
      />
    </Suspense>
  );
};
