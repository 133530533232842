import { Arrow, Info, Refresh } from 'assets/icons';
import { DisplayedError } from 'components/DisplayedError';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { themed } from 'utils';

import { GhostButton } from './GhostButton';
import { ViewTransition } from './ViewTransition';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${themed('spacing.xl')};
  padding: ${themed('spacing.xxl')};
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.xl')};
`;

const BackButton = styled(GhostButton)`
  svg {
    // Point arrow icon to the left
    transform: rotateZ(-180deg);
  }
`;

export const ErrorBoundaryFallback = ({
  error,
  resetErrorBoundary
}: {
  error: Error;
  resetErrorBoundary: VoidFunction;
}) => {
  const [str] = useLocalisation();
  const [timeBeforeGoingBack, setTime] = useState(10);
  const isDone = useRef(false);
  const navigate = useNavigate();

  const clearErrorAndGoBack = useCallback(() => {
    resetErrorBoundary();
    navigate(-1);
  }, [navigate, resetErrorBoundary]);

  useEffect(() => {
    if (isDone.current) return;

    const timeout = setTimeout(() => {
      if (timeBeforeGoingBack > 0) {
        setTime(timeBeforeGoingBack - 1);
      } else {
        isDone.current = true;
        clearErrorAndGoBack();
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [clearErrorAndGoBack, timeBeforeGoingBack]);

  return (
    <ViewTransition>
      <Container>
        <DisplayedError>
          <Info />
          {error.message}
        </DisplayedError>
        <Bottom>
          <BackButton onClick={clearErrorAndGoBack}>
            <Arrow />
            {str('general.goBack')} ({timeBeforeGoingBack})
          </BackButton>
          <GhostButton onClick={resetErrorBoundary}>
            <Refresh />
            {str('general.retry')}
          </GhostButton>
        </Bottom>
      </Container>
    </ViewTransition>
  );
};
