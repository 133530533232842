import { PlayFill } from 'assets/icons';
import { Image } from 'components/Image';
import { VideoPlayer } from 'components/VideoPlayer';
import { AnimatePresence, motion, type Variants } from 'framer-motion';
import { useState } from 'react';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

const Container = styled.div`
  display: flex;
  gap: ${rem(12)};
`;
const Thumbnails = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  flex-grow: 0;
  margin: 0;
  padding: ${themed('spacing.m')};
  list-style: none;
`;
const Thumbnail = styled.li`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${themed('spacing.xxxl')};
  height: ${themed('spacing.xxxl')};
  background-color: ${themed('color.offWhite')};

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const ActiveThumbnailBox = styled(motion.div)`
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid ${rem(2)} ${themed('color.black')};
`;
const VideoIndicator = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: ${themed('borderRadius')};
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);

  svg {
    position: relative;
    left: ${rem(1)}; // Visual alignment

    path {
      fill: ${themed('color.white')};
    }
  }
`;
const Slide = styled(motion.div)`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  border-radius: ${themed('borderRadius')};
  background-color: ${themed('color.offWhite')};

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img {
    object-position: center top;
  }
`;

export const ProductSlideshow = ({
  slides
}: {
  slides: readonly { url: string; thumbnailUrl: string; isVideo: boolean }[];
}) => {
  const [current, setCurrent] = useState(1);
  const currentSlide = slides[current];

  return (
    <Container>
      <Thumbnails>
        {slides.map(({ thumbnailUrl, isVideo }, i) => {
          const isActive = i === current;
          return (
            <Thumbnail key={`${thumbnailUrl}-${i}`} onClick={() => setCurrent(i)}>
              <Image src={thumbnailUrl} />
              {isVideo && (
                <VideoIndicator>
                  <PlayFill />
                </VideoIndicator>
              )}
              {isActive && (
                <ActiveThumbnailBox layoutId="product-slideshow-active-thumbnail" transition={{ ease: 'easeInOut' }} />
              )}
            </Thumbnail>
          );
        })}
      </Thumbnails>
      <AnimatePresence mode="wait">
        <Slide key={current} variants={SLIDE_VARIANTS} initial="hidden" animate="visible" exit="hidden">
          {currentSlide?.isVideo ? (
            <VideoPlayer key={currentSlide?.url} src={currentSlide?.url} />
          ) : (
            <Image key={currentSlide?.url} src={currentSlide?.url} />
          )}
        </Slide>
      </AnimatePresence>
    </Container>
  );
};

const SLIDE_VARIANTS: Variants = {
  hidden: { opacity: 0, y: 12 },
  visible: { opacity: 1, y: 0 }
};
