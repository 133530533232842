import {
  CopyAlt,
  Download as DownloadIcon,
  Edit,
  Eye,
  Filter,
  Grid,
  List,
  Newness,
  Pause,
  Plus,
  Replen,
  Sort,
  Unit
} from 'assets/icons';
import {
  AssortmentModal,
  Checkbox,
  ContentWithSidePanel,
  Dialog,
  FilterSlideOut,
  IAM,
  Image,
  ListView,
  LoadingSpinner,
  Modal,
  ProductAttributesModal,
  ProductCard,
  ProductCardHeights,
  ProductDetails,
  ProductSearchSuggestion,
  ResourceLoadedEmpty,
  ResourceLoadingFailed,
  Skeleton,
  type SortDetails,
  SortDirection,
  SortPopOver,
  Toggle,
  Tooltip,
  useContextfulSearch,
  useCreateBoard
} from 'components';
import { Entity, type EntityContext, FinalAssortmentViewed, ProductsFilterSet, type SidePanelId } from 'domain-events';
import { SimpleNav, SlotId, Toolbar, useAddBreadcrumb, useAddPageToolbarControl } from 'pages';
import { FailedLoadingProducts, TrackRoute, useCustomersData, useLocalisation, useSlot } from 'providers';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GridComponents, VirtuosoGrid } from 'react-virtuoso';
import { ProductListMode, routes } from 'routes';
import {
  filterHasValue,
  FilterType,
  getStatusValueFrom,
  useHandleExportEvents,
  useProductsFilters,
  useServices
} from 'services';
import styled, { css } from 'styled-components';
import {
  canvasViewTag,
  filterIdToRenderedName,
  filterOptionToRenderedLabel,
  findById,
  formatPrice,
  getBrand,
  getPC9s,
  multiSort,
  plpAddToFaCancelTag,
  plpAddToFaTag,
  plpAssortmentAddtoAssortmentTag,
  plpAssortmentAddtoCompareTag,
  plpAssortmentAddtoStoryTag,
  plpAssortmentEditAttributeTag,
  plpAssortmentEditTag,
  plpAssortmentPublishTag,
  plpAssortmentRemoveProductTag,
  plpAssortmentSortChangeTag,
  plpSelectCopyAssortmentTag,
  relativePath,
  rem,
  themed,
  useEventSubscriptions,
  useIAM,
  useRoute,
  type ViewRoute
} from 'utils';
import {
  handleAddProductTag,
  handleAuditTrialTag,
  handleShowFilterTag,
  handleVisibilitySaveTag,
  handleVisibilityTag
} from 'utils/tags/filterTagUtils';
import { pdpViewTypeChange } from 'utils/tags/pdpTagsUtils';
import { pdpEdit, pdpModalClose, pdpNameCarouselTag } from 'utils/tags/pdpTagsUtils';

import { Row } from '@tanstack/react-table';
import { Defect, searchProducts } from '@yourxx/support';
import { PricingGroup, ProductListData, UIProduct } from '@yourxx/types';
import { getPricingGroups } from '@yourxx/ui-utils';

import { useAddProductsToStoryUI } from './AddToStory';
import { NewAFModal, RemoveAFModal } from './AssortmentFinalization';
import { useAssortmentSummaryUI } from './AssortmentSummary';
import { CancelFailedDataRequestOnHistoryBack } from './CancelFailedDataRequestOnHistoryBack';
import { CompareBoardSetup } from './CompareBoardSetup';
import { ConvertToPrebookAndSizeSetup } from './ConvertToPrebookAndSizeSetup';
import { useCopyAssortmentUI } from './CopyAssortment';
import { Download } from './Download';
import { HandleProductEvents } from './HandleProductEvents';
import {
  useAddProductUI,
  useAssortmentAuditTrailUI,
  useExports,
  useRemoveProductUI,
  useSidePanel,
  useUpdateAssortment,
  useUpdateLine
} from './hooks';
import { InsightsSetup } from './InsightsSetup';
import { LegacyStories } from './LegacyStories';
import { PricingGroupModal } from './PricingGroup';
import { productMultisortBy } from './productMultisortBy';
import { ProductsFooter } from './ProductsFooter';
import { TrackSidePanel } from './TrackSidePanel';
import { ColumnsView } from './views/ColumnsView';
import { VisibilitySettings, VisibilitySettingsModal } from './VisibilitySettings';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const ContentWrapper = styled.div`
  ${themed('flexColumn')};
`;
const NewnessIconBackground = styled.div`
  background-color: ${themed('color.red')};
  padding: ${themed('spacing.s')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${themed('spacing.s')};
`;
const ProductsGrid = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'context'
})`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${rem(180)}, 1fr));
  gap: ${themed('spacing.l')};
  padding: 0 ${themed('spacing.xxxl')};

  // TODO: Temporary layout fix until old views get migrated to new layout.

  [class^='CommonLayout__Container'] & {
    padding: 0 ${themed('spacing.xl')};
  }
  > div:last-of-type {
    margin-bottom: ${themed('spacing.xl')};
  }
`;
const PageTitle = styled.span<{ $isInverted?: boolean }>`
  ${({ $isInverted }) =>
    $isInverted
      ? css`
          position: relative;
          left: -${themed('spacing.xs')};
          border-radius: ${themed('borderRadius')};
          padding: ${themed('spacing.xs')} ${themed('spacing.s')};
          background-color: ${themed('color.black')};
          color: ${themed('color.white')};
        `
      : ''};
`;
const Label = styled.span`
  align-self: center;
  margin-left: ${themed('spacing.s')};
  color: ${themed('color.grey')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.regular')};
`;

const ProductGridComponents: GridComponents = {
  List: forwardRef(({ children, ...props }, ref) => (
    <ProductsGrid ref={ref} {...props}>
      {children}
    </ProductsGrid>
  ))
};

const ArchivedLabel = () => {
  const [str] = useLocalisation();
  return <Label>({str('general.archived')})</Label>;
};

const sortProducts = <T extends UIProduct<ProductListData>>(currentSort: SortDetails<T>, products: T[]) => {
  if (!currentSort) return [...products];

  const by = productMultisortBy(currentSort.by);
  const fallbacks = { vlp: 9999 } as Partial<T>;
  return multiSort(products, { by, fallbacks, dir: currentSort.dir ?? SortDirection.ASC });
};

enum ToolbarOrder {
  GridView,
  ListView,
  ColumnsView,
  AuditTrail,
  Download,
  AddProducts,
  Sort,
  Filters
}

export const ProductsPage = (props: {
  brand: string;
  customerId?: string;
  entityId: string;
  customersOrLines: 'customers' | 'lines';
}) => {
  const [str] = useLocalisation();
  const navigate = useNavigate();
  const { exportsService, eventBus, toastService } = useServices();
  const {
    isLoading,
    error,
    loadAssortmentsFor,
    loadProductsFor,
    productsFor,
    assortmentsFor,
    retry,
    pricingGroupId,
    setPricingGroupId,
    clearCache
  } = useCustomersData();
  const { updateAssortment } = useUpdateAssortment();
  const location = useLocation();
  const { updateLine } = useUpdateLine();
  const { canUse, canNotUse } = useIAM();
  const { createBoard } = useCreateBoard({
    customerId: props.customerId,
    assortmentId: props.customersOrLines === 'customers' ? props.entityId : undefined,
    lineId: props.customersOrLines === 'lines' ? props.entityId : undefined
  });

  const [previousProduct, setPreviousProduct] = useState<UIProduct<ProductListData> | undefined>();
  const [nextProduct, setNextProduct] = useState<UIProduct<ProductListData> | undefined>();
  const [showAssortmentModal, setAssortmentModalVisibility] = useState<boolean>(false);
  const [showProductAttributesModal, setProductAttributesModalVisibility] = useState<boolean>(false);
  const [showPricingGroupModal, setPricingGroupModalVisibility] = useState<boolean>(false);
  const [isLoadingPricingGroups, setIsLoadingPricingGroups] = useState<boolean>(true);
  const [pricingGroups, setPricingGroups] = useState<PricingGroup[]>([]);
  const [showNewAFModal, setNewAFModalVisibility] = useState<boolean>(false);
  const [showRemoveAFModal, setRemoveAFModalVisibility] = useState<boolean>(false);
  const [showPublishModal, setPublishModalVisibility] = useState<boolean>(false);
  const [updatingAssortment, setUpdatingAssortment] = useState<boolean>(false);
  const [showVisibilitySettings, setVisibilitySettingsVisibility] = useState<boolean>(false);
  const [visibilitySettings, setVisibilitySettings] = useState<VisibilitySettings>({
    parentLine: true,
    prebookDeliveries: true,
    atOnceDeliveries: true,
    forecastInfo: true
  });

  // TODO: Refactor (all the things): routes are buggy and hard to reason about.
  const { route, params } = useRoute();
  const { customerId, assortmentId, lineId, productPageType, productListMode, productModalType, canvasType } =
    useParams();
  const assortmentOrLineId = assortmentId ?? lineId;

  const {
    brandName = props.brand,
    customersOrLines = props.customersOrLines,
    customerOrLineId = props.customerId ?? props.entityId,
    customerAssortmentId = props.entityId
  } = useParams();

  const isViewingLine = customersOrLines === 'lines';
  const isViewingAssortment = customersOrLines === 'customers';
  const isViewingStories = productPageType === 'stories';
  const isViewingSummaryPage = productPageType === 'summary';
  const isViewingParentLine = productPageType === 'parent';
  const isFinalAssortmentPage = productPageType === 'final';
  const isCanvasModalRequested = productModalType === 'canvas';
  const isInsightsModalRequested = productModalType === 'insights';

  // TODO this need to go once we refactor routes
  const pc9 =
    isInsightsModalRequested && canvasType
      ? canvasType
      : !isInsightsModalRequested && !isCanvasModalRequested && productModalType;

  useEffect(() => {
    if (!route) return;

    // if (canNotUse("view")) {
    //   return navigate(`/${brandName}/customers`, {replace: true});
    // }
    //
    // if (canNotUse("header.viewParent")) {
    //   return navigate(relativePath(`../../products/${productListMode}`), {replace: true});
    // }
  }, [brandName, navigate, productListMode, route]);

  useEffect(() => {
    if (isLoading || !assortmentOrLineId) return;

    if (isViewingLine) loadProductsFor(assortmentOrLineId, 'line');
    if (isViewingParentLine) loadProductsFor(assortmentOrLineId, 'parentLine');
    if (isViewingAssortment && productPageType?.match(/(products|final|stories|summary)/i)) {
      loadProductsFor(assortmentOrLineId, 'assortment');
    }
  }, [
    assortmentOrLineId,
    isLoading,
    isViewingLine,
    isViewingParentLine,
    isViewingAssortment,
    productPageType,
    loadProductsFor
  ]);

  const assortmentOrLineProducts = useMemo(() => {
    const tag = assortmentId ? 'assortment' : lineId ? 'line' : undefined;
    if (!tag) throw new Defect('Expected products tag, but it is missing');

    if (!assortmentOrLineId) return null;
    const p = productsFor(assortmentOrLineId, tag);
    const dp = productsFor(assortmentOrLineId, 'droppedAndRemoved');

    if (!p) return null;

    return {
      ...p,
      products: (p?.products ?? []).concat(dp?.products ?? [])
    };
  }, [assortmentId, assortmentOrLineId, lineId, productsFor]);

  const isViewingGlobalLine = isViewingLine && !!assortmentOrLineProducts && !assortmentOrLineProducts.details.parentId;

  useEffect(() => {
    if (!assortmentOrLineProducts?.details) return;
    const { archivedAt } = assortmentOrLineProducts.details;

    const isArchived = Boolean(assortmentId && archivedAt);
    if (isArchived && canUse('viewArchived')) {
      return navigate(relativePath('../../../../'), { replace: true });
    }
  }, [assortmentId, assortmentOrLineProducts?.details, canUse, navigate]);

  const finalAssortments = useMemo(() => {
    if (customerId && assortmentOrLineProducts?.details.seasonH) {
      return assortmentsFor(customerId)?.assortments?.[
        assortmentOrLineProducts?.details.seasonH?.toLocaleLowerCase()
      ]?.filter(a => a.assortmentType === 'FINAL');
    }
  }, [assortmentsFor, customerId, assortmentOrLineProducts?.details.seasonH]);

  const parentLineProducts = useMemo(() => {
    if (!assortmentOrLineId) return null;
    const p = productsFor(assortmentOrLineId, 'parentLine');
    const dp = productsFor(assortmentOrLineId, 'parentLineDroppedAndRemoved');
    if (!p) return null;
    return {
      ...p,
      products: (p?.products ?? []).concat(dp?.products ?? [])
    };
  }, [assortmentOrLineId, productsFor]);

  const products = useMemo(
    () => (isViewingParentLine ? parentLineProducts?.products ?? [] : assortmentOrLineProducts?.products ?? []),
    [isViewingParentLine, parentLineProducts?.products, assortmentOrLineProducts?.products]
  );
  const assortmentOrParentLineProducts = isViewingParentLine ? parentLineProducts : assortmentOrLineProducts;

  const isFinalAssortment = useMemo(
    () =>
      // Until the data is loaded, we'll just trust the URL
      !assortmentOrParentLineProducts
        ? productPageType === 'final'
        : assortmentOrParentLineProducts?.details.assortType === 'FINAL',
    [assortmentOrParentLineProducts, productPageType]
  );

  useEffect(() => {
    if (!assortmentOrParentLineProducts?.details) return;
    const { publishedAt } = assortmentOrParentLineProducts.details;

    if (isViewingAssortment && !publishedAt && canNotUse('viewPublished', 'customers.assortment.products')) {
      return navigate(relativePath(`../../../`), { replace: true });
    }
  }, [assortmentOrParentLineProducts?.details, canNotUse, isViewingAssortment, navigate]);

  useEffect(() => {
    if (isViewingSummaryPage || isViewingStories) return;

    if (isFinalAssortmentPage && !isFinalAssortment) {
      return navigate(relativePath(`../../products/${productListMode}`), { replace: true });
    } else if (isFinalAssortment && !isFinalAssortmentPage) {
      return navigate(relativePath(`../../final/${productListMode}`), { replace: true });
    }
  }, [isFinalAssortment, isFinalAssortmentPage, isViewingStories, isViewingSummaryPage, navigate, productListMode]);

  useEffect(() => {
    if (!route) return;

    const paramsCopy = {
      ...route.params,
      canvasType: undefined,
      canvasBoardId: undefined,
      productModalType: undefined
    };

    if (route.matches(routes.assortmentCanvasBoard) || route.matches(routes.assortmentCanvasBoards)) {
      navigate(`${routes.assortmentProducts.with(paramsCopy)}`, { replace: true });
    }
    if (route.matches(routes.lineCanvasBoard) || route.matches(routes.lineCanvasBoards)) {
      navigate(`${routes.lineProducts.with(paramsCopy)}`, { replace: true });
    }
  }, [navigate, productPageType, route]);

  const {
    searchTerm,
    changeTerm,
    clear: clearSearchTerm,
    addSuggestions
  } = useContextfulSearch({
    contextId: `${assortmentId ? routes.assortmentProducts : routes.lineProducts}`
  });
  // Remove search term when unmounting as per SHOW-7066
  useEffect(() => () => clearSearchTerm(), [clearSearchTerm]);

  // TODO: Move EntityContext creation to its own constructor function, throw on missing data and return undefined on catch
  const context = useMemo<EntityContext | undefined>(() => {
    const entityName = assortmentOrParentLineProducts?.details.name;
    if (!entityName) return;

    if (isViewingLine && lineId) {
      return { entity: Entity.Line, id: lineId, name: entityName };
    }

    if (isViewingAssortment && assortmentId && customerId) {
      return {
        entity: isFinalAssortment ? Entity.FinalAssortment : Entity.Assortment,
        id: assortmentId,
        name: entityName,
        customerId: customerId
      };
    }

    return undefined;
  }, [
    assortmentId,
    assortmentOrParentLineProducts?.details.name,
    customerId,
    isFinalAssortment,
    isViewingAssortment,
    isViewingLine,
    lineId
  ]);

  const { filters, filterUtils, filterOptions, filterProductsFn } = useProductsFilters({
    products,
    finalAssortments,
    storiesOrder: assortmentOrParentLineProducts?.details?.orderedStories,
    context,
    statuses: assortmentOrParentLineProducts?.details?.statuses
  });
  // If DROP and REMOVE status filter values are left over from other assortments, fetch the data for the current assortment.
  useEffect(() => {
    if (!assortmentOrLineId) return;
    const statusFilter = findById('status')(filters.activeCombination.filters);
    if (!statusFilter || statusFilter.type === FilterType.PriceRange) return;

    if (filterHasValue(statusFilter, 'DROP', 'REMOVE')) {
      loadProductsFor(assortmentOrLineId, 'droppedAndRemoved');
      loadProductsFor(assortmentOrLineId, 'parentLineDroppedAndRemoved');
    }
  }, [assortmentOrLineId, filters.activeCombination.filters, loadProductsFor]);
  // If the user chooses DROP or REMOVE status values for the current assortment, fetch the data.
  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(ProductsFilterSet, event => {
          const filter = event.payload.filter;
          if (filter.id !== 'status' || filter.type === FilterType.PriceRange || !assortmentOrLineId) return;

          if (filterHasValue(filter, 'DROP', 'REMOVE')) {
            loadProductsFor(assortmentOrLineId, 'droppedAndRemoved', event.traceId());
            loadProductsFor(assortmentOrLineId, 'parentLineDroppedAndRemoved', event.traceId());
          }
        })
      ],
      [assortmentOrLineId, eventBus, loadProductsFor]
    )
  );
  const sidePanel = useSidePanel<SidePanelId>('filters');
  const areFiltersOpen = sidePanel.is('filters');
  const filteredProducts = useMemo(() => filterProductsFn(products), [filterProductsFn, products]);
  const defaultSort = useMemo<SortDetails<UIProduct<ProductListData>>>(
    () => ({ by: 'vlp', dir: SortDirection.ASC }),
    []
  );
  const [currentSort, setCurrentSort] = useState<SortDetails<UIProduct<ProductListData>>>(defaultSort);
  const sortDetails = useMemo(
    () =>
      productListMode === 'grid' && !isViewingSummaryPage
        ? {
            currentSort,
            onSort: (sortObject: SortDetails<UIProduct<ProductListData>>) => {
              const newSort = sortObject ?? defaultSort;
              if (newSort.by && newSort.dir) {
                plpAssortmentSortChangeTag({
                  type: newSort.by,
                  direction: newSort.dir === 1 ? 'asc' : 'desc',
                  assortmentId: assortmentOrLineId,
                  customerId
                });
              }
              return setCurrentSort(newSort);
            }
          }
        : undefined,
    [assortmentOrLineId, currentSort, customerId, defaultSort, isViewingSummaryPage, productListMode]
  );

  const sortedProducts = useMemo(() => sortProducts(currentSort, filteredProducts), [filteredProducts, currentSort]);

  const productsSearchResults = useMemo(() => {
    // We're searching over sorted products because the search results will be sorted by relevance and we want that to
    // trump the sorting order the user selected.
    return !searchTerm ? null : searchProducts(searchTerm, sortedProducts);
  }, [searchTerm, sortedProducts]);

  useEffect(() => {
    if (!productsSearchResults?.length) return;

    addSuggestions(
      productsSearchResults.slice(0, 20).map(result => {
        const firstMatch = result.matches[0];
        const firstMatchValue = firstMatch.value;
        return {
          label: firstMatchValue,
          renderedLabel: (
            <ProductSearchSuggestion
              pc9={result.item.pc9}
              desc={firstMatch.meta === 'PC9' ? result.item.name : firstMatchValue}
            />
          ),
          onSelect: () => changeTerm(firstMatchValue)
        };
      })
    );
  }, [addSuggestions, changeTerm, productsSearchResults]);

  const productsRendered = useMemo(() => {
    if (productsSearchResults) return productsSearchResults.map(({ item }) => item);
    else return sortedProducts;
  }, [productsSearchResults, sortedProducts]);

  const productCardHeight = useMemo(
    () =>
      productsRendered?.reduce((maxHeight, product) => {
        // New check, is there *any* price, this takes 0 and undefined into account which happens for WSP sometimes
        const hasPrice = Boolean(product.wsp || product.rrp);
        const productExtraInfo = Boolean(product.storeGrades || product.sellThrough || product.rateOfSale);
        const hasFinish = Boolean(product.finish);

        let height = ProductCardHeights.S; // Default to small size
        if (hasPrice && hasFinish) {
          height = productExtraInfo ? ProductCardHeights.L : ProductCardHeights.M;
        }

        return Math.max(maxHeight, height);
      }, ProductCardHeights.S) ?? ProductCardHeights.S,
    [productsRendered]
  );

  useEffect(() => {
    if (productsRendered.length && pc9) {
      const productIndex = productsRendered.findIndex(p => p.pc9 === pc9);
      setPreviousProduct(productsRendered[productIndex - 1]);
      setNextProduct(productsRendered[productIndex + 1]);
    }
  }, [productsRendered, pc9, setPreviousProduct, setNextProduct]);

  useEffect(() => {
    if (assortmentOrLineProducts?.details.season && showPricingGroupModal && !pricingGroups.length) {
      getPricingGroups({ season: assortmentOrLineProducts.details.season })
        .then(data => {
          setIsLoadingPricingGroups(false);
          setPricingGroups(data.hits.filter(pg => pg.brand === getBrand('levi').name));
        })
        .catch(error => {
          setIsLoadingPricingGroups(false);
          console.error('Error fetching pricing groups:', error);
        });
    }
  }, [assortmentOrLineProducts?.details.season, showPricingGroupModal, pricingGroups]);

  const selectedPricingGroup = useMemo(
    () => pricingGroups.find(pg => pg.id === pricingGroupId),
    [pricingGroups, pricingGroupId]
  );

  const addProductUI = useAddProductUI({
    context,
    targetAssortmentSeason: assortmentOrParentLineProducts?.details?.seasonH,
    canAddFromParentLine: !isFinalAssortment,
    parentLineId: assortmentOrParentLineProducts?.details.parentId,
    products: assortmentOrParentLineProducts?.products ?? [],
    sidePanel,
    isFinalAssortment
  });
  // TODO: Extract product selection logic from useRemoveProductUI state
  const removeProductUI = useRemoveProductUI({ context });

  const auditTrailUI = useAssortmentAuditTrailUI({ context, sidePanel });

  const addProductsToStoryUI = useAddProductsToStoryUI({
    stories: assortmentOrLineProducts?.details?.stories ?? [],
    context,
    products: removeProductUI.state.selectedProducts
  });
  const copyAssortmentUI = useCopyAssortmentUI({ context });
  const assortmentSummaryUI = useAssortmentSummaryUI({
    context,
    currency: assortmentOrLineProducts?.details.currency ?? ''
  });

  const renderedPreAppliedFilters = useMemo(() => {
    if (!filters.activeCombination.filters.length) return undefined;
    return filters.activeCombination.filters.reduce((accum, filter) => {
      // We also skip status as it is sent separately (v1 compatibility issue)
      if (!filter.value || filter.id === 'status') return accum;

      const filterName = filterIdToRenderedName(str, filter.id);
      if (filter.type === FilterType.PriceRange) {
        if (!filter.value.range) return accum;

        const formattedRange = `${filter.value.type.toUpperCase()} ${filter.value.range.map(value => formatPrice(assortmentOrParentLineProducts?.details.currency, value)).join(' – ')}`;
        return { ...accum, [filterName]: formattedRange };
      }

      const values = Array.isArray(filter.value) ? filter.value : [filter.value];
      const formattedValues = values.map(value =>
        filterOptionToRenderedLabel({ str, filterId: filter.id, optionValue: value, finalAssortments })
      );

      return { ...accum, [filterName]: formattedValues };
    }, {});
  }, [assortmentOrParentLineProducts?.details.currency, filters.activeCombination.filters, finalAssortments, str]);
  const exportedPC9s = useMemo(() => {
    if (isViewingSummaryPage)
      return getPC9s(
        // For summary exports we want ALL products regardless of active filters and only ADD + PENDING.
        assortmentOrLineProducts?.products?.filter(({ status }) => status === 'ADD' || status === 'PENDING') ?? []
      );

    const selectedPc9s = getPC9s(removeProductUI.state.selectedProducts);
    return selectedPc9s.length ? selectedPc9s : getPC9s(productsRendered);
  }, [
    assortmentOrLineProducts?.products,
    isViewingSummaryPage,
    productsRendered,
    removeProductUI.state.selectedProducts
  ]);
  const exports = useExports({
    service: exportsService,
    context,
    isForParentLine: isViewingParentLine,
    renderedPreAppliedFilters: useMemo(
      () => (isViewingSummaryPage ? {} : renderedPreAppliedFilters),
      [isViewingSummaryPage, renderedPreAppliedFilters]
    ),
    status: useMemo(
      () => (isViewingSummaryPage ? undefined : getStatusValueFrom(filters.activeCombination.filters)),
      [filters.activeCombination.filters, isViewingSummaryPage]
    ),
    pc9s: exportedPC9s,
    pricingGroupId,
    onExportReady: useCallback((resourceUrl: string) => window.open(resourceUrl, '_blank'), [])
  });
  useHandleExportEvents({ eventBus, str });

  const onPDPClose = (pc9?: string) => {
    if (!route) throw new Defect('Unknown route found when closing PDP modal.');
    // TODO these need to go once we refactor routes
    pdpModalClose(assortmentId, customerId, pc9, isFinalAssortment);
    if (route.matches(routes.assortmentCanvasBoards)) {
      if ('canvasType' in params) delete params.canvasType;
      return navigate(`${routes.assortmentInsights.with(params)}`);
    }
    if ('productModalType' in params) delete params.productModalType;
    if (route.matches(routes.lineProduct)) return navigate(`${routes.lineProducts.with(params)}`);
    else if (route.matches(routes.assortmentProduct)) return navigate(`${routes.assortmentProducts.with(params)}`);
  };

  const onPDPNav = useCallback(
    (pc9?: string) => {
      if (!pc9) return undefined;
      pdpNameCarouselTag(assortmentId, customerId, pc9, isFinalAssortment);
      if (route?.matches(routes.lineProduct)) navigate(`${route.with({ productModalType: pc9 })}`);
      else if (route?.matches(routes.assortmentProduct)) navigate(`${route.with({ productModalType: pc9 })}`);
    },
    [assortmentId, customerId, route, navigate]
  );

  const onAFCreate = useCallback(
    (assortmentId: string, navigateToAF?: boolean) => {
      if (route) {
        if (navigateToAF) {
          navigate(`${route.with({ assortmentId, productPageType: 'final' })}`);
        }
        removeProductUI.state.state === 'Idle' && removeProductUI.state.clearSelection();
        setNewAFModalVisibility(false);
      }
    },
    [route, navigate, setNewAFModalVisibility, removeProductUI]
  );

  const publishType = useMemo(
    () => str(lineId ? 'Assortment.pages.products.line' : 'Assortment.pages.products.assortment'),
    [str, lineId]
  );

  const handleAssortmentUpdate = useCallback(
    ({ publish }: { publish?: boolean }) => {
      const updateParams = {
        attributes: lineId ? { publishedAt: publish ? new Date().toISOString() : null } : { published: publish },
        handleLoading: () => setUpdatingAssortment(true),
        handleSuccess: () =>
          toastService.send(
            <span>
              {str(publish ? 'Assortment.publishing.publish.toast' : 'Assortment.publishing.unpublish.toast', {
                type: publishType,
                customerName: assortmentOrParentLineProducts?.details.customerName
              })}
            </span>
          ),
        handleError: () =>
          toastService.send(
            <span>
              {str(publish ? 'Assortment.publishing.publish.error' : 'Assortment.publishing.unpublish.error', {
                type: publishType.toLowerCase()
              })}
            </span>,
            'error'
          ),
        handleFinally: () => {
          setUpdatingAssortment(false);
          setPublishModalVisibility(false);
        }
      };
      lineId ? updateLine(updateParams) : updateAssortment(updateParams);
    },
    [
      lineId,
      updateLine,
      updateAssortment,
      toastService,
      str,
      publishType,
      assortmentOrParentLineProducts?.details.customerName
    ]
  );

  useAddBreadcrumb(
    isViewingLine
      ? assortmentOrLineProducts?.details?.seasonH ?? parentLineProducts?.details?.seasonH ?? str('general.goBack')
      : assortmentOrLineProducts?.details?.customerName ??
          parentLineProducts?.details?.customerName ??
          str('general.goBack'),
    0,
    relativePath('../../../')
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const assortmentOrLineName = assortmentOrLineProducts?.details?.name ?? parentLineProducts?.details?.name;
  const entityName = assortmentOrLineName ?? searchParams.get('tempTitle');
  useEffect(() => {
    if (!assortmentOrLineName) return;

    // Once we get the actual name from the response, we can go ahead and remove the temporary search param.
    setSearchParams(
      prev => {
        const updated = new URLSearchParams(prev);
        updated.delete('tempTitle');
        return updated;
      },
      { replace: true }
    );
  }, [assortmentOrLineName, setSearchParams]);
  useSlot(
    SlotId.PageTitle,
    useMemo(
      () => (
        <>
          {entityName ? (
            <PageTitle $isInverted={isFinalAssortment} children={entityName} />
          ) : (
            isLoading && (
              <Skeleton.Loading>
                <Skeleton.Heading $width="16rem" />
              </Skeleton.Loading>
            )
          )}
          {assortmentOrParentLineProducts?.details?.archivedAt && <ArchivedLabel />}
        </>
      ),
      [assortmentOrParentLineProducts?.details?.archivedAt, entityName, isFinalAssortment, isLoading]
    )
  );

  useAddPageToolbarControl(
    useMemo(
      () => ({
        icon: <Eye />,
        hint: str('Assortment.visibilitySettings.title'),
        onClick: () => {
          handleVisibilityTag(assortmentId, customerId, isFinalAssortment);
          setVisibilitySettingsVisibility(true);
        },
        isActive: showVisibilitySettings,
        isVisible: canUse(`header.editVisibility`)
      }),
      [canUse, showVisibilitySettings, str]
    ),
    0
  );

  useAddPageToolbarControl(
    useMemo(
      () => ({
        icon: <Edit />,
        hint: str('Assortment.Modal.edit.title'),
        onClick: () => {
          pdpEdit(assortmentId, customerId, isFinalAssortment);
          setAssortmentModalVisibility(true);
        },
        isActive: showAssortmentModal,
        isVisible: canUse(`header.editAssortment`)
      }),
      [canUse, showAssortmentModal, str]
    ),
    1
  );

  useAddPageToolbarControl(
    useMemo(
      () => ({
        icon: <CopyAlt />,
        hint: str('Assortment.Copy.Modal.title'),
        onClick: () => {
          if (copyAssortmentUI.state.state === 'Idle' && assortmentOrParentLineProducts) {
            plpSelectCopyAssortmentTag({
              assortmentName: assortmentOrParentLineProducts.details?.name,
              assortmentId,
              customerId
            });
            copyAssortmentUI.state.requestCopy();
          }
        },
        isActive: copyAssortmentUI.state.state === 'RequestedCopy',
        isDisabled: copyAssortmentUI.state.state !== 'Idle',
        isVisible: canUse(`header.copyAssortment`)
      }),
      [canUse, assortmentId, assortmentOrParentLineProducts, copyAssortmentUI.state, customerId, str]
    ),
    2
  );

  useAddPageToolbarControl(
    useMemo(
      () => ({
        icon: <Eye />,
        hint: str('AssortmentSettings.pricingGroupSelect.label'),
        label: selectedPricingGroup ? `${selectedPricingGroup.name} (${selectedPricingGroup.currency})` : undefined,
        onClick: () => setPricingGroupModalVisibility(true),
        isVisible: customersOrLines === 'lines'
      }),
      [customersOrLines, selectedPricingGroup, str]
    ),
    0
  );

  // Loading indicator for customers data (e.g. customers index, customer assortments, assortment/line products)
  useAddPageToolbarControl(
    useMemo(
      () => ({
        icon: <></>,
        isLoading: true,
        isVisible: isLoading
      }),
      [isLoading]
    ),
    Infinity
  );

  useSlot(
    SlotId.PageToolbarRight,
    <IAM action="header.publish">
      <Tooltip
        text={str(
          assortmentOrParentLineProducts?.details?.publishedAt ? 'Admin.forms.unpublish' : 'Admin.forms.publish'
        )}
        position="left"
        noPadding
        offsetX={-18}
      >
        <span
          onClick={e => {
            e.preventDefault();
            setPublishModalVisibility(true);
          }}
        >
          <Toggle align="right" checked={!!assortmentOrParentLineProducts?.details?.publishedAt} />
        </span>
      </Tooltip>
    </IAM>
  );

  useSlot(
    SlotId.ProductsMenu,
    <SimpleNav
      id="products-menu"
      customerId={customerId}
      assortmentId={assortmentId}
      isFinalAssortment={isFinalAssortment}
      onNavItemClick={() => {
        if (removeProductUI.state.state === 'Idle') removeProductUI.state.clearSelection();
      }}
      items={[
        {
          to: relativePath(`../../${isFinalAssortment ? 'final' : 'products'}/${productListMode}`),
          label: str(`ProductListing.filters.${customersOrLines === 'customers' ? 'assortment' : 'line'}`)
        },
        {
          to: relativePath(`../../parent/${productListMode}`),
          label: str('ProductListing.filters.parentLine'),
          hint: canUse('header.viewParent') ? assortmentOrParentLineProducts?.details?.parentName : undefined,
          isHidden: canNotUse('header.viewParent') || !visibilitySettings.parentLine
        },
        {
          to: relativePath(`../../stories`),
          label: str('ProductListing.filters.stories'),
          state: { cameFrom: location.pathname },
          isHidden: canNotUse('header.viewStories')
        },
        {
          to: relativePath(`../../summary/${productListMode}`),
          label: str('ProductListing.filters.summary'),
          isHidden: canNotUse('header.viewSummary')
        }
      ]}
    />
  );

  useSlot(
    SlotId.ProductsToolbar,
    <Toolbar.Item
      icon={<Grid />}
      hint={str('ProductListing.viewMode.grid')}
      isActive={productListMode === ProductListMode.Grid}
      // FIXME: These should not be rendered at all on the summary route
      isVisible={!isViewingSummaryPage}
      to={relativePath(`../${ProductListMode.Grid}`)}
      onClick={() => {
        // pdpViewTypeChange({ assortmentId: assortmentOrLineId, customerId, type: ProductListMode.Grid });
        pdpViewTypeChange(assortmentOrLineId, customerId, ProductListMode.Grid);
      }}
    />,
    ToolbarOrder.GridView
  );

  useSlot(
    SlotId.ProductsToolbar,
    <Toolbar.Item
      icon={<List />}
      hint={str('ProductListing.viewMode.list')}
      isActive={productListMode === ProductListMode.List}
      // FIXME: These should not be rendered at all on the summary route
      isVisible={!isViewingSummaryPage}
      to={relativePath(`../${ProductListMode.List}`)}
      onClick={() => {
        pdpViewTypeChange(assortmentOrLineId, customerId, ProductListMode.List);
      }}
    />,
    ToolbarOrder.ListView
  );

  useSlot(
    SlotId.ProductsToolbar,
    <Toolbar.Item
      icon={<Pause />}
      hint={str('ProductListing.viewMode.byCategory')}
      isActive={productListMode === ProductListMode.Columns}
      to={relativePath(`../${ProductListMode.Columns}`)}
      onClick={() => {
        pdpViewTypeChange(assortmentOrLineId, customerId, ProductListMode.Columns);
      }}
      isVisible={isFinalAssortmentPage}
    />,
    ToolbarOrder.ColumnsView
  );

  useSlot(
    SlotId.ProductsToolbar,
    <IAM action="header.viewAudit">
      <Toolbar.Item
        icon={<Unit />}
        hint={str('AssortmentFinalization.auditTrail.title')}
        isActive={sidePanel.is('auditTrail')}
        onClick={() => {
          handleAuditTrialTag(assortmentId, customerId, isFinalAssortment);
          sidePanel.set('auditTrail');
        }}
        isVisible={!isViewingSummaryPage}
      />
    </IAM>,
    ToolbarOrder.AuditTrail
  );

  useSlot(
    SlotId.ProductsToolbar,
    useMemo(() => {
      const props = {
        icon: <DownloadIcon />,
        hint: str('ProductListing.download.label'),
        isVisible: !isViewingSummaryPage
      };
      return (
        <Download
          isFinalAssortment={isFinalAssortment}
          isExporting={exports.isExporting}
          options={exports.options}
          trigger={triggerProps => <Toolbar.Item {...props} {...triggerProps} />}
        />
      );
    }, [exports.isExporting, exports.options, str, isViewingSummaryPage]),
    ToolbarOrder.Download
  );

  useSlot(
    SlotId.ProductsToolbar,
    <IAM action="header.addTo">
      <Toolbar.Item
        icon={<Plus />}
        hint={str('Canvas.actions.addProducts')}
        isActive={sidePanel.is('addProduct')}
        isVisible={!(isViewingParentLine || isViewingSummaryPage)}
        onClick={() => {
          // FIXME: Move this configuration away and only provide a VoidFunction callback.
          handleAddProductTag(assortmentId, customerId, isFinalAssortment, !sidePanel.is('addProduct'));
          sidePanel.set('addProduct');
        }}
      />
    </IAM>,
    ToolbarOrder.AddProducts
  );

  useSlot(
    SlotId.ProductsToolbar,
    useMemo(() => {
      const props = {
        icon: <Sort />,
        hint: str('ProductListing.sort.title')
      };
      return (
        sortDetails && (
          <SortPopOver
            currentSort={sortDetails.currentSort}
            onSort={sortDetails.onSort}
            options={[
              { key: 'vlp', label: str('ProductListing.sort.by.vlp') },
              { key: 'name', label: str('ProductListing.sort.by.name') },
              { key: 'pc9', label: str('ProductListing.sort.by.pc9') },
              { key: 'newness', label: str('ProductListing.sort.by.newness') },
              { key: 'color', label: str('ProductListing.sort.by.color') },
              { key: 'gender', label: str('ProductListing.sort.by.gender') },
              { key: 'mfpCategory', label: str('ProductListing.sort.by.category') },
              { key: 'category2', label: str('ProductListing.sort.by.fit') },
              { key: 'ranking', label: str('ProductListing.sort.by.ranking') },
              { key: 'wsp', label: str('ProductListing.sort.by.wsp') },
              { key: 'rrp', label: str('ProductListing.sort.by.rrp') },
              { key: 'lastUpdatedAt', label: str('ProductListing.sort.by.lastModified') }
            ]}
            trigger={triggerProps => <Toolbar.Item {...props} {...triggerProps} />}
          />
        )
      );
    }, [sortDetails, str]),
    ToolbarOrder.Sort
  );

  useSlot(
    SlotId.ProductsToolbar,
    <Toolbar.Item
      icon={<Filter />}
      hint={str('ProductListing.filters.title')}
      isActive={sidePanel.is('filters')}
      onClick={() => handleShowFilterTag(sidePanel, customerId, assortmentId, isFinalAssortment)}
      isVisible={!isViewingSummaryPage}
      badgeContent={(filters.filtersCount && String(filters.filtersCount)) || undefined}
    />,
    ToolbarOrder.Filters
  );

  const editProducts = useCallback(() => {
    plpAssortmentEditAttributeTag({
      pc9s: removeProductUI.state.selectedProducts.map(p => p.pc9),
      assortmentId: assortmentOrLineId,
      customerId
    });
    setProductAttributesModalVisibility(true);
  }, [assortmentOrLineId, customerId, removeProductUI.state.selectedProducts]);
  const removeProducts = useCallback(() => {
    plpAssortmentRemoveProductTag({
      pc9s: removeProductUI.state.selectedProducts.map(p => p.pc9),
      assortmentId: assortmentOrLineId,
      customerId
    });
    if (removeProductUI.state.state === 'Idle') removeProductUI.state.remove();
  }, [removeProductUI, assortmentOrLineId, customerId]);
  const addProductsToFA = useCallback(() => {
    plpAddToFaTag({
      pc9s: removeProductUI.state.selectedProducts.map(p => p.pc9),
      assortmentId: assortmentOrLineId,
      customerId
    });
    setNewAFModalVisibility(true);
  }, [removeProductUI, assortmentOrLineId, customerId, setNewAFModalVisibility]);
  const addProductsToAssortment = useCallback(() => {
    plpAssortmentAddtoAssortmentTag({
      pc9s: removeProductUI.state.selectedProducts.map(p => p.pc9),
      assortmentId: assortmentOrLineId,
      customerId
    });
    setProductAttributesModalVisibility(true);
  }, [removeProductUI, assortmentOrLineId, customerId]);
  const addProductsToComparator = useCallback(() => {
    canvasViewTag({
      assortmentId: customerAssortmentId,
      customerId: customerOrLineId,
      source: 'bottom_nav',
      pc9s: removeProductUI.state.selectedProducts.map(p => p.pc9)
    });
    plpAssortmentAddtoCompareTag({
      pc9s: removeProductUI.state.selectedProducts.map(p => p.pc9),
      assortmentId: assortmentOrLineId,
      customerId
    });
    createBoard({ pc9Codes: removeProductUI.state.selectedProducts.map(p => p.pc9), redirect: true });
  }, [
    customerAssortmentId,
    customerOrLineId,
    removeProductUI.state.selectedProducts,
    assortmentOrLineId,
    customerId,
    createBoard
  ]);
  const addProductsToStory = useCallback(() => {
    plpAssortmentAddtoStoryTag({
      pc9s: removeProductUI.state.selectedProducts.map(p => p.pc9),
      assortmentId: assortmentOrLineId,
      customerId
    });
    if (addProductsToStoryUI.state.state === 'Idle') addProductsToStoryUI.state.requestAdd();
  }, [removeProductUI, assortmentOrLineId, customerId, addProductsToStoryUI]);

  const hasNotLoadedProductsForView = isViewingParentLine
    ? !parentLineProducts?.products
    : !assortmentOrLineProducts?.products;

  const trackViewHasRendered = useCallback(
    (_route: ViewRoute<any>) => {
      if (context?.entity !== Entity.FinalAssortment) return;
      eventBus.emit(new FinalAssortmentViewed(context));
    },
    [context, eventBus]
  );

  const errorMessage = useMemo(() => {
    if (error instanceof FailedLoadingProducts) {
      return str('Assortment.getProductsError');
    }
  }, [error, str]);

  if (isLoading && hasNotLoadedProductsForView) {
    return (
      <Wrapper>
        <LoadingSpinner label={str('general.loading')} />
      </Wrapper>
    );
  }
  if (errorMessage) {
    return (
      <Wrapper>
        <CancelFailedDataRequestOnHistoryBack />
        <ResourceLoadingFailed
          error={errorMessage}
          retryLabel={str('general.retry')}
          onRetry={retry}
          cancelLabel={str('general.cancel')}
          onCancel={() => navigate(-1)}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TrackRoute route={routes.assortmentProducts.with({ productPageType: 'final' })} track={trackViewHasRendered} />
      <HandleProductEvents />
      <ContentWrapper>
        {productPageType?.match(/summary|stories/) && context ? (
          {
            stories: <LegacyStories context={context} />,
            summary: <ContentWrapper>{assortmentSummaryUI.ui}</ContentWrapper>
          }[productPageType]
        ) : (
          <ContentWithSidePanel
            hasOpenSidePanel={Boolean(sidePanel.id)}
            sidePanel={
              <>
                <TrackSidePanel context={context} sidePanelId={sidePanel.id} />
                {addProductUI}
                {<IAM action="header.viewAudit">{auditTrailUI}</IAM>}
                <FilterSlideOut
                  allowFilterCombinations={canUse('edit.filters')}
                  currency={assortmentOrParentLineProducts?.details.currency}
                  onClose={() => handleShowFilterTag(sidePanel, customerId, assortmentId, isFinalAssortment)}
                  state={filters}
                  filterOptions={filterOptions}
                  isFinalAssortment={isFinalAssortment}
                  isOpen={areFiltersOpen}
                  onFilterExpanded={filter => {
                    if (!assortmentOrLineId) return;
                    const event = filterUtils.onFilterExpanded(filter);
                    if (filter.id === 'notInFinalAssortment' && customerId) {
                      loadAssortmentsFor(customerId, event.traceId());
                    }
                  }}
                  loadingFilters={filterUtils.loadingFilters}
                />
              </>
            }
          >
            {!productsRendered?.length || !products.length ? (
              <ResourceLoadedEmpty
                message={str(!products.length ? 'Assortment.noProductsFound' : 'Assortment.noFilterResult')}
              />
            ) : (
              <>
                {productListMode === 'list' && (
                  <ListView
                    data={productsRendered}
                    columns={[
                      {
                        accessorKey: 'select',
                        header: () => (
                          <Checkbox
                            id="headerCheckbox"
                            checked={productsRendered.length === removeProductUI.state.selectedProducts.length}
                            onChange={() => {
                              if (removeProductUI.state.state !== 'Idle') return;
                              if (productsRendered.length === removeProductUI.state.selectedProducts.length)
                                removeProductUI.state.clearSelection();
                              else {
                                removeProductUI.state.changeSelection(removeProductUI.state.selectedProducts);
                                removeProductUI.state.changeSelection(productsRendered);
                              }
                            }}
                            hidden={false}
                            extraPadding
                          />
                        ),
                        cell: ({ row }: { row: Row<any> }) => (
                          <Checkbox
                            id={row.original.pc9}
                            checked={removeProductUI.state.selectedProducts.includes(row.original)}
                            onChange={() => {
                              if (removeProductUI.state.state === 'Idle')
                                removeProductUI.state.changeSelection([row.original]);
                            }}
                            hidden={false}
                            extraPadding
                          />
                        ),
                        enableSorting: false,
                        size: 40
                      },
                      {
                        accessorKey: 'url',
                        header: '',
                        cell: ({ getValue, row }) => (
                          <Image
                            src={`${getValue()}&width=256&height=256`}
                            alt={row.original.pc9}
                            onClick={() => navigate(row.original.pc9)}
                            style={{ cursor: 'pointer' }}
                          />
                        ),
                        enableSorting: false,
                        size: 70,
                        meta: { aspectRatio: 1 }
                      },
                      {
                        accessorKey: 'name',
                        header: str('Assortment.table.productName'),
                        cell: ({ getValue, row }) => (
                          <div style={{ cursor: 'pointer' }} onClick={() => navigate(row.original.pc9)}>
                            {getValue()}
                          </div>
                        ),
                        meta: { flex: 2, minSize: 100, align: 'left' }
                      },
                      {
                        accessorKey: 'pc9',
                        header: str('Assortment.table.pc9'),
                        cell: ({ renderValue }) => renderValue(),
                        size: 100
                      },
                      {
                        accessorKey: 'replen',
                        header: str('Assortment.table.replen'),
                        cell: ({ getValue }) => (getValue() === 'TRUE' ? <Replen /> : '-'),
                        size: 100
                      },
                      ...(isFinalAssortment
                        ? []
                        : [
                            {
                              accessorKey: 'newness',
                              header: str('Assortment.table.newness'),
                              cell: ({ getValue, renderValue }: { getValue: any; renderValue: any }) => {
                                const value = getValue() ? getValue() : '';
                                if (['co', 'c/o'].includes(value.toLowerCase())) {
                                  return (
                                    <NewnessIconBackground>
                                      <Newness />
                                    </NewnessIconBackground>
                                  );
                                } else {
                                  return renderValue();
                                }
                              },
                              size: 100
                            },
                            {
                              accessorKey: 'finish',
                              header: str('Assortment.table.finish'),
                              cell: ({ renderValue }: { renderValue: any }) => renderValue(),
                              meta: { flex: 1, minSize: 100 }
                            },
                            {
                              accessorKey: 'mfpCategory',
                              header: str('Assortment.table.category'),
                              cell: ({ renderValue }: { renderValue: any }) => renderValue(),
                              meta: { flex: 1, minSize: 100 }
                            },
                            {
                              accessorKey: 'color',
                              header: str('Assortment.table.colour'),
                              cell: ({ renderValue }: { renderValue: any }) => renderValue(),
                              size: 100
                            },
                            {
                              accessorKey: 'vlp',
                              header: str('Assortment.table.vlp'),
                              cell: ({ renderValue }: { renderValue: any }) => renderValue(),
                              defaultSortDir: defaultSort.dir,
                              size: 70
                            }
                          ]),
                      ...(!lineId || (lineId && pricingGroupId)
                        ? [
                            {
                              accessorKey: 'wsp',
                              header: str('Assortment.table.wsp'),
                              cell: ({ getValue }: { getValue: any }) => {
                                const value = getValue();
                                return formatPrice(
                                  assortmentOrParentLineProducts?.details.currency,
                                  value !== 0 ? value : undefined
                                );
                              },
                              size: 80
                            },
                            {
                              accessorKey: 'rrp',
                              header: str('Assortment.table.rrp'),
                              cell: ({ getValue }: { getValue: any }) => {
                                const value = getValue();
                                return formatPrice(
                                  assortmentOrParentLineProducts?.details.currency,
                                  value !== 0 ? value : undefined
                                );
                              },
                              size: 80
                            }
                          ]
                        : []),
                      ...(!lineId
                        ? [
                            {
                              accessorKey: 'rateOfSale',
                              header: str('Assortment.table.ros'),
                              cell: ({ renderValue }: { renderValue: any }) => renderValue(),
                              size: 70
                            }
                          ]
                        : [])
                    ]}
                  />
                )}
                {productListMode === 'grid' && (
                  <div>
                    <VirtuosoGrid
                      data={productsRendered}
                      components={ProductGridComponents}
                      itemContent={(_, data) => (
                        <ProductCard
                          product={data}
                          pageDetails={assortmentOrParentLineProducts?.details}
                          canSelectItems={true}
                          isSelected={Boolean(
                            removeProductUI.state.selectedProducts.find(item => item.pc9 === data.pc9)
                          )}
                          onClick={() => navigate(data.pc9)}
                          selectHidden={false}
                          onSelect={() => {
                            if (removeProductUI.state.state === 'Idle') removeProductUI.state.changeSelection([data]);
                          }}
                          height={productCardHeight}
                        />
                      )}
                    />
                  </div>
                )}
                {productListMode === 'columns' && (
                  <ColumnsView
                    isSelected={product =>
                      Boolean(removeProductUI.state.selectedProducts.find(item => item.pc9 === product.pc9))
                    }
                    onSelect={(...products) => {
                      if (removeProductUI.state.state === 'Idle') removeProductUI.state.changeSelection(products);
                    }}
                    products={productsRendered}
                  />
                )}
              </>
            )}
          </ContentWithSidePanel>
        )}
      </ContentWrapper>
      {productPageType !== 'summary' && (
        <ProductsFooter
          isLine={isViewingLine}
          isGlobalLine={isViewingGlobalLine}
          products={productsRendered ?? []}
          selectedProducts={removeProductUI.state.selectedProducts.length}
          onDeselectAll={() => {
            if (removeProductUI.state.state === 'Idle') removeProductUI.state.clearSelection();
          }}
          onEdit={editProducts}
          onRemove={removeProducts}
          onAddToFA={addProductsToFA}
          onAddToAssortment={addProductsToAssortment}
          onAddToComparator={addProductsToComparator}
          onAddToStory={addProductsToStory}
        />
      )}
      {removeProductUI.ui}
      {addProductsToStoryUI.ui}
      {copyAssortmentUI.ui}
      {showAssortmentModal && (
        <IAM action={'header.editAssortment'}>
          <AssortmentModal
            pageDetails={assortmentOrParentLineProducts?.details}
            onClose={() => setAssortmentModalVisibility(false)}
            onEdit={data => {
              setAssortmentModalVisibility(false);
              if (data) {
                plpAssortmentEditTag({ attributes: JSON.stringify(data), assortmentId, customerId });
              }
              removeProductUI.state.state === 'Idle' && removeProductUI.state.clearSelection();
              if (assortmentOrParentLineProducts?.details.id) {
                loadProductsFor(assortmentOrParentLineProducts.details.id, 'assortment');
              }
            }}
            onError={() => toastService.send(<span>{str('Assortment.edit.error')}</span>, 'error')}
          />
        </IAM>
      )}
      {showProductAttributesModal && (
        <ProductAttributesModal
          context={context}
          products={removeProductUI.state.selectedProducts}
          storeGradesOptions={assortmentOrParentLineProducts?.details.storeGrades}
          newnessOptions={assortmentOrParentLineProducts?.details.newness}
          addProduct={isViewingParentLine}
          onClose={() => setProductAttributesModalVisibility(false)}
          onSuccess={() => {
            if (removeProductUI.state.state === 'Idle') removeProductUI.state.clearSelection();
            setProductAttributesModalVisibility(false);
          }}
          iamPrefix={lineId ? 'line.product' : 'assortment.product'}
        />
      )}
      <CompareBoardSetup context={context} page={assortmentOrParentLineProducts} />
      <InsightsSetup />
      {context?.entity === Entity.FinalAssortment && <ConvertToPrebookAndSizeSetup context={context} />}
      {pc9 && (
        <Modal onClose={() => onPDPClose(pc9)}>
          <ProductDetails
            pc9={pc9}
            context={context}
            pricingGroupId={pricingGroupId}
            isFinalAssortment={isFinalAssortment}
            isParentLine={isViewingParentLine || isInsightsModalRequested}
            previousProduct={previousProduct}
            nextProduct={nextProduct}
            onPrevious={previousProduct ? () => onPDPNav(previousProduct.pc9) : undefined}
            onNext={nextProduct ? () => onPDPNav(nextProduct.pc9) : undefined}
            status={Object.keys(assortmentOrParentLineProducts?.details?.statuses ?? {})}
          />
        </Modal>
      )}
      {showNewAFModal && assortmentOrParentLineProducts && customerId && assortmentOrLineId && (
        <NewAFModal
          onClose={() => {
            plpAddToFaCancelTag({ assortmentId: assortmentOrLineId, customerId });
            setNewAFModalVisibility(false);
          }}
          onCreate={(newAssortmentId, navigateToAF) => onAFCreate(newAssortmentId, navigateToAF)}
          onGoTo={() => removeProductUI.state.state === 'Idle' && removeProductUI.state.clearSelection()}
          context={context}
          pageDetails={assortmentOrParentLineProducts.details}
          finalAssortments={finalAssortments}
          selectedProducts={removeProductUI.state.selectedProducts}
        />
      )}
      {showRemoveAFModal && (
        <RemoveAFModal
          onRemove={feedback => console.log(feedback)}
          onClose={() => setRemoveAFModalVisibility(false)}
          selectedProductsCount={removeProductUI.state.selectedProducts.length}
          pageDetails={assortmentOrParentLineProducts?.details}
        />
      )}
      {showPublishModal && (
        <Dialog
          title={str(
            assortmentOrParentLineProducts?.details.publishedAt
              ? 'Assortment.publishing.unpublish.modal.title'
              : 'Assortment.publishing.publish.modal.title',
            {
              type: publishType.toLowerCase()
            }
          )}
          onClose={() => setPublishModalVisibility(false)}
          content={
            updatingAssortment ? (
              <LoadingSpinner />
            ) : (
              <p>
                {str(
                  assortmentOrParentLineProducts?.details.publishedAt
                    ? 'Assortment.publishing.unpublish.modal.message'
                    : 'Assortment.publishing.publish.modal.message',
                  {
                    type: publishType.toLowerCase(),
                    customerName: assortmentOrParentLineProducts?.details.customerName
                  }
                )}
              </p>
            )
          }
          confirm={{
            label: str(
              assortmentOrParentLineProducts?.details.publishedAt ? 'Admin.forms.unpublish' : 'Admin.forms.publish'
            ),
            handler: () => {
              plpAssortmentPublishTag({
                published: !assortmentOrParentLineProducts?.details.publishedAt,
                assortmentId,
                customerId
              });
              handleAssortmentUpdate({ publish: !assortmentOrParentLineProducts?.details.publishedAt });
            },
            disabled: updatingAssortment
          }}
          cancel={{ label: str('general.cancel'), handler: () => setPublishModalVisibility(false) }}
        />
      )}
      {showPricingGroupModal && lineId && (
        <PricingGroupModal
          isLoading={isLoadingPricingGroups}
          pricingGroups={pricingGroups}
          pricingGroupId={pricingGroupId}
          onConfirm={pricingGroupId => {
            setPricingGroupId(pricingGroupId);
            clearCache({ id: lineId, tag: 'line' });
            setTimeout(() => loadProductsFor(lineId, 'line'), 0);
            setPricingGroupModalVisibility(false);
          }}
          onCancel={() => setPricingGroupModalVisibility(false)}
        />
      )}
      {showVisibilitySettings && (
        <VisibilitySettingsModal
          visibilitySettings={visibilitySettings}
          onConfirm={settings => {
            handleVisibilitySaveTag(customerId, assortmentId, isFinalAssortment);
            setVisibilitySettings(settings);
            setVisibilitySettingsVisibility(false);
          }}
          onCancel={() => setVisibilitySettingsVisibility(false)}
        />
      )}
    </Wrapper>
  );
};
