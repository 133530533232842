import TagManager from 'react-gtm-module';

import { CanvasTags, InsightsTags, LandingTags, PDPTags, PLPTags } from './';

export * from './canvasTags';
export * from './insightsTags';
export * from './landingTags';
export * from './pdpTags';
export * from './plpTags';

export const tagsCallback = (
  tag: CanvasTags | InsightsTags | LandingTags | PDPTags | PLPTags,
  data: Record<string, string | number | undefined> = {}
) =>
  TagManager.dataLayer({
    dataLayer: {
      event: tag,
      ...data,
      user_role: JSON.parse(localStorage.getItem('user_profile') ?? '{}').role,
      user_id: JSON.parse(localStorage.getItem('user_profile') ?? '{}')?.userId
    }
  });
