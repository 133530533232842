import { useCustomersData } from 'providers';
import { useServices } from 'services';

import { useHandleAssortmentUpdated } from './hooks';
import { useHandleAssortmentCopied } from './hooks/useHandleAssortmentCopied';
import { useHandleLineUpdated } from './hooks/useHandleLineUpdated';

export const HandleProductEvents = () => {
  const { eventBus } = useServices();
  const { clearCache, loadProductsFor, loadAssortmentsFor } = useCustomersData();
  useHandleAssortmentUpdated({ eventBus, clearCache, loadProductsFor, loadAssortmentsFor });
  useHandleAssortmentCopied({ eventBus, clearCache });
  useHandleLineUpdated({ eventBus, clearCache, loadProductsFor });
  return null;
};
