import { PlayFill } from 'assets/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const PlayButton = styled.button<{ $isVisible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 0;
  border-radius: ${themed('borderRadius')};
  padding: 0;
  width: ${rem(40)};
  height: ${rem(32)};
  background-color: ${themed('color.black')};
  transform: translate3d(-50%, -50%, 0);
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  will-change: opacity;
  transition-property: opacity;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};
  cursor: pointer;

  svg path {
    fill: ${themed('color.white')};
  }
`;

export interface VideoPlayerProps {
  src: string;
  posterSrc?: string;
  onPlay?: VoidFunction;
  onPause?: VoidFunction;
  onEnd?: VoidFunction;
}

export const VideoPlayer = ({ src, posterSrc, onPlay, onPause, onEnd }: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = useCallback(() => {
    if (videoRef.current?.paused) videoRef.current?.play();
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    const playCallback = () => {
      onPlay?.();
      setIsPlaying(true);
    };
    const pauseCallback = () => {
      onPause?.();
      setIsPlaying(false);
    };
    const endCallback = () => {
      if (video) {
        onEnd?.();
        video.currentTime = 0;
      }
    };
    video?.addEventListener('play', playCallback);
    video?.addEventListener('pause', pauseCallback);
    video?.addEventListener('ended', endCallback);

    return () => {
      video?.removeEventListener('play', playCallback);
      video?.removeEventListener('pause', pauseCallback);
      video?.removeEventListener('ended', endCallback);
    };
  }, [onEnd, onPause, onPlay]);

  return (
    <>
      <video
        ref={videoRef}
        autoPlay={false}
        controls
        muted
        color="white"
        poster={posterSrc}
        controlsList="nodownload nofullscreen"
        disablePictureInPicture
      >
        <source src={src} />
      </video>
      <PlayButton $isVisible={!isPlaying} onClick={togglePlay}>
        <PlayFill />
      </PlayButton>
    </>
  );
};
