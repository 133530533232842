import { SpinnerAlt } from 'assets/icons';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { HeaderSearch } from 'components/Header';
import { ProductMiniCard } from 'components/ProductMiniCard';
import { SlideInOut } from 'components/SlideInOut';
import { Tab, Tabs } from 'components/Tabs';
import type { UseAddProductReturn } from 'pages/Products';
import { TopLevelCategoryBreakdown } from 'pages/Products/ProductsFooter';
import { useLocalisation } from 'providers';
import { useLayoutEffect, useRef, useState } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';
import styled from 'styled-components';
import { pluralise, rem, themed } from 'utils';
import { handleSelectPc9Assortment } from 'utils/tags/filterTagUtils';

import { Defect } from '@yourxx/support';

const StyledSlideInOut = styled(SlideInOut)<{ $topOffset?: number }>`
  width: ${rem(350)};
  height: ${({ $topOffset }) => ($topOffset ? `calc(100% - ${2 * $topOffset}px)` : '100%')};
  max-height: ${({ $topOffset }) => ($topOffset ? `calc(100% - ${2 * $topOffset}px)` : '100%')};
`;
const StyledLoader = styled(SpinnerAlt)`
  margin-left: ${themed('spacing.m')};
  width: ${rem(24)};
  height: ${rem(24)};
`;
const Content = styled.div<{ $paddingBottom: number }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ $paddingBottom }) => rem(14, 18, $paddingBottom + 16)};

  .virtuoso-grid-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${rem(72)}, 1fr));
    gap: ${themed('spacing.m')};
  }
`;
const SearchBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${themed('spacing.l')};
`;
const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${themed('borderRadius')};
  min-height: ${rem(30)};
  background-color: ${themed('color.white')};

  > * {
    flex-grow: 1;
    max-width: 100%;
    max-height: 100%;
  }

  button {
    width: 100%;
    display: flex;
    flex-grow: 1;

    > div {
      flex-grow: 1;
      max-width: calc(100% - ${themed('spacing.l')});

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        max-width: 100%;
      }
    }
  }
`;
const ProductsContainer = styled.div`
  flex-grow: 1;
  height: 100%;
`;
const SelectionControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(4, 0, 6)};

  button {
    ${themed('typography.h4')};
    padding: ${rem(10.5)} ${themed('spacing.s')};
  }
`;
const Footer = styled.div`
  box-sizing: border-box;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
  width: 100%;
  padding: ${rem(9, 16, 11)};
  background-color: ${themed('color.white')};

  button {
    justify-content: center;
    width: 100%;
    padding: ${themed('spacing.m')};
    ${themed('typography.h4')};
  }
`;
const SelectionSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${themed('spacing.xs')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.regular')};
  text-transform: uppercase;
  font-variant-numeric: tabular-nums;
`;
const TopLevelCategoryBreakdownWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${themed('spacing.xs')};
  color: ${themed('color.grey')};
`;
const StyledTabs = styled(Tabs)`
  height: ${rem(25)};
  margin-bottom: ${rem(12)};

  ul {
    justify-content: center;
  }

  li {
    flex-grow: 0;
    background-color: transparent;

    @media (hover: hover) {
      &:hover {
        background-color: transparent;
      }
    }

    &::after {
      height: ${themed('spacing.xs')};
    }

    span {
      padding: 0;
      ${themed('typography.h4')};
      white-space: nowrap;
    }
  }
`;
const AddButton = styled(Button)`
  will-change: opacity;
  transition-property: opacity;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};

  svg {
    width: ${rem(15)};
    height: ${rem(15)};
  }
`;

type Assortment = { assortmentId: string; assortmentName: string; count: number };
type Product = { category0?: string; category1?: string; pc9: string; url: string; name: string };

interface AddProductSlideOutProps<P extends Product, A extends Assortment> extends UseAddProductReturn<P, A> {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  topOffset?: number;
  customerId?: string;
  isFinalAssortment?: boolean;
}

export const AddProductSlideOut = <P extends Product, A extends Assortment>({
  assortments,
  products,
  source,
  changeSource,
  onClose,
  selectedProducts,
  selectProducts,
  selectAll,
  canSelectAll,
  searchTerm,
  searchFor,
  clearAll,
  canClearAll,
  canAdd,
  add,
  isOpen,
  isLoading,
  isAdding,
  canAddFromParentLine,
  canAddFromAssortments,
  canAddFromLine,
  topOffset,
  customerId,
  isFinalAssortment
}: AddProductSlideOutProps<P, A>) => {
  const [str] = useLocalisation();
  const footerElRef = useRef<HTMLDivElement | null>(null);
  const [contentBottomPadding, setContentBottomPadding] = useState<number>(0);

  useLayoutEffect(() => {
    setContentBottomPadding(footerElRef.current?.getBoundingClientRect().height ?? 0);
  }, [products]);

  return (
    <StyledSlideInOut
      heading={
        <>
          {str('Canvas.actions.addProducts')}
          {isLoading && <StyledLoader />}
        </>
      }
      top={topOffset}
      isOpen={isOpen}
      onClose={onClose}
      $topOffset={topOffset}
    >
      <Content $paddingBottom={contentBottomPadding}>
        <div>
          <SearchBox>
            <HeaderSearch
              searchTerm={searchTerm}
              onChange={searchFor}
              placeholder="PC9"
              disableCollapsing
              disableAutoInputCapture
            />
          </SearchBox>
          <StyledTabs>
            {canAddFromLine && (
              <Tab
                value="line"
                isSelected={isLoading || source?.type === 'line'}
                onClick={() => {
                  if (source?.type !== 'line') changeSource({ type: 'line' });
                }}
              >
                {str('ProductListing.filters.line')}
              </Tab>
            )}
            {canAddFromAssortments && (
              <Tab
                value="assortment"
                isSelected={isLoading || source?.type === 'assortment'}
                onClick={() => {
                  if (source?.type !== 'assortment' && assortments.length)
                    changeSource({ type: 'assortment', item: assortments[0] });
                }}
              >
                {str('ProductListing.filters.assortment')}
              </Tab>
            )}
            {canAddFromParentLine && (
              <Tab
                value="parentLine"
                isSelected={source?.type === 'parentLine'}
                onClick={() => changeSource({ type: 'parentLine' })}
              >
                {str('ProductListing.filters.parentLine')}
              </Tab>
            )}
          </StyledTabs>
          {(!source || source.type === 'assortment') && (
            <DropdownWrapper>
              <Dropdown
                onSelect={id => {
                  const maybeItem = assortments.find(item => item.assortmentId === id);
                  if (!maybeItem) throw new Defect('Selected product source not found in props.');
                  handleSelectPc9Assortment(id, customerId, maybeItem.assortmentName, isFinalAssortment);
                  changeSource({ type: 'assortment', item: maybeItem });
                }}
                trigger={
                  source && (
                    <div title={source.item.assortmentName}>
                      <span>
                        {source.item.assortmentName} ({source.item.count})
                      </span>
                    </div>
                  )
                }
                items={assortments.map(item => ({
                  key: item.assortmentId,
                  label: (
                    <div>
                      {item.assortmentName} ({item.count})
                    </div>
                  )
                }))}
              />
            </DropdownWrapper>
          )}
          <SelectionControls>
            <Button onClick={selectAll} disabled={!canSelectAll}>
              {str('Canvas.product.selectAll')}
            </Button>
            <Button onClick={clearAll} disabled={!canClearAll}>
              {str('Canvas.product.clearAll')}
            </Button>
          </SelectionControls>
        </div>

        <ProductsContainer>
          <VirtuosoGrid
            data={products}
            itemContent={(_, product) => (
              <ProductMiniCard
                key={product.pc9}
                product={product}
                isSelected={selectedProducts.includes(product)}
                onSelect={() => selectProducts(product)}
                onClick={() => selectProducts(product)}
              />
            )}
          />
        </ProductsContainer>
        <Footer ref={footerElRef}>
          <SelectionSummary>
            {Boolean(selectedProducts.length) && (
              <>
                {selectedProducts.length} {str('general.selected')} {str('general.outOf')}{' '}
              </>
            )}
            <>
              {products.length} {pluralise(products.length, str('general.item.singular'), str('general.item.plural'))}
            </>
            <TopLevelCategoryBreakdownWrapper>
              <TopLevelCategoryBreakdown products={products} />
            </TopLevelCategoryBreakdownWrapper>
          </SelectionSummary>
          <AddButton disabled={!canAdd} onClick={add} variant="primary">
            {isAdding ? <StyledLoader /> : str('general.add')}
          </AddButton>
        </Footer>
      </Content>
    </StyledSlideInOut>
  );
};
