import type { PC9Sizing } from '../PC9Sizing';

export const rollupMonthsSizing = (sizing: PC9Sizing, unitPrice: number) => {
  const result: Record<string, { units: number; price: number }> = {};

  let prevMonth;
  for (const [month, _d1, _d2, value] of sizing) {
    if (prevMonth !== month) prevMonth = month;
    (result[month] ??= { units: 0, price: 0 }).units += value ?? 0;
    result[month].price = result[month].units * unitPrice;
  }

  return result;
};
