import { toCase } from '@yourxx/support';
import { ToCase } from '@yourxx/types';

import { auditCardTs, AuditPanelEvents } from '../EventCards';

export const AuditGenericCard = (event: AuditPanelEvents) => {
  return (
    <div>
      {auditCardTs(event)}
      <h4>
        {event.by?.initials} - {toCase[ToCase.Sentence](event.event)}
      </h4>
    </div>
  );
};
