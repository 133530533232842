import { ReactNode } from 'react';

import {
  AuditLineProductAdd,
  AuditLineProductDrop,
  AuditLineProductRemove,
  AuditLineProductUpdate,
  UpdateLineEvent
} from '@yourxx/types';

import { AuditGenericCard } from '../AuditCard/AuditGenericCard';
import { AuditProductEditCard } from '../AuditCard/AuditProductEditCard';
import { AuditPanelEvents } from '../EventCards';

export const lineEventCards: Record<string, (event: AuditPanelEvents) => ReactNode> = {
  line_update: (event: AuditPanelEvents): ReactNode => {
    return AuditGenericCard(event as AuditPanelEvents<UpdateLineEvent>);
  },
  line_product_update: (event: AuditPanelEvents): ReactNode => {
    return AuditProductEditCard('edited', event as AuditPanelEvents<AuditLineProductUpdate>);
  },
  line_product_add: (event: AuditPanelEvents): ReactNode => {
    return AuditProductEditCard('added', event as AuditPanelEvents<AuditLineProductAdd>);
  },
  line_product_remove: (event: AuditPanelEvents): ReactNode => {
    return AuditProductEditCard('removed', event as AuditPanelEvents<AuditLineProductRemove>);
  },
  line_product_drop: (event: AuditPanelEvents): ReactNode => {
    return AuditGenericCard(event as AuditPanelEvents<AuditLineProductDrop>);
  }
};
