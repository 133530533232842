import type { ReactNode } from 'react';

import { Defect } from '@yourxx/support';

import { DomainEvent } from '../EventBus';

type Payload = { content: ReactNode; type?: 'success' | 'error' };

const dismissNotificationNotAssigned = () => {
  throw new Defect('Toast notification dismiss function not assigned.');
};

export class ToastRequested extends DomainEvent<Payload> {
  protected static nextId = 0;

  public readonly id: number;
  public dismiss: VoidFunction = dismissNotificationNotAssigned;

  constructor(payload: Payload) {
    super(payload);
    this.id = ++ToastRequested.nextId;
  }

  static error(content: Payload['content']) {
    return new ToastRequested({ type: 'error', content });
  }

  static success(content: Payload['content']) {
    return new ToastRequested({ type: 'success', content });
  }
}
