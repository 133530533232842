import { FeatureFlag, ResourceLoadedEmpty } from 'components';
import { Card } from 'components/Card';
import { format } from 'date-fns';
import { useLocalisation } from 'providers';
import { forwardRef, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { GridComponents, VirtuosoGrid } from 'react-virtuoso';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { getSeasonH } from '@yourxx/support';
import { type CustomerAssortmentProps, FeatureFlags, type GetCustomerAssortmentResponse } from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

const CardsGrid = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'context'
})`
  display: grid;
  gap: ${themed('spacing.l')};
  grid-template-columns: repeat(4, 1fr);
  margin-top: ${themed('spacing.l')};
  padding: 0 ${themed('spacing.xxxl')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding: 0 ${themed('spacing.xl')};
  }

  @media (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const SeasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > div {
    height: auto !important;
    flex: 1;
  }
`;
const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  border: 0;
  border-radius: ${themed('spacing.l')};
  height: 100%;

  a {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: ${themed('spacing.m')};
    min-height: ${rem(160)};
    height: 100%;
    padding: ${themed('spacing.l')};
    color: ${themed('color.black')};
    text-decoration: none;

    div.assortment-parent-line-name {
      color: ${themed('color.grey')};
    }
  }
  h2 {
    margin: 0;
    ${themed('typography.h2')};
    line-height: ${rem(22)}; // Overriding until the Design team can provide it for all typography headings
    font-weight: ${themed('font.weight.bold')};
    text-transform: uppercase;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    ${themed('typography.h5')};

    span {
      margin-right: ${themed('spacing.l')};
      ${themed('typography.h5')};
      line-height: 1.3;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

const AssortmentCardsGridComponents: GridComponents = {
  List: forwardRef(({ children, ...props }, ref) => (
    <CardsGrid ref={ref} {...props}>
      {children}
    </CardsGrid>
  ))
};

export const AssortmentCard = ({ data }: { data: CustomerAssortmentProps }) => {
  const [str] = useLocalisation();

  const pluraliseItem = useCallback(
    (count: number) => (count === 1 ? str('general.item.singular') : str('general.item.plural')),
    [str]
  );

  const productsCount = useMemo(() => {
    const { add = 0, pending = 0 } = data;
    return add + pending;
  }, [data]);

  return (
    <StyledCard key={data.assortmentId} showPointer>
      <Link
        to={{
          pathname: `${data.assortmentId}/${data.assortmentType === 'FINAL' ? 'final' : 'products'}/grid`,
          search: new URLSearchParams({ tempTitle: data.assortmentName }).toString()
        }}
        title={data.assortmentName}
      >
        <FeatureFlag flags={[FeatureFlags.Assortment_ViewParentLineName]}>
          <div className="assortment-parent-line-name">
            <span>{data.parentName} &gt;</span>
          </div>
        </FeatureFlag>
        <h2>{data.assortmentName}</h2>
        <div>
          <span>
            {productsCount} {pluraliseItem(productsCount)}
          </span>
          {data.updatedAt && (
            <FeatureFlag flags={[FeatureFlags.Assortment_ViewLastUpdated]}>
              <span>
                {str('general.lastUpdated')}: {format(data.updatedAt, 'd MMM  yyyy, HH:MM')}
              </span>
            </FeatureFlag>
          )}
          <span>
            {data.publishedAt
              ? `${str('general.publishedAt')}: ${format(data.publishedAt, 'd MMM  yyyy, HH:MM')}`
              : str('general.notYetPublished')}
          </span>
        </div>
      </Link>
    </StyledCard>
  );
};

export const AssortmentsGrid = ({
  assortments,
  activeTab,
  season
}: {
  assortments: Required<GetCustomerAssortmentResponse>['assortments'];
  activeTab: string;
  season: string | undefined;
}) => {
  const [str] = useLocalisation();
  const canViewUnpublishedAssortments = canUse(FeatureFlags.Assortment_ViewUnpublished);

  // TODO: Convert ot dumb component: Get filtered assortments list from the parent.
  const filteredAssortments = useMemo(() => {
    if (!season) return [];

    return assortments?.[season]?.filter(a => {
      if (activeTab === 'final') {
        return !a.archivedAt && a.assortmentType === 'FINAL';
      }
      if (!canViewUnpublishedAssortments && !a.publishedAt) {
        return false;
      }
      if (activeTab === 'archived') {
        return Boolean(a.archivedAt);
      }
      if (activeTab === 'active') {
        return !a.archivedAt && !a.assortmentType;
      }
      return false;
    });
  }, [assortments, season, activeTab, canViewUnpublishedAssortments]);

  return season ? (
    <SeasonWrapper key={getSeasonH(season)}>
      {filteredAssortments.length ? (
        <VirtuosoGrid
          data={filteredAssortments}
          className="virtualGrid"
          components={AssortmentCardsGridComponents}
          itemContent={(_, data) => <AssortmentCard key={data.assortmentId} data={data} />}
        />
      ) : (
        <ResourceLoadedEmpty message={str('Landing.customers.noAssortments')} />
      )}
    </SeasonWrapper>
  ) : null;
};
