import { ReactNode } from 'react';

import {
  AuditOrderCreate,
  AuditOrderDelete,
  AuditOrderLocationCreate,
  AuditOrderLocationDelete,
  AuditOrderLocationUpdate,
  AuditOrderProductDelete,
  AuditOrderProductUpdate,
  AuditOrderUpdate
} from '@yourxx/types';

import { AuditOrderProductUpdateCard } from '../AuditCard/AuditOrderProductIUpdateCard';
import { auditCardTs, AuditPanelEvents } from '../EventCards';

export const orderEventCards: Record<string, (event: AuditPanelEvents) => ReactNode> = {
  order_create: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderCreate>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} created order</h4>
        <span>{event.displayName}</span>
      </div>
    );
  },
  order_delete: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderDelete>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} deleted order</h4>
        <span>{event.orderId}</span>
      </div>
    );
  },
  order_location_create: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderLocationCreate>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} added order location</h4>
        <span>{event.displayName}</span>
      </div>
    );
  },
  order_location_delete: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderLocationDelete>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} deleted order location</h4>
        <span>{event.orderLocationId}</span>
      </div>
    );
  },
  order_update: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderUpdate>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} updated order</h4>
      </div>
    );
  },
  order_location_update: (event: AuditPanelEvents): ReactNode => {
    event = event as AuditPanelEvents<AuditOrderLocationUpdate>;
    return (
      <div>
        {auditCardTs(event)}
        <h4>{event.by?.initials} updated order location</h4>
      </div>
    );
  },
  order_product_update: (event: AuditPanelEvents): ReactNode => {
    return AuditOrderProductUpdateCard(event as AuditPanelEvents<AuditOrderProductUpdate>);
  },
  order_product_delete: (event: AuditPanelEvents): ReactNode => {
    return AuditOrderProductUpdateCard(event as AuditPanelEvents<AuditOrderProductDelete>);
  }
};
