import { tagsCallback } from '.';

export enum PLPTags {
  PLPASSORTMENTEDITATTRIBUTE = 'plp_assortment_edit_attribute',
  PLPATTRIBUTEINHERIT = 'plp_attribute_inherit',
  PLPATTRIBUTEPHASINGSTART = 'plp_attribute_phasing_start',
  PLPATTRIBUTEPHASINGEND = 'plp_attribute_phasing_end',
  PLPATTRIBUTENEWNESS = 'plp_attribute_newness',
  PLPATTRIBUTEMANDATORY = 'plp_attribute_mandatory',
  PLPATTRIBUTEVLPORDER = 'plp_attribute_vlporder',
  PLPATTRIBUTERANKING = 'plp_attribute_ranking',
  PLPATTRIBUTESTOREGRADES = 'plp_attribute_store_grades',
  PLPATTRIBUTENOTES = 'plp_attribute_notes',
  PLPATTRIBUTESAVECHANGES = 'plp_attribute_save_changes',
  PLPASSORTMENTREMOVEPRODUCT = 'plp_assortment_remove_product',
  PLPREMOVECONFIRM = 'plp_remove_confirm',
  PLPASSORTMENTADDTOSTORY = 'plp_assortment_addto_story',
  PLPADDTOSTORYCHOOSE = 'plp_addto_story_choose',
  PLPADDTOSTORYADD = 'plp_addto_story_add',
  PLPASSORTMENTADDTOCOMPARE = 'plp_assortment_addto_compare',
  PLPASSORTMENTADDTOASSORTMENT = 'plp_assortment_addto_assortment',
  PLPASSORTMENTSORTCHANGE = 'plp_assortment_sort_change',
  PLPASSORTMENTDOWNLOADTYPE = 'plp_assortment_download_type',
  FAASSORTMENTDOWNLOADTYPE = 'FA_download',
  PLPASSORTMENTADDPRODUCT = 'plp_assortment_add_product',
  FAASSORTMENTADDPRODUCT = 'FA_assortment_add_product',
  PLPADDPRODUCTSEARCH = 'add_product_search',
  FAADDPRODUCTSEARCH = 'FA_add_product_search',
  PLPADDPRODUCTCONFIRM = 'plp_add_product_confirm',
  FAADDPRODUCTCONFIRM = 'FA_add_PC9_add',
  PLPASSORTMENTFILTERCHANGE = 'plp_assortment_filter_change',
  PLPASSORTMENTFILTERREARRANGE = 'plp_assortment_filter_rearrange',
  PLPASSORTMENTPUBLISH = 'plp_assortment_publish',
  PLPASSORTMENTEDIT = 'plp_assortment_edit',
  PLPSELECTCOPYASSORTMENT = 'plp_select_copy_assortment',
  PLPCONFIRMCOPYASSORTMENT = 'plp_confirm_copy_assortment',
  PLPADDTOFA = 'plp_addto_FA',
  PLPADDTOFAADD = 'plp_addto_FA_add',
  PLPADDTOFACREATE = 'plp_addto_FA_create',
  PLPADDTOFACANCEL = 'plp_addto_FA_cancel',
  PLPADDTOFAEXISTING = 'plp_addto_FA_existing',
  PLPADDTOFACREATENEW = 'plp_addto_FA_create_new',
  PDPVIEWTYPECHANGE = 'plp_view_type_change',
  FAVIEWTYPECHANGE = 'FA_view',
  LANDINGFILTERCLEAR = 'assortment_filter_clear',
  FALANDINGFILTERCLEAR = 'FA_landing_filter_clear_all',
  LANDINGFILTERREORDER = 'plp_filter_reorder',
  FAFILTERREORDER = 'FA_filter_reorder',
  LANDINGFILTERSAVE = 'assortment_filter_save',
  FILTERCOMBINATIONADD = 'filter_combination_add',
  FAFILTERCOMBINATIONADD = 'FA_filter_combination_add',
  FALANDINGFILTERSAVE = 'FA_landing_filter_save',
  LANDINGFILTERSAVEUSE = 'assortment_filter_save_used',
  FALANDINGFILTERSAVEUSE = 'FA_landing_filter_save_used',
  PLPAUDITTRIAL = 'assortment_audit_trial',
  FAPLPAUDITTRIAL = 'FA_assortment_audit_trial',
  PLPAUDITTRIALADDBACK = 'plp_audit_trail_addback',
  FAAUDITTRIALADDBACK = 'FA_audit_trail_addback',
  PLPVISIBILITYSETTINGS = 'assortment_visibility_setting',
  FAPLPVISIBILITYSETTINGS = 'FA_assortment_visibility_setting',
  PLPVISIBILITYSETTINGSAVE = 'assortment_visibility_setting_save',
  FAPLPVISIBILITYSETTINGSAVE = 'FA_assortment_visibility_setting_save',
  PLPTABS = 'plp_tabs',
  FAPLPTABS = 'FA_plp_tabs',
  PLPEXPANDALL = 'assortment_filter_expand',
  FAFILTEREXPANDALL = 'FA_filter_expand',
  PLPSELECTPC9ASSORTMENT = 'plp_select_PC9_assortment',
  FASELECTPC9ASSORTMENT = 'FA_select_PC9_assortment',
  SELECTALLPC9 = 'plp_select_all_pc9',
  FASELECTALLPC9 = 'FA_add_PC9_selectall',
  CLEARALLPC9 = 'plp_clear_all_pc9',
  FACLEARALLPC9 = 'FA_clear_all_pc9'
}

export const plpAssortmentEditAttributeTag = ({
  pc9s,
  assortmentId,
  customerId
}: {
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTEDITATTRIBUTE, {
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributeInheritTag = ({
  inheritedAttribute,
  pc9s,
  assortmentId,
  customerId,
  selected
}: {
  inheritedAttribute: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
  selected: boolean;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTEINHERIT, {
    inherited_attribute: inheritedAttribute,
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId,
    select: selected ? 'select' : 'unselect'
  });
};

export const plpAttributePhasingStartTag = ({
  value,
  pc9s,
  assortmentId,
  customerId
}: {
  value: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTEPHASINGSTART, {
    value,
    pc9_code: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributePhasingEndTag = ({
  value,
  pc9s,
  assortmentId,
  customerId
}: {
  value: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTEPHASINGEND, {
    value,
    pc9_code: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributeNewnessTag = ({
  value,
  pc9s,
  assortmentId,
  customerId
}: {
  value: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTENEWNESS, {
    value,
    pc9_code: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributeMandatoryTag = ({
  value,
  pc9s,
  assortmentId,
  customerId
}: {
  value: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTEMANDATORY, {
    value,
    pc9_code: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributeVlpOrderTag = ({
  value,
  pc9s,
  assortmentId,
  customerId
}: {
  value: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTEVLPORDER, {
    value,
    pc9_code: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributeRankingTag = ({
  value,
  pc9s,
  assortmentId,
  customerId
}: {
  value: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTERANKING, {
    value,
    pc9_code: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributeStoreGradesTag = ({
  storeGrades,
  pc9s,
  assortmentId,
  customerId
}: {
  storeGrades: string[];
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTESTOREGRADES, {
    store_grades: storeGrades.join(', '),
    pc9_code: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributeNotesTag = ({
  value,
  pc9s,
  assortmentId,
  customerId
}: {
  value: string;
  pc9s: string[] | string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTENOTES, {
    value,
    pc9_code: Array.isArray(pc9s) ? pc9s.join(', ') : pc9s,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAttributeSaveChangesTag = ({
  pc9s,
  assortmentId,
  customerId,
  updatedAttributes
}: {
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
  updatedAttributes?: string;
}) => {
  tagsCallback(PLPTags.PLPATTRIBUTESAVECHANGES, {
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId,
    updated_attributes: updatedAttributes
  });
};

export const plpAssortmentRemoveProductTag = ({
  pc9s,
  assortmentId,
  customerId
}: {
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTREMOVEPRODUCT, {
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpRemoveConfirmTag = ({
  pc9s,
  assortmentId,
  customerId
}: {
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPREMOVECONFIRM, {
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAssortmentAddtoStoryTag = ({
  pc9s,
  assortmentId,
  customerId
}: {
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTADDTOSTORY, {
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAddtoStoryChooseTag = ({
  storyName,
  pc9s,
  assortmentId,
  customerId
}: {
  storyName: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPADDTOSTORYCHOOSE, {
    story_name: storyName,
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAddtoStoryAddTag = ({
  storyName,
  pc9s,
  assortmentId,
  customerId
}: {
  storyName: string;
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPADDTOSTORYADD, {
    story_name: storyName,
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAssortmentAddtoCompareTag = ({
  pc9s,
  assortmentId,
  customerId
}: {
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTADDTOCOMPARE, {
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAssortmentAddtoAssortmentTag = ({
  pc9s,
  assortmentId,
  customerId
}: {
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTADDTOASSORTMENT, {
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAssortmentSortChangeTag = ({
  type,
  direction,
  assortmentId,
  customerId
}: {
  type: string;
  direction: 'desc' | 'asc';
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTSORTCHANGE, {
    sort_type: type,
    sort_direction: direction,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAssortmentFilterChangeTag = ({
  pc9,
  assortmentId,
  customerId
}: {
  pc9: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTFILTERCHANGE, {
    pc9_code: pc9,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAssortmentFilterRearrangeTag = ({
  pc9,
  assortmentId,
  customerId
}: {
  pc9: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTFILTERREARRANGE, {
    pc9_code: pc9,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAssortmentPublishTag = ({
  published,
  assortmentId,
  customerId
}: {
  published: boolean;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTPUBLISH, {
    publish: published ? 'publish' : 'unpublish',
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAssortmentEditTag = ({
  attributes,
  assortmentId,
  customerId
}: {
  attributes: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPASSORTMENTEDIT, {
    attributes,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpSelectCopyAssortmentTag = ({
  assortmentName,
  assortmentId,
  customerId
}: {
  assortmentName: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPSELECTCOPYASSORTMENT, {
    assortmen_name: assortmentName,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpConfirmCopyAssortmentTag = ({
  assortmentName,
  assortmentId,
  customerId,
  targetCustomersIds,
  settings
}: {
  assortmentName: string;
  assortmentId?: string;
  customerId?: string;
  targetCustomersIds: string[];
  settings: string[];
}) => {
  tagsCallback(PLPTags.PLPCONFIRMCOPYASSORTMENT, {
    assortmen_name: assortmentName,
    assortment_id: assortmentId,
    customer_id: customerId,
    target_customers_ids: targetCustomersIds.join(', '),
    settings: settings.join(', ')
  });
};

export const plpAddToFaTag = ({
  pc9s,
  assortmentId,
  customerId
}: {
  pc9s: string[];
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPADDTOFA, {
    pc9_codes: pc9s.join(', '),
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAddToFaAddTag = ({
  assortmentId,
  customerId,
  faName,
  faId
}: {
  assortmentId?: string;
  customerId?: string;
  faName: string;
  faId: string;
}) => {
  tagsCallback(PLPTags.PLPADDTOFAADD, {
    assortment_id: assortmentId,
    customer_id: customerId,
    fa_name: faName,
    fa_id: faId
  });
};

export const plpAddToFaCreateTag = ({
  assortmentId,
  customerId,
  faName,
  category
}: {
  assortmentId?: string;
  customerId?: string;
  faName: string;
  category?: string;
}) => {
  tagsCallback(PLPTags.PLPADDTOFACREATE, {
    assortment_id: assortmentId,
    customer_id: customerId,
    fa_name: faName,
    category
  });
};

export const plpAddToFaCancelTag = ({ assortmentId, customerId }: { assortmentId?: string; customerId?: string }) => {
  tagsCallback(PLPTags.PLPADDTOFACANCEL, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAddToFaExistingTag = ({ assortmentId, customerId }: { assortmentId?: string; customerId?: string }) => {
  tagsCallback(PLPTags.PLPADDTOFAEXISTING, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const plpAddToFaCreateNewTag = ({
  assortmentId,
  customerId
}: {
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PLPTags.PLPADDTOFACREATENEW, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};
