import { Newness, Replen } from 'assets/icons';
import { Accordion } from 'components/Accordion';
import { VerticalDivider } from 'components/Divider';
import { Dropdown, DropdownItemContentType } from 'components/Dropdown';
import { ColourGroup } from 'components/FilterSlideOut/components/ColourFilter';
import { IAM } from 'components/IAM';
import { Input } from 'components/Input';
import { PreText } from 'components/PreText';
import { Tag } from 'components/Tag';
import { Tooltip } from 'components/Tooltip';
import { format } from 'date-fns';
import type { EntityContext } from 'domain-events';
import { usePhasingOptions, usePhasingRangeState } from 'pages/Products';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import styled from 'styled-components';
import {
  formatPrice,
  pdpArrowInteractionTag,
  pdpPhasingEndTag,
  pdpPhasingStartTag,
  RANKING_OPTIONS,
  rem,
  themed,
  useIAM
} from 'utils';
import { pdpDescriptionTag } from 'utils/tags/pdpTagsUtils';

import { Defect } from '@yourxx/support';
import { PageDetails, ProductDetailsData, UIProduct, UpdateProductDetails } from '@yourxx/types';

import { SizeGroup } from './SizeGroup';

const ProductDetailContainer = styled.div`
  flex: 1;
  overflow: auto;
  font-weight: ${themed('font.weight.light')};

  > * {
    padding: ${themed('spacing.s')} 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
`;
const Heading = styled.span`
  font-size: ${themed('font.size.m')};
  font-weight: ${themed('font.weight.semiBold')};
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;
const StyledVerticalDivider = styled(VerticalDivider)`
  height: auto;
  margin: 0 ${themed('spacing.l')};
`;
const AccordionContent = styled.div`
  width: 100%;
  background-color: ${themed('color.white')};
  padding: ${themed('spacing.l')};
  display: inline-flex;
  justify-content: space-between;
  box-sizing: border-box;
`;
export const PricingSection = styled.div`
  display: grid;
  width: 100%;
  height: fit-content;
  font-size: ${themed('font.size.m')};
  font-weight: ${themed('font.weight.light')};

  div {
    display: flex;
    justify-content: space-between;
    height: fit-content;

    div {
      align-items: center;
    }
  }

  > * {
    padding: ${themed('spacing.s')} 0;
  }
`;
const PriceDisclaimer = styled.span`
  font-size: ${themed('font.size.s')};
  color: ${themed('color.grey')};
`;

export const ColourContent = styled.div`
  width: 100%;
  display: grid;

  span {
    margin-right: ${themed('spacing.m')};
  }

  > * {
    width: 100%;
    display: flex;
    align-items: flex-start;
    align-self: center;
    justify-content: space-between;
  }
`;
export const ColourSpan = styled.div<{ $colour?: string }>`
  background: ${({ $colour }) => ColourGroup[$colour as keyof typeof ColourGroup]};
  border-style: solid;
  border-color: ${themed('color.black')};
  border-width: ${({ $colour }) => ($colour?.toLocaleLowerCase() === 'white' ? rem(1) : 0)};
  border-radius: 50%;
  height: ${rem(16)};
  width: ${rem(16)};
  box-sizing: border-box;
`;
export const ColorCodeWrapper = styled.div`
  display: flex;

  * {
    text-transform: capitalize;
  }
`;
const DetailSection = styled.div`
  display: grid;
  width: 100%;
  height: fit-content;

  p {
    font-weight: ${themed('font.weight.bold')};
    margin-bottom: ${themed('spacing.s')};
    text-transform: uppercase;

    &:first-of-type {
      margin-top: 0;
    }
  }

  input {
    padding: ${themed('spacing.s')};
    margin: -${themed('spacing.s')} 0 -${themed('spacing.s')} -${themed('spacing.s')};
    max-width: ${rem(80)};
    font-weight: ${themed('font.weight.light')};
  }
`;
const AccordionTextContent = styled.div`
  display: block;
  width: 100%;

  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${themed('spacing.l')};
  }

  h4 {
    padding-top: 0;
  }
`;
const PricePositionLabel = styled.span`
  font-size: ${themed('font.size.s')};
  color: ${themed('color.white')};
  background-color: ${themed('color.grey')};
  border-radius: ${themed('spacing.s')};
  padding: 0 ${themed('spacing.s')};
  margin-left: ${themed('spacing.l')};
`;
const IncreasedLineHeight = styled.div`
  line-height: 1.4;
`;

export const ProductStats = ({
  product,
  details,
  context,
  isFinalAssortment
}: {
  context: EntityContext | undefined;
  product: UIProduct<ProductDetailsData>;
  details?: PageDetails;
  isFinalAssortment?: boolean;
}) => {
  const [str] = useLocalisation();
  const { productsService, toastService } = useServices();
  const { productPageType, customerId, assortmentId: customerAssortmentId, lineId } = useParams();
  const { canUse, canNotUse } = useIAM();
  const customerOrLineId = customerId ?? lineId;
  const [accountRanking, setAccountRanking] = useState<number | undefined>();
  const [vlpOrder, setVlpOrder] = useState<number | undefined>();

  const phasingOptions = usePhasingOptions(product.phasing);
  const { phasingStart, phasingEnd, setStart, setEnd } = usePhasingRangeState(
    product.firstOnFloor ?? phasingOptions[0]?.key,
    product.lastOnFloor ?? phasingOptions[phasingOptions.length - 1]?.key
  );

  const isCarryOver = !!(product.newness && ['co', 'c/o'].includes(product.newness.toLowerCase()));
  const isReplen = product.replen === 'TRUE';
  const isFinal = productPageType === 'final';

  useEffect(() => {
    setAccountRanking(product.ranking ? +product.ranking : undefined);
    if (product.firstOnFloor && setStart) {
      setStart(product.firstOnFloor);
    }
    if (product.lastOnFloor && setEnd) {
      setEnd(product.lastOnFloor);
    }
    if (product.vlp) {
      setVlpOrder(product.vlp);
    }
  }, [product]);

  const onProductUpdate = (field: Omit<UpdateProductDetails, 'pc9'>) => {
    if (!context) throw new Defect('Context is missing.');

    productsService
      .update({ context, items: [{ pc9: product.pc9 }], attributes: field })
      .then(() => toastService.send(<span>{str('PDP.product.updateSuccessful', { count: 1 })}</span>))
      .catch(() => toastService.send(<span>{str('PDP.product.updateFailed', { count: 1 })}</span>, 'error'));
  };

  const handleSectionExpanded = useCallback(
    (isExpanded: boolean, sectionName: string, isResizing?: boolean) => {
      if (isResizing) return;
      pdpArrowInteractionTag({
        action: isExpanded ? 'expand' : 'collapse',
        section: sectionName,
        productId: product.pId,
        assortmentId: customerAssortmentId,
        customerId: customerOrLineId
      });
    },
    [product, customerOrLineId, customerAssortmentId]
  );

  const planningName: string | undefined = details?.planningName ?? details?.sapPlanningName;

  return (
    <ProductDetailContainer>
      {isFinal && canUse(`view.sizing`) && product.sizes && (
        <Accordion
          key="sizing"
          header={
            <Heading>{`${str('PDP.stats.sizing')}${canUse(`view.sizingPgName`) && product.isPgSizes && planningName ? ` - ${planningName}` : ''}`}</Heading>
          }
          defaultExpanded
          borderRadius={8}
          onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
            handleSectionExpanded(isExpanded, str('PDP.stats.sizing'), isResizing)
          }
        >
          <AccordionContent>
            <SizeGroup sizes={product.sizes} />
          </AccordionContent>
        </Accordion>
      )}

      <Accordion
        key="pricing"
        header={
          <Heading>
            {str('PDP.stats.pricing')}
            <IAM action={`view.pricePos`}>
              {product.pricePos && <PricePositionLabel>{product.pricePos}</PricePositionLabel>}
            </IAM>
          </Heading>
        }
        defaultExpanded
        borderRadius={8}
        onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
          handleSectionExpanded(isExpanded, str('PDP.stats.pricing'), isResizing)
        }
      >
        <AccordionContent>
          {product.currency && (
            <>
              <PricingSection>
                <IAM action={`view.wsp`}>
                  {product.wsp && (
                    <div>
                      <span>{str('PDP.stats.wholesale')}</span>
                      <span>{formatPrice(product.currency, product.wsp)}</span>
                    </div>
                  )}
                </IAM>
                <IAM action={`view.rrp`}>
                  {product.rrp && (
                    <div>
                      <span>{str('PDP.stats.retail')}</span>
                      <span>{formatPrice(product.currency, product.rrp)}</span>
                    </div>
                  )}
                </IAM>
                <PriceDisclaimer>{str('PDP.stats.priceDisclaimer')}</PriceDisclaimer>
              </PricingSection>
              <StyledVerticalDivider />
            </>
          )}
          <PricingSection>
            {product.phasing && (
              <IAM action={`view.phasing`}>
                {
                  <div>
                    <span>{str('PDP.stats.phasing')}</span>
                    <div>
                      {canUse(`edit.firstOnFloor`) ? (
                        <Dropdown
                          trigger={phasingStart && format(phasingStart, 'MMM yy')}
                          compactTrigger
                          items={phasingOptions}
                          defaultSelected={phasingStart ? [phasingStart] : undefined}
                          onSelect={val => {
                            setStart(val);
                            pdpPhasingStartTag({
                              start: format(val, 'MMM yy'),
                              productId: product.pId,
                              customerId: customerOrLineId,
                              assortmentId: customerAssortmentId
                            });
                            onProductUpdate({ firstOnFloor: val });
                          }}
                          disabled={canNotUse(`edit.firstOnFloor`)}
                          setMinWidth={false}
                        />
                      ) : (
                        <>{phasingStart ? format(phasingStart, 'MMM yy') : '-'} </>
                      )}

                      {str('general.to')}
                      {canUse(`edit.lastOnFloor`) ? (
                        <Dropdown
                          trigger={phasingEnd && format(phasingEnd, 'MMM yy')}
                          compactTrigger
                          items={phasingOptions}
                          defaultSelected={phasingEnd ? [phasingEnd] : undefined}
                          onSelect={val => {
                            setEnd(val);
                            pdpPhasingEndTag({
                              end: format(val, 'MMM yy'),
                              productId: product.pId,
                              customerId: customerOrLineId,
                              assortmentId: customerAssortmentId
                            });
                            onProductUpdate({ lastOnFloor: val });
                          }}
                          disabled={false}
                          setMinWidth={false}
                        />
                      ) : (
                        <> {phasingEnd ? format(phasingEnd, 'MMM yy') : ' -'}</>
                      )}
                    </div>
                  </div>
                }
              </IAM>
            )}
            <IAM action={`view.ranking`}>
              <div>
                <span>{str('PDP.stats.ranking')}</span>
                {canUse(`edit.ranking`) ? (
                  <Dropdown
                    itemContentType={DropdownItemContentType.PLAIN}
                    trigger={
                      RANKING_OPTIONS.find(option => option.value === accountRanking)
                        ? // @ts-expect-error: localisation will not accept string
                          str(RANKING_OPTIONS.find(option => option.value === accountRanking).key)
                        : ''
                    }
                    compactTrigger
                    placeHolder={str('PDP.stats.selectRanking')}
                    items={RANKING_OPTIONS.map(option => ({
                      key: `${option.value}`,
                      label: str(option.key)
                    }))}
                    onSelect={val => {
                      setAccountRanking(+val);
                      onProductUpdate({ ranking: +val });
                    }}
                    disabled={false}
                  />
                ) : (
                  <span>{accountRanking ?? '-'}</span>
                )}
              </div>
            </IAM>
          </PricingSection>
        </AccordionContent>
      </Accordion>

      {product.color && (
        <Accordion
          key="colours"
          header={<Heading>{str('PDP.stats.colours')}</Heading>}
          defaultExpanded
          borderRadius={8}
          onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
            handleSectionExpanded(isExpanded, str('PDP.stats.colours'), isResizing)
          }
        >
          <AccordionContent>
            <ColourContent>
              {product.color && (
                <div>
                  <span>{str('PDP.stats.displayedColour')}</span>
                  <ColorCodeWrapper>
                    <Tooltip text={product.color.toLowerCase()} position="left" offsetX={10}>
                      <ColourSpan $colour={product.color} />
                    </Tooltip>
                  </ColorCodeWrapper>
                </div>
              )}
              <IAM action={`view.inColors`}>
                {Boolean(product.inColors) && (
                  <div>
                    <span>{str('PDP.stats.coloursInAssortment')}</span>
                    <ColorCodeWrapper>
                      {product.inColors
                        ? product.inColors
                            ?.filter(c => c.toLowerCase() !== 'u')
                            ?.map(color => (
                              <Tooltip key={color} text={color.toLowerCase()} position="left" offsetX={10}>
                                <ColourSpan $colour={color} />
                              </Tooltip>
                            ))
                        : '-'}
                    </ColorCodeWrapper>
                  </div>
                )}
              </IAM>
              <IAM action={`view.outColors`}>
                {
                  <div>
                    <span>{str('PDP.stats.coloursNotInAssortment')}</span>
                    <ColorCodeWrapper>
                      {product.outColors
                        ? product.outColors
                            ?.filter(c => c.toLowerCase() !== 'u')
                            ?.map(color => (
                              <Tooltip key={color} text={color.toLowerCase()} position="left" offsetX={10}>
                                <ColourSpan $colour={color} />
                              </Tooltip>
                            ))
                        : '-'}
                    </ColorCodeWrapper>
                  </div>
                }
              </IAM>
            </ColourContent>
          </AccordionContent>
        </Accordion>
      )}

      <IAM action={`view.sizing`}>
        {product.sizes && (
          <Accordion
            key="sizes"
            header={
              <Heading>{`${str('PDP.stats.sizing')}${details?.planningName && product.isPgSizes ? ` - ${details.planningName}` : ''}`}</Heading>
            }
            defaultExpanded
            borderRadius={8}
            onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
              handleSectionExpanded(isExpanded, str('PDP.stats.sizing'), isResizing)
            }
          >
            <AccordionContent>
              <SizeGroup sizes={product.sizes} />
            </AccordionContent>
          </Accordion>
        )}
      </IAM>
      <IAM action={`view.materials`}>
        {product.materials && (
          <Accordion
            key="composition&care"
            header={<Heading>{str('PDP.labels.compositionAndCare')}</Heading>}
            defaultExpanded={false}
            borderRadius={8}
            onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
              handleSectionExpanded(isExpanded, str('PDP.labels.compositionAndCare'), isResizing)
            }
          >
            <AccordionContent>
              <IncreasedLineHeight>
                <PreText text={product.materials} />
              </IncreasedLineHeight>
            </AccordionContent>
          </Accordion>
        )}
      </IAM>
      <IAM action={`view.description`}>
        {product.details && (
          <Accordion
            key="description"
            header={<Heading>{str('PDP.labels.description')}</Heading>}
            defaultExpanded={false}
            borderRadius={8}
            onToggle={(isExpanded: boolean, isResizing: boolean | undefined) => {
              if (isExpanded) {
                pdpDescriptionTag(customerAssortmentId, customerId, product.pc9, isFinalAssortment);
              }
              handleSectionExpanded(isExpanded, str('PDP.labels.description'), isResizing);
            }}
          >
            <AccordionContent>
              <AccordionTextContent>
                <PreText text={product.details} />
                {product.fitting && (
                  <>
                    <h4>{str('PDP.stats.sizeAndFit')}</h4>
                    <PreText text={product.fitting} />
                  </>
                )}
              </AccordionTextContent>
            </AccordionContent>
          </Accordion>
        )}
      </IAM>
      <IAM action={`view.features`}>
        {(!!product.fabric?.length || !!product.sustainability?.length) && (
          <Accordion
            key="features"
            header={<Heading>{str('PDP.labels.features')}</Heading>}
            defaultExpanded={false}
            borderRadius={8}
            onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
              handleSectionExpanded(isExpanded, str('PDP.labels.features'), isResizing)
            }
          >
            <AccordionContent>
              <AccordionTextContent>
                <IAM action={`view.fabric`}>
                  {!!product.fabric?.length && (
                    <div>
                      <span>{str('PDP.labels.fabricInnovation')}</span>
                      <span>{product.fabric.map(x => x).join(', ')}</span>
                    </div>
                  )}
                </IAM>
                <IAM action={`view.sustainability`}>
                  {!!product.sustainability?.length && (
                    <div>
                      <span>{str('PDP.labels.sustainability')}</span>
                      <span>{product.sustainability.map(x => x).join(', ')}</span>
                    </div>
                  )}
                </IAM>
              </AccordionTextContent>
            </AccordionContent>
          </Accordion>
        )}
      </IAM>
      <IAM action={`view.sustainabilityStory`}>
        {product.sustainabilityStory && (
          <Accordion
            key="sustainabilityStory"
            header={<Heading>{str('PDP.stats.sustainabilityStory')}</Heading>}
            defaultExpanded={false}
            borderRadius={8}
            onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
              handleSectionExpanded(isExpanded, str('PDP.stats.sustainabilityStory'), isResizing)
            }
          >
            <AccordionContent>{product.sustainabilityStory}</AccordionContent>
          </Accordion>
        )}
      </IAM>
      {
        <Accordion
          key="details"
          header={<Heading>{str('PDP.tabs.details').toUpperCase()}</Heading>}
          defaultExpanded
          borderRadius={8}
          onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
            handleSectionExpanded(isExpanded, str('PDP.tabs.details'), isResizing)
          }
        >
          <AccordionContent>
            <DetailSection>
              <IAM action={`view.vlp`}>
                <p>{str('PDP.labels.vlpOrder')}</p>
                {canUse(`edit.vlp`) ? (
                  <>
                    <Input
                      type="number"
                      min={1}
                      max={9999}
                      value={vlpOrder ?? ''}
                      placeholder={str('PDP.labels.vlpOrderPlaceholder')}
                      onChange={e => {
                        setVlpOrder(+e.target.value);
                        onProductUpdate({ vlp: +e.target.value });
                      }}
                    />
                  </>
                ) : (
                  <>
                    <span>{vlpOrder ?? '-'} </span>
                  </>
                )}
              </IAM>
              <IAM action={`view.newness`}>
                {isCarryOver ? (
                  <>
                    <p>{str('PDP.labels.newness')}</p>
                    <Tag $backgroundColor={'red'}>
                      <Newness />
                      <p>{str('PDP.labels.carryover')}</p>
                    </Tag>
                  </>
                ) : (
                  <>
                    <p>{str('PDP.labels.newness')}</p>
                    <span>{product.newness ?? '-'}</span>
                  </>
                )}
              </IAM>
              <IAM action={`view.replen`}>
                {isReplen ? (
                  <>
                    <p>{str('PDP.labels.replen')}</p>
                    <Tag $backgroundColor={'gray'}>
                      <Replen />
                      <p>{str('PDP.labels.replen')}</p>
                    </Tag>
                  </>
                ) : (
                  <>
                    <p>{str('PDP.labels.replen')}</p>
                    <span>{'-'}</span>
                  </>
                )}
              </IAM>
              <IAM action={`view.mandatory`}>
                <p>{str('PDP.labels.mandatory')}</p>
                <span>{product.mandatory ? str('general.yes').toUpperCase() : str('general.no').toUpperCase()}</span>
              </IAM>
              <IAM action={`view.seasonality`}>
                <p>{str('PDP.labels.seasonality')}</p>
                <span>{product.seasonality ?? '-'}</span>
              </IAM>
            </DetailSection>
            <StyledVerticalDivider />
            <DetailSection>
              <IAM action={`view.plannedLifecycle`}>
                <p>{str('PDP.labels.plannedLifecycle')}</p>
                <span>{product.plannedLifecycle ?? '-'}</span>
              </IAM>
              <IAM action={`view.distribution`}>
                <p>{str('PDP.labels.distribution')}</p>
                {product.distribution?.length ? (
                  <>
                    {product.distribution.map(d => (
                      <span key={d}>{d}</span>
                    ))}
                  </>
                ) : (
                  '-'
                )}
              </IAM>
              <IAM action={`view.segmentation`}>
                <p>{str('PDP.labels.segmentation')}</p>
                <span>{product.segmentation?.join(', ') ?? '-'}</span>
              </IAM>
              <IAM action={`view.lastModified`}>
                <p>{str('PDP.labels.lastModified')}</p>
                <span>{product.lastUpdatedAt ? format(product.lastUpdatedAt, 'dd MMM yyyy hh:mm') : '-'}</span>
              </IAM>
            </DetailSection>
          </AccordionContent>
        </Accordion>
      }
      <IAM action={`view.replacerFinish`}>
        {product.replacerFinish && (
          <Accordion
            key="replacerFinish"
            header={<Heading>{str('PDP.labels.finishReplacer').toUpperCase()}</Heading>}
            defaultExpanded={false}
            borderRadius={8}
            onToggle={(isExpanded: boolean, isResizing: boolean | undefined) =>
              handleSectionExpanded(isExpanded, str('PDP.labels.finishReplacer'), isResizing)
            }
          >
            <AccordionContent>
              <AccordionTextContent>
                <span>{product.replacerFinish}</span>
              </AccordionTextContent>
            </AccordionContent>
          </Accordion>
        )}
      </IAM>
    </ProductDetailContainer>
  );
};
