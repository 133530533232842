import { Toggle } from 'components/Toggle';
import { useLocalisation } from 'providers';
import { styled } from 'styled-components';
import { rem, useLocalStorageState } from 'utils';

import { useAddOrderToolbarControl } from '../../OrderLayout';

const StyledToggle = styled(Toggle)`
  pointer-events: none;
  height: 100%;
  width: ${rem(28)};
  margin-right: ${rem(2)};

  input + span {
    left: 0;
    top: 50%;
    width: ${rem(28)};
    height: ${rem(14)};
    transform: translateY(-50%);

    &::before {
      width: ${rem(14)};
      height: ${rem(14)};
    }
  }

  input:checked + span::before {
    transform: translatex(${rem(14)});
  }
`;

export const useMultiMonthEditToggle = ({ isDisabled }: { isDisabled?: boolean } = {}) => {
  const [str] = useLocalisation();
  const [enabled, setEnabled] = useLocalStorageState(false, '@yourxx/order-capture/enable-multi-month-edit');

  useAddOrderToolbarControl(
    {
      icon: <StyledToggle checked={enabled} onChange={() => undefined} />,
      label: str('Order.sizing.multiMonthEditLabel').toUpperCase(),
      isDisabled,
      onClick: () => setEnabled(prev => !prev)
    },
    -Infinity
  );

  return enabled;
};
