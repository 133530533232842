import {
  type CellClassParams,
  type CellValueChangedEvent,
  type ColDef,
  type EditableCallbackParams,
  type GridApi,
  type ICellRendererParams,
  themeQuartz,
  type ValueFormatterParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Disabled } from 'assets/icons';
import { Hint } from 'pages/CommonLayout';
import { useCallback, useMemo, useState } from 'react';
import {
  NULL_DIMENSION,
  type OrderSizingContext,
  type OrderSizingManager,
  type PC9Sizing,
  ProductSize,
  type SizeGrid,
  SizingMonths
} from 'services';
import { parseMonthId } from 'services/OrdersService/utils';
import { css, styled } from 'styled-components';
import { rem, themed, useClickOutside } from 'utils';

interface SizingRowData {
  d2: string;
  [key: string]: string | null;
}
interface SizingCellParams extends ICellRendererParams {
  data: SizingRowData;
  colDef: { headerName: string };
}

const borderBase = css`
  z-index: ${themed('zIndex.100')};
  content: '';
  position: absolute;
  background-color: var(--ag-border-color);
`;

const gridBordersStyling = css`
  .ag-root-wrapper {
    border: 0;
  }

  .ag-header:not(#specificity-bump) {
    border: 0;
  }

  .ag-header-row {
    // Top border
    &::before {
      ${borderBase};
      top: 0;
      left: 0;
      width: 100%;
      height: var(--ag-row-border-width);
    }
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container {
    // Left border
    &::after {
      ${borderBase};
      top: 0;
      left: 0;
      width: var(--ag-row-border-width);
      height: 100%;
    }
  }

  .ag-row,
  .ag-header-row {
    border: 0;

    // Bottom border
    &::after {
      ${borderBase};
      top: calc(var(--ag-row-height) - ${rem(1)});
      left: 0;
      width: 100%;
      height: var(--ag-row-border-width);
    }
  }

  .ag-cell-inline-editing:not(#specificity-bump),
  .ag-cell-focus:not(#specificity-bump) {
    outline: none;
    border: 0 !important;

    // Cell focus pseudo-border
    &::before {
      z-index: ${themed('zIndex.200')};
      box-sizing: border-box;
      pointer-events: none;
      z-index: ${themed('zIndex.100')};
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: calc(100% - var(--ag-row-border-width));
      height: calc(100% - var(--ag-row-border-width));
      border: solid ${rem(3)} ${themed('color.blue')};
    }

    input {
      border: none;
      outline: none;
    }
  }

  .ag-cell:not(#specificity-bump),
  .ag-header-cell:not(#specificity-bump),
  .ag-pinned-left-header:not(#specificity-bump) {
    border: 0;

    // Right border
    &::after {
      ${borderBase};
      top: 0;
      right: 0;
      width: var(--ag-row-border-width);
      height: 100%;
    }
  }
`;

const CELL_SIZE = 40;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const GridWrapper = styled.div`
  display: flex;
  flex: 1 1 0;

  > div {
    width: 100%;
    height: 100%;
  }
  div,
  input {
    text-align: center;
  }

  --ag-font-size: ${themed('font.size.s')};
  --ag-font-family: ${themed('font.family')};
  --ag-border-radius: 0;
  --ag-wrapper-border-radius: 0;
  --ag-borders: solid ${rem(1)};
  --ag-border-color: ${themed('color.grey')};
  --ag-cell-horizontal-border: solid ${rem(1)};
  --ag-cell-vertical-border: solid ${rem(1)};
  --ag-row-border-style: solid;
  --ag-row-border-width: ${rem(1)};
  --ag-header-background-color: ${themed('color.white')};
  --ag-odd-row-background-color: ${themed('color.white')};
  --ag-row-height: ${CELL_SIZE}px;
  --ag-header-height: ${CELL_SIZE}px;

  .ag-root-wrapper {
    width: 100%;
  }

  .ag-column-first {
    pointer-events: none;
    font-weight: ${themed('font.weight.medium')};
  }

  .ag-header {
    margin-bottom: ${rem(-0.5)};

    &-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-align: center;

      &-label {
        justify-content: center;
        font-weight: normal;
      }

      &-comp-wrapper {
        justify-content: center;

        span {
          font-weight: ${themed('font.weight.medium')};
        }
      }
    }
  }

  ${gridBordersStyling}

  .ag-cell:not(#specificity-bump) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    ${themed('typography.h3')};
    font-weight: ${themed('font.weight.regular')};

    .has-value {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${themed('color.greenLight')};
      width: 100%;
      height: 100%;
    }
  }

  .ag-cell-inline-editing:not(#specificity-bump) {
    input {
      padding: 0;
      height: 100%;
      width: 100%;
      background-color: ${themed('color.transparentBlue')};
      font-variant: tabular-nums;
    }
  }

  .disabled-cell {
    cursor: not-allowed;
    background-color: ${themed('color.greyLight')};
    pointer-events: none;
  }
`;

const Size = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0 ${themed('spacing.s')};
  ${themed('typography.h3')};
  font-size: ${rem(16)};
  font-weight: ${themed('font.weight.bold')};
  font-variant: tabular-nums;

  &::before {
    content: attr(data-size);
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-variant: tabular-nums;
  }
`;
const Cell = styled.div`
  font-variant: tabular-nums;
`;

interface SizingCellRendererProps {
  formattedValue: string;
  d1: string;
  d2: string;
  months: number[];
  sizing: PC9Sizing;
  sizeGrid: SizeGrid;
}

const SizingCellRenderer = ({ formattedValue, d1, d2, months, sizing, sizeGrid }: SizingCellRendererProps) => {
  if (!sizeGrid.supports(d1, d2)) return null;
  if (!formattedValue) return formattedValue;

  const cell = <Cell className="has-value">{formattedValue}</Cell>;
  if (months.length === 1) return cell;

  return (
    <Hint of={cell}>
      {months.map(month => {
        const v = sizing.value(month, d1, d2);
        if (!v) return null;
        return (
          <Cell key={month}>
            {parseMonthId(month).monthName}: {v}
          </Cell>
        );
      })}
    </Hint>
  );
};

const contextMenuItems = [
  'cut' as const,
  'copy' as const,
  'paste' as const,
  'separator' as const,
  'csvExport' as const,
  'excelExport' as const
];

export const ProductSizingGrid = ({
  isReadOnly,
  context,
  months,
  manager,
  sizing
}: {
  isReadOnly: boolean;
  context: OrderSizingContext;
  months: number[];
  manager: OrderSizingManager;
  sizing: PC9Sizing;
}) => {
  const columnDefs: ColDef<SizingRowData>[] = useMemo(
    () => [
      {
        field: 'd1',
        width: 60,
        headerComponent: () => <Disabled />,
        pinned: 'left' as const,
        lockPinned: true,
        headerName: '',
        valueFormatter: (params: ValueFormatterParams<SizingRowData>) => {
          if (!params.data) return '';
          return renderD2Label(params.data.d2);
        },
        cellRenderer: (params: { valueFormatted: string }) => (
          <Size title={params.valueFormatted} data-size={params.valueFormatted} />
        )
      },
      ...context.product.sizeGrid.d1s().map(d1 => ({
        field: d1,
        minWidth: 60,
        flex: 1,
        type: 'numericColumn',
        cellDataType: false,
        headerName: renderD1Label(d1),
        headerComponent: ({ displayName: d1Label }: { displayName: string }) => (
          <Size title={d1Label} data-size={d1Label} />
        ),
        editable: (params: EditableCallbackParams<SizingRowData>) =>
          !isReadOnly && context.product.sizeGrid.supports(d1, params.data?.d2 ?? ''),
        cellRenderer: (params: SizingCellParams) => (
          <SizingCellRenderer
            formattedValue={params.data[d1] ?? ''}
            d1={d1}
            d2={params.data.d2}
            months={months}
            sizing={sizing}
            sizeGrid={context.product.sizeGrid}
          />
        ),
        valueParser: ({ newValue }: { newValue: string }) => {
          const value = parseInt(String(newValue).trim(), 10);
          return Number.isFinite(value) ? value : null;
        },
        cellClass: (params: CellClassParams<SizingRowData>) =>
          !context.product.sizeGrid.supports(d1, params.data?.d2 ?? '') ? 'disabled-cell' : ''
      }))
    ],
    [context.product.sizeGrid, isReadOnly, months, sizing]
  );

  const rowData = useMemo(
    () =>
      context.product.sizeGrid.d2s().map(d2 => ({
        d2,
        ...Object.fromEntries(
          context.product.sizeGrid.d1s().map(d1 => {
            const sizing = manager.sizingOf(context);
            return [
              d1,
              months.length === 1
                ? sizing.value(months[0], d1, d2)
                : months.length > 1 &&
                    months.every(month => sizing.value(month, d1, d2) === sizing.value(months[0], d1, d2))
                  ? sizing.value(months[0], d1, d2)
                  : '•'
            ];
          })
        )
      })),
    [context, manager, months]
  );

  const handleCellValueChanged = useCallback(
    ({ newValue, data, column }: CellValueChangedEvent<SizingRowData>) => {
      const trimmedValue = newValue?.toString().trim();

      if (!trimmedValue) {
        manager.size({
          ...context,
          adjustments: [[SizingMonths(...months), ProductSize(column.getColId(), data.d2), null]]
        });
        return;
      }

      const value = parseInt(trimmedValue, 10);
      if (Number.isFinite(value)) {
        manager.size({
          ...context,
          adjustments: [[SizingMonths(...months), ProductSize(column.getColId(), data.d2), value]]
        });
      }
    },
    [context, manager, months]
  );

  const [gridApi, setGridApi] = useState<GridApi>();
  const clearFocusedCell = useCallback(() => gridApi?.clearFocusedCell(), [gridApi]);
  const wrapper = useClickOutside(clearFocusedCell);

  return (
    <Container>
      <GridWrapper ref={wrapper}>
        <AgGridReact
          onGridReady={event => setGridApi(event.api)}
          theme={themeQuartz}
          columnDefs={columnDefs}
          rowData={rowData}
          suppressMovableColumns
          suppressColumnMoveAnimation
          suppressRowHoverHighlight
          tooltipShowDelay={0}
          onCellValueChanged={handleCellValueChanged}
          getContextMenuItems={() => contextMenuItems}
          singleClickEdit
          defaultColDef={defaultColDef}
          stopEditingWhenCellsLoseFocus
        />
      </GridWrapper>
    </Container>
  );
};

const renderD1Label = (d1: string) => (d1 === NULL_DIMENSION ? '–' : isNaN(Number(d1)) ? d1 : `W${d1}`);

const renderD2Label = (d2: string) => (d2 === NULL_DIMENSION ? '–' : isNaN(Number(d2)) ? d2 : `L${d2}`);

const defaultColDef = {
  resizable: false,
  sortable: false,
  suppressHeaderMenuButton: true
};
