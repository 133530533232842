import { styled } from 'styled-components';
import { rem, themed } from 'utils';

export const DisplayedError = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  border-radius: ${themed('borderRadius')};
  padding: ${themed('spacing.m')} ${themed('spacing.l')};
  background-color: ${themed('color.red')};
  color: ${themed('color.white')};
  ${themed('typography.h4')};

  svg {
    width: ${rem(24)};
    height: ${rem(24)};
  }

  svg path {
    stroke: ${themed('color.white')};
  }
`;
