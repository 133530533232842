import { Bell, Compare, Insights, Settings } from 'assets/icons';
import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { FlexWrapper } from 'components/FlexWrapper';
import { IAM } from 'components/IAM';
import { useInsights } from 'components/Insights';
import { Tab, Tabs } from 'components/Tabs';
import { Tooltip } from 'components/Tooltip';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { redirect, useLocation } from 'react-router-dom';
import { useServices } from 'services';
import styled from 'styled-components';
import {
  appBrands,
  getBrand,
  HEADER_TABS,
  landingSearchTag,
  rem,
  themed,
  useDebouncedFn,
  useEventSubscriptions,
  useIAM
} from 'utils';

import { LocalisationStrings } from '@yourxx/translations/en';
import { getAdminUrl, getV1Url } from '@yourxx/ui-utils/src/utils/appSwitching';

import { type SearchSuggestion, SearchSuggestionsCleared, SearchSuggestionsCreated } from './ContextfulSearchProvider';
import { HeaderSearch } from './HeaderSearch';
import { LanguageSelector } from './LanguageSelector';
import { ProfileModal } from './ProfileModal';

const StyledHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  height: ${rem(56)};
  box-shadow: ${themed('boxShadow')};
  z-index: ${themed('zIndex.100')};
  padding: 0 ${themed('spacing.xxxl')};
  background-color: ${themed('color.white')};

  // TODO: Temporary layout fix until old views get migrated to new layout.

  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
  }
`;
const HeaderTabs = styled(Tabs)`
  align-items: flex-end;
  height: 100%;

  li {
    min-width: ${rem(120)};
  }
`;
const WidgetsWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;
const HeaderWidgets = styled.div<{ $alignRight?: boolean }>`
  display: flex;
  height: auto;
  gap: ${themed('spacing.m')};
  align-items: ${({ $alignRight }) => ($alignRight ? 'center' : 'flex-end')};
  justify-content: ${({ $alignRight }) => ($alignRight ? 'flex-end' : 'unset')};
  flex: ${({ $alignRight }) => ($alignRight ? 1 : 'none')};
`;
const HeaderButton = styled(Button)`
  background-color: transparent;
  width: max-content;
  height: max-content;
  padding: ${themed('spacing.s')};
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.medium')};
  line-height: ${rem(15)};
  letter-spacing: ${rem(0.6)};
  align-self: center;
  align-items: center;

  svg {
    width: ${themed('spacing.xl')};
    height: ${themed('spacing.xl')};
  }
`;
const HeaderBrandWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: ${rem(54)};

  svg {
    width: ${rem(87)};
    height: ${rem(36)};
  }
`;
const NotificationLabel = styled.div`
  border-radius: 50%;
  background-color: ${themed('color.red')};
  color: ${themed('color.white')};
  position: absolute;
  top: 0;
  right: 0;
  width: ${rem(18)};
  height: ${rem(18)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${themed('font.size.xs')};
  letter-spacing: ${rem(-0.5)};
  line-height: 0;
`;

type HeaderProps = {
  onLogoClick?: VoidFunction;
  showHeaderTabs?: boolean;
  showSearchMenu?: boolean;
  searchProps: {
    searchTerm: string;
    changeTerm: (searchTerm: string) => void;
    clear: VoidFunction;
    placeholder?: string;
  };
  showLanguageSelector?: boolean;
  showProfileAvatar?: boolean;
  showHeaderLogo?: boolean;
  brand?: string;
  availableLanguages?: { [key: string]: string };
  preferredLanguage?: string | null;
  onComparatorClick?: VoidFunction;
  onInsightsClick?: VoidFunction;
  onLanguageChange?: (val: string) => void;
  onRegionAndLanguageChange?: (val: string) => void;
  onNotificationClick?: VoidFunction;
  onTabChange?: (tab: string) => void;
  selectedTab: string;
};

type HeaderTabData = {
  id: (typeof HEADER_TABS)[number];
  titleId: keyof LocalisationStrings;
  isVisible: () => boolean; // Function instead of pure boolean, as this has to be evaluated in the current-user context
};

// TODO: Split this component up into subcomponents, props object is getting out of hand.
export const Header = ({
  onLogoClick,
  showHeaderTabs = true,
  showSearchMenu = true,
  searchProps,
  showLanguageSelector = true,
  showProfileAvatar = true,
  showHeaderLogo = true,
  brand,
  onComparatorClick,
  onInsightsClick,
  onNotificationClick,
  selectedTab,
  onTabChange
}: HeaderProps) => {
  const [str, localisationsAreLoading, { availableLocales, currentLocale, changeLocale }] = useLocalisation();
  const { insightsCount } = useInsights();
  const { pathname } = useLocation();

  const { canUse } = useIAM();
  const currentIcon = brand && getBrand(brand) ? getBrand(brand).icon : appBrands.levi.icon;
  const [displayProfileModal, setDisplayProfileModal] = useState<boolean>(false);
  const [insightsButtonDisabled, setInsightsButtonDisabled] = useState<boolean>(false);
  const [newInsightsCount, setNewInsightsCount] = useState<number>(0);
  const [pendingInsightsCount, setPendingInsightsCount] = useState<number>(0);
  const prefix = 'header';

  const HEADER_TABS_DATA: { [key in (typeof HEADER_TABS)[number]]: HeaderTabData } = {
    customers: {
      id: 'customers',
      titleId: 'Tab.header.customers.label',
      isVisible: () => canUse('viewCustomers', prefix)
    },
    lines: {
      id: 'lines',
      titleId: 'Tab.header.lines.label',
      isVisible: () => canUse('viewLines', prefix)
    }
  };

  const adminUrl = useMemo(() => {
    return getAdminUrl(window.location.href);
  }, [window.location.href]);

  const v1Url = useMemo(() => {
    return getV1Url(window.location.href);
  }, [window.location.href]);

  useEffect(() => {
    if (insightsCount) {
      setNewInsightsCount((insightsCount.recommendations?.new || 0) + (insightsCount.suggestions?.new || 0));
      setPendingInsightsCount(
        (insightsCount.recommendations?.pending || 0) + (insightsCount.suggestions?.pending || 0)
      );
    }
  }, [insightsCount]);

  useEffect(() => {
    setInsightsButtonDisabled(newInsightsCount === 0 && pendingInsightsCount === 0);
  }, [newInsightsCount, pendingInsightsCount]);

  const { eventBus } = useServices();
  const [searchSuggestions, setSearchSuggestions] = useState<readonly SearchSuggestion[]>();
  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(SearchSuggestionsCreated, event => {
          setSearchSuggestions(event.payload.suggestions);
        }),

        eventBus.on(SearchSuggestionsCleared, _ => {
          setSearchSuggestions(undefined);
        })
      ],
      [eventBus]
    )
  );

  const { changeTerm } = searchProps;
  const onSearch = useCallback(
    useDebouncedFn((searchTerm: string) => {
      landingSearchTag({ location: pathname, term: searchTerm });
      changeTerm(searchTerm);
    }, 500),
    [changeTerm, pathname]
  );

  return (
    <StyledHeader>
      {showHeaderLogo && (
        <HeaderBrandWrapper>
          <HeaderButton onClick={onLogoClick}>{currentIcon()}</HeaderButton>
        </HeaderBrandWrapper>
      )}
      <WidgetsWrapper>
        {showHeaderTabs && (
          <HeaderWidgets>
            <HeaderTabs>
              {HEADER_TABS.map(tab => HEADER_TABS_DATA[tab]).map(
                tab =>
                  tab.isVisible() && (
                    <Tab
                      key={tab.id}
                      value={tab.id}
                      isSelected={selectedTab === tab.id}
                      onClick={() => onTabChange?.(tab.id)}
                    >
                      {str(tab.titleId)}
                    </Tab>
                  )
              )}
            </HeaderTabs>
          </HeaderWidgets>
        )}
        <HeaderWidgets $alignRight>
          <IAM action={'viewGoToV1'} prefix={'header'}>
            <a href={v1Url}>
              <HeaderButton onClick={() => redirect(v1Url)}>{str('general.goToV1')}</HeaderButton>
            </a>
          </IAM>

          {showSearchMenu && (
            <HeaderSearch
              placeholder={searchProps.placeholder}
              searchTerm={searchProps.searchTerm}
              suggestions={searchSuggestions}
              onChange={onSearch}
              debounceDelayInMs={200}
              disableAutoInputCapture
            />
          )}
          {onComparatorClick && (
            <Tooltip text={str('Header.compareBoards')} position="bottom">
              <HeaderButton onClick={onComparatorClick}>
                <Compare />
              </HeaderButton>
            </Tooltip>
          )}
          {onInsightsClick && (
            <Tooltip
              textComponent={
                <FlexWrapper>
                  <span>
                    {newInsightsCount > 0 &&
                      str('Header.newInsights', {
                        count: newInsightsCount
                      })}
                  </span>
                  <span>
                    {pendingInsightsCount > 0 &&
                      str('Header.pendingInsights', {
                        count: pendingInsightsCount
                      })}
                  </span>
                </FlexWrapper>
              }
              disabled={insightsButtonDisabled}
              position="bottom"
            >
              <HeaderButton onClick={onInsightsClick} disabled={insightsButtonDisabled}>
                <Insights />
                {newInsightsCount > 0 && (
                  <NotificationLabel>{newInsightsCount > 9 ? '9+' : newInsightsCount}</NotificationLabel>
                )}
              </HeaderButton>
            </Tooltip>
          )}
          {showLanguageSelector && (
            <LanguageSelector
              default={currentLocale ?? undefined}
              selected={currentLocale ?? undefined}
              onChange={changeLocale}
              languages={availableLocales()}
              isLoading={localisationsAreLoading}
            />
          )}
          {onNotificationClick && (
            <Tooltip text={str('Header.notifications')} position="bottom">
              <HeaderButton onClick={onNotificationClick}>
                <Bell />
              </HeaderButton>
            </Tooltip>
          )}

          <IAM action={'viewAdmin'} prefix={prefix}>
            <a href={adminUrl}>
              <Tooltip text={str('Header.admin')} position="bottom">
                <HeaderButton onClick={() => redirect(adminUrl)}>
                  <Settings />
                </HeaderButton>
              </Tooltip>
            </a>
          </IAM>

          {showProfileAvatar && (
            <Tooltip text={str('Header.profile')} position="bottom">
              <Avatar onClick={() => setDisplayProfileModal(prev => !prev)} />
            </Tooltip>
          )}
        </HeaderWidgets>
      </WidgetsWrapper>
      {displayProfileModal && <ProfileModal onClose={() => setDisplayProfileModal(false)} />}
    </StyledHeader>
  );
};
