import type { OrderProductsMap } from '@yourxx/types';

import { NULL_DIMENSION, type OrderId } from '../../index';
import type { OrderSizingComputedRecords } from '../OrderSizingManager';
import { parseMonthId } from './parseMonthId';

/**
 * TODO: Replace internal FE state to match BE state so we can have less mapping.
 * TODO: Add locationId to computed state, even though all locations within the same order will get the same value per PC9 per size.
 */
export const updateOrderSizingRecords = (
  orderId: OrderId,
  computedOut: OrderSizingComputedRecords,
  orderProductsMap: OrderProductsMap | undefined = {}
): void => {
  // Add data from the response to the local state
  for (const locationId in orderProductsMap) {
    for (const monthKey in orderProductsMap[locationId]) {
      for (const pc9 in orderProductsMap[locationId][monthKey]) {
        for (const size in orderProductsMap[locationId][monthKey][pc9]) {
          const month = monthKey.replace(/-/, '');
          const [d1, d2 = NULL_DIMENSION] = size.split('_');

          computedOut[orderId] ??= {};
          computedOut[orderId][pc9] ??= { state: {}, version: 0 };
          computedOut[orderId][pc9].state[month] ??= {};
          computedOut[orderId][pc9].state[month][d1] ??= {};

          const { qty } = orderProductsMap[locationId][monthKey][pc9][size];
          computedOut[orderId][pc9].state[month][d1][d2] = qty;
        }
      }
    }
  }

  // Remove local stale data if not present in the response
  const [locationId] = Object.keys(orderProductsMap);

  for (const pc9 in computedOut[orderId]) {
    for (const month in computedOut[orderId][pc9].state) {
      for (const d1 in computedOut[orderId][pc9].state[month]) {
        for (const d2 in computedOut[orderId][pc9].state[month][d1]) {
          const parsed = parseMonthId(Number(month));
          const deliveryDate = `${parsed.year}-${String(parsed.month).padStart(2, '0')}`;

          const size = d2 === NULL_DIMENSION ? d1 : `${d1}_${d2}`;
          if (!locationId || typeof orderProductsMap?.[locationId]?.[deliveryDate]?.[pc9]?.[size] === 'undefined') {
            computedOut[orderId][pc9].state[month][d1][d2] = null;
          }
        }
      }
    }
  }
};
