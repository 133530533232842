import type { LocalisedStringFor } from 'providers';

import { toCase } from '@yourxx/support';
import { ToCase } from '@yourxx/types';

export const filterIdToRenderedName = (str: LocalisedStringFor, filterId: string) => {
  switch (filterId) {
    // Product filters
    case 'gender':
    case 'color':
    case 'status':
    case 'newness':
    case 'seasonality':
    case 'distribution':
    case 'story':
    case 'tops':
    case 'bottoms':
    case 'fit':
    case 'footwear':
    case 'accessories':
    case 'price':
    case 'notInFinalAssortment':
    case 'fabric':
    case 'pricePos':
    case 'ranking':
    case 'firstOnFloor':
    case 'sustainability':
    case 'mandatory':
    case 'storeGrades':
    case 'logoExecution':
      return str(`ProductListing.filterNames.${filterId}`);

    // Customers filters
    case 'regionName':
    case 'archived':
      return str(`Landing.customers.filters.${filterId}`);

    default:
      return toCase[ToCase.Sentence](filterId);
  }
};
