import { ViewRoute } from 'utils/ViewRoute';

export enum ProductListMode {
  Grid = 'grid',
  List = 'list',
  Columns = 'columns'
}
export type ProductPageType = 'products' | 'parent' | 'final';
export type ProductModalType = 'canvas' | 'insights';
export type CanvasType = 'comparator' | 'share';

type Brand = { brandName: string; canvasType?: CanvasType; canvasBoardId?: string };

type Customer = Brand & { customerId: string };

type Assortment = Customer & {
  assortmentId: string;
  productPageType: ProductPageType;
  productListMode: ProductListMode;
  productModalType?: ProductModalType;
  canvasType?: CanvasType;
  canvasBoardId?: string;
};

type AssortmentStories = Customer & { assortmentId: string; productPageType: ProductPageType };
type LineStories = Brand & { lineId: string; productPageType: 'parent' | 'products' };

type ProductLine = Brand & { lineId: string; productPageType: 'parent' | 'products'; productListMode: ProductListMode };

const brand = ViewRoute<Brand>('/:brandName');
const customers = ViewRoute<Brand>(`${brand}/customers`);

const assortments = ViewRoute<Customer>(`${brand}/customers/:customerId/:season/:assortmentType`);
const customerInsights = ViewRoute<Partial<Assortment>>(`${assortments}/:productModalType`);
const assortmentProducts = ViewRoute<Partial<Assortment>>(
  `${assortments}/:assortmentId/:productPageType/:productListMode`
);
const assortmentStories = ViewRoute<Partial<AssortmentStories>>(`${assortments}/:assortmentId/:productPageType`);
const assortmentProduct = ViewRoute<Partial<Assortment>>(`${assortmentProducts}/:productModalType`);
const assortmentCanvasBoards = ViewRoute<Partial<Assortment>>(`${assortmentProduct}/:canvasType`);
const assortmentCanvasBoard = ViewRoute<Partial<Assortment>>(`${assortmentCanvasBoards}/:canvasBoardId`);
const assortmentInsights = ViewRoute<Partial<Assortment>>(`${assortmentProducts}/:productModalType`);

const lines = ViewRoute<Brand>(`${brand}/lines/:season`);
const lineProducts = ViewRoute<ProductLine>(`${lines}/:lineId/:productPageType/:productListMode`);
const lineStories = ViewRoute<LineStories>(`${lines}/:lineId/:productPageType/:productListMode`);
const lineProduct = ViewRoute<Partial<ProductLine>>(`${lineProducts}/:productModalType`);
const lineCanvasBoards = ViewRoute<Partial<ProductLine>>(`${lineProduct}/:canvasType`);
const lineCanvasBoard = ViewRoute<Partial<ProductLine>>(`${lineCanvasBoards}/:canvasBoardId`);

const orders = ViewRoute(`${customers}/:customerId/:season/orders`);
const order = ViewRoute(`${orders}/:orderSlug`);
const orderSizing = ViewRoute(`${order}/:pc9`);

export const routes = {
  brand,
  customers,
  assortments,
  customerInsights,
  assortmentStories,
  assortmentProducts,
  assortmentProduct,
  assortmentCanvasBoards,
  assortmentCanvasBoard,
  assortmentInsights,
  lines,
  lineProducts,
  lineStories,
  lineProduct,
  lineCanvasBoards,
  lineCanvasBoard,
  orders,
  order,
  orderSizing
} as const;

export type Route = (typeof routes)[keyof typeof routes];
