import {
  ApiAuditEvent,
  ApiGetAuditEvents,
  auditApiEndpoints,
  AuditEventParams,
  GetUserAuditDetails
} from '@yourxx/types';

import { toRequest } from '../../utils';
import { apiClient } from '../client';

export type GetAuditEventsApi = ApiAuditEvent & { by?: GetUserAuditDetails };
export const getAuditEvents = async (params: AuditEventParams): Promise<ApiAuditEvent[][]> => {
  const request = toRequest({ path: auditApiEndpoints.audit, method: 'GET', params });
  const { events, users } = await apiClient.request<ApiGetAuditEvents>(request);
  const data = events
    .sort((a, b) => (a.ts < b.ts ? 1 : -1))
    .reduce((acc: Record<string, GetAuditEventsApi[]>, event) => {
      const date = event.date.split('T')[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      const data = event?.byUserId && users[event.byUserId] ? { ...event, by: users[event.byUserId] } : event;
      acc[date].push(data);
      return acc;
    }, {});

  return Object.values(data);
};

/**
 * @deprecated This api endpoint no longer exists. The UI will require a refactor to work with the correct endpoint
 */
export const getAuditTrail = async (_: { assortmentId: string }) => {
  console.error('getAuditTrail endpoint no longer exists');
  return [];
};
