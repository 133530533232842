import { ColDef, GetContextMenuItemsParams } from 'ag-grid-enterprise';
import { Edit } from 'assets/icons';
import { AgListView, Button, Checkbox } from 'components';
import { IAM } from 'components/IAM';
import { format } from 'date-fns';
import { Hint } from 'pages/CommonLayout';
import { StatusCapsule } from 'pages/Orders/components';
import { useLocalisation } from 'providers';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { type OrderModel, OrderStatus } from 'services';
import styled from 'styled-components';
import { formatPrice, themed, useIAM } from 'utils';

import type { OrderModalType } from '.';

const Wrapper = styled.div`
  padding: 0 ${themed('spacing.xl')} ${themed('spacing.m')};
`;
const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${themed('spacing.s')};

  button {
    padding: ${themed('spacing.s')};
  }
`;
const OrderLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  color: ${themed('color.black')};
  ${themed('typography.h4')};
  font-variant: tabular-nums;

  @media (hover: hover) {
    &:hover {
      color: ${themed('color.grey')};
    }
  }
`;
const OrderLocations = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${themed('spacing.s')};
  min-width: 0;
`;
const OrderLocation = styled.span`
  background-color: ${themed('color.offWhite')};
  padding: ${themed('spacing.xs')} ${themed('spacing.s')};
  border-radius: ${themed('borderRadius')};
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const OrderLocationHint = styled.div`
  padding: ${themed('spacing.xs')} ${themed('spacing.s')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.regular')};
`;

export const OrderList = ({
  orders,
  selectedOrders,
  setSelectedOrders,
  setCurrentOrder,
  setOrderModalType,
  onPlace,
  onDelete,
  onCopy
}: {
  orders: OrderModel[];
  selectedOrders: OrderModel[];
  setSelectedOrders(orders: OrderModel[]): void;
  setCurrentOrder(order: OrderModel): void;
  setOrderModalType: Dispatch<SetStateAction<OrderModalType | undefined>>;
  onPlace(orders: OrderModel[]): void;
  onDelete: VoidFunction;
  onCopy(source: OrderModel, target: OrderModel): Promise<boolean>;
}) => {
  const [str] = useLocalisation();

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'noExport',
        headerComponent: () => {
          const editableOrders = orders.filter(o => !o.isReadOnly);

          return (
            <Checkbox
              id="headerCheckbox"
              checked={editableOrders.length === selectedOrders.length}
              onChange={() => {
                setSelectedOrders(editableOrders.length === selectedOrders.length ? [] : editableOrders);
              }}
              extraPadding
            />
          );
        },
        cellRenderer: ({ data }: { data: OrderModel }) => (
          <span style={{ display: 'flex' }}>
            <Checkbox
              id={data.id}
              disabled={data.isReadOnly}
              checked={selectedOrders.includes(data)}
              onChange={() => {
                setSelectedOrders(
                  selectedOrders.includes(data)
                    ? selectedOrders.filter(p => p.id !== data.id)
                    : [...selectedOrders, data]
                );
              }}
              extraPadding
            />
          </span>
        ),
        width: 60,
        resizable: false
        // FIXME: This causes the column to not render after data refresh
        // pinned: 'left' as const
      },
      {
        field: 'details.displayName',
        headerName: str('Landing.order.table.orderName'),
        cellRenderer: ({ value, data }: { value: string; data: { slug: string; id: string } }) => (
          <OrderLink to={data.slug}>{value}</OrderLink>
        ),
        flex: 2,
        minWidth: 200
      },
      {
        field: 'details.assortmentName',
        headerName: str('Landing.order.table.finalAssortment'),
        flex: 1,
        minWidth: 160
      },
      {
        field: 'locations',
        headerName: str('Landing.order.table.location'),
        // FIXME: This doesn't export well in Excel. Figure out how to fix.
        valueGetter: ({ data }: { data: OrderModel }) => Object.values(data.details.locations ?? {}),
        cellRenderer: ({
          value: allLocations
        }: {
          value: readonly { orderLocationId: string; displayName: string }[];
        }) => {
          const [firstThree, rest] = [allLocations.slice(0, 3), allLocations.slice(3)];
          return (
            <OrderLocations>
              {firstThree.map(location => (
                <Hint key={location.orderLocationId} of={<OrderLocation>{location.displayName}</OrderLocation>}>
                  <OrderLocationHint>{location.displayName}</OrderLocationHint>
                </Hint>
              ))}
              {!!rest.length && (
                <Hint of={<OrderLocation>({str('general.moreCount', { count: rest.length })})</OrderLocation>}>
                  {rest.map(location => (
                    <OrderLocationHint key={location.orderLocationId}>{location.displayName}</OrderLocationHint>
                  ))}
                </Hint>
              )}
            </OrderLocations>
          );
        },
        flex: 2,
        minWidth: 160
      },
      {
        field: 'details.createdAt',
        headerName: str('Landing.order.table.orderCreated'),
        valueFormatter: ({ value }) => {
          if (!value) return '';
          return format(value, 'MMM dd, yyyy');
        },
        initialSort: 'desc',
        flex: 1,
        minWidth: 140
      },
      {
        field: 'details.breakdown.pc9s',
        headerName: str('Landing.order.table.products'),
        valueFormatter: ({ value }) => value ?? '-',
        flex: 1,
        minWidth: 150
      },
      {
        field: 'details.breakdown.units',
        headerName: str('Landing.order.table.units'),
        valueFormatter: ({ value }) => value ?? '-',
        flex: 1,
        minWidth: 120
      },
      {
        field: 'details.breakdown.rrp',
        headerName: str('Landing.order.table.price'),
        valueFormatter: ({ value, data }) => formatPrice(data.details.currency, value) ?? '-',
        flex: 1,
        minWidth: 120
      },
      {
        field: 'status',
        headerName: str('Landing.order.table.status'),
        cellRenderer: ({ value }: { value: any }) => {
          return <StatusCapsule status={value ?? OrderStatus.New} />;
        },
        flex: 1,
        minWidth: 120
      },
      {
        field: 'noExport',
        headerName: '',
        cellRenderer: ({ data }: { data: any }) => (
          <ButtonsWrapper>
            <IAM action="orders.edit" prefix={'customers'}>
              <Hint
                of={
                  <Button
                    onClick={() => {
                      setCurrentOrder(data);
                      setOrderModalType('edit');
                    }}
                  >
                    <Edit />
                  </Button>
                }
              >
                {str('Order.modal.edit.title')}
              </Hint>
            </IAM>
          </ButtonsWrapper>
        ),
        sortable: false,
        resizable: false,
        width: 80
      }
    ],
    [str, orders, selectedOrders, setSelectedOrders, setCurrentOrder, setOrderModalType]
  );

  const { canNotUse } = useIAM();
  const contextMenuItems = useCallback(
    (params: GetContextMenuItemsParams<OrderModel>): any => {
      const targetOrder = params.node?.data;
      if (!targetOrder) return [];

      const hasDisabledEditing = targetOrder.isReadOnly || canNotUse('orders.edit', 'customers');

      return [
        {
          name: str('general.edit'),
          disabled: hasDisabledEditing,
          action: () => {
            setCurrentOrder(targetOrder);
            setOrderModalType('edit');
          }
        },
        {
          name: str('Order.sizing.pasteFrom'),
          disabled: hasDisabledEditing,
          subMenu: orders
            .filter(order => order !== targetOrder)
            .map(sourceOrder => ({
              name: sourceOrder.details.displayName,
              disabled: !sourceOrder.details.breakdown.units,
              action: async () => {
                await onCopy(sourceOrder, targetOrder);
              }
            }))
        },
        {
          name: str('Landing.order.footer.placeOrders', { count: 1 }),
          disabled:
            targetOrder.isReadOnly || !canNotUse('orders.place', 'customers') || !targetOrder.details.breakdown.units,
          action: () => {
            onPlace([targetOrder]);
          }
        },
        {
          name: str('general.delete'),
          disabled: targetOrder.isReadOnly || canNotUse('orders.delete', 'customers'),
          action: () => {
            setCurrentOrder(targetOrder);
            onDelete();
          }
        }
      ];
    },
    [canNotUse, onCopy, onDelete, onPlace, orders, setCurrentOrder, setOrderModalType, str]
  );

  const exportColumns = useMemo(
    () => columnDefs.map(c => c.field as string).filter(c => c !== 'noExport'),
    [columnDefs]
  );

  return (
    <Wrapper>
      <AgListView
        data={orders}
        columns={columnDefs}
        exportColumns={exportColumns}
        contextMenuItems={contextMenuItems}
      />
    </Wrapper>
  );
};
