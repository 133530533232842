import styled from 'styled-components';

import { AuditAssortmentProductRemove, AuditLineProductRemove } from '@yourxx/types';

import { Button } from '../../Button';
import { auditCardTs, AuditPanelEvents } from '../EventCards';
import { productHeading } from './AuditProductEditCard';

const RemovedProductCard = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

type Event = AuditPanelEvents<AuditLineProductRemove | AuditAssortmentProductRemove>;
export const AuditRemovedProductCard = (event: Event) => {
  return (
    <RemovedProductCard>
      <div>
        {auditCardTs(event)}
        <h4>
          {event.by?.initials} removed {productHeading(event.products)}
        </h4>
        <span>{event.products.map(p => p.pc9).join(', ')}</span>
      </div>
      {event.buttonLabel && <Button onClick={event.buttonCallback}>{event.buttonLabel}</Button>}
    </RemovedProductCard>
  );
};
