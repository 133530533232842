import {
  ApiDeleteOrderProduct,
  ApiGetOrderProduct,
  ApiGetOrderProductOut,
  ApiUpdateOrderProductParams,
  orderApiEndpoints,
  UpdateOrderProduct
} from '@yourxx/types';

import { toRequest } from '../../';
import { apiClient } from '../client';

type ApiUpdateDeleteProductOut = { message: 'success' };

export const getOrderProduct = async (params: Required<ApiGetOrderProduct>): Promise<ApiGetOrderProductOut> => {
  const request = toRequest({ path: orderApiEndpoints.orderProduct, params, method: 'GET' });
  return apiClient.request<ApiGetOrderProductOut>(request);
};

export const deleteOrderProduct = async (params: ApiDeleteOrderProduct): Promise<ApiUpdateDeleteProductOut> => {
  const request = toRequest({ path: orderApiEndpoints.orderProduct, params, method: 'DELETE' });
  return apiClient.request<ApiUpdateDeleteProductOut>(request);
};

type updateOrderProductIn = { params: ApiUpdateOrderProductParams; products: UpdateOrderProduct[] };
export const updateOrderProduct = async ({
  params,
  products
}: updateOrderProductIn): Promise<ApiUpdateDeleteProductOut> => {
  const request = toRequest({ path: orderApiEndpoints.orderProduct, params, method: 'POST', body: { products } });
  return apiClient.request<ApiUpdateDeleteProductOut>(request);
};
