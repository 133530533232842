import { useEffect, useState } from 'react';

export const useLocalStorageState = <T>(defaultValue: T, lsKey: string) => {
  const [state, setState] = useState<T>(() => {
    return JSON.parse(localStorage.getItem(lsKey) ?? JSON.stringify(defaultValue));
  });

  useEffect(() => {
    localStorage.setItem(lsKey, JSON.stringify(state));
  }, [lsKey, state]);

  return [state, setState] as const;
};
