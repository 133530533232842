import { Eye, Refresh, SpinnerAlt } from 'assets/icons';
import { SlideInOut } from 'components/SlideInOut';
import { format } from 'date-fns';
import { Hint } from 'pages/CommonLayout/components/Hint';
import { useLocalisation } from 'providers';
import { Fragment } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { AuditAssortmentProductRemove, AuditEventsFor } from '@yourxx/types';
import { getAuditEvents } from '@yourxx/ui-utils';

import { EventCards } from './EventCards';

export const StyledSlideInOut = styled(SlideInOut)`
  height: 100%;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${themed('spacing.l')};
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
`;
export const Card = styled.div<{
  $isNew?: boolean;
  $display: 'block' | 'flex';
  $hasGroupDate?: boolean;
  $isGreyedOut?: boolean;
}>`
  position: relative;
  display: ${({ $display }) => $display};
  align-items: center;
  gap: ${themed('spacing.m')};
  background-color: ${({ $isGreyedOut }) => ($isGreyedOut ? themed('color.greyLight') : themed('color.white'))};
  border-radius: ${themed('borderRadius')};
  padding: ${rem(14, 10)};
  margin-bottom: ${themed('spacing.m')};
  border-left-style: solid;
  border-left-width: ${({ $isNew }) => rem($isNew ? 3 : 0)};
  border-left-color: ${themed('color.green')};
  margin-top: ${({ $hasGroupDate }) => ($hasGroupDate ? themed('spacing.xl') : 0)};
  will-change: border-left-width;
  transition-property: border-left-width;
  transition-timing-function: linear;
  transition-duration: ${themed('transition.duration')};

  img {
    width: ${rem(60)};
  }

  h4 {
    margin: ${themed('spacing.s')} 0;
    ${themed('typography.h3')};
    font-weight: ${themed('font.weight.semiBold')};
  }

  span {
    ${themed('typography.h4')};
    font-weight: ${themed('font.weight.light')};
  }

  > div {
    display: -webkit-box;
    flex-grow: 1;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: auto;
  }

  p {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.light')};

    span {
      font-weight: ${themed('font.weight.semiBold')};
    }
  }

  button {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.medium')};
    padding: 0;

    svg {
      width: ${rem(24)};
      height: ${rem(24)};
    }
  }
`;
export const GroupDate = styled.span`
  position: absolute;
  left: 0;
  top: ${rem(-20)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const RefreshButton = styled(Refresh)`
  align-self: center;
  border-radius: ${themed('spacing.s')};
  cursor: pointer;
`;
export const HideAssortEvents = styled(Eye)`
  align-self: center;
  border-radius: ${themed('spacing.s')};
  cursor: pointer;
`;
export const StyledLoader = styled(SpinnerAlt)`
  margin-left: ${themed('spacing.m')};
  width: ${rem(16)};
  height: ${rem(16)};
`;

type AuditTrailProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onAddBack?: (products: AuditAssortmentProductRemove['products']) => void;
} & AuditEventsFor;

const ts14Days = 1000 * 60 * 60 * 24 * 14;

export const AuditTrail = ({ isOpen, onClose, onAddBack, ...params }: AuditTrailProps) => {
  const queryClient = useQueryClient();
  const [str] = useLocalisation();

  const { isLoading, data: eventGroups } = useQuery({
    queryKey: ['getAuditEvents', params],
    queryFn: async () => {
      const endTs = Date.now();
      const startTs = endTs - ts14Days;
      return getAuditEvents({ ...params, endTs, startTs });
    }
  });

  return (
    <StyledSlideInOut
      heading={<>{str('AssortmentFinalization.auditTrail.title')}</>}
      isOpen={isOpen}
      onClose={onClose}
      width={350}
      headerControls={
        <Hint
          of={
            <span>
              <RefreshButton onClick={() => queryClient.invalidateQueries({ queryKey: ['getAuditEvents', params] })} />
            </span>
          }
        >
          {str('general.refresh')}
        </Hint>
      }
    >
      <Wrapper>
        {isLoading && <StyledLoader />}
        {!eventGroups?.length && !isLoading && <p>{str('AssortmentFinalization.auditTrail.noActivity')}</p>}
        {eventGroups && eventGroups.length > 0 && (
          <Virtuoso
            data={eventGroups}
            itemContent={(_, eventGroup) => (
              <Fragment key={eventGroup[0].id}>
                {eventGroup.map((event, i) => (
                  <Card $display="flex" key={event.id} $hasGroupDate={i === 0}>
                    {i === 0 && (
                      <GroupDate>
                        <span>{format(eventGroup[0].date, 'dd MMM yyyy')}</span>
                      </GroupDate>
                    )}
                    {EventCards[event.event]({
                      ...event,
                      ...(event.event === 'assortment_product_remove' && onAddBack
                        ? {
                            buttonLabel: str('AssortmentFinalization.auditTrail.addBack'),
                            buttonCallback: () => onAddBack(event.products)
                          }
                        : {})
                    })}
                  </Card>
                ))}
              </Fragment>
            )}
          />
        )}
      </Wrapper>
    </StyledSlideInOut>
  );
};
