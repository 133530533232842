import { Info as InfoIcon } from 'assets/icons';
import { Hint } from 'pages/CommonLayout/components';
import { useLocalisation } from 'providers';
import { css, styled } from 'styled-components';
import { rem, themed } from 'utils';

import type { ProductSizingTotals } from '../types';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${themed('spacing.m')};
  border-radius: ${themed('borderRadius')};
  padding: ${themed('spacing.m')} ${themed('spacing.xl')};
  background-color: ${themed('color.offWhiteLight')};
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.light')};
`;

const divider = css`
  content: '';
  display: inline-flex;
  width: ${rem(1)};
  height: ${rem(15)};
  background-color: ${themed('color.greyMid')};
`;
const Totals = styled.h4`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.s')};
  margin: 0;
  font-weight: ${themed('font.weight.semiBold')};
`;
const Metrics = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: ${themed('spacing.xxl')};
`;
const Metric = styled.li`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.s')};

  span {
    display: flex;
    align-items: center;
    gap: ${themed('spacing.s')};
  }

  span + span::before {
    ${divider};
  }

  span + span:last-child {
    color: #505050; // FIXME: Outside of theme
  }

  strong {
    font-weight: ${themed('font.weight.bold')};
  }

  & + & {
    position: relative;

    &::before {
      position: absolute;
      left: -${themed('spacing.l')};
      ${divider};
    }
  }
`;
const Info = styled.span`
  cursor: help;
  width: ${rem(16)};
  height: ${rem(16)};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: ${rem(12)};
    max-height: ${rem(12)};
  }
`;
const LocationRollups = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.s')};
`;
const LocationMetrics = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: ${themed('spacing.m')};
  ${themed('typography.h4')};

  & ${Metrics} {
    justify-self: end;
  }
`;
const Address = styled.span``;

export interface SizingTotalsProps extends ProductSizingTotals {
  perLocation: ({ address: string } & ProductSizingTotals)[];
}

export const SizingTotals = ({ price, units, perLocation }: SizingTotalsProps) => {
  const [str] = useLocalisation();
  return (
    <Container>
      <Totals>
        {str('Order.metrics.totals')}
        <Hint
          of={
            <Info>
              <InfoIcon />
            </Info>
          }
        >
          <LocationRollups>
            {perLocation.map(({ address, units, price }) => (
              <LocationMetrics key={address}>
                <Address>{address}: </Address>
                <Metrics>
                  <Metric>
                    <span>
                      {str('Order.metrics.units')} <strong>{units}</strong>
                    </span>
                  </Metric>
                  <Metric>
                    <span>
                      {str('Order.metrics.price')} <strong>{price}</strong>
                    </span>
                  </Metric>
                </Metrics>
              </LocationMetrics>
            ))}
          </LocationRollups>
        </Hint>
      </Totals>
      <Metrics>
        <Metric>
          <span>
            {str('Order.metrics.units')} <strong>{units}</strong>
          </span>
        </Metric>
        <Metric>
          <span>
            {str('Order.metrics.price')} <strong>{price}</strong>
          </span>
        </Metric>
      </Metrics>
    </Container>
  );
};
