import { DefaultMenuItem, GetContextMenuItems, ModuleRegistry, RowClickedEvent, themeQuartz } from 'ag-grid-community';
import {
  AllEnterpriseModule,
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  GridReadyEvent,
  LicenseManager
} from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { themed } from 'utils';

ModuleRegistry.registerModules([AllEnterpriseModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-070092}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Levi_Strauss_&_Co.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{YourXX}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{YourXX}_need_to_be_licensed___{YourXX}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_May_2026}____[v3]_[01]_MTc3NzkzNTYwMDAwMA==d368774474d29a4eea3d01eef35f116a'
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  button.export {
    margin-bottom: 5px;
    font-weight: bold;
  }
`;
const GridWrapper = styled.div`
  display: flex;
  flex: 1 1 0px;

  > div {
    width: 100%;
    height: 100%;
  }

  .ag-header-cell {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.bold')};
  }
  .ag-header-cell-comp-wrapper {
    justify-content: center;
  }
  .ag-header-cell-label {
    justify-content: center;
  }
  .ag-cell-wrapper {
    max-width: 100%;
  }
  .ag-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${themed('spacing.m')};
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.regular')};
    line-height: 1.4;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export type AGListViewProps = {
  data: any;
  columns: ColDef[];
  rowHeight?: number;
  onGridReady?: (params: GridReadyEvent) => void;
  excelExportParams?: ExcelExportParams;
  csvExportParams?: CsvExportParams;
  exportColumns?: string[];
  contextMenuItems?: GetContextMenuItems<any>;
  onRowClick?: (e: RowClickedEvent) => void;
};

export const AgListView = forwardRef<AgGridReact<any>, AGListViewProps>(
  (
    {
      data,
      columns,
      rowHeight,
      onGridReady,
      excelExportParams,
      csvExportParams,
      exportColumns,
      contextMenuItems,
      onRowClick
    },
    ref
  ) => {
    const localGridRef = useRef<AgGridReact<any>>(null);
    const gridRef = (ref ?? localGridRef) as React.RefObject<AgGridReact<any>>;

    const defaultExcelExportParams = useMemo<ExcelExportParams | undefined>(() => {
      if (!excelExportParams && !exportColumns) return undefined;
      return { ...excelExportParams, ...(exportColumns ? { columnKeys: exportColumns } : {}) };
    }, [excelExportParams, exportColumns]);

    const defaultCsvExportParams = useMemo<CsvExportParams | undefined>(() => {
      if (!csvExportParams && !exportColumns) return undefined;
      return { ...csvExportParams, ...(exportColumns ? { columnKeys: exportColumns } : {}) };
    }, [csvExportParams, exportColumns]);

    const extendedContextMenuItems = useMemo(() => {
      const defaultItems = [
        // 'copy', 'copyWithHeaders', 'separator',
        'excelExport',
        'csvExport'
      ] as DefaultMenuItem[];

      if (contextMenuItems) {
        return (params: any) => {
          const customItems = typeof contextMenuItems === 'function' ? contextMenuItems(params) : contextMenuItems;
          const iterableItems = Array.isArray(customItems) ? customItems : [];
          return [...iterableItems, ...(['separator', ...defaultItems] as DefaultMenuItem[])];
        };
      }
      return () => defaultItems;
    }, [contextMenuItems]);

    const customComparator = (valueA: any, valueB: any) => {
      if (valueA == null && valueB == null) return 0;
      if (valueA == null) return 1;
      if (valueB == null) return -1;
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    };

    return (
      <Container>
        <GridWrapper>
          <AgGridReact
            theme={themeQuartz}
            ref={gridRef}
            rowData={data}
            onGridReady={onGridReady}
            columnDefs={columns}
            rowHeight={rowHeight}
            gridOptions={{ suppressCellFocus: true, rowStyle: { cursor: onRowClick ? 'pointer' : 'auto' } }}
            defaultExcelExportParams={defaultExcelExportParams}
            defaultCsvExportParams={defaultCsvExportParams}
            getContextMenuItems={extendedContextMenuItems}
            onRowClicked={onRowClick}
            enableCellTextSelection
            defaultColDef={{
              resizable: true,
              sortable: true,
              wrapText: true,
              autoHeight: true,
              suppressHeaderMenuButton: true,
              comparator: customComparator
            }}
          />
        </GridWrapper>
      </Container>
    );
  }
);
