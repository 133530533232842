import { Plus } from 'assets/icons';
import { Button } from 'components/Button';
import { IAM } from 'components/IAM';
import { ProductDetailsContext } from 'components/ProductDetails';
import { State } from 'pages/Products';
import { useLocalisation } from 'providers';
import { useContext } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { VideoPlayer, type VideoPlayerProps } from '../VideoPlayer';

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${themed('color.white')};

  video {
    position: relative;
    top: 0;
    left: 0;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    background-color: ${themed('color.white')};

    &::-webkit-media-controls-panel {
      background-image: none !important;
      filter: brightness(0.4);
    }

    &::-webkit-media-controls-fullscreen-button {
      display: none;
    }
  }
`;

const AddVideoPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${themed('color.greyMid')};

  button {
    min-height: ${rem(36)};
    border-radius: ${themed('borderRadius')};
    background-color: transparent;
    color: ${themed('color.black')};
    ${themed('typography.h4')};

    @media (hover: hover) {
      &:hover {
        background-color: ${themed('color.black')};
        color: ${themed('color.white')};

        svg path {
          stroke: ${themed('color.white')};
        }
      }
    }
  }
`;

export const Video = ({
  src,
  posterSrc,
  'data-testId': testId,
  onPlay,
  onPause,
  onEnd
}: VideoPlayerProps & {
  'data-testId'?: string;
}) => {
  const [str] = useLocalisation();
  // TODO: Refactor with custom hook
  const state = useContext(ProductDetailsContext);

  return (
    <VideoContainer data-testid={testId}>
      {src ? (
        <VideoPlayer src={src} posterSrc={posterSrc} onPlay={onPlay} onPause={onPause} onEnd={onEnd} />
      ) : (
        <IAM action={`edit.video`}>
          <AddVideoPanel>
            <Button
              variant="secondary"
              onClick={() => {
                if (state?.state === State.Empty) state.addVideo();
              }}
            >
              <Plus />
              {str('PDP.labels.addVideo')}
            </Button>
          </AddVideoPanel>
        </IAM>
      )}
    </VideoContainer>
  );
};
