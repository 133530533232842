import { Footer, IAM } from 'components';
import { Button } from 'components/Button';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import { OrderStatus } from 'services';
import styled from 'styled-components';
import { themed } from 'utils';

const OrdersButton = styled(Button)`
  padding: ${themed('spacing.m')} !important;
`;
const DeleteButton = styled(OrdersButton)`
  color: ${themed('color.red')};
`;

export const StatusBreakdown = <T extends { status: OrderStatus }>({ orders }: { orders: readonly T[] }) => {
  const [str] = useLocalisation();

  const statuses = useMemo(
    () =>
      orders.reduce(
        (accumulator, order) => {
          if (order.status) {
            accumulator[order.status] = (accumulator[order.status] || 0) + 1;
          }
          return accumulator;
        },
        {} as Record<OrderStatus, number>
      ),
    [orders]
  );

  return (
    <>
      {Object.entries(statuses).map(([key, count], i, arr) => {
        const divider = i === arr.length - 1 ? '' : i === arr.length - 2 ? ` ${str('general.and')}` : ',';

        return (
          <span key={key}>
            {count} {str(`Order.status.${key as OrderStatus}`)}
            {divider}
          </span>
        );
      })}
    </>
  );
};

export const OrderFooter = <T extends { status: OrderStatus }>({
  orders,
  selectedOrders,
  onDeselectAll,
  placeOrders,
  deleteOrders
}: {
  orders: readonly T[];
  selectedOrders: number;
  onDeselectAll: VoidFunction;
  placeOrders?: VoidFunction;
  deleteOrders?: VoidFunction;
}) => {
  const [str] = useLocalisation();

  // FIXME: Use the new layout slotted footer.
  return (
    <Footer
      items={orders}
      selectedItems={selectedOrders}
      onDeselectAll={onDeselectAll}
      breakdown={<StatusBreakdown orders={orders} />}
      leftActionButtons={
        <IAM action="orders.delete" prefix={'customers'}>
          {deleteOrders && (
            <DeleteButton onClick={deleteOrders}>
              {str('Landing.order.footer.deleteOrders', { count: selectedOrders })}
            </DeleteButton>
          )}
        </IAM>
      }
      rightActionButtons={
        selectedOrders > 0 && (
          <IAM action="orders.submit" prefix={'customers'}>
            {placeOrders && (
              <OrdersButton variant="primary" onClick={placeOrders}>
                {str('Landing.order.footer.placeOrders', { count: selectedOrders })}
              </OrdersButton>
            )}
          </IAM>
        )
      }
    />
  );
};
