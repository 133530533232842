import { AuditEvent } from '../../audit';
import { GetUserAuditDetails } from '../base/user';

export type AuditEventParams = { startTs: number; endTs?: number } & AuditEventsFor;
export type AuditEventsFor = OneOf<AuditEventFilters>;
export type OneOf<T> = { [K in keyof T]-?: Pick<T, K> & Partial<T> }[keyof T];
export type AuditEventFilters = {
  orderId?: string;
  assortmentId?: string;
  lineId?: string;
};

export type ApiGetAuditEvents = {
  events: ApiAuditEvent[];
  users: Record<string, GetUserAuditDetails>;
};
export const auditApiEndpoints = {
  audit: `/v2/audit/events`
} as const;

export type ApiAuditEvent<T = AuditEvent> = { id: string; date: string; ts: number } & AuditEvent<T>;
