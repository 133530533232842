import { createContext } from 'react';
import type { OrderModel, OrderSizingClipboard, OrderSizingManager, OrdersManager } from 'services';

import type { Nullable } from '@yourxx/support';
import type { CustomerAssortmentProps, CustomerBillTo, CustomerShipTo, CustomerSoldTo } from '@yourxx/types';

import type { OrderDetails, OrderSizing } from './types';

export interface OrdersContextValue extends OrdersManager {
  brand: string;
  customerId: string;
  customerName: string;
  finalAssortments: CustomerAssortmentProps[];
  shipToLocations: CustomerShipTo[];
  billToLocations: CustomerBillTo[];
  soldToLocations: CustomerSoldTo[];
  loadCustomerOrders(season: string): Promise<readonly OrderModel[]>;
  loadOrderDetails(slugOrOrderId: string): Promise<OrderDetails>;
  loadOrderSizing(orderId: string): Promise<OrderSizing>;
  copyOrderSizing(source: OrderModel, target: OrderModel): Promise<boolean>;
  isLoading: boolean;
  manager: OrderSizingManager;
  clipboard: OrderSizingClipboard;
}

export const OrdersContext = createContext<Nullable<OrdersContextValue>>(null);
