import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { equals, toggleArrayValue } from '@yourxx/support';

interface UseSelectMonthsProps<Month = string> {
  months?: readonly Month[];
  allowMultiple?: boolean;
}

interface UseSelectMonthsReturn<Month = string> {
  selectedMonths: Month[];
  selectMonth(...monthIds: Month[]): void;
  replace(...monthIds: Month[]): void;
}

const key = 'months' as const;
const delimiter = '_'; // Encoded in the URL remains unchanged.
const encode = <T extends string>(months: T[]) => months.join(delimiter);
const decode = (encoded: string) => encoded.split(delimiter).filter(Boolean);

export const useSelectMonths = <M extends string>({
  months = [],
  allowMultiple = true
}: UseSelectMonthsProps<M> = {}): UseSelectMonthsReturn<M> => {
  const [params, setParams] = useSearchParams();
  const value = params.get(key) ?? '';
  const state = useMemo(() => decode(value).sort() as M[], [value]);

  const setState = useCallback(
    (...monthIds: M[]) => {
      setParams(
        prev => {
          const updated = new URLSearchParams(prev);
          const parsed = decode(updated.get(key) ?? '') as M[];

          if (equals(monthIds, parsed)) return updated;

          const encoded = encode<M>(allowMultiple ? toggleArrayValue(monthIds, parsed).sort() : [monthIds[0]]);
          if (!encoded) updated.delete(key);
          else updated.set(key, encoded);
          return updated;
        },
        { replace: true }
      );
    },
    [allowMultiple, setParams]
  );

  useEffect(() => {
    if (!allowMultiple && state.length > 1) setState(state[0]);
  }, [allowMultiple, setState, state]);

  useEffect(() => {
    if (!state.length && months.length) {
      setState(...months);
    }
  }, [months, setState, state.length]);

  const replace = useCallback(
    (...monthIds: M[]) => {
      setParams(
        prev => {
          const updated = new URLSearchParams(prev);
          updated.delete(key);
          const encoded = encode<M>([...monthIds].sort());
          updated.set(key, encoded);
          return updated;
        },
        { replace: true }
      );
    },
    [setParams]
  );

  return {
    selectedMonths: state,
    selectMonth: setState,
    replace
  };
};
