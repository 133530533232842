import { MotionOutlet } from 'components';
import { SlotId, Toolbar } from 'pages';
import { Slot } from 'providers';
import { styled } from 'styled-components';
import { themed } from 'utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  padding: 0 0 ${themed('spacing.xxxl')};
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.xxl')};
  padding: ${themed('spacing.l')} 0;

  > * {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
  }
  > div:nth-child(2) {
    justify-content: flex-end;
  }
`;

export const OrderLayout = () => (
  <Container>
    <Top>
      <Slot id={SlotId.OrderTotals} />
      <Slot id={SlotId.OrderToolbar} Container={Toolbar.Container} Wrapper={Toolbar.ItemWrapper} />
    </Top>
    <MotionOutlet animate />
  </Container>
);
