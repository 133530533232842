import type { OrderSizingRecords } from './OrderSizingManager';

/**
 * We're storing each PC9s sizing history per order id in this global object, along with the computed state for each.
 * The computed state represents a reduce over history entries based on top of existing API data (essentially event sourcing style).
 */
export const records: OrderSizingRecords = {
  history: {},
  computed: {}
};

/**
 * For debugging purposes we expose this to the Browser's console.
 * We're not storing any sensitive data here, so it's safe.
 */
Object.defineProperty(window, 'sizingRecords', {
  enumerable: true,
  value: records,
  writable: false,
  configurable: false
});
