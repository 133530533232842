import type { LocalisedStringFor } from 'providers';
import { useCallback } from 'react';
import { useEventSubscriptions } from 'utils';

import type { EventBus } from '../EventBus';
import { ToastRequested } from '../ToastService';
import { CopyAssortmentFailed, CopyAssortmentSuccessful } from './events';

export const useRequestToastsOnCopyAssortment = ({
  eventBus,
  str
}: {
  eventBus: EventBus;
  str: LocalisedStringFor;
}) => {
  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(CopyAssortmentSuccessful, event =>
          eventBus.emit(
            ToastRequested.success(
              str('Assortment.Copy.toast.success', { count: event.payload.command.targetCustomers.length })
            ).trace(event)
          )
        ),

        eventBus.on(CopyAssortmentFailed, event =>
          eventBus.emit(
            ToastRequested.error(<div title={event.payload.reason}>{str('Assortment.Copy.toast.failure')}</div>).trace(
              event
            )
          )
        )
      ],
      [eventBus, str]
    )
  );

  return null;
};
