import { Entity, type EntityContext, type SidePanelId } from 'domain-events';
import { useCallback } from 'react';
import { Defect, useServices } from 'services';

import { AuditAssortmentProductRemove } from '@yourxx/types';

import { AuditTrail } from '../../../components/AuditTrail/AuditTrail';
import type { UseSidePanelReturn } from './useSidePanel';

export const useAssortmentAuditTrailUI = ({
  context,
  sidePanel
}: {
  context: EntityContext | undefined;
  sidePanel: UseSidePanelReturn<SidePanelId>;
}) => {
  const { productsService, toastService } = useServices();
  const isLine = context?.entity === Entity.Line;
  const id =
    context?.entity === Entity.Assortment || context?.entity === Entity.FinalAssortment || isLine
      ? context.id
      : undefined;

  const onClose = useCallback(() => sidePanel.set('auditTrail'), [sidePanel]);
  const onAddBack = useCallback(
    (products: AuditAssortmentProductRemove['products']) => {
      if (!id) throw new Defect('Cannot add product back to assortment: assortment id is not available.');
      if (!context) throw new Defect('Cannot add product back to assortment: context is not available.');
      if (!id || !context || !products) return;

      productsService
        .add({ context, items: products })
        .catch(error => toastService.send(<>{error instanceof Error ? error.message : `${error}`}</>, 'error'))
        .then(() => toastService.send(<>Product successfully added back into the assortment.</>, 'success'));
    },
    [id, context, productsService, toastService]
  );

  const params = isLine ? { lineId: id } : { assortmentId: id };

  return <AuditTrail isOpen={sidePanel.id === 'auditTrail'} onClose={onClose} onAddBack={onAddBack} {...params} />;
};
