import { Arrow } from 'assets/icons';
import { useLocalisation, useSlot } from 'providers';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { relativePath, rem, themed } from 'utils';

import { SlotId } from '../../../SlotId';

const BackToProductList = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${rem(6)};
  color: ${themed('color.black')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.regular')};
  text-decoration: none;

  svg {
    width: ${rem(16)};
    transform: rotateY(180deg);
  }

  @media (hover: hover) {
    &:hover {
      color: ${themed('color.grey')};

      svg path {
        fill: currentColor;
      }
    }
  }
`;

export const BackToProductsListLink = () => {
  const [str] = useLocalisation();

  useSlot(
    SlotId.OrderTotals,
    <BackToProductList to={relativePath('..')}>
      <Arrow /> {str('Order.sizing.backButtonLabel')}
    </BackToProductList>
  );

  return null;
};
