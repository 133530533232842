import { useContextfulSearch } from 'components/Header/ContextfulSearchProvider';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { type OrderModel } from 'services';

import { createSearchFn } from '@yourxx/support';

export interface UseOrdersSearchProps {
  contextId: string;
  orders: readonly OrderModel[];
}

export const useOrdersSearch = ({ contextId, orders }: UseOrdersSearchProps) => {
  const navigate = useNavigate();
  const { searchTerm, addSuggestions } = useContextfulSearch({ contextId });
  const searchedOrders = useMemo(() => (searchTerm ? searchOrders(searchTerm, orders) : null), [orders, searchTerm]);

  useEffect(() => {
    if (!searchedOrders?.length) return;

    addSuggestions(
      searchedOrders.slice(0, 20).map(result => ({
        label: result.item.details.displayName,
        onSelect: () => navigate(result.item.slug)
      }))
    );
  }, [addSuggestions, navigate, searchedOrders]);
};

const searchOrders = createSearchFn<OrderModel>([
  { meta: 'Slug', getter: o => o.slug },
  { meta: 'DisplayName', getter: o => o.details.displayName }
]);
