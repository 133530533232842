import { SpinnerAlt } from 'assets/icons';
import { Dialog } from 'components/Dialog';
import { useEffect } from 'react';
import { ToastRequested, useServices } from 'services';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { useLocalisation } from '../../LocalisationProvider';

const Container = styled.div`
  & [class^='Dialog__Content'] {
    background-color: ${themed('color.white')};
  }

  button {
    display: none;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${themed('spacing.l')};
  min-height: ${rem(288)};
  min-width: ${rem(552)};
  background-color: ${themed('color.white')};

  h2 {
    margin: 0;
    ${themed('typography.h1')}
    text-transform: uppercase;
  }

  p {
    margin: 0;
    ${themed('typography.h4')}
  }
`;

export const PendingChangesDialog = ({ isLoading }: { isLoading: boolean }) => {
  const [str] = useLocalisation();
  const { eventBus } = useServices();

  useEffect(() => {
    return () => {
      if (!isLoading) eventBus.emit(ToastRequested.success(str('general.unsavedChangesDone')));
    };
  }, [eventBus, isLoading, str]);

  return (
    <Container>
      <Dialog
        title=""
        onClose={() => undefined}
        content={
          <Content>
            <h2>{str('general.unsavedChangesTitle')}</h2>
            <p>{str('general.unsavedChangesDescription')}</p>
            <SpinnerAlt />
          </Content>
        }
      />
    </Container>
  );
};
