import { PgData } from '../../dao/assortment';
import { BasicOrderDetails, ProductSizeIdMap } from '../../dao/order';
import { CmApiData } from './customer';

export type ApiAddDeleteProductIn = { id: string; pc9s: string[] };
export type ApiUpdateProductIn = { id: string; products: UpdateProductDetails[] };
export type ApiUpdateDeleteProductOut =
  | ApiUpdateDeleteSuccessBody
  | ApiUpdateDeleteErrorsBody
  | ApiUpdateDeleteErrorBody
  | (ApiUpdateDeleteSuccessBody & ApiUpdateDeleteErrorsBody);
export type ApiUpdateDeleteSuccessBody = { products: { pc9: string }[] };
export type ApiUpdateDeleteErrorsBody = { errors: { pc9: string; error: string }[] };
export type ApiUpdateDeleteErrorBody = { error: string };

export type ApiGetProductIn = ProductQueryStringParameters;
export type StoryImageUrlMap = { [key: string]: string };
export type ApiGetProductOut<T = ProductDetails> = {
  details: PageDetails;
  orderDetails?: BasicOrderDetails;
  products?: T[];
  storyImages?: StoryImage[];
};

export const ProductView = {
  List: 'list',
  Slider: 'slider',
  Details: 'details'
} as const;

export type ProductQueryStringParameters = {
  view: (typeof ProductView)[keyof typeof ProductView];
  id?: string;
  pricing_group_id?: string;
  parent?: 'true' | 'false';
  product_status?: string;
  // FIXME: IGNORED BY THE API.
  status?: string[] | string;
  pc9?: string[] | string;
};

export type StoryImage = {
  title: string;
  imageUrl?: string;
};

export type UIImage = {
  imageType: string;
  url: string;
  size?: number;
};

export type UIProduct<T> = T & {
  url: string;
  currency?: string;
  imageUrls?: UIImage[];
  storyImages?: StoryImage[];
};

export type ProductSize = [string, string?];
export type ProductSizes = ProductSize[];

export type ProductDetails = ProductListData | ProductSliderData | ProductDetailsData;
export type ExtraPageParams = {
  cutOffDate?: string;
  imageUrl: string;
  imageUrlParams: string;
  seasonH: string;
  orderedStories?: string[];
};

export type PageDetails = PgData & CmApiData & ExtraPageParams;

export type ProductSliderData = {
  spId: string;
  name: string;
  pc9: string;
  newness: string;
};

export type EditableData = {
  firstOnFloor?: string;
  lastOnFloor?: string;
  newness?: string;
  ranking?: number;
  mandatory?: boolean;
  vlp?: number;
};

export type IsDraftValue = {
  firstOnFloor: boolean;
  lastOnFloor: boolean;
  newness: boolean;
  ranking: boolean;
  mandatory: boolean;
  vlp: boolean;
};

export type InheritedData = {
  firstOnFloor?: string;
  lastOnFloor?: string;
  newness?: string;
  ranking?: number;
  mandatory?: boolean;
  vlp?: number;
  note?: NoteField;
  isDraft?: IsDraftValue;
  phasing?: {
    start?: string;
    end?: string;
  };
};

export type ProductListData = InheritedData & {
  spId: string;
  name: string;
  pc9: string;
  pcgId: string;
  pId: string;
  wsp?: number;
  rrp?: number;
  story?: string[];
  status: string;
  statusDraft: string;
  gender: string;
  seasonality?: string;
  distribution?: string[];
  replen?: string;
  segmentation?: string[];
  category0?: string;
  category1?: string;
  category2?: string;
  color?: string;
  pricePos?: string;
  sustainability?: string[];
  fabric?: string[];
  finish?: string;
  mfpCategory?: string;
  replacedByPc9?: string;
  sellThrough?: number;
  rateOfSale?: number;
  weeksOnSale?: number;
  deliveries?: ProductDeliveries[];
  storeGrades?: string[];
  lastUpdatedAt?: string;
  inFinalAssortments?: string[];
  statusParent?: string;
  parentNote?: NoteField;
};

export type ProductDetailsData = ProductListData & {
  sustainabilityStory?: string;
  logoExecution?: string;
  materials?: string;
  fitting?: string;
  isPgSizes?: boolean;
  sizes?: ProductSizes;
  details?: string;
  winAtMarketplace?: string[];
  csErrata?: string;
  plannedLifecycle?: string;
  priceStartDate?: Date | string;
  priceEndDate?: Date | string;
  images?: string[];
  replacerFinish?: string;
  video?: string;
  inColors?: string[];
  outColors?: string[];
  sizeIds?: ProductSizeIdMap;
};

export type ProductDeliveries = {
  type?: string;
  delivery_date?: string;
  quantity?: number;
};

export const AllDraftKeys: Readonly<Array<ProductDraftKeys>> = [
  'firstOnFloor',
  'lastOnFloor',
  'newness',
  'ranking',
  'sellThrough',
  'rateOfSale',
  'weeksOnSale',
  'note',
  'deliveries',
  'storeGrades',
  'vlpOrder',
  'replacedByPc9'
] as const;
export type ProductDraftKeys = keyof ProductDraft;

export type NoteField = {
  comment: string;
  updatedAt?: Date | string;
  author: string;
};

export type ProductDraft = {
  firstOnFloor?: string;
  lastOnFloor?: string;
  newness?: string;
  ranking?: string;
  sellThrough?: number;
  rateOfSale?: number;
  weeksOnSale?: number;
  note?: NoteField;
  parentNote?: NoteField;
  deliveries?: ProductDeliveries[];
  storeGrades?: string[];
  vlpOrder?: number;
  replacedByPc9?: string;
};

export type ApiUpdateProductQueryParams = {
  id: string;
  pc9: string;
};

export type ApiUpdateProductBody = {
  id?: string;
  products: UpdateProductDetails[];
};

export type UpdateProductDetails = UpdateAssortmentProduct | UpdateLineProduct;
export type UpdateAssortmentProduct = {
  pc9: string;
  status?: string;
  firstOnFloor?: string | null;
  lastOnFloor?: string | null;
  newness?: string | null;
  ranking?: number | null;
  vlp?: number | null;
  note?: string | null;
  rateOfSale?: number | null;
  sellThrough?: number | null;
  weeksOnSale?: number | null;
  deliveries?: ProductDeliveries[] | null;
  storeGrades?: string[] | null;
  video?: string | null;
  mandatory?: boolean;
};

export type UpdateLineProduct = {
  pc9: string;
  status?: string;
  firstOnFloor?: string;
  lastOnFloor?: string;
  newness?: string;
  ranking?: number;
  vlp?: number;
  note?: string;
  mandatory?: boolean;
  video?: string | null;
};

export type GetCountBySiblingAssortDetailsOut = {
  siblings?: { name: string; id: string }[];
};
