import { Dropdown } from 'components/Dropdown';
import { Image } from 'components/Image';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { pdpStoryTag, rem, themed } from 'utils';

import { ProductDetailsData, StoryImage, UIProduct } from '@yourxx/types';

const StoriesContainer = styled.div`
  padding: ${themed('spacing.l')} 0;
`;
const StoriesHeading = styled.div`
  display: flex;
  justify-content: space-between;
  place-items: center;
  min-height: ${themed('spacing.xxl')};

  h3 {
    margin: 0;
    ${themed('typography.h3')};
    font-weight: ${themed('font.weight.semiBold')};
  }
`;
const StoriesDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${themed('spacing.m')};
`;
const StoriesDropdownTrigger = styled.span`
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.semiBold')};
`;
const StoryCard = styled.div`
  box-sizing: border-box;
  background-color: ${themed('color.white')};
  overflow: hidden;
  border: solid ${rem(1)} ${themed('color.greyMid')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${themed('spacing.xxxl')};
  transition-property: border-color;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};

  @media (hover: hover) {
    a:hover & {
      border-color: ${themed('color.black')};
    }
  }

  span {
    overflow: hidden;
    display: -webkit-box;
    flex: 1;
    padding: 0 ${themed('spacing.m')};
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.regular')};
    line-height: ${rem(12)};
    text-transform: uppercase;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  > div,
  img {
    box-sizing: border-box;
    height: 100%;
    width: ${rem(70)};
    object-fit: cover;
  }
`;
const StoryLink = styled(Link)`
  color: inherit;
  text-transform: none;
  text-decoration: none;
`;

export const Stories = ({
  stories,
  navigationState,
  product,
  maxShownStories = 4
}: {
  navigationState: Record<string, any>;
  stories: (StoryImage & { storyUrl?: string })[];
  product: UIProduct<ProductDetailsData>;
  maxShownStories?: number;
}) => {
  const [str] = useLocalisation();
  const navigate = useNavigate();
  const { customerId, assortmentId: customerAssortmentId } = useParams();
  const [shownStories, restOfStories] = useMemo(
    () => [stories.slice(0, maxShownStories), stories.slice(maxShownStories, stories.length)],
    [maxShownStories, stories]
  );

  const onStoryCard = (story: StoryImage & { storyUrl?: string }) => {
    pdpStoryTag({
      storyName: story.title,
      productId: product.pId,
      assortmentId: customerAssortmentId,
      customerId: customerId
    });
  };

  return (
    <StoriesContainer>
      <StoriesHeading>
        <h3>{str('PDP.stories.present')}</h3>
        {restOfStories.length > 0 && (
          <Dropdown
            trigger={
              <StoriesDropdownTrigger>
                {str('PDP.stories.hiddenCount', { count: restOfStories.length })}
              </StoriesDropdownTrigger>
            }
            items={restOfStories.map(s => ({ key: s.title, label: s.title }))}
            onSelect={title => {
              const match = restOfStories.find(s => s.title === title);
              if (match?.storyUrl) navigate(match.storyUrl, { state: navigationState });
            }}
            maxHeight={100}
          />
        )}
      </StoriesHeading>
      <StoriesDetails>
        {shownStories.map(story => {
          const { imageUrl, storyUrl, title } = story;
          const storyCard = (
            <StoryCard key={title} onClick={() => onStoryCard(story)}>
              <span>{title}</span>
              {imageUrl && <Image src={imageUrl} />}
            </StoryCard>
          );

          if (!storyUrl) return storyCard;

          return (
            <StoryLink to={storyUrl} key={title} state={navigationState} title={title}>
              {storyCard}
            </StoryLink>
          );
        })}
      </StoriesDetails>
    </StoriesContainer>
  );
};
