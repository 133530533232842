export const apiEndpoints = {
  login: (action?: string) => {
    const searchParams = new URLSearchParams();
    if (action) {
      searchParams.append('action', action);
      return `/auth/login?${searchParams.toString()}`;
    }
    return `/auth/login`;
  },
  authToken: `/auth/token`,
  authRefresh: `/auth/refresh`,
  userProfile: `/user/profile`,
  canvas: `/v2/canvas`,
  canvasProduct: `/v2/canvas/products`,
  canvasProductDetails: `/v2/canvas/product/details`,
  canvasBoardItems: `/v2/canvas/board/items`,
  canvasBoardItem: `/v2/canvas/board/item`,
  canvasBoardShare: `/v2/canvas/board/share`,
  canvasCompareBoard: `/v2/canvas/board/compare`,
  canvasBoards: `/v2/canvas/boards`,
  canvasBoard: `/v2/canvas/board`,
  insightsProductDetails: `/v2/insights/product/details`,
  insightsGetForAssortment: ({ assortmentId }: { assortmentId: string }) => `/assortments/${assortmentId}/insights`,
  insightsCountForAssortment: ({ assortmentId }: { assortmentId: string }) =>
    `/assortments/${assortmentId}/insights/count`,
  insightsGetForCustomer: ({ customerId }: { customerId: string }) => `/customers/${customerId}/insights`,
  insightsCountForCustomer: ({ customerId }: { customerId: string }) => `/customers/${customerId}/insights/count`,
  insightsUpdate: ({ assortmentId, timestamp }: { assortmentId: string; timestamp: string }) =>
    `/assortments/${assortmentId}/insights/${timestamp}`,
  insightsAddProducts: ({ assortmentId }: { assortmentId: string }) => `/assortments/${assortmentId}/products`,
  insightsUploadStorySets: `/insights/upload/storysets`,
  assortmentProduct: ({ assortmentId, productId }: { assortmentId: string; productId: string }) =>
    `/assortments/${assortmentId}/products/${productId}`,
  assortmentProductUpdate: ({ assortmentId }: { assortmentId: string }) => `/assortments/${assortmentId}/products`,
  lines: ({ brand }: { brand: string }) => `/lines?brand=${brand}`,
  customerAssortments: ({ customerId }: { customerId: string }) => `assortments?customerId=${customerId}`,
  stories: ({
    assortmentId,
    offset,
    limit,
    hidden
  }: {
    assortmentId: string;
    offset: string;
    limit: string;
    hidden: string;
  }) => `/assortments/${assortmentId}/xstories?offset=${offset}&limit=${limit}&hidden=${hidden}`,
  story: ({ assortmentOrLineId, storyId }: { assortmentOrLineId: string; storyId: string }) =>
    `/assortments/${assortmentOrLineId}/xstories/${storyId}`,
  customers: `/v2/customer`,
  product: `/v2/product`,
  line: `/v2/line`,
  editAssortment: ({ assortmentId }: { assortmentId: string }) => `/v2/assortments/${assortmentId}`,
  copyAssortment: ({ assortmentId }: { assortmentId: string }) => `/v2/assortments/${assortmentId}/copy`,
  assortmentSummary: ({ assortmentId }: { assortmentId: string }) => `/assortments/${assortmentId}/summary`,
  editLine: ({ lineId }: { lineId: string }) => `/assortments/${lineId}`,
  assortments: '/v2/assortments',
  export: `/export`,
  video: ({ pc9 }: { pc9: string }) => `/products/${pc9}/video`,
  pricingGroups: ({ season }: { season: string }) => `/pricing-groups?season=${season}`
};
