import type { OrderSizingManager, OrderSizingRecords } from '../OrderSizingManager';
import {
  type OrderId,
  type OrderSizingCommand,
  type OrderSizingContext,
  type PC9,
  ProductSize,
  type SizingAdjustment,
  SizingMonths
} from '../OrdersService';
import { PC9Sizing } from '../PC9Sizing';
import { fallsWithinAvailabilityWindow } from '../utils';
import { computeState } from './computeState';
import { History } from './History';

export const createOrderSizingManager = ({
  records: { history, computed },
  onUpdate
}: {
  records: OrderSizingRecords;
  onUpdate?: (cmds: OrderSizingCommand[]) => void;
}): OrderSizingManager => {
  const onSizingUpdated = (cmds: OrderSizingCommand[], revertedAdjustments?: readonly SizingAdjustment[]) => {
    for (const { orderId, product } of cmds) {
      const { pc9 } = product;
      (computed[orderId] ??= {})[pc9] = computeState((history[orderId] ??= {})[pc9], computed[orderId][pc9]);
    }

    // Here we're getting the previous state, after the undo, in the form of adjustments to be sent off to persistence.
    if (revertedAdjustments?.length) {
      const revertedAdjustmentsForPersistence: SizingAdjustment[] = [];

      for (const [months, size] of revertedAdjustments) {
        const [d1, d2] = size;
        for (const month of months) {
          const valueAfterUndo = computed[cmds[0].orderId][cmds[0].product.pc9].state[month][d1][d2];
          revertedAdjustmentsForPersistence.push([SizingMonths(month), ProductSize(d1, d2), valueAfterUndo ?? null]);
        }
      }

      if (revertedAdjustmentsForPersistence.length)
        return onUpdate?.(cmds.map(cmd => ({ ...cmd, adjustments: revertedAdjustmentsForPersistence })));
    }

    onUpdate?.(cmds);
  };

  // TODO: Move this to OrderSizingRecords
  const historyFor = (orderId: OrderId, pc9: PC9) => {
    // Initialise if not found
    const baseState = (computed[orderId] ??= {})[pc9];
    return ((history[orderId] ??= {})[pc9] ??= History(baseState));
  };

  const manager: OrderSizingManager = {
    size: async (...cmds) => {
      let hasAnythingChanged = false;

      const stateChangingCommands: OrderSizingCommand[] = [];

      for (const cmd of cmds) {
        const adjustments = compatibleAdjustments(cmd.adjustments, cmd);
        if (historyFor(cmd.orderId, cmd.product.pc9).add(adjustments)) {
          hasAnythingChanged = true;
          stateChangingCommands.push({ ...cmd, adjustments });
        }
      }

      if (hasAnythingChanged) onSizingUpdated(stateChangingCommands);
      return hasAnythingChanged;
    },

    undo: async cmd => {
      const revertedAdjustments = historyFor(cmd.orderId, cmd.product.pc9).undo();
      onSizingUpdated([{ ...cmd, adjustments: [] }], revertedAdjustments);
    },

    canUndo: cmd => {
      return historyFor(cmd.orderId, cmd.product.pc9).canUndo;
    },

    redo: async cmd => {
      const reappliedAdjustments = historyFor(cmd.orderId, cmd.product.pc9).redo();
      if (reappliedAdjustments?.length) onSizingUpdated([{ ...cmd, adjustments: reappliedAdjustments }]);
    },

    canRedo: cmd => {
      return historyFor(cmd.orderId, cmd.product.pc9).canRedo;
    },

    clear: async (cmd, months) => {
      await manager.size({
        ...cmd,
        adjustments: Array.from(manager.sizingOf(cmd))
          .filter(([month]) => months?.includes(month) ?? true)
          .map<SizingAdjustment>(([month, d1, d2, _]) => {
            return [SizingMonths(month), ProductSize(d1, d2), null];
          })
      });
    },

    copy: async (source, ...targets) => {
      const adjustments = manager.sizingOf(source).toAdjustments();
      const commands = targets.map(target => ({
        ...target,
        adjustments: compatibleAdjustments(adjustments, target),
        isCopied: true
      }));
      return manager.size(...commands);
    },

    sizingOf: cmd =>
      PC9Sizing({
        ...cmd,
        version: history[cmd.orderId]?.[cmd.product.pc9]?.version ?? 0,
        values: computed[cmd.orderId]?.[cmd.product.pc9]?.state ?? {}
      }),

    unitsOf: cmd => {
      // TODO: Consider caching total count when version changes.
      let total = 0;
      for (const [, , , units] of manager.sizingOf(cmd)) if (units) total += units;
      return total;
    }
  };

  return manager;
};

const compatibleAdjustments = (
  adjustments: readonly SizingAdjustment[],
  target: OrderSizingContext
): SizingAdjustment[] => {
  const availability = target.product.availability;
  if (!availability) return [];

  const targetSizeGrid = target.product.sizeGrid;
  return adjustments.reduce<SizingAdjustment[]>((accum, [months, [d1, d2], units]) => {
    const validMonths = months.filter(month => fallsWithinAvailabilityWindow(month, availability));
    if (!validMonths.length) return accum;

    if (targetSizeGrid.supports(d1, d2)) accum.push([SizingMonths(...validMonths), ProductSize(d1, d2), units]);

    return accum;
  }, []);
};
