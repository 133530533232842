import { Nullable } from '@yourxx/support';

import type { PC9Availability } from '../OrderSizingManager/types';
import { parseMonthId } from './parseMonthId';

export const spreadPC9Availability = (availability: Nullable<PC9Availability>) => {
  if (!availability) return [];

  const [start, end] = availability.map(m => parseMonthId(Number(m)));
  const months: string[] = [];

  for (let m = start.month, y = start.year; m <= end.month || y < end.year; m++) {
    if (m > 12) {
      m = 1;
      y++;
    }
    months.push(`${y}${String(m).padStart(2, '0')}`);
  }

  return months;
};
