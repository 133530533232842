import { Info } from 'assets/icons';
import { Button, Checkbox, Dialog, IAM, Input, LoadingSpinner } from 'components';
import { DisplayedError } from 'components/DisplayedError';
import { Select } from 'components/Select';
import { useLocalisation } from 'providers';
import { useMemo, useState } from 'react';
import { Defect, type OrderModel, type OrdersManager } from 'services';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { CustomerAssortmentProps, type CustomerBillTo, CustomerShipTo, type CustomerSoldTo } from '@yourxx/types';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.l')};
  max-width: ${rem(650)};
`;
const EditSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${themed('spacing.m')};
  padding-bottom: ${themed('spacing.l')};
  margin-bottom: ${themed('spacing.s')};
  border-bottom: solid ${rem(1)} ${themed('color.greyMid')};

  button {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.regular')};
    padding: ${themed('spacing.s')} 0;

    &:last-of-type {
      color: ${themed('color.red')};
    }

    // If it's the only button, let it be right-aligned.
    &:last-child {
      margin-left: auto;
    }
  }
`;
const Label = styled.label`
  display: flex;
  flex-direction: column;

  input {
    margin: ${themed('spacing.m')} 0;
  }
`;
const StyledSelect = styled(Select)`
  .Select__value-container--is-multi {
    overflow: auto;
    max-height: ${rem(90)};
  }
`;
const CheckboxLabel = styled.label<{ $isVisible?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
  margin-bottom: ${rem(50)};

  span {
    font-size: ${themed('font.size.s')};
  }
`;
const Address = styled.div`
  margin: ${themed('spacing.m')} 0 0;
  ${themed('typography.h4')};
  color: ${themed('color.grey')};
`;

export type OrderModalType = 'create' | 'edit' | 'duplicate';

type OrderCreateEditModalProps = {
  isReadOnly?: boolean;
  type?: OrderModalType;
  order?: OrderModel;
  assortments: CustomerAssortmentProps[];
  preselectedAssortmentId?: string;
  shipToLocations: CustomerShipTo[] | undefined;
  billToLocations: CustomerBillTo[] | undefined;
  soldToLocations: CustomerSoldTo[] | undefined;
  onClose: VoidFunction;
  isLoading?: boolean;
} & Pick<OrdersManager, 'createOrder' | 'editOrder' | 'duplicateOrder' | 'deleteOrders'>;

type LocationOption<T> = { original: T; label: string; value: string };

export const OrderCreateEditModal = ({
  isReadOnly,
  type = 'create',
  order,
  assortments,
  preselectedAssortmentId,
  shipToLocations,
  billToLocations,
  soldToLocations,
  onClose,
  createOrder,
  editOrder,
  duplicateOrder: _duplicateOrder,
  deleteOrders
}: OrderCreateEditModalProps) => {
  const [str] = useLocalisation();
  const [assortment, setAssortment] = useState(
    order && assortments
      ? assortments.find(a => a.assortmentId === order.details.assortmentId)
      : assortments?.find(({ assortmentId }) => assortmentId === preselectedAssortmentId)
  );
  const isLoading = false; // TODO: Get from provider
  const isEditing = type === 'edit' && !!order;
  const isDuplicating = type === 'duplicate' && !!order;
  const [poNumber, setPoNumber] = useState(order?.details.poNumber ?? '');
  const [displayName, setDisplayName] = useState(
    isEditing ? order.details.displayName : isDuplicating ? `${order.details.displayName} (Copy)` : ''
  );
  const [orderPerLocation, setOrderPerLocation] = useState(false);

  const soldToOptions = useMemo(
    () =>
      soldToLocations?.map(location => ({
        original: location,
        value: location.id,
        label: locationDisplayName(location)
      })) ?? [],
    [soldToLocations]
  );
  const [selectedSoldToId, setSelectedSoldToId] = useState(order?.details.soldTo ?? soldToOptions[0]?.value);
  const selectedSoldToOption = useMemo(
    () => soldToOptions.find(({ original }) => original.id === selectedSoldToId),
    [selectedSoldToId, soldToOptions]
  );

  const billToOptions = useMemo(
    () =>
      billToLocations?.map(location => ({
        original: location,
        value: location.id,
        label: locationDisplayName(location)
      })) ?? [],
    [billToLocations]
  );
  const [selectedBillToId, setSelectedBillToId] = useState<string>(order?.details.billTo ?? billToOptions[0]?.value);
  const selectedBillToOption = useMemo(
    () => billToOptions.find(({ original }) => original.id === selectedBillToId),
    [billToOptions, selectedBillToId]
  );

  const shipToOptions = useMemo(
    () =>
      shipToLocations?.map(location => ({
        original: location,
        value: location.id,
        label: locationDisplayName(location)
      })) ?? [],
    [shipToLocations]
  );
  const [selectedShipToIds, setSelectedShipToIds] = useState<string[]>(
    isEditing ? Object.values(order?.details.locations ?? {}).map(l => l.shipTo ?? l.orderLocationId) : []
  );
  const selectedShipToOptions = useMemo<LocationOption<CustomerShipTo>[]>(() => {
    const selected: LocationOption<CustomerShipTo>[] = [];
    for (const id of selectedShipToIds) {
      const match = shipToOptions.find(({ value }) => value === id);
      if (match) selected.push(match);
    }
    return selected;
  }, [shipToOptions, selectedShipToIds]);

  const handleCreate = () => {
    if (!assortment) throw new Defect('Final Assortment was not selected for creating an order.');
    if (!selectedSoldToId) throw new Defect('Cannot create order without soldTo id.');

    createOrder({
      finalAssortment: { id: assortment.assortmentId, name: assortment.assortmentName },
      displayName,
      billTo: selectedBillToId,
      soldTo: selectedSoldToId,
      poNumber,
      locations: selectedShipToOptions.map(({ original }) => ({
        shipTo: original.id,
        displayName: locationDisplayName(original)
      })),
      orderPerLocation
    });
  };

  const handleUpdate = () => {
    if (!order) throw new Defect('Order missing when updating.');

    return editOrder?.({
      order,
      update: {
        displayName,
        billTo: selectedBillToOption?.original.id,
        poNumber,
        locations: selectedShipToOptions.map(({ original }) => {
          const existing = order.locationByShipTo(original.id);
          return {
            orderLocationId: existing?.orderLocationId,
            shipTo: original.id,
            displayName: locationDisplayName(original)
          };
        })
      }
    });
  };

  return (
    <Dialog
      title={str(`Order.modal.${type}.title`)}
      maxWidth={650}
      cancel={{ label: str('general.cancel'), handler: onClose }}
      confirm={{
        label: isLoading ? <LoadingSpinner /> : str(isEditing ? 'general.save' : 'general.create'),
        handler: () => (isEditing ? handleUpdate() : handleCreate()),
        disabled: isReadOnly || !assortment || !soldToLocations?.length || !displayName || isLoading
      }}
      onClose={onClose}
      content={
        <Content>
          {isEditing && (
            <EditSection>
              {/* TODO: Bring "Duplicate" back when UX flow is more refined (Bee wants this hidden for the time being). */}
              {/*<IAM action="orders.create">*/}
              {/*  <Button onClick={() => duplicateOrder(order)}>{str('Order.modal.duplicate.title')}</Button>*/}
              {/*</IAM>*/}
              <IAM action="orders.delete" prefix={'customers'}>
                <Button disabled={isReadOnly} onClick={() => deleteOrders([order])}>
                  {str('Order.modal.delete.title')}
                </Button>
              </IAM>
            </EditSection>
          )}
          <Label>
            <LabelText>
              {str('Order.modal.label.finalAssortment')}
              <MandatoryMark />
            </LabelText>
            <Select
              options={assortments.map(a => ({
                key: a.assortmentId,
                label: a.assortmentName
              }))}
              placeholder={str('Order.modal.label.finalAssortmentPlaceholder')}
              isDisabled={type !== 'create'}
              onChange={(option: any) => setAssortment(assortments.find(a => a.assortmentId === option.key))}
              value={
                assortment
                  ? {
                      key: assortment.assortmentId,
                      label: assortment.assortmentName
                    }
                  : undefined
              }
            />
          </Label>
          <Label>
            <LabelText>
              {str('Order.modal.label.orderName')}
              <MandatoryMark />
            </LabelText>
            <Input
              placeholder={str('Order.modal.label.orderNamePlaceholder')}
              value={displayName}
              disabled={isReadOnly}
              onChange={e => setDisplayName(e.target.value)}
            />
          </Label>
          <Label>
            <LabelText>{str('Order.modal.label.poNumber')}</LabelText>
            <Input
              placeholder={str('Order.modal.label.poNumberPlaceholder')}
              value={poNumber}
              disabled={isReadOnly}
              onChange={e => setPoNumber(e.target.value)}
            />
          </Label>
          <Label>
            <LabelText>
              {str('Order.modal.label.locations')}
              <MandatoryMark />
            </LabelText>
            <StyledSelect
              isDisabled={isReadOnly}
              options={shipToOptions}
              value={selectedShipToOptions}
              onChange={(options: any) => setSelectedShipToIds(options.map((option: any) => option.value))}
              placeholder={str('Order.modal.label.locationsPlaceholder')}
              maxMenuHeight={240}
              menuPlacement="top"
              isMulti
            />
          </Label>
          {selectedShipToOptions?.length > 1 && !isEditing && (
            <CheckboxLabel>
              <Checkbox checked={orderPerLocation} onChange={e => setOrderPerLocation(e)} />
              <span>{str('Order.modal.label.orderForEachLocation')}</span>
            </CheckboxLabel>
          )}
          {billToOptions.length === 1 && (
            <Label>
              {str('Order.modal.label.billToLocations')}
              <Address>{billToOptions[0].label}</Address>
            </Label>
          )}
          {billToOptions.length > 1 && (
            <Label>
              <LabelText>
                {str('Order.modal.label.billToLocations')}
                <MandatoryMark />
              </LabelText>
              <StyledSelect
                isDisabled={isReadOnly}
                options={billToOptions}
                value={selectedBillToOption}
                onChange={(option: any) => setSelectedBillToId(option.value)}
                maxMenuHeight={240}
                isMulti={false}
                menuPlacement="top"
              />
            </Label>
          )}
          {soldToOptions.length === 1 && (
            <Label>
              {str('Order.modal.label.soldToLocations')}
              <Address>{locationDisplayName(soldToOptions[0].original)}</Address>
            </Label>
          )}
          {soldToOptions.length > 1 && (
            <Label>
              <LabelText>
                {str('Order.modal.label.soldToLocations')}
                <MandatoryMark />
              </LabelText>
              <StyledSelect
                isDisabled={isReadOnly}
                options={soldToOptions}
                value={selectedSoldToOption}
                onChange={(option: any) => setSelectedSoldToId(option.value)}
                maxMenuHeight={240}
                isMulti={false}
                menuPlacement="top"
              />
            </Label>
          )}
          {(!shipToLocations?.length || !billToLocations?.length || !shipToLocations?.length) && (
            <DisplayedError>
              <InfoIcon />
              {str('Order.modal.missingLocationData')}
            </DisplayedError>
          )}
        </Content>
      }
    />
  );
};

const locationDisplayName = <T extends { id: string; name: string; postalCode: string; city: string }>(l: T) => {
  return [l.name, l.postalCode, l.city, `(id: ${l.id})`].filter(Boolean).join(' | ');
};

const LabelText = styled.span``;

const MandatoryMark = styled.span`
  color: ${themed('color.red')};
  display: inline-flex;
  align-items: center;
  margin-left: ${themed('spacing.xs')};

  &::before {
    content: '*';
  }
`;

const InfoIcon = styled(Info)`
  width: ${rem(16)};
  height: ${rem(16)};
`;
