import type { Nullable } from '@yourxx/support';

import type { OrderSizingClipboard, SizingClipboardValue } from './OrderSizingClipboard';
import type { OrderSizingManager } from './OrderSizingManager';
import { type OrderSizingContext, type SizingAdjustment, SizingMonths } from './OrdersService';

export const createOrderSizingClipboard = ({
  manager,
  onCopied,
  onPasted
}: {
  manager: OrderSizingManager;
  onCopied?: (context: OrderSizingContext) => void;
  onPasted?: (context: OrderSizingContext) => void;
}): OrderSizingClipboard => {
  let value: Nullable<SizingClipboardValue> = null;

  return {
    canCopy: sizing => !sizing.isEmpty(),

    copy(sizing, specificMonth) {
      if (sizing.isEmpty()) return;

      value = {
        from: { locationId: sizing.locationId, orderId: sizing.orderId, product: sizing.product },
        adjustments: sizing.toAdjustments(specificMonth)
      };

      onCopied?.(sizing);
    },

    canPaste: () => Boolean(value),

    paste: async (target, ...targetMonths) => {
      if (!value) return;

      const adjustments = targetMonths.length
        ? targetMonths.flatMap(targetMonth => {
            // There is a value, since the check above, but Typescript...
            return (
              value?.adjustments.map<SizingAdjustment>(([_, size, value]) => [
                SizingMonths(targetMonth),
                size,
                value
              ]) ?? []
            );
          })
        : value.adjustments;

      return manager.size({ ...target, isCopied: true, adjustments }).then(hasUpdated => {
        if (hasUpdated) onPasted?.(target);
      });
    },

    get value() {
      return value;
    }
  };
};
