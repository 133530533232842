import { type EntityContext, ProductsFilterSet } from 'domain-events';
import { useCallback, useEffect, useRef } from 'react';
import { type EventBus } from 'services';

import { useMachine } from '@xstate/react';
import { type AnyFilter, Defect, type Nullable } from '@yourxx/support';

import { DefaultFilterCombination } from '../DefaultFilterCombination';
import { FilterCombination } from '../FilterCombination';
import { FiltersOrder, FiltersService } from '../FiltersService';
import { DefaultFilterCombinationRepository } from './DefaultFilterCombinationRepository';
import { getDefaultProductFilters } from './getDefaultProductFilters';
import { createStateMachine } from './stateMachine';
import { Action, State } from './types';
import { notNull } from './utils';

export interface UseProductFiltersProps {
  service: FiltersService;
  eventBus: EventBus;
  context?: EntityContext;
  onFilterSet?: (filter: AnyFilter, activeCombination: FilterCombination) => AnyFilter;
  defaultFilterCombination?: DefaultFilterCombination;
}

/**
 * We're storing Products-related Default Filter Combinations (DFCs, in-memory combination, so not the persisted ones)
 * in this repository and keying them by the given context id (e.g. assortment or line id). If, in the current user's
 * session, there is no DFC stored, one will be created for the given context id and provided ready for use.
 */
const productsDFCRepository = DefaultFilterCombinationRepository(getDefaultProductFilters);

const identity = (filter: AnyFilter) => filter;

/**
 * TODO:
 *   - Add more robust FilterCombination name validation (most likely delegating to the service)
 */
export const useFiltersState = ({
  service,
  eventBus,
  context,
  onFilterSet = identity,
  defaultFilterCombination = productsDFCRepository.getById(context ? context.id : 'global')
}: UseProductFiltersProps): UseProductFiltersReturn => {
  const stateMachine = useRef(createStateMachine({ defaultFilterCombination }));
  const [state, send] = useMachine(stateMachine.current, {
    services: {
      async loadPersistedData(context) {
        if (!context.pendingCommand) {
          // Initial load
          const [filtersOrder, defaultFiltersOrder, filterCombinations] = await Promise.all([
            service.loadFiltersOrder(),
            service.defaultFiltersOrder(),
            service.loadFilterCombinations()
          ]);
          return { filtersOrder, defaultFiltersOrder, filterCombinations };
        } else if (context.pendingCommand?.type === 'reorder')
          return { filtersOrder: await service.loadFiltersOrder() };
        return { filterCombinations: await service.loadFilterCombinations() };
      },

      handleCommand(context, event) {
        if (event.type !== Action.Confirm && event.type !== Action.Set)
          throw new Defect(`service called with wrong event type ${event.type}`);

        if (!context.pendingCommand) throw new Defect('expected to have a pending command');
        return service.handle(context.pendingCommand);
      }
    },
    actions: {
      onSet: (_context, event) => {
        if (event.type === Action.Set && context)
          for (const filter of event.command.filters) eventBus.emit(new ProductsFilterSet({ context, filter }));
      }
    }
  });

  /**
   * Due to the async nature of the app, a context might not be available immediately, in which case when we do get
   * a context, we want to make sure we're updating the DFC in the state machine, so that all subsequent edits are
   * performed against the correct object instance.
   */
  useEffect(() => {
    send({ type: Action.UpdateDefaultFilterCombination, item: defaultFilterCombination });
  }, [defaultFilterCombination, send]);

  const { filterCombinations, activeCombination, defaultFiltersOrder, filtersOrder, error, pendingCommand } =
    state.context;

  const selectedCombination = activeCombination.base;

  const retry = useCallback(() => send({ type: Action.Retry }), [send]);

  const save = useCallback(() => {
    const previousOrNewSaveCommand =
      state.context.pendingCommand?.type === 'save'
        ? state.context.pendingCommand
        : { type: 'save' as const, name: '', filters: activeCombination.filters };

    return send({ type: Action.Save, command: previousOrNewSaveCommand });
  }, [activeCombination.filters, send, state.context.pendingCommand]);

  const rename = useCallback(
    (item: FilterCombination) => send({ type: Action.Rename, command: { type: 'rename', item, name: item.name } }),
    [send]
  );

  const inputName = useCallback(
    (name: string) => {
      if (pendingCommand?.type !== 'save' && pendingCommand?.type !== 'rename')
        throw new Defect('Expected save or rename command');

      return send({ type: Action.InputName, command: { ...pendingCommand, name } });
    },
    [pendingCommand, send]
  );

  const cancel = useCallback(() => send({ type: Action.Cancel }), [send]);

  const confirm = useCallback(() => send({ type: Action.Confirm }), [send]);

  const deleteItem = useCallback(
    (item: FilterCombination) => send({ type: Action.Delete, command: { type: 'delete', item } }),
    [send]
  );

  const reorder = useCallback(
    () => send({ type: Action.Reorder, command: { type: 'reorder', order: filtersOrder } }),
    [filtersOrder, send]
  );

  const updateOrder = useCallback(
    (order: FiltersOrder) => send({ type: Action.UpdateOrder, command: { type: 'reorder', order } }),
    [send]
  );

  const reset = useCallback(
    () => send({ type: Action.UpdateOrder, command: { type: 'reorder', order: defaultFiltersOrder } }),
    [defaultFiltersOrder, send]
  );

  const changeActiveCombination = useCallback(
    (item: FilterCombination) => send({ type: Action.ChangeActiveFilter, item }),
    [send]
  );

  const set = useCallback(
    (...filters: AnyFilter[]) =>
      send({
        type: Action.Set,
        command: {
          type: 'set',
          item: activeCombination,
          filters: filters.map(filter => onFilterSet(filter, activeCombination))
        }
      }),
    [activeCombination, onFilterSet, send]
  );

  const clearAll = useCallback(() => {
    activeCombination.resetToDefaultFilters();
    set(...activeCombination.filters);
  }, [activeCombination, set]);

  const canClear = useCallback(
    (filterId: string) => {
      if (!filtersOrder.includes(filterId))
        throw new Defect(`Unknown filter with id ${filterId} was considered for clearing.`);

      return activeCombination.canClear(filterId);
    },
    [activeCombination, filtersOrder]
  );

  const clear = useCallback(
    (filterId: string) => {
      set(...activeCombination.clear(filterId).filters);
    },
    [activeCombination, set]
  );

  const filtersCount = activeCombination.filtersCount;

  if (state.value === State.LoadingPersistedData)
    return {
      state: state.value,
      filterCombinations,
      activeCombination,
      filtersCount,
      selectedCombination,
      filtersOrder
    };

  if (state.value === State.FailedLoadingPersistedData)
    return {
      state: state.value,
      filterCombinations,
      activeCombination,
      filtersCount,
      selectedCombination,
      filtersOrder,
      error: notNull(error),
      retry
    };

  if (state.value === State.RenamingFilterCombination || state.value === State.NewFilterCombination) {
    if (pendingCommand?.type !== 'rename' && pendingCommand?.type !== 'save')
      throw new Defect('Expected rename or save command');

    return {
      state: state.value,
      filterCombinations,
      activeCombination,
      filtersCount,
      selectedCombination,
      filtersOrder,
      name: pendingCommand.name,
      inputName,
      canConfirm: pendingCommand.name.length > 0,
      confirm,
      cancel
    };
  }

  if (state.value === State.PendingDeleteConfirmation) {
    if (pendingCommand?.type !== 'delete') throw new Defect('Expected delete command');

    return {
      state: state.value,
      filterCombinations,
      activeCombination,
      filtersCount,
      selectedCombination,
      filtersOrder,
      toBeDeleted: pendingCommand.item,
      confirm,
      cancel
    };
  }

  if (state.value === State.PersistingChanges)
    return {
      state: state.value,
      filterCombinations,
      activeCombination,
      filtersCount,
      selectedCombination,
      filtersOrder
    };

  if (state.value === State.ReorderingFilters) {
    if (pendingCommand?.type !== 'reorder') throw new Defect(`Expected reorder command`);

    return {
      state: state.value,
      filterCombinations,
      activeCombination,
      filtersCount,
      selectedCombination,
      filtersOrder: pendingCommand.order,
      updateOrder,
      confirm,
      cancel,
      reset,
      hasReset: pendingCommand.order === defaultFiltersOrder
    };
  }

  return {
    state: State.Idle,
    filterCombinations,
    activeCombination,
    filtersCount,
    selectedCombination,
    filtersOrder,
    save,
    delete: deleteItem,
    rename,
    reorder,
    changeActiveCombination,
    set,
    canClear,
    clear,
    canClearAll: !activeCombination.containsOnlyDefaultFilters,
    clearAll
  };
};

export type UseProductFiltersReturn = {
  filterCombinations: ReadonlyArray<FilterCombination>;
  filtersOrder: FiltersOrder;
  activeCombination: Readonly<FilterCombination>;
  filtersCount: number;
  selectedCombination: Nullable<FilterCombination>;
} & (
  | {
      state: State.LoadingPersistedData;
    }
  | {
      state: State.FailedLoadingPersistedData;
      error: Error;
      retry: VoidFunction;
    }
  | {
      state: State.Idle;
      save: VoidFunction;
      delete: (item: FilterCombination) => void;
      rename: (item: FilterCombination) => void;
      reorder: VoidFunction;
      changeActiveCombination: (item: FilterCombination) => void;
      set: (filter: AnyFilter) => void;
      canClear(filterId: string): boolean;
      clear(filterId: string): void;
      canClearAll: boolean;
      clearAll: VoidFunction;
    }
  | {
      state: State.ReorderingFilters;
      updateOrder: (order: FiltersOrder) => void;
      confirm: VoidFunction;
      cancel: VoidFunction;
      reset: VoidFunction;
      hasReset: boolean;
    }
  | {
      state: State.NewFilterCombination;
      name: string;
      inputName: (newName: string) => void;
      canConfirm: boolean;
      confirm: VoidFunction;
      cancel: VoidFunction;
    }
  | {
      state: State.RenamingFilterCombination;
      name: string;
      inputName: (newName: string) => void;
      canConfirm: boolean;
      confirm: VoidFunction;
      cancel: VoidFunction;
    }
  | {
      state: State.PendingDeleteConfirmation;
      toBeDeleted: FilterCombination;
      confirm: VoidFunction;
      cancel: VoidFunction;
    }
  | {
      state: State.PersistingChanges;
    }
);
