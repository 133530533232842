import { ToCase } from '@yourxx/types';

import { isArray, isDate, isNumeric, isObject, isValidDate } from './is';

type ToDateTypes = number | string | Date;
type ToDateParams = { dateFn?: 'toISOString' | 'getTime' };
type ToDateFromArray = ToDateTypes[];
type ToDateFromObject = { [key: string]: ToDateTypes | unknown };
type ToDate = ToDateFromArray | ToDateFromObject | ToDateTypes | unknown;

const dateRegex = /(?<day>\d{1,2})-(?<month>\d{1,2})-(?<year>\d{2,4}).*/gm;

const isHumanDate = (date: string): Date | string => {
  const newDate = new Date(date);
  if (isValidDate(newDate)) {
    return newDate;
  }
  const regexOut = new RegExp(dateRegex).exec(date);
  if (regexOut && regexOut.groups) {
    const { day, month, year } = regexOut.groups;
    const fullYear = year.length === 2 ? `20${year}` : year;
    return new Date(`${fullYear}-${month}-${day}`);
  }
  return date;
};

const formatDate = (date: Date, params?: ToDateParams) => {
  return params?.dateFn ? date[params.dateFn]() : date;
};

export const toDate = (date: unknown, params?: ToDateParams): ToDate => {
  if (typeof date === 'string') {
    date = isHumanDate(date);
  }
  if (isDate(date) || typeof date === 'number' || typeof date === 'string') {
    const newDate = new Date(date);
    if (isValidDate(newDate)) {
      return formatDate(newDate, params);
    }
    return date;
  }
  if (isArray(date)) {
    return date.map(d => toDate(d, params));
  }
  if (isObject(date)) {
    return Object.fromEntries(Object.entries(date).map(([k, v]) => [k, toDate(v, params)]));
  }
  return date;
};

export const toUpperFirst = (str: string) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str);

const toCaseRegex = new RegExp(/((\s|_|-)\w)|([A-Z]){1}/g);
const toUpper = (str: string) => (str.toUpperCase() === str ? str.toLowerCase() : str);
export const toCase = {
  [ToCase.Camel]: (str: string): string => {
    return toUpper(str).replace(toCaseRegex, v => v.slice(-1).toUpperCase());
  },
  [ToCase.Snake]: (str: string): string => {
    return toUpper(str).replace(toCaseRegex, v => `_${v.slice(-1).toLowerCase()}`);
  },
  [ToCase.Kebab]: (str: string): string => {
    return toUpper(str).replace(toCaseRegex, v => `-${v.slice(-1).toLowerCase()}`);
  },
  [ToCase.Space]: (str: string): string => {
    return toUpper(str).replace(toCaseRegex, v => ` ${v.slice(-1).toLowerCase()}`);
  },
  [ToCase.Sentence]: (str: string): string => {
    return toUpperFirst(toCase[ToCase.Space](str).trim());
  },
  [ToCase.UpperFirstSpace]: (str: string): string => {
    return str.replace(new RegExp(/((\s|_|-)\w)|([A-Z]){1}/g), v => ` ${v.slice(-1).toUpperCase()}`).trim();
  }
};

export const toPlanningCd = (input?: string | number): string | undefined => {
  return isNumeric(input) ? `${Number(input)}` : input;
};

export const toLocationId = (input?: string | null) => {
  const newInput = input?.replace(/-.*/, '');
  return isNumeric(newInput) ? parseInt(newInput).toString() : newInput;
};

export const toOrderDeliveryDate = (deliveryDate: string | Date) => {
  if (typeof deliveryDate === 'string') {
    deliveryDate = new Date(deliveryDate);
  }
  return deliveryDate.toISOString().slice(0, 7);
};

export const toTrimSize = (size?: string): string | undefined => {
  size = size?.trim();
  return size === '-' ? undefined : size;
};

export const toSizeKey = (size1?: string, size2?: string): string => {
  const key = [toTrimSize(size1), toTrimSize(size2)].filter(Boolean);
  return key.length ? `#${key.join('#')}` : '#';
};

export const toSfnExecutionName = (customer: string, order: string): string => {
  if (customer.length + order.length > 42) {
    customer = customer.slice(0, 41 - Math.min(order.length, 20));
  }
  return `${customer}_${order}`
    .toLowerCase()
    .trim()
    .replace(/(\s+|_+|-+)/g, '_')
    .replace(/_+/g, '_')
    .replace(/[^0-9a-z_]|(^_)|(_$)/g, '')
    .slice(0, 42);
};
