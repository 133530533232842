import type { FiltersSchema } from '@yourxx/support';

import { deserialiseFilters, type SerialisedFilter } from './Filter';
import { FilterCombination, type FilterCombinationId } from './FilterCombination';
import type { FiltersOrder } from './FiltersService';

export interface Sink {
  items: FilterCombination[];
  filtersOrder: FiltersOrder;
}

interface SerialisedCombination {
  id: FilterCombinationId;
  name: string;
  filters: SerialisedFilter[];
}

export const createLocalStorageSink = (keyPrefix: string, filtersSchema: FiltersSchema): Sink => {
  const combinationsKey = `${keyPrefix}/combinations`;
  const orderingKey = `${keyPrefix}/ordering`;
  let items: FilterCombination[] = [];
  let ordering: FiltersOrder = [];

  try {
    const serialisedItems: SerialisedCombination[] = JSON.parse(localStorage.getItem(combinationsKey) ?? '[]');
    items = deserialiseCombinations(serialisedItems, filtersSchema);

    // Store the updated items back to localStorage
    localStorage.setItem(combinationsKey, JSON.stringify(items));
    ordering = JSON.parse(localStorage.getItem(orderingKey) ?? '[]');
  } catch (error) {
    console.warn('Error loading from localStorage:', error);
  }

  return {
    get items() {
      return items;
    },

    set items(newItems) {
      localStorage.setItem(combinationsKey, JSON.stringify(newItems));
      items = newItems;
    },

    get filtersOrder() {
      return ordering;
    },

    set filtersOrder(newOrdering) {
      localStorage.setItem(orderingKey, JSON.stringify(newOrdering));
      ordering = newOrdering;
    }
  };
};

export const deserialiseCombinations = (
  serialised: ReadonlyArray<SerialisedCombination>,
  filtersSchema: FiltersSchema
): FilterCombination[] => {
  return serialised.map(item => {
    const updatedFilters = item.filters.map(filter => {
      const schemaFilter = filtersSchema.find(f => f.id === filter.id);
      return schemaFilter ? updateFilterToMatchSchema(filter, schemaFilter) : filter;
    });

    return FilterCombination(item.id, item.name, deserialiseFilters(updatedFilters));
  });
};

const updateFilterToMatchSchema = (
  filter: SerialisedFilter,
  schemaFilter: { id: string; type: string }
): SerialisedFilter => {
  if (filter.type === schemaFilter.type) return filter;

  const updatedFilter = { ...filter, type: schemaFilter.type };

  if (schemaFilter.type === 'Multi' && !Array.isArray(updatedFilter.value)) {
    // Convert Single to Multi
    updatedFilter.value = updatedFilter.value !== null ? [updatedFilter.value] : [];
  } else if (schemaFilter.type === 'Single' && Array.isArray(updatedFilter.value)) {
    // Convert Multi to Single
    updatedFilter.value = updatedFilter.value.length > 0 ? updatedFilter.value[0] : null;
  }

  return updatedFilter;
};
