import type { AsyncVoidFunction, Nullable } from '@yourxx/support';
import { CustomerAssortmentProps, CustomerShipTo } from '@yourxx/types';

import type { OrderModel } from './OrderModel';
import type { PC9Availability } from './OrderSizingManager/types';
import type { SizeGrid } from './SizeGrid';

type Season = string;
export type OrderId = string;

export type GetCustomerOrderIds = () => Promise<Record<Season, OrderId[]>>;

export type GetOrdersCommand = { brand: string; customerId: string };
export type LoadOrdersCommand = GetOrdersCommand & { getCustomerOrderIds: GetCustomerOrderIds };
export type LoadOrderCommand = LoadOrdersCommand & { slugOrOrderId: string };

export type CreateOrderCommand = GetOrdersCommand & {
  finalAssortmentId: string;
  finalAssortmentName: string;
  orderName: string;
  soldTo: string;
  billTo?: string;
  poNumber?: string;
  locations: { shipTo: string; displayName: string }[];
};

export interface OrdersService {
  orders(cmd: GetOrdersCommand): OrderModel[];
  loadOrders(cmd: LoadOrdersCommand): Promise<void>;
  loadOrder(cmd: LoadOrderCommand): Promise<OrderModel>;
  createOrder(cmd: CreateOrderCommand): Promise<void>;
}

export enum OrderStatus {
  InProgress = 'InProgress', // TODO: From Figma, but this does not appear to be something supported by the API
  Created = 'Created',
  Failed = 'Failed',
  Submitted = 'Submitted',
  New = 'New' // Used when API returns `null` or `undefined` for status
}

/**
 * @deprecated Remove and rename OrderModel to Order
 */
export interface Order {
  finalAssortment: CustomerAssortmentProps;
  locations: CustomerShipTo[];
  orderId: OrderId;
  orderName: string;
  created: string;
  products: number;
  units: number;
  price: number;
  currency: string;
  status: OrderStatus;
}

export interface OrdersManager {
  orders: OrderModel[];
  loadOrders: AsyncVoidFunction;
  findOrder(slugOrOrderId: string): OrderModel | undefined;
  duplicateOrder(order: OrderModel): Promise<void>;

  // TODO: Extend method types from OrdersService
  createOrder(cmd: {
    finalAssortment: { id: string; name: string };
    displayName: string;
    billTo?: string;
    soldTo: string;
    poNumber?: string;
    locations: { shipTo: string; displayName: string }[];
    orderPerLocation?: boolean;
  }): Promise<void>;

  editOrder(cmd: {
    order: OrderModel;
    update: {
      displayName: string;
      billTo?: string;
      poNumber?: string;
      locations: { shipTo: string; displayName: string }[];
    };
  }): Promise<void>;

  deleteOrders(orders: OrderModel[]): Promise<void>;

  placeOrders(orders: OrderModel[]): Promise<void>;
}

export type PC9 = string;
export type Dimension = string;
export type Units = Nullable<number>;
export const NULL_DIMENSION = 'NULL_DIMENSION';

/**
 * e.g. [202501, 202502, 202505]
 */
// FIXME: Maybe we don't need this strict typing...
export type SizingMonth = { readonly _kind: unique symbol } & number;
export const SizingMonth = (month: number): SizingMonth => month as SizingMonth;

export type SizingMonths = { readonly _kind: unique symbol } & SizingMonth[];
export const SizingMonths = (...ids: number[]): SizingMonths => {
  if (!ids.length) throw new RangeError('SizingMonthIds cannot be empty.');
  return ids.map(SizingMonth).sort() as SizingMonths;
};

/**
 * e.g. ['L28', 'W34] or ['XL']
 */
// FIXME: Maybe we don't need this strict typing...
export type ProductSize = { readonly _kind: unique symbol } & ([string] | [string, string]);

export const ProductSize = (...dimensions: string[]): ProductSize => {
  if (!dimensions.length) throw new RangeError('ProductSize cannot be empty.');
  if (dimensions.length > 2) throw new RangeError('ProductSize cannot have more than 2 dimensions.');

  // TODO: Validate dimensions format?
  return dimensions as ProductSize;
};

export interface OrderSizingContext {
  orderId: string;
  locationId: '*' | string;
  product: { pc9: string; sizeGrid: SizeGrid; availability: Nullable<PC9Availability> };
  isCopied?: boolean;
}

export type SizingAdjustment = [SizingMonths, ProductSize, Units];

export interface OrderSizingCommand extends OrderSizingContext {
  adjustments: readonly SizingAdjustment[];
}
