import { ShowAsyncErrorAndGoBack, ViewTransition } from 'pages';
import { OrderCapture } from 'pages/Landing/Assortments/OrderCapture';
import { useOrders } from 'providers';
import { Suspense, useDeferredValue, useMemo } from 'react';
import { Await, Route, Routes } from 'react-router-dom';
import type { OrderModel, OrdersManager } from 'services';
import { styled } from 'styled-components';
import { parseSeasonParam, useEnsureRouteParams } from 'utils';

import type { CustomerAssortmentProps, CustomerBillTo, CustomerShipTo, CustomerSoldTo } from '@yourxx/types';

import { useSetupCustomerView } from '../../Customer';
import { CustomerOrderRoute } from '../CustomerOrder';
import { CustomerOrdersIndexSkeleton } from './CustomerOrdersIndexSkeleton';

export const CustomerOrdersIndex = (_props: { brand: string; customerId: string }) => (
  <Routes>
    <Route index Component={IndexRoute} />
    <Route path=":orderId/*" Component={CustomerOrderRoute} />
  </Routes>
);

const IndexRoute = () => {
  const [customerId, season] = useEnsureRouteParams('customerId', 'season');
  return <Index customerId={customerId} season={season} />;
};

const Index = ({ customerId, season }: { customerId: string; season: string }) => {
  const { loadCustomerOrders, ...rest } = useOrders();
  const maybeSeason = parseSeasonParam(season);
  useSetupCustomerView({ customerId, season: maybeSeason, currentView: 'orders' });

  // These ensure that when underlying data changes the view is not re-built from scratch causing a messy UX.
  const promise = useMemo(() => loadCustomerOrders(season), [loadCustomerOrders, season]);
  const deferredPromise = useDeferredValue(promise);

  return (
    <Suspense fallback={<ViewTransition children={<CustomerOrdersIndexSkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={deferredPromise}
        children={_ => <ViewTransition children={<View {...rest} />} />}
      />
    </Suspense>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const View = (
  props: OrdersManager & {
    shipToLocations: CustomerShipTo[];
    billToLocations: CustomerBillTo[];
    soldToLocations: CustomerSoldTo[];
    finalAssortments: CustomerAssortmentProps[];
    copyOrderSizing(source: OrderModel, target: OrderModel): Promise<boolean>;
  }
) => (
  <Container>
    <OrderCapture {...props} />
  </Container>
);
