import { useMemo } from 'react';
import { asErrorMessage } from 'utils';

import type { OrderSummaryMap } from '@yourxx/types';
import { createOrderApi, deletesOrderApi, getOrderProduct, getOrderSummaries } from '@yourxx/ui-utils';

import type { EventBus } from '../EventBus';
import {
  CreateOrderFailed,
  OrderCreated,
  OrderCreateRequested,
  OrderDeleted,
  OrdersLoaded,
  OrdersLoadFailed,
  OrdersRequested
} from './events';
import { OrderModel } from './OrderModel';
import { type OrdersService } from './OrdersService';
import { findOrder } from './utils/findOrder';

export interface OrdersServiceProps {
  eventBus: EventBus;
  onLoad?: typeof getOrderSummaries;
  onLoadProducts?: typeof getOrderProduct;
  onCreate?: typeof createOrderApi;
  onDelete?: typeof deletesOrderApi;
}

export const createOrdersService = ({
  eventBus,
  onLoad = getOrderSummaries,
  onLoadProducts = getOrderProduct,
  onCreate = createOrderApi,
  onDelete = deletesOrderApi
}: OrdersServiceProps): OrdersService => {
  type CacheKey = string;
  type Season = string;
  type OrderId = string;
  const pCache: Record<CacheKey, Promise<OrderSummaryMap>> = {};
  const cache: Record<CacheKey, OrderModel[]> = {};
  const orderIdsBySeason: Record<CacheKey, Record<Season, OrderId[]>> = {};

  const keyOf = <T extends { customerId: string }>(cmd: T): CacheKey => cmd.customerId;

  eventBus.on(OrderDeleted, event => {
    const key = keyOf(event.payload);
    cache[key] = cache[key]?.filter(o => o.id !== event.payload.orderId);

    // TODO:
    // orderIdsBySeason[key] = cache[key].filter(id => );
  });

  const service: OrdersService = {
    orders: cmd => cache[keyOf(cmd)] ?? [],

    loadOrder: async cmd => {
      if (!service.orders(cmd).length) {
        await service.loadOrders({
          brand: cmd.brand,
          customerId: cmd.customerId,
          getCustomerOrderIds: cmd.getCustomerOrderIds
        });
      }

      const order = findOrder(cmd.slugOrOrderId, service.orders(cmd));
      if (order) return order;

      throw new ReferenceError(`Order ${cmd.slugOrOrderId} for customer ${cmd.customerId} not found.`);
    },

    loadOrders: async cmd => {
      const { brand, customerId } = cmd;
      const key = keyOf(cmd);

      if (!Object.keys((orderIdsBySeason[key] ??= {})).length) {
        orderIdsBySeason[key] = await cmd.getCustomerOrderIds();
      }

      const orderIds = Object.values(orderIdsBySeason[key]).flatMap(o => o);

      // We can't call the API without any order ids...
      if (!orderIds.length) return;

      const ordersRequested = new OrdersRequested({ brand, customerId, orderIds });

      if (!pCache[key]) {
        pCache[key] = onLoad({ orderIds });
        eventBus.emit(ordersRequested);
      }

      if (!cache[key]) {
        cache[key] = [];
        pCache[key]
          .then(map => {
            for (const details of Object.values(map)) {
              const order = OrderModel({ details, brand, customerId, onLoadProducts, onDelete, eventBus });
              cache[key].push(order);
            }

            const loaded = new OrdersLoaded(ordersRequested.payload);
            eventBus.emit(loaded.trace(ordersRequested));
          })
          .catch(error => {
            const failed = new OrdersLoadFailed({ ...ordersRequested.payload, reason: asErrorMessage(error) });
            eventBus.emit(failed.trace(ordersRequested));

            // Remove failed promise and state from cache
            delete pCache[key];
            delete cache[key];
          });
      }

      return pCache[key].then(() => undefined);
    },

    createOrder: async cmd => {
      const p = onCreate({
        assortmentId: cmd.finalAssortmentId,
        displayName: cmd.orderName,
        locations: cmd.locations,
        billTo: cmd.billTo,
        soldTo: cmd.soldTo,
        poNumber: cmd.poNumber
      });

      const requested = new OrderCreateRequested({
        brand: cmd.brand,
        customerId: cmd.customerId,
        displayName: cmd.orderName,
        finalAssortmentId: cmd.finalAssortmentId,
        finalAssortmentName: cmd.finalAssortmentName
      });

      p.then(details => {
        const { brand, customerId } = cmd;
        const key = keyOf({ customerId });
        const order = OrderModel({ brand, customerId, details, onLoadProducts, eventBus });
        (cache[key] ??= []).push(order);

        const created = new OrderCreated({ ...requested.payload, orderId: order.id, slug: order.slug });
        eventBus.emit(created.trace(requested));
      }).catch(error => {
        const createOrderFailed = new CreateOrderFailed({ ...requested.payload, reason: asErrorMessage(error) });
        eventBus.emit(createOrderFailed.trace(requested));
      });

      return p.then(() => undefined);
    }
  };

  return service;
};

export const useCreateOrdersService = (props: OrdersServiceProps): OrdersService =>
  useMemo(() => createOrdersService(props), [props]);
