import { Defect } from '@yourxx/support';

import type { SizingAdjustment } from '../OrdersService';
import { computeState } from './computeState';
import { SizingComputedState } from './types';

interface HistoryEntry {
  isReverted: boolean;
  adjustments: SizingAdjustment[];
}

const HistoryEntry = (adjustments: SizingAdjustment[]): HistoryEntry => ({ isReverted: false, adjustments });

export interface History {
  entries: readonly HistoryEntry[];
  // The computed state on top of which the history entry's adjustments will be applied
  base: Readonly<SizingComputedState>;
  rebase(newBase: Readonly<SizingComputedState>): void;
  // Every time there's a state change this number will be incremented
  version: number;
  // Returns true if the adjustments have triggered a state change
  add(adjustments: readonly SizingAdjustment[]): boolean;
  willChangeFrom(adjustments: readonly SizingAdjustment[]): boolean;
  // Returns the reverted adjustments, if any
  undo(): readonly SizingAdjustment[] | undefined;
  canUndo: boolean;
  // Returns the previously-reverted adjustments, if any
  redo(): readonly SizingAdjustment[] | undefined;
  canRedo: boolean;
}

/**
 * Stores a history of adjustments done over time and provides a computed state (a fold of all adjustments, which have
 * not been reverted, based on top of the provided initial state).
 */
export const History = (
  // FIXME: Don't make this optional, initial state needs to be provided by either API or service.
  base: SizingComputedState = { version: 0, state: {} },
  entries: HistoryEntry[] = [],
  version = base.version
): Readonly<History> => {
  const history: History = {
    entries,
    // We're cloning here because we want a separate instance, which cannot be modified from outside the History object.
    // This means that adjustments stored here will always be applied on top of this initial state, regardless of
    // what happens with the given initial `base` object.
    base: clone(base),

    rebase(_newBase) {
      // TODO: Add implementation
      // Just like git, here we want to replace the base on top of which these history adjustments are applied.
      // The use case is for when there's new data from the API which may have been added by a different user, or the same user in a different tab.
      // We want that new data to be added to the existing one and then apply history on top of them in the final computed state.
      throw new Defect('Not yet implemented.');
    },

    willChangeFrom: adjustments => {
      // Get current computed state pseudo-checksum
      const currentState = computeState(history);
      const currentChecksum = serialise(currentState);

      // Get new computed state with the new adjustments pseudo-checksum
      const tempEntries = [...entries, HistoryEntry([...adjustments])];
      const newState = computeState(History(history.base, tempEntries, version));
      const newChecksum = serialise(newState);

      return currentChecksum !== newChecksum;
    },

    add: adjustments => {
      if (!history.willChangeFrom(adjustments)) return false;

      // If there are any reverted entries at the end, we're removing them from history before adding new ones
      // This provides better UX when interacting with undo/redo functionality.
      const lastNonRevertedIndex = entries.findLastIndex(entry => !entry.isReverted);
      entries.splice(lastNonRevertedIndex + 1);

      entries.push(HistoryEntry([...adjustments]));
      version++;
      return true;
    },

    undo: () => {
      for (let i = entries.length - 1; i >= 0; i--) {
        const entry = entries[i];
        if (entry.isReverted) continue;
        entry.isReverted = true;
        version++;
        return entry.adjustments;
      }
    },

    get canUndo() {
      for (const entry of entries) if (!entry.isReverted) return true;
      return false;
    },

    redo: () => {
      if (!entries.length) return;

      let redoableEntryIndex = -1;

      for (let i = entries.length - 1; i >= 0; i--) {
        const entry = entries[i];
        if (entry.isReverted) redoableEntryIndex = i;
        else break;
      }

      if (redoableEntryIndex >= 0) {
        const entry = entries[redoableEntryIndex];
        if (!entry.isReverted) return;
        entry.isReverted = false;
        version++;

        return entry.adjustments;
      }
    },

    get canRedo() {
      return entries[entries.length - 1]?.isReverted ?? false;
    },

    get version() {
      return version;
    }
  };

  return history;
};

// Acts as a checksum to easily determine if adjustments cause a change in the state by comparing two strings.
const serialise = ({ state }: SizingComputedState) => {
  let value = '';
  // Sorting is importing here to ensure the string is constructed in the same way regardless of the order of adjustments.
  for (const month of Object.keys(state).sort())
    for (const d1 of Object.keys(state[month]).sort())
      for (const d2 of Object.keys(state[month][d1]).sort()) {
        const v = state[month][d1][d2];
        if (typeof v === 'undefined') continue;
        value += `${month},${d1},${d2},${JSON.stringify(v)}|`;
      }
  return value;
};

const clone = <T>(x: T): T => JSON.parse(JSON.stringify(x));
